import { useEffect, useState } from 'react'
import { Drivers, Storage } from '@ionic/storage'
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'

const storageDbName = 'favurIonicDB'

type UseIonicStorageReturnT = {
  setItem: (key: string, value: string) => Promise<void>
  getItem: (key: string) => Promise<string | undefined>
  removeItem: (key: string) => Promise<void>
  loading: boolean
}

const useIonicStorage = (): UseIonicStorageReturnT => {
  const [db, setDb] = useState<Storage | null>(null)
  useEffect(() => {
    const initDb = async () => {
      const store = new Storage({
        name: storageDbName,
        driverOrder: [CordovaSQLiteDriver._driver, Drivers.LocalStorage],
      })
      const createdDb = await store.create()
      setDb(createdDb)
    }

    initDb()
  }, [])

  const setItem = (key: string, value: string): Promise<void> => {
    if (!db) return new Promise((resolve) => resolve())
    return db.set(key, value)
  }

  const getItem = (key: string): Promise<string | undefined> => {
    if (!db) return new Promise((resolve) => resolve(undefined))
    return db.get(key)
  }

  const removeItem = (key: string): Promise<void> => {
    if (!db) return new Promise((resolve) => resolve())
    return db.remove(key)
  }

  return { setItem, getItem, removeItem, loading: db === null }
}

export default useIonicStorage
