import { QueryStatus } from 'react-query'
import { groupBy, sortBy, uniq } from 'lodash/fp'
import { TFunction } from 'i18next'
import { shiftTypes } from 'constants/shift'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { AUDIENCE_TYPE, CmsContentT, PlannedShiftT, ShiftsByTenantCCT, TenantT } from 'types'
import { shiftText } from 'utils/shifts'
import { TeamplanDayT, TeamplanPersonsCostCenterT, TeamplanPersonsT } from 'types/teamplan'
import { TeamplanDetailT } from 'pages/Shifts/TeamplanDetailPage/types'
import { ShiftsByDateT, ShiftUpdateStatus } from '../types'
import { IEffectiveShiftProps, TeamplanCostCenterT } from './types'
import { sortTeamPlanShifts } from './ShiftCard/TeamsSection/utils'

const prepareAbsences = (absenceList: PlannedShiftT[]) => {
  const groupedByTenant = Object.values(groupBy('personId', absenceList)).map(
    (absenceGroup) => sortBy(['percent', 'from', 'until'], absenceGroup)[0],
  )
  return sortBy(['percent', 'from', 'until'], groupedByTenant)
}

const prepareShifts = (shiftList: PlannedShiftT[]) => {
  const groupedByTenantAndDescription = Object.values(groupBy('personId', shiftList))
    .map((list) => Object.values(groupBy('shortDescription', list)).map((byDesc) => byDesc[0]))
    .flat()
  return sortBy(['personId', 'from', 'until'], groupedByTenantAndDescription)
}

export const prepareShowingShifts = (shiftList: PlannedShiftT[]) => {
  const absences = shiftList.filter((shift) => shift.type === shiftTypes.absence)
  const shifts = shiftList.filter((shift) => shift.type !== shiftTypes.absence)

  return [...prepareShifts(shifts), ...prepareAbsences(absences)]
}

export const getTaskIds = (shifts: ShiftsByDateT[]) => {
  const ids = shifts
    .flatMap((date) =>
      date.tenants.flatMap((tenant) => tenant.shifts.map((shift) => (shift as PlannedShiftT).updateTaskId)),
    )
    .filter((taskId) => Boolean(taskId)) as number[]
  return uniq(ids)
}

export const hasAcknowledgedShiftUpdate = (shift: PlannedShiftT, shitUpdateTasks: TaskT<ShiftUpdateTaskT>[]) => {
  const task: TaskT<ShiftUpdateTaskT> | undefined = shitUpdateTasks.find(
    (updateTask) => `${updateTask.id}` === `${shift.updateTaskId}`,
  )

  return task === undefined || task.status === ShiftUpdateStatus.reviewFrontlineSeen
}

export const getShiftText = (
  t: TFunction,
  { isMultiTenant, shift }: Pick<IEffectiveShiftProps, 'isMultiTenant' | 'shift'>,
) => {
  const shiftTypeLabel = shiftText(t, shift.timeType)

  if (isMultiTenant && shift.type === shiftTypes.absence) {
    return `${t('page.shifts.content.absence')}: ${shiftTypeLabel}`
  }

  return shiftTypeLabel
}

export const getCurrentTeamPlan = (
  loading: boolean,
  teamplanData: TeamplanDayT,
  date: string,
  tenantId: string,
): 'loading' | TeamplanPersonsT[] | undefined => {
  if (loading) return 'loading'
  if (!teamplanData || !teamplanData[date] || !teamplanData[date][tenantId]) return undefined
  return teamplanData[date][tenantId]
}

export const anyTeamplanData = (teamplanData: TeamplanDayT): boolean => {
  const days = Object.values(teamplanData)
  return days.some((day) => {
    const teams = Object.values(day)
    return teams.length > 0
  })
}

export const anyEffectiveShifts = (effectiveShifts: ShiftsByDateT[]): boolean => {
  return effectiveShifts.length > 0
}

type TenantByIdT = {
  [tenantId: string]: TenantT
}

export const getTenantsList = (
  shifts: ShiftsByDateT[],
  teamplanTenants: TenantT[],
  effectiveShifts: ShiftsByDateT[],
): TenantByIdT => {
  const shiftTenants =
    shifts && shifts.length > 0
      ? shifts
          .flatMap((shift) => shift.tenants)
          .map((tenant) => ({ id: tenant.tenantId, name: tenant.tenantName } as TenantT))
      : []
  const effectiveShiftTenants =
    effectiveShifts && effectiveShifts.length > 0
      ? effectiveShifts
          .flatMap((shift) => shift.tenants)
          .map((tenant) => ({ id: tenant.tenantId, name: tenant.tenantName } as TenantT))
      : []
  return [...shiftTenants, ...teamplanTenants, ...effectiveShiftTenants].reduce((acc, tenant) => {
    return acc[tenant.id] !== undefined ? acc : { [tenant.id]: tenant, ...acc }
  }, {} as TenantByIdT)
}

export const sortTenantsListToIds = (tenantList: TenantByIdT, shifts: Map<string, ShiftsByTenantCCT[]>) => {
  const onlyAbsenceTenants = Object.values(tenantList).filter((tenant) => {
    const tenantShifts = shifts.get(tenant.id) ?? []
    return tenantShifts.every((tenantShiftList) =>
      tenantShiftList.shifts.every((shift) => shift.type === shiftTypes.absence),
    )
  })

  const sortedOnlyAbsenceTenantsIds = sortBy('name', onlyAbsenceTenants).map((tenant) => tenant.id)
  const otherTenants = Object.values(tenantList).filter((tenant) => !sortedOnlyAbsenceTenantsIds.includes(tenant.id))
  const sortedOtherTenantIds = sortBy('name', otherTenants).map((tenant) => tenant.id)

  return [...sortedOtherTenantIds, ...sortedOnlyAbsenceTenantsIds]
}

export const groupCostCentersByTenant = (tenants: ShiftsByTenantCCT[]) =>
  tenants.reduce((acc, tenant) => {
    const currentTenants = acc.get(tenant.tenantId)
    if (currentTenants) return acc.set(tenant.tenantId, [...currentTenants, tenant])
    return acc.set(tenant.tenantId, [tenant])
  }, new Map<string, ShiftsByTenantCCT[]>())

export const findEffectiveShiftListByTenant = (
  effectiveShifts: ShiftsByDateT[],
  effectiveShiftStatus: QueryStatus,
  tenantId: string,
) => {
  if (effectiveShiftStatus === 'loading') {
    return undefined
  }

  if (effectiveShifts.length === 0) {
    return []
  }

  return effectiveShifts[0].tenants.filter((tenant) => tenant.tenantId === tenantId)
}

export const getAllCostCenterFromTeamplan = (teamplanData: TeamplanDayT) => {
  return Object.values(teamplanData)
    .flatMap((teamplanDay) => Object.values(teamplanDay))
    .flat()
    .filter((teamplanPerson) => teamplanPerson.type === 'cost_center')
    .map((teamplanPerson) => {
      const tpcc = teamplanPerson as TeamplanPersonsCostCenterT
      return {
        id: tpcc.id,
        name: tpcc.name,
        tenantName: tpcc.tenantName,
      } as TeamplanCostCenterT
    })
}

export const getTeamPlanDetail = (
  loading: boolean,
  teamplan: TeamplanDayT,
  currentDay: string,
  tenantId: string,
  teamId: string,
): TeamplanDetailT => {
  const tenantTeamplan = getCurrentTeamPlan(loading, teamplan, currentDay, tenantId)
  const { attendances, otherAbsences, vacationAbsences } = sortTeamPlanShifts(tenantTeamplan)

  return {
    attendances: attendances[teamId] ?? [],
    otherAbsences: otherAbsences[teamId] ?? [],
    vacationAbsences: vacationAbsences[teamId] ?? [],
  }
}

export const getEventList = ({ events, uuid }: { events: CmsContentT[]; uuid: string }) =>
  events.reduce<CmsContentT[]>((eventsToDisplay, event) => {
    const tenantAudiences = event.audiences
      ?.filter((audience) => audience.audienceType === AUDIENCE_TYPE.tenant)
      .map((audience) => audience.uuid)

    if (!tenantAudiences?.length) {
      return [...eventsToDisplay, event]
    }

    const isTenantIncluded = tenantAudiences.includes(uuid)

    if (isTenantIncluded) {
      return [...eventsToDisplay, event]
    }

    return eventsToDisplay
  }, [])
