import React from 'react'
import { SxProps, TextFieldProps } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Select from 'components/Select'
import { getKebabCaseFromSnakeCase } from 'utils/string'
import { selectClasses } from './styles'

interface IControlledSelectProps {
  name: string
  options: { [key: string]: string }
  adornment?: React.ReactNode
  required?: boolean
  sx?: SxProps
}

const ControlledSelect: React.FC<TextFieldProps & IControlledSelectProps> = ({
  adornment,
  name,
  options,
  sx,
  ...props
}) => {
  const { t } = useFavurTranslation()
  const id = getKebabCaseFromSnakeCase(name)
  const optionEntries = Object.entries(options)

  return (
    <Select
      fullWidth
      id={id}
      InputProps={{
        endAdornment: adornment,
      }}
      data-testid={`controlled-select-${name}`}
      sx={[selectClasses.root, ...(Array.isArray(sx) ? sx : [sx])]}
      {...props}
    >
      <option value="" label="">
        -
      </option>

      {optionEntries.map(([value, label]) => (
        <option value={value} key={value} data-testid={`controlled-select-${name}-${value}`}>
          {t(label)}
        </option>
      ))}
    </Select>
  )
}

export default ControlledSelect
