import React from 'react'
import { Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useSelectedStatesContext from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { getUserName } from 'utils/person'
import { PersonT } from 'types'
import { absenceRequestListClasses as classes } from '../styles'
import SmallScreenPageHeader from '../SmallScreenPageHeader'
import ManagerTasks from '.'

interface IManagerTasksPageProps {
  refetchAll: () => void
  refetchTask: (personId: string, dateFrom: string, dateTo: string) => void
}

const ManagerTasksPage: React.FC<IManagerTasksPageProps> = ({ refetchAll, refetchTask }) => {
  const { t } = useFavurTranslation()
  const { selectedPerson, selectedTaskUuid } = useSelectedStatesContext()
  const userName = selectedPerson
    ? getUserName(selectedPerson as PersonT)
    : t('absencePlanner.taskbar.managerTasks.subtitle')

  return (
    <Box sx={classes.page}>
      {selectedTaskUuid === null && (
        <SmallScreenPageHeader title={t('absencePlanner.taskbar.managerTasks.header')} subtitle={userName} />
      )}
      <ManagerTasks
        hideTitle
        refetchAll={refetchAll}
        refetchTask={(personId: string, dateFrom: string, dateTo: string) => refetchTask(personId, dateFrom, dateTo)}
      />
    </Box>
  )
}

export default ManagerTasksPage
