import React from 'react'

export type ContentTypeT = 'page' | 'event'

export type TabT = {
  id: string | number
  label: React.ReactNode
  disabled?: boolean
}

export type EventTimelineT = 'PAST' | 'UPCOMING'

export enum EventFilterTabs {
  past,
  upcoming,
}

export type FormImageUrl = {
  filename: string
}
