import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { tasksPaginatedQuery } from 'pages/Tasks/queries'
import { MonthlySheetTaskT, TaskT, TasksPaginated } from 'pages/Tasks/types'
import { useMemo, useState } from 'react'
import { newDateWithoutTime } from 'utils/date'
import { addMonths } from 'date-fns'
import { taskTypes } from 'shared/constants'
import { MonthlySheetStatus } from '../types'

interface IUsePastMonthWtc {
  currentMonthTask: TaskT<MonthlySheetTaskT>
}
const usePastMonthWtc = ({ currentMonthTask }: IUsePastMonthWtc) => {
  const [prevMonthTask, setPrevMonthTask] = useState<TaskT<MonthlySheetTaskT>>()
  const {
    person,
    taskData: { startDate },
  } = currentMonthTask

  const prevMonthData = useMemo(() => {
    const date = newDateWithoutTime(startDate)
    const datePrevMonth = addMonths(date, -1)
    return {
      month: datePrevMonth.getMonth(),
      year: datePrevMonth.getFullYear(),
    }
  }, [startDate])

  useJamesApolloQuery<{ tasksPaginated: TasksPaginated }>(tasksPaginatedQuery, {
    variables: {
      office: false,
      filters: {
        // eslint-disable-next-line camelcase
        task_types: [taskTypes.monthlySheet],
        // eslint-disable-next-line camelcase
        person_ids: [person?.id],
        // eslint-disable-next-line camelcase
        internal_task_statuses: [MonthlySheetStatus.markedChangeNextMonth],
      },
    },
    onCompleted(data) {
      const tasks = data.tasksPaginated.list as TaskT<MonthlySheetTaskT>[]
      const filteredTasks = tasks.filter((task) => {
        const taskStartDate = newDateWithoutTime(task.taskData.startDate)
        return taskStartDate.getMonth() === prevMonthData.month && taskStartDate.getFullYear() === prevMonthData.year
      })
      if (filteredTasks.length) setPrevMonthTask(filteredTasks[0])
    },
  })

  return { prevMonthTask }
}

export default usePastMonthWtc
