import { useState, useCallback } from 'react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { defaultPhoneNumberCountryCode } from 'constants/i18n'

export type FormPhoneInputT = {
  countryCode: string
  phoneNumber: string
  isValidNumber: boolean
}

const usePhoneNumber = (sessionPhone: string) => {
  const processPhoneNumber = (unprocessedPhoneNumber: string | null) => {
    if (!unprocessedPhoneNumber) {
      return {
        countryCode: '',
        phoneNumber: '',
        isValidNumber: false,
      }
    }

    const parsedPhoneNumber =
      unprocessedPhoneNumber.length > 3
        ? parsePhoneNumberFromString(unprocessedPhoneNumber, defaultPhoneNumberCountryCode)
        : undefined
    if (parsedPhoneNumber) {
      const { countryCallingCode, nationalNumber } = parsedPhoneNumber
      const isValidNumber = parsedPhoneNumber.isValid()
      return {
        countryCode: countryCallingCode as string,
        phoneNumber: nationalNumber as string,
        isValidNumber,
      }
    }
    return {
      countryCode: '',
      phoneNumber: unprocessedPhoneNumber,
      isValidNumber: false,
    }
  }

  const [phone, setPhone] = useState(processPhoneNumber(sessionPhone))

  const updatePhoneNumber = useCallback(
    (number: string) => {
      const phoneNumber = number.match(/^(\+|00)/) || !phone.countryCode ? number : `+${phone.countryCode}${number}`
      setPhone(processPhoneNumber(phoneNumber))
    },
    [phone.countryCode],
  )

  const getFullPhoneNumber = (middleSpace = true) =>
    `+${phone.countryCode + (middleSpace ? ' ' : '')}${phone.phoneNumber}`

  return { updatePhoneNumber, phone, setPhone, getFullPhoneNumber }
}

export default usePhoneNumber
