import React from 'react'
import { Grid, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { dateInstanceToFavurDate, newDateWithoutTime } from 'utils/date'
import { DivisionLine } from 'components/Basics'
import { ROLE_VIEWS } from 'constants/roles'
import useRolesViews from 'hooks/useRolesViews'
import { taskTypes } from 'shared/constants'
import Card from 'components/Basics/Card'
import classes from '../styles'
import {
  AbsenceRequestTaskT,
  MonthlySheetTaskT,
  ShareUserDataTaskT,
  ShiftUpdateTaskT,
  TaskCardDataT,
  TaskDataT,
  TaskT,
} from '../types'
import WtcMonthlySheetBody from './WtcMonthlySheetBody'
import ShareUserDataBody from './ShareUserDataBody'
import AbsenceRequestBody from './AbsenceRequestBody'
import ShiftUpdateBody from './ShiftUpdateBody'
import useSendManualReminder from '../useSendManualReminder'

const datePattern = `yyyy-MM-dd HH:mm:ss.SSSSSS'Z'`

const TaskCard: React.FC<TaskCardDataT<TaskDataT>> = ({
  office,
  task,
  onDelete,
  onSentReminderSuccess,
  onClickDetails,
}) => {
  const { t } = useFavurTranslation()
  const taskDate = dateInstanceToFavurDate(newDateWithoutTime(task.updatedAt, datePattern))
  const { activeView } = useRolesViews()
  const isOfficeView = activeView === ROLE_VIEWS.office

  const { sendManualReminder } = useSendManualReminder(task, onSentReminderSuccess)

  return (
    <Card marginBottom="20px" data-testid="task-card">
      <Grid container spacing={0} sx={classes.cardTopGrid}>
        <Grid item xs={5}>
          <Typography align="left" sx={classes.taskCardDataText}>
            {t(`filter.task_type.${task.taskType}`)}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography align="center" sx={classes.taskCardDataText}>
            {t(`tasks.tab.${task.statusFe}`)}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography align="right" sx={classes.taskCardDataText}>
            {taskDate}
          </Typography>
        </Grid>
      </Grid>
      <DivisionLine />
      <>
        {task.taskType === taskTypes.monthlySheet && (
          <WtcMonthlySheetBody task={task as TaskT<MonthlySheetTaskT>} office={office} />
        )}
        {task.taskType === taskTypes.shareUserData && (
          <ShareUserDataBody task={task as TaskT<ShareUserDataTaskT>} office={office} />
        )}
        {task.taskType === taskTypes.absenceRequest && (
          <AbsenceRequestBody
            task={task as TaskT<AbsenceRequestTaskT>}
            office={office}
            onClickDetails={onClickDetails}
          />
        )}
        {task.taskType === taskTypes.shiftUpdate && (
          <ShiftUpdateBody
            task={task as TaskT<ShiftUpdateTaskT>}
            office={office}
            onDelete={onDelete}
            sendManualReminder={isOfficeView ? sendManualReminder : undefined}
          />
        )}
      </>
    </Card>
  )
}

export default TaskCard
