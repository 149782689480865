import React from 'react'
import { useHistory } from 'react-router'

import useFavurTranslation from 'hooks/useFavurTranslation'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import routes from 'services/RoutesProvider/routes'
import palette from 'utils/theme/palette'
import { DeleteTrashRemoveM } from 'icons'

const DeleteUser: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <UserSettingsSectionCTA
      title={t('page.userSettings.profile.settingsSection.deleteUserAccount.title')}
      onClick={() => {
        history.push(routes.profileDeactivateAccount)
      }}
      icon={<DeleteTrashRemoveM fill={palette.primary.main} />}
    />
  )
}

export default DeleteUser
