import React, { useState } from 'react'
import { AlertColor, AlertTitle, IconButton, Alert as MuiAlert } from '@mui/material'
import { AlertErrorS, AlertInfoS, AlertSuccessS, AlertWarningS, ArrowRightThinS, CloseS } from 'icons'
import palette from 'utils/theme/palette'
import { classes } from './styles'

interface IAlertProps {
  severity: AlertColor
  content: string
  title?: string
  onClick?: () => void
  dismissable?: boolean
}

const Alert: React.FC<IAlertProps> = ({ severity, content, dismissable = false, title, onClick }) => {
  const [show, setShow] = useState(true)
  const onClose = () => setShow(false)

  if (!show) return null

  return (
    <MuiAlert
      severity={severity}
      sx={classes.alert}
      iconMapping={{
        success: <AlertSuccessS />,
        info: <AlertInfoS />,
        error: <AlertErrorS />,
        warning: <AlertWarningS />,
      }}
      onClose={dismissable ? onClose : undefined}
      slots={{
        closeIcon: () => <CloseS fill={palette.alert.info.text} />,
      }}
      action={
        onClick ? (
          <IconButton onClick={onClick} disableFocusRipple disableRipple disableTouchRipple>
            <ArrowRightThinS />
          </IconButton>
        ) : undefined
      }
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {content}
    </MuiAlert>
  )
}

export default Alert
