import React, { useEffect, useState } from 'react'
import { isRoleViewValid, ROLE_VIEWS } from 'constants/roles'
import { FF_VIEW_ROLE } from 'constants/featureFlags'
import useSessionContext from 'hooks/useSessionContext'
import useFeatureFlag from 'hooks/useFeatureFlag'
import OfficeViewContext from './context'
import type { RoleViewT } from 'types'

interface IOfficeViewProviderProps {
  children?: React.ReactNode
}

const OfficeViewService: React.FC<IOfficeViewProviderProps> = ({ children }) => {
  const { persons } = useSessionContext()
  const roleViewFF = useFeatureFlag(FF_VIEW_ROLE)
  const getDefaultView = (): RoleViewT => {
    if (isRoleViewValid(roleViewFF)) {
      return roleViewFF as RoleViewT
    }

    return ROLE_VIEWS.frontliner
  }
  const [activeView, setActiveView] = useState<RoleViewT>(getDefaultView())

  useEffect(() => {
    if (!isRoleViewValid(roleViewFF) && persons !== undefined) {
      setActiveView(ROLE_VIEWS.frontliner)
    }
  }, [persons, roleViewFF])

  return <OfficeViewContext.Provider value={{ activeView, setActiveView }}>{children}</OfficeViewContext.Provider>
}

export default OfficeViewService
