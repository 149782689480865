import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import useFavurTranslation from 'hooks/useFavurTranslation'
import ActionButtons from 'components/Buttons/ActionButtons'
import CheckList from './CheckList'
import DateRange from './DateRange'
import { FilterType } from '../types'
import useFilterContext from '../context'
import { classes } from '../styles'
import Persons from './Persons'

interface IFilterPageProps {
  headerTitle: string
}

const FilterPage: React.FC<IFilterPageProps> = ({ headerTitle }) => {
  const { t } = useFavurTranslation()
  const {
    closeFilterPage,
    getConfiguration,
    resetFilterPage,
    submitFilterPage,
    validateFilters,
    getScrollTo,
    setScrollTo,
  } = useFilterContext()

  useEffect(() => {
    const scrollDestination = getScrollTo()
    if (!scrollDestination) return undefined
    const elem = document.querySelector(`#${scrollDestination}`)
    if (elem === null) {
      setScrollTo(undefined)
      return undefined
    }
    const timer = setTimeout(() => elem.scrollIntoView({ behavior: 'auto', block: 'start' }), 100)

    return () => {
      clearTimeout(timer)
    }
  }, [getScrollTo, setScrollTo])

  return (
    <Page
      header={
        <WithBackwardLink
          closeIcon
          title={t('filter.title.header')}
          topTitle={headerTitle}
          multipleLines={false}
          onClick={closeFilterPage}
        />
      }
      hideNativeNavigation
      hasFloatingButtons
    >
      <Box sx={classes.filterContainer}>
        <Box>
          {getConfiguration().map((config) => {
            switch (config.type) {
              case FilterType.MULTIPLE_CHOICE:
                return <CheckList key={config.name} configuration={config} />
              case FilterType.DATE_RANGE:
                return <DateRange key={config.name} configuration={config} />
              case FilterType.PERSONS:
                return <Persons key={config.name} configuration={config} />
              default:
                return <></>
            }
          })}
        </Box>
        <ActionButtons
          cancelAction={resetFilterPage}
          cancelLabel={t('filter.button.reset')}
          validateLabel={t('filter.button.view')}
          validateAction={submitFilterPage}
          isValidateButtonActive={validateFilters()}
          isFloating
          noNavigation
        />
      </Box>
    </Page>
  )
}

export default FilterPage
