import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import useSessionContext from 'hooks/useSessionContext'
import useJamesQuery from 'hooks/useJamesQuery'
import usePinContext from 'hooks/usePinContext'
import useJamesMutation from 'hooks/useJamesMutation'
import useFavurTranslation from 'hooks/useFavurTranslation'
import CenteredBox from 'components/CenteredBox'
import Numpad from 'components/Numpad'
import Select from 'components/Select'
import routes from 'services/RoutesProvider/routes'
import { SecureConnectionResultT, TenantNamesT } from 'types'
import { TOKEN_LENGTH, TOKEN_STATUSES } from 'constants/highSecurityConnection'
import AssetCheckmark from 'icons/AssetCheckmarkSvg'
import Snackbar from 'components/Snackbar'
import { PersonData } from '../../types'
import { classes as securityClasses } from '../../styles'
import { inputClasses, tokenClasses as classes } from './styles'

const Token: React.FC<{ goToStep: (stepName: string) => void }> = ({ goToStep }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { personIds } = useSessionContext()
  const { setPinContext } = usePinContext()
  const [token, setToken] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [errorText, setErrorText] = useState<{
    title: string | undefined
    message: string
  }>({
    title: t('securitySetup.token.flash.title'),
    message: t('securitySetup.token.flash.text'),
  })
  const [personData, setPersonData] = useState<PersonData>({ personId: '', tenantName: '' })
  const [dropdownError, setDropdownError] = useState(false)
  const [nextPage, setNextPage] = useState('')
  const { data: tenantNames, status } = useJamesQuery<{ lowSecurityTenantNames: TenantNamesT[] }>(
    'low_security_tenant_names',
    `query{
      lowSecurityTenantNames {
        tenantName,
        personId
      }
    }`,
  )
  const [establishSecureConnection] = useJamesMutation(
    `establishSecureConnection(personId: #personId, token: "#token"){
      status,
      validUntil,
      tenantName,
      errorCode
    }`,
  )
  const dropdownFilled = useCallback(() => {
    if (personData.personId === '') {
      setDropdownError(true)
      return false
    }
    setDropdownError(false)
    return true
  }, [setDropdownError, personData.personId])
  const startMutation = useCallback(
    (personId: number) => {
      establishSecureConnection({ personId, token }).then(
        (res: { establishSecureConnection: SecureConnectionResultT }) => {
          if (res?.establishSecureConnection.status === TOKEN_STATUSES.successGoToPin) {
            setPersonData({ personId, tenantName: res?.establishSecureConnection.tenantName })
            setOpenError(false)
            setNextPage('pin')
            setPinContext({ securitySetupTokenConfirmed: true })
            setShowSuccess(true)
          }
          if (res?.establishSecureConnection.status === TOKEN_STATUSES.connectionEstablished) {
            setPersonData({ personId, tenantName: res?.establishSecureConnection.tenantName })
            setOpenError(false)
            setNextPage('dashboard')
            setShowSuccess(true)
          }
          if (res?.establishSecureConnection.status === TOKEN_STATUSES.error) {
            if (res?.establishSecureConnection.errorCode) {
              setErrorText({
                title: undefined,
                message: t(res?.establishSecureConnection.errorCode),
              })
            } else {
              setErrorText({
                title: t('securitySetup.token.flash.title'),
                message: t('securitySetup.token.flash.text'),
              })
            }
            setOpenError(true)
          }
        },
      )
    },
    [establishSecureConnection, setOpenError, setShowSuccess, setPersonData, token, setPinContext, setErrorText, t],
  )
  const verifyToken = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      if (token.length !== TOKEN_LENGTH) {
        setErrorText({
          title: t('securitySetup.token.flashLength.title'),
          message: t('securitySetup.token.flashLength.text'),
        })
        setOpenError(true)
        return null
      }

      if (personIds?.length === 1) {
        startMutation(personIds[0])
      } else {
        dropdownFilled() && startMutation(personData.personId as number)
      }
      return true
    },
    [startMutation, dropdownFilled, t, personIds, personData, token.length],
  )

  useEffect(() => {
    if (!showSuccess) return undefined

    const successTimeout = setTimeout(() => {
      if (nextPage === 'pin') {
        goToStep('PIN_INFO')
      }
      if (nextPage === 'dashboard') {
        history.push(routes.dashboard)
      }
    }, 2100)
    return () => {
      clearTimeout(successTimeout)
    }
  }, [showSuccess, nextPage, history, goToStep])

  useEffect(() => {
    if (tenantNames?.lowSecurityTenantNames?.length === 1) {
      setPersonData({
        personId: tenantNames?.lowSecurityTenantNames?.[0].personId,
        tenantName: tenantNames?.lowSecurityTenantNames?.[0].tenantName,
      })
    }
  }, [tenantNames])

  if (status !== 'success' || !tenantNames?.lowSecurityTenantNames) {
    return null
  }

  const handleChange = (value: string) => {
    if (value === '-1' || value === '') {
      setPersonData({ personId: '', tenantName: '' })
      return
    }
    const pair = tenantNames.lowSecurityTenantNames.find((e: PersonData) => `${e.personId}` === `${value}`)
    if (!pair) {
      setPersonData({ personId: '', tenantName: '' })
      return
    }
    setPersonData({ personId: pair.personId, tenantName: pair.tenantName })
  }

  return (
    <Box component="form" onSubmit={verifyToken} sx={securityClasses.root}>
      <CenteredBox sx={[securityClasses.root, ...(!showSuccess ? [securityClasses.spaceBetween] : [])]}>
        <Box>
          <Typography variant="h2" sx={securityClasses.title}>
            {t(showSuccess ? 'securitySetup.token.content.titleSuccess' : 'securitySetup.token.content.title')}
          </Typography>
        </Box>
        <Snackbar open={openError} setOpen={setOpenError} flashText={errorText} />
        {!showSuccess && (
          <>
            {tenantNames.lowSecurityTenantNames.length > 1 && (
              <Select
                sx={securityClasses.dropdown}
                label={t('securitySetup.token.select.label')}
                value={personData.personId}
                error={dropdownError}
                onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                  handleChange(event.target.value as string)
                }}
              >
                <option value="-1" />
                {tenantNames.lowSecurityTenantNames.map((option: PersonData) => (
                  <option key={option.personId} value={option.personId}>
                    {option.tenantName}
                  </option>
                ))}
              </Select>
            )}
            <Box sx={classes.token}>
              <Typography variant="caption">{t('securitySetup.token.content.inputLabel')}</Typography>
              <Box sx={classes.inputContainer} data-testid="token">
                <Typography variant="h3" sx={inputClasses.input}>
                  {token}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={securityClasses.numpad}>
                <Numpad
                  outputLength={6}
                  onNumpadValueChange={(string) => setToken(string)}
                  numpadType="pin"
                  onConfirm={() => {}}
                />
              </Box>
              <Button
                size="large"
                onClick={() => goToStep('INTRO')}
                sx={[securityClasses.cancelButton, classes.cancelButton]}
                data-testid="security-setup-cancel-token"
              >
                {t('securitySetup.token.button.cancel')}
              </Button>
            </Box>
          </>
        )}
        {showSuccess && (
          <>
            <Box sx={securityClasses.checkmark}>
              <AssetCheckmark />
            </Box>
          </>
        )}
      </CenteredBox>
    </Box>
  )
}

export default Token
