import { useCallback, useEffect, useState } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useSessionContext from 'hooks/useSessionContext'
import { CmsContentT, CmsPaginatedContentT } from 'types'
import { ContentTypeT, EventTimelineT } from './types'
import { listPaginatedcontents } from './queries'
import { DEFAULT_PAGE_SIZE } from './constants'

type PaginatedContentsProps = {
  contentType: ContentTypeT
  pageSize?: number
  eventTimelineFilter?: EventTimelineT
  date?: string
}

const appendContent = (newEntries: CmsContentT[], currentEntries: CmsContentT[]) => {
  return newEntries.reduce((acc, newContent) => {
    const existingContent = acc.find((content) => content.uuid === newContent.uuid)
    if (!existingContent) return [...acc, newContent]
    return acc
  }, currentEntries)
}

const useListCmsPaginatedContents = ({
  contentType,
  pageSize = DEFAULT_PAGE_SIZE,
  eventTimelineFilter,
  date,
}: PaginatedContentsProps) => {
  const { cmsActivated } = useSessionContext()
  const [page, setPage] = useState(1)
  const [contents, setContents] = useState<CmsContentT[]>([])
  const { data: contentData, loading: loading, error: error } = useJamesApolloQuery<{
    cmsPaginatedContentList: CmsPaginatedContentT
  }>(listPaginatedcontents, {
    variables: {
      contentType: contentType,
      page: page,
      pageSize: pageSize,
      eventTimeline: eventTimelineFilter,
      date: date,
    },
    fetchPolicy: 'cache-and-network',
    skip: !cmsActivated,
  })

  const loadMore = useCallback(() => {
    setPage(page + 1)
  }, [page])

  useEffect(() => {
    const entries = contentData?.cmsPaginatedContentList.entries
    if (!loading && entries) {
      const updatedContentsList = appendContent(entries, contents)
      setContents(updatedContentsList)
    }
  }, [contentData, contents, loading])

  const totalCount = contentData?.cmsPaginatedContentList.totalEntries || 0

  const hasMoreElements = Boolean(totalCount && contents && totalCount > contents.length)

  return {
    list: contents,
    loadMore,
    hasMoreElements,
    totalEntries: totalCount,
    loading,
    error,
  }
}

export default useListCmsPaginatedContents
