import React from 'react'
import { Box } from '@mui/material'
import { ShiftTimeOfDayT, shiftTimesOfDay } from 'types'
import { AbsenceFullS, AbsenceHalfAfternoonS, AbsenceHalfMorningS } from 'icons'
import { badgeClasses } from '../styles'
import { IBadgeProps } from '../types'

const getAbsenceIcon = (timeOfDay: ShiftTimeOfDayT | undefined) => {
  switch (timeOfDay) {
    case shiftTimesOfDay.am:
      return <AbsenceHalfMorningS />
    case shiftTimesOfDay.pm:
      return <AbsenceHalfAfternoonS />
    default:
      return <AbsenceFullS />
  }
}

const AbsenceBadge: React.FC<IBadgeProps> = ({ badge, dataTestId }) => {
  const { timeOfDay } = badge

  return (
    <Box sx={[badgeClasses.absenceBadge, badgeClasses.badgeFullWidth]} data-testid={dataTestId}>
      {getAbsenceIcon(timeOfDay)}
    </Box>
  )
}

export default AbsenceBadge
