import React, { memo, useCallback, useEffect, useState } from 'react'
import { Box, Button, ClickAwayListener, Tooltip, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PersonT } from 'types'
import { ArrowRightThinS } from 'icons'
import { getAvatarFromPerson } from 'pages/Tasks/utils/timeline'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import useAvatarSidebarStateContext from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import { tooltipClasses } from 'components/Badge/styles'
import { avatarClasses } from './styles'
import AbsencePlannerAvatarIcon from '../AvatarIcon'
import { taskBarStates } from '../TaskBar/types'
import { scrollerDivId } from '../Grid'

interface IAbsencePlannerAvatarProps {
  name: string
  tasks?: number
  showBorder?: boolean
  selected?: boolean
  person: PersonT
  openUserTasks?: boolean
}

const AbsencePlannerAvatar: React.FC<IAbsencePlannerAvatarProps> = ({
  name,
  tasks,
  showBorder,
  selected,
  person,
  openUserTasks = false,
}) => {
  const { t } = useFavurTranslation()
  const avatarIconData = getAvatarFromPerson(person, t, false)
  const { deselectPerson, selectNewPerson } = useSelectedStatesActionsContext()
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const setTaskBarState = useTaskBarStateDispatchContext()
  const { avatarBarExpanded: isExpanded } = useAvatarSidebarStateContext()
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const onClick = useCallback(
    (avatarPerson: PersonT, personTaskCount: number, isSelected: boolean) => {
      // Deselecting takes priority. Does nothing but deselecting
      if (isSelected) {
        deselectPerson()
        return
      }

      // Small screen
      if (isSmallScreen) {
        if (!isExpanded) {
          setTooltipOpen((value) => !value)
        } else {
          if (personTaskCount > 0) {
            selectNewPerson(avatarPerson)
            setTaskBarState(openUserTasks ? taskBarStates.userTasks : taskBarStates.managerTasks)
          }
        }

        return
      }

      // Big screen, allows selections
      selectNewPerson(avatarPerson)
      if (personTaskCount > 0) {
        setTaskBarState(openUserTasks ? taskBarStates.userTasks : taskBarStates.managerTasks)
      }
    },
    [isSmallScreen, selectNewPerson, deselectPerson, isExpanded, setTaskBarState, openUserTasks],
  )

  //This solution covers both touch scroll and mouse wheel scroll.
  //If we only want to do this with touch scroll, it's way easier,
  //because the ClickAwayListener component has an onTouch prop that we can use there.
  useEffect(() => {
    if (!isSmallScreen || !tooltipOpen) return undefined
    const scroller = document.getElementById(scrollerDivId)
    if (!scroller) return undefined
    const callback = () => setTooltipOpen(false)
    scroller.addEventListener('scroll', callback, { once: true })
    return () => {
      scroller.removeEventListener('scroll', callback)
    }
  }, [isSmallScreen, tooltipOpen])

  return (
    <Box
      id={`${person.id}_sidebar-avatar`}
      sx={[avatarClasses.container, ...(selected ? [avatarClasses.avatarSelected] : [])]}
      onClick={() => {
        onClick && onClick(person, tasks ?? 0, Boolean(selected))
      }}
    >
      <Tooltip
        arrow
        title={
          <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
            <Box textAlign="start" display="flex" flexDirection="column">
              <Typography variant="body2" sx={avatarClasses.tooltipText}>
                {name}
              </Typography>
              {tasks && tasks > 0 ? (
                <Button
                  variant="text"
                  onClick={() => {
                    selectNewPerson(person)
                    setTaskBarState(openUserTasks ? taskBarStates.userTasks : taskBarStates.managerTasks)
                  }}
                  color="secondary"
                  sx={avatarClasses.tooltipButton}
                >
                  {t(
                    tasks > 1
                      ? 'component.absenceRequestConflicts.conflicts.pendingRequests'
                      : 'component.absenceRequestConflicts.conflict.pendingRequests',
                    { numConflicts: tasks },
                  )}
                  <ArrowRightThinS />
                </Button>
              ) : null}
            </Box>
          </ClickAwayListener>
        }
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="right"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -8],
                },
              },
            ],
          },
          tooltip: {
            sx: tooltipClasses.tooltip,
          },
          arrow: {
            sx: tooltipClasses.arrow,
          },
        }}
      >
        <Box>
          <AbsencePlannerAvatarIcon avatarIconData={avatarIconData} showBorder={showBorder} tasks={tasks} />
        </Box>
      </Tooltip>

      <Box sx={avatarClasses.collapsableContent(isExpanded)}>
        <Box sx={avatarClasses.textContainer}>
          <Typography variant="body2" sx={avatarClasses.nameText}>
            {name}
          </Typography>
        </Box>
        {Boolean(tasks) && <ArrowRightThinS />}
      </Box>
    </Box>
  )
}

export default memo(AbsencePlannerAvatar)
