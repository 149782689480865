import React from 'react'
import { Box } from '@mui/material'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const TeamProfilePicL: React.FC<IconPropsT> = () => {
  return (
    <Box sx={{ width: ICON_SIZE_L, height: ICON_SIZE_L }}>
      <img alt="Team Profile Pic" src="/assets/icon/teamProfilePic.png" />
    </Box>
  )
}

export default TeamProfilePicL
