import { useEffect, useState, Dispatch } from 'react'
import { useHistory } from 'react-router-dom'
import { SessionValidityT } from 'types'
import { PinStatusT, PinEntryActionT, PinEntryT } from 'pages/SecuritySetup/types'
import routes from 'services/RoutesProvider/routes'
import { PIN_STATUSES } from 'constants/highSecurityConnection'

// no pin set, redirect to security-setup page
export const useRedirectIfPinNotSetOrBlocked = ({
  pinStatus,
  dispatchPin,
}: {
  pinStatus: { pinStatusData: PinStatusT | undefined; status: string }
  dispatchPin: Dispatch<PinEntryActionT>
}) => {
  const history = useHistory()
  useEffect(() => {
    if (pinStatus.status !== 'loading') {
      if (pinStatus?.pinStatusData?.pinStatus && pinStatus?.pinStatusData?.pinStatus === PIN_STATUSES.notSet) {
        history.push(routes.securitySetup, { from: { pathname: history.location.pathname } })
      }
      if (pinStatus?.pinStatusData?.pinStatus && pinStatus?.pinStatusData?.pinStatus === PIN_STATUSES.blocked) {
        dispatchPin({ type: 'pinBlocked' })
      }
    }
  }, [history, pinStatus, dispatchPin])
}

// Render component accordingly on receiving session validity and pin status.
// Do not render component when there will be a redirect (no pin set). Keep loading component and let redirect be handled by effect above
// This is to prevent rendering pin entry component with immediate redirect.
export const useRenderChildOrPin = ({
  sessionValidity,
  pinStatus,
  pinState,
}: {
  sessionValidity?: SessionValidityT
  pinStatus?: PinStatusT | undefined
  pinState?: PinEntryT
}) => {
  const [loadingActive, setLoadingActive] = useState(true)
  const [componentRendered, setComponentRendered] = useState(false)

  useEffect(() => {
    if (pinState?.pinValue.length === pinStatus?.pinLength) {
      setLoadingActive(true)
    }
    // pin set or blocked and some validity -> discard loading, render children or pin
    if (
      sessionValidity &&
      [PIN_STATUSES.set, PIN_STATUSES.blocked].includes(pinStatus?.pinStatus || '') &&
      pinState?.pinValue.length !== pinStatus?.pinLength
    ) {
      setLoadingActive(false)
    }
    // -> valid now -> render children
    if (sessionValidity?.highSecSessionValidNow) {
      setComponentRendered(true)
      setLoadingActive(false)
    }
  }, [pinStatus, sessionValidity, pinState])
  return { loadingActive, setLoadingActive, componentRendered, setComponentRendered }
}
