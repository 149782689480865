import React from 'react'
import { Button, Typography } from '@mui/material'
import { isAndroidNative, isIphoneNative, isNativeNoOverride } from 'utils/platform'
import { ANDROID_PLAY_STORE_LINK, IOS_APP_STORE_LINK } from 'constants/appStores'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Dialog from 'components/Dialog'

export const translations = {
  close: 'common.dialog.button.close',
  content: 'versionRequirementDialog.content',
  reload: 'versionRequirementDialog.button.reload',
  title: 'versionRequirementDialog.title',
  update: 'versionRequirementDialog.button.update',
}

const VersionRequirementDialog: React.FC<{ dismiss: () => void }> = ({ dismiss }) => {
  const { t } = useFavurTranslation()

  const dialogDetails = {
    callback: () => {
      if (isNativeNoOverride()) {
        if (isIphoneNative()) {
          window.location.href = IOS_APP_STORE_LINK
        }
        if (isAndroidNative()) {
          window.location.href = ANDROID_PLAY_STORE_LINK
        }
      } else {
        window.location.reload()
      }

      dismiss()
    },
    callToAction: isNativeNoOverride() ? translations.update : translations.reload,
  }

  return (
    <Dialog
      actions={
        <>
          <Button color="secondary" onClick={dismiss} size="large" data-testid="version-req-dlg-dimiss-button">
            {t(translations.close)}
          </Button>
          <Button color="secondary" onClick={dialogDetails.callback} size="large">
            {t(dialogDetails.callToAction)}
          </Button>
        </>
      }
      open
      title={t(translations.title)}
    >
      <Typography variant="body2">{t(translations.content)}</Typography>
    </Dialog>
  )
}

export default VersionRequirementDialog
