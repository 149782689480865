import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import theme from 'utils/theme'
import { CloseThinM } from 'icons'
import { classes } from './styles'
import { absenceRequestListClasses } from '../styles'

interface ISmallScreenPageHeaderProps {
  title: string
  subtitle?: string
  onClick?: () => void
}

const SmallScreenPageHeader: React.FC<ISmallScreenPageHeaderProps> = ({ title, subtitle, onClick }) => {
  const setTaskBarState = useTaskBarStateDispatchContext()

  return (
    <Box sx={classes.headerContainer}>
      <Box sx={classes.header}>
        <IconButton
          onClick={
            onClick
              ? onClick
              : () => {
                  setTaskBarState(null)
                }
          }
          sx={classes.closeIcon}
          size="large"
        >
          <CloseThinM fill={theme.palette.primary.main} />
        </IconButton>
        <Box sx={classes.headerText}>
          <Typography variant="subtitle1" sx={absenceRequestListClasses.title}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body1" sx={absenceRequestListClasses.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default SmallScreenPageHeader
