import React from 'react'
import Card from 'components/Basics/Card'
import CenteredBox from 'components/CenteredBox'
import { Box } from '@mui/material'
import { useLoadingWithMinDisplayTime } from './useLoadingWithMinDisplayTime'
import { loadingClasses } from './styles'
import TriangleAnimation from './TriangleAnimation'

export const loadingBirdAltText = 'loading animation'
export interface ILoadingIconProps {
  status: string
  minMs?: number
  children: React.ReactNode
}

const SimpleLoading: React.FC<{ small?: boolean; smallContainer?: boolean; dataTestid?: string }> = ({
  small = false,
  smallContainer = false,
  dataTestid,
}) => {
  return (
    <CenteredBox dataTestId={dataTestid ?? 'loading-animation'}>
      <TriangleAnimation small={small} smallContainer={smallContainer} />
    </CenteredBox>
  )
}

const LoadingIconWrapper: React.FC<ILoadingIconProps> = ({ status, children, minMs }) => {
  const [showLoading] = useLoadingWithMinDisplayTime({ status, minMs })
  if (showLoading) {
    return <SimpleLoading />
  }
  return <>{children}</>
}

const BoxedLoading: React.FC<{ gap?: number }> = ({ gap }) => {
  return (
    <Box marginLeft={gap} marginRight={gap} sx={loadingClasses.box}>
      <Card data-testid="loading-icon">
        <SimpleLoading small />
      </Card>
    </Box>
  )
}

export default LoadingIconWrapper
export { SimpleLoading, BoxedLoading }
