import React from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { Box, Typography, Paper, Button } from '@mui/material'
import { Browser } from '@capacitor/browser'
import Page from 'components/Page'
import routes from 'services/RoutesProvider/routes'
import useJamesMutation from 'hooks/useJamesMutation'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AcceptTermsConditionsResultT } from 'types'
import { classes } from './styles'
import { acceptTermsConditionsMutation } from './queries'

const TermsAndConditionsUpdate: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  const [acceptTermsMutation] = useJamesMutation(acceptTermsConditionsMutation())

  const acceptTerms = () => {
    acceptTermsMutation().then(
      (data: AcceptTermsConditionsResultT) => {
        data?.acceptTermsConditions?.success && history.push(routes.dashboard)
      },
      () => {},
    )
  }

  return (
    <Page
      hideNativeNavigation
      hideWebToolbar
      header={<Typography variant="h2">{t('page.termsAndConditionsUpdate.title')}</Typography>}
    >
      <Box component={Paper} boxShadow="2" sx={classes.body}>
        <Typography variant="body2">
          <Trans i18nKey="page.termsAndConditionsUpdate.content.paragraph1" components={{ bold: <strong /> }} />
        </Typography>
        <Typography variant="body2" sx={classes.paragraph2}>
          <Trans i18nKey="page.termsAndConditionsUpdate.content.paragraph2" components={{ bold: <strong /> }} />
        </Typography>
        <Box sx={classes.buttonContainer}>
          <Button
            sx={classes.button}
            type="submit"
            size="large"
            variant="contained"
            color="secondary"
            onClick={acceptTerms}
            data-testid="accept-terms"
          >
            {t('page.termsAndConditionsUpdate.content.accept')}
          </Button>
          <Button
            onClick={() =>
              window.open(t('page.termsAndConditionsUpdate.url.termsAndConditions'), '_blank', 'noopener,noreferrer')
            }
            sx={classes.link}
            size="large"
            data-testid="view-terms"
          >
            {t('page.termsAndConditionsUpdate.content.termsAndConditions')}
          </Button>
          <Button
            onClick={async () => {
              await Browser.open({ url: t('page.termsAndConditionsUpdate.url.dataProtection') })
            }}
            sx={classes.link}
            size="large"
            data-testid="view-privacy"
          >
            {t('navigation.main.info.privacy')}
          </Button>
        </Box>
      </Box>
    </Page>
  )
}

export default TermsAndConditionsUpdate
