import { Locale } from 'date-fns'
import { getFormattedFromISOString } from 'utils/date'
import { taskTypes } from 'shared/constants'
import {
  TaskT,
  TaskDataT,
  AbsenceRequestTaskT,
  MonthlySheetTaskT,
  ShareUserDataTaskT,
  ShiftUpdateTaskT,
} from '../types'

export const isAbsenceRequestTask = (task: TaskT<TaskDataT>): task is TaskT<AbsenceRequestTaskT> =>
  task.taskType === taskTypes.absenceRequest

export const isMonthlySheetTask = (task: TaskT<TaskDataT>): task is TaskT<MonthlySheetTaskT> =>
  task.taskType === taskTypes.monthlySheet

export const isShareUserDataTask = (task: TaskT<TaskDataT>): task is TaskT<ShareUserDataTaskT> =>
  task.taskType === taskTypes.shareUserData

export const getTasksByDay = (tasks: TaskT<ShiftUpdateTaskT>[], locale: Locale | undefined) =>
  tasks.reduce((acc, task) => {
    const { shiftsUpdated } = task
    if (!shiftsUpdated) return acc
    const date = getFormattedFromISOString(shiftsUpdated[0].date, 'yyyy-MM-dd', locale)
    const current = acc.get(date) ?? []
    return acc.set(date, [...current, task])
  }, new Map<string, TaskT<ShiftUpdateTaskT>[]>())
