import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const PersonalDataFormM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M2.284 2.018c-.07.012-.209.049-.307.082A2.435 2.435 0 0 0 .313 4.178c-.035.337-.035 15.306 0 15.644A2.434 2.434 0 0 0 2.16 21.95c.176.044.416.046 9.84.046s9.665-.002 9.84-.046a2.447 2.447 0 0 0 1.593-1.259c.141-.273.224-.565.256-.899.018-.184.023-2.164.017-6.304-.007-5.668-.01-6.047-.05-6.212-.24-.992-.999-1.718-1.974-1.888-.181-.032-.9-.036-5.6-.036h-5.39l-.948-1.265C9.208 3.372 8.728 2.756 8.64 2.67a2.63 2.63 0 0 0-1.073-.613l-.187-.053L4.896 2c-1.382-.002-2.54.006-2.612.018Zm.284 1.489a.979.979 0 0 0-.7.566l-.056.139v15.576l.056.139c.072.176.27.392.44.477.07.036.198.076.284.09.219.035 18.598.035 18.816 0 .086-.014.214-.054.285-.09.17-.085.368-.3.44-.477l.055-.14.007-6.011c.006-4.428 0-6.05-.02-6.156a.895.895 0 0 0-.364-.58c-.244-.18.317-.163-5.995-.176l-5.676-.012-.11-.052a1.032 1.032 0 0 1-.202-.132c-.05-.044-.574-.723-1.164-1.508-.741-.988-1.102-1.45-1.169-1.496a1.437 1.437 0 0 0-.226-.12l-.129-.052-2.22-.004c-1.252-.002-2.277.006-2.352.019Zm9.099 5.424c-1.187.124-2.179 1.007-2.476 2.205-.071.287-.09.824-.038 1.119a2.918 2.918 0 0 0 1.012 1.763l.155.126-.21.1a4.794 4.794 0 0 0-1.573 1.236c-.463.573-.857 1.393-.857 1.783 0 .276.183.549.453.673a.642.642 0 0 0 .303.05c.167 0 .21-.01.32-.07.205-.113.32-.26.424-.546.201-.55.434-.897.851-1.273.57-.514 1.22-.767 1.97-.767.818 0 1.472.28 2.089.893.378.377.558.665.752 1.207.08.221.213.382.402.486.111.06.154.07.32.07a.665.665 0 0 0 .515-.192.72.72 0 0 0 .236-.495c.015-.205-.061-.462-.26-.877a4.614 4.614 0 0 0-2.115-2.153l-.257-.127.167-.137c1.023-.841 1.325-2.328.715-3.521a2.852 2.852 0 0 0-2.273-1.555 2.247 2.247 0 0 0-.625.002Zm.021 1.522c-.25.055-.442.164-.648.368-.291.292-.408.569-.408.97 0 .405.117.687.408.977.546.545 1.372.545 1.922 0a1.26 1.26 0 0 0 .406-.969c0-.405-.117-.687-.408-.978a1.328 1.328 0 0 0-1.272-.368Z"
      />
    </Svg>
  )
}

export default PersonalDataFormM
