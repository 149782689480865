import { Typography } from '@mui/material'
import { TimelineItemContentTextT } from 'components/Timeline/types'
import React from 'react'
import { classes } from './styles'

const TimelineItemComponentText: React.FC<TimelineItemContentTextT> = ({ text }) => {
  return (
    <Typography variant="body2" sx={classes.text}>
      {text}
    </Typography>
  )
}

export default TimelineItemComponentText
