import React, { useMemo } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useTasksQueryPaginated from 'hooks/useTasksQueryPaginated'
import { taskStates } from 'pages/Tasks/constants'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { taskTypes } from 'shared/constants'
import useFilterContext from 'pages/AbsencePlanner/contexts/FilterContext'
import useSelectedStatesContext from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { TASK_PAGE_LIMIT } from '../ManagerTasks'
import AbsenceRequestTab from '..'

interface UserTasksProps {
  refetchAll: () => void
  refetchTask: (personId: string, dateFrom: string, dateTo: string) => void
  hideTitle?: boolean
}

const UserTasks: React.FC<UserTasksProps> = ({ refetchAll, refetchTask, hideTitle = false }) => {
  const { t } = useFavurTranslation()
  const { selectedTaskUuid } = useSelectedStatesContext()
  const { shownTeams } = useFilterContext()

  const initialFilters = useMemo(
    () => ({
      // eslint-disable-next-line camelcase
      task_types: [taskTypes.absenceRequest],
      // eslint-disable-next-line camelcase
      task_statuses: [taskStates.pending],
      // eslint-disable-next-line camelcase
      team_ids: shownTeams,
    }),
    [shownTeams],
  )

  const { list: userTasks, loading, loadMore, hasMoreElements, handleRefetch, reload } = useTasksQueryPaginated({
    initialFilters,
    limit: TASK_PAGE_LIMIT,
    skip: Boolean(selectedTaskUuid),
  })

  return (
    <AbsenceRequestTab
      tasks={userTasks as TaskT<AbsenceRequestTaskT>[]}
      title={t('absencePlanner.taskbar.frontlinerTasks.title')}
      subtitle={t('absencePlanner.taskbar.frontlinerTasks.subtitle')}
      loading={loading}
      loadMore={loadMore}
      hasMoreElements={hasMoreElements}
      reloadTaskList={reload}
      refetchAll={() => {
        handleRefetch()
        refetchAll()
      }}
      refetchTask={(personId: string, dateFrom: string, dateTo: string) => {
        handleRefetch()
        refetchTask(personId, dateFrom, dateTo)
      }}
      hideTitle={hideTitle}
    />
  )
}

export default UserTasks
