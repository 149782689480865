import { gql } from '@apollo/client'

export const getCmsContentQuery = gql`
  query cmsContentGet($contentUuid: ID!) {
    cmsContentGet(uuid: $contentUuid) {
      uuid
      contentType
      contentStatusType
      createdAt
      startDate
      endDate
      isWholeDay
      history {
        insertedAt
        updatedAt
      }
      metadatas {
        title
        shortDescription
        language
      }
      blocks {
        order
        type
        data {
          ... on TextBlockData {
            html
          }
          ... on ImageBlockData {
            altText
            filename
          }
        }
        language
      }
    }
  }
`

export const listContents = gql`
  query cmsContentList {
    cmsContentList {
      uuid
      metadatas {
        title
        shortDescription
      }
    }
  }
`

export const listPaginatedcontents = gql`
  query cmsPaginatedContentList(
    $contentType: String
    $page: Int
    $pageSize: Int
    $eventTimeline: EventTimelineType
    $date: String
  ) {
    cmsPaginatedContentList(
      contentType: $contentType
      page: $page
      pageSize: $pageSize
      eventTimeline: $eventTimeline
      date: $date
    ) {
      pageNumber
      pageSize
      totalPages
      totalEntries
      entries {
        audiences {
          uuid
          audienceType
        }
        uuid
        startDate
        endDate
        isWholeDay
        metadatas {
          title
          shortDescription
        }
      }
    }
  }
`
