import { Theme } from '@mui/material'

export const classes = {
  iconButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  iconContainer: (theme: Theme) => ({
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    width: '40px',
    height: '40px',
    borderRadius: '8px',
  }),
  icon: {
    position: 'relative',
    top: '10px',
    left: '6px',
  },
  contentContainer: { display: 'flex', justifyContent: 'space-between', width: '100%', marginLeft: '8px' },
  title: (theme: Theme) => ({
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.text.primary,
    paddingLeft: '0px',
    textAlign: 'left',
  }),
  subtitle: (theme: Theme) => ({
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.text.primary,
  }),
}
