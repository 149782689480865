import { Box, Typography } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import { classes } from './styles'

interface IUserSettingsSectionProps {
  title?: string
  subtitle?: string
}

const UserSettingsSection: React.FC<PropsWithChildren<IUserSettingsSectionProps>> = ({ title, subtitle, children }) => {
  return (
    <Box sx={classes.root}>
      {(title || subtitle) && (
        <Box sx={classes.titleContainer}>
          {title && (
            <Typography variant="h3" sx={classes.title}>
              {title}
            </Typography>
          )}

          {subtitle && (
            <Typography variant="body2" sx={classes.subtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>
      )}

      {children}
    </Box>
  )
}

export default UserSettingsSection
