import { gql } from '@apollo/client'
import { notificationQueryReturnValues } from 'shared/queries'

export const notificationsCountQuery = gql`
  query notificationCounts {
    notificationCounts {
      manager
      personal
    }
  }
`

export const notificationsPaginatedQuery = gql`
  query notificationsPaginated($isManager: Boolean!, $limit: Int, $offset: Int) {
    notificationsPaginated(isManager: $isManager, limit: $limit, offset: $offset) {
      list {
        ${notificationQueryReturnValues}
      }
      totalCount
    }
  }
`

export const acknowledgeAllMutation = gql`
  mutation dismissAllNotifications($isManager: Boolean!) {
    dismissAllNotifications(isManager: $isManager) {
      success
    }
  }
`

export const acknowledgeOneMutation = gql`
  mutation dismissNotification($notificationId: ID!) {
    dismissNotification(notificationId: $notificationId) {
      success
    }
  }
`
