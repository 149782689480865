import React from 'react'
import routes from 'services/RoutesProvider/routes'
import PinLogin from 'pages/PinLogin'
import Redirect from 'components/Redirect'

const ForcePinLogin: React.FC = () => {
  return (
    <PinLogin>
      <Redirect route={routes.profile} />
    </PinLogin>
  )
}

export default ForcePinLogin
