import { ApolloError, QueryHookOptions } from '@apollo/client'
import { omit } from 'lodash/fp'

export type JamesApolloArgsT = {
  query: string
  queryEncapsulation?: boolean
  queryName?: string
  queryOptions?: QueryHookOptions
}

export const removeTypenameFromObject = (object: Record<string, unknown>) => omit(['__typename'], object)

const REGEX = /.*code: "(.*?)"/

export const getFirstErrorCode = (e: ApolloError): string => {
  if (e.graphQLErrors.length < 1) {
    return ''
  }
  const error = e.graphQLErrors[0].message
  const parsed = REGEX.exec(error) ?? []
  return parsed[1] ?? ''
}
