import React, { useCallback, useState } from 'react'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import ControlledSelect from 'components/Forms/components/ControlledSelect'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import { AbsenceDateT, AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import ToggleBar from 'components/ToggleBar'
import { detailClasses } from 'pages/AbsencePlanner/components/TaskBar/AbsenceRequestTab/AbsenceRequestDetail/styles'
import { absenceTypes } from '../../constants'
import { classes } from './styles'
import { AcceptAbsenceRequestFieldsT } from './types'
import IndividualAbsenceType from './IndividualAbsenceType'
import CheckEmployeeSaldo from '../ActionButtons/CheckEmployeeSaldo'

const MAX_LENGTH_REVIEWER_COMMENT = 1000
const MAX_LENGTH_INTERNAL_COMMENT = 50

interface IFormProps {
  onSubmit: (approveData: AcceptAbsenceRequestFieldsT) => void
  taskDates: AbsenceDateT[]
  fullScreen?: boolean
  isSmallScreen?: boolean
  task: TaskT<AbsenceRequestTaskT>
}

const Form: React.FC<IFormProps> = ({ onSubmit, taskDates, fullScreen = false, isSmallScreen = false, task }) => {
  const { t } = useFavurTranslation()
  const isSingleDay = taskDates.length <= 1
  const [absenceType, setAbsenceType] = useState<string>('')
  const [absenceErrors, setAbsenceErrors] = useState<string[]>(Array(taskDates.length).fill(''))

  // This cleans the __typename to send it back. Also the type that is free by default on creation
  const [datesData, setDatesData] = useState<AbsenceDateT[]>(
    taskDates.map((item) => {
      const cleanItem = {
        date: item.date,
        period: item.period,
        type: '',
      }

      return cleanItem
    }),
  )
  const [reviewerComment, setReviewerComment] = useState<string>()
  const [internalComment, setInternalComment] = useState<string>()

  const [individualTypeToggle, setIndividualTypeToggle] = useState<number>(0)

  const handleAbsenceTypeForAll = useCallback(
    (newType: string) => {
      const arrayModified = datesData.map((item) => {
        const value = { ...item, type: newType }
        return value
      })

      setDatesData(arrayModified)
    },
    [datesData],
  )

  const handleAbsenceTypeToggle = useCallback(
    (e: React.SyntheticEvent<Element, Event>, newIndex: number) => {
      setIndividualTypeToggle(newIndex)
      // If we move the toggle to same, we reset the individual values
      if (newIndex === 0) {
        handleAbsenceTypeForAll(absenceType)
      }
    },
    [absenceType, handleAbsenceTypeForAll],
  )

  const getData = useCallback(() => {
    return { datesData, reviewerComment, internalComment }
  }, [datesData, internalComment, reviewerComment])

  const handleTypeChange = useCallback(
    (newType: string, index: number) => {
      const { [index]: item, ...rest } = datesData
      const value = { ...item, type: newType }
      const modified = Object.values({ [index]: value, ...rest }) as AbsenceDateT[]

      setDatesData(modified)
    },
    [datesData],
  )

  const validData = useCallback(
    (data: AcceptAbsenceRequestFieldsT) => {
      const requiredError = t('common.error.required')

      const newErrorsArray = data.datesData.map((value) => {
        if (!value.type) {
          return requiredError
        }
        return ''
      })

      setAbsenceErrors(newErrorsArray)
      return !newErrorsArray.find((value) => {
        return Boolean(value)
      })
    },
    [t],
  )

  const resetErrorIndex = useCallback(
    (value: string, index: number) => {
      const newErrorsArray = [...absenceErrors]

      if (value) {
        newErrorsArray[index] = ''
        setAbsenceErrors(newErrorsArray)
      }
    },
    [absenceErrors],
  )

  //It is done here, because it takes a lot to render if done inside the main template
  const getIndividualTypeSelects = useCallback(() => {
    return (
      <Box>
        {datesData.map((value, index) => (
          <IndividualAbsenceType
            key={`individual-absence-type-${value.date}`}
            absenceDate={value}
            onChange={(newType) => {
              resetErrorIndex(newType, index)
              handleTypeChange(newType, index)
            }}
            error={Boolean(absenceErrors[index])}
            helperText={absenceErrors[index]}
          />
        ))}
      </Box>
    )
  }, [absenceErrors, datesData, handleTypeChange, resetErrorIndex])

  return (
    <>
      <Box sx={fullScreen || !isSmallScreen ? classes.formContentFullscreen : classes.formContent}>
        {!isSingleDay && (
          <Box sx={classes.absenceType}>
            <Typography sx={{ fontWeight: '600' }} variant="body2">
              {t('absenceRequest.accept.absenceType.label')}
            </Typography>
            <ToggleBar
              currentToggleIndex={individualTypeToggle}
              handleChange={handleAbsenceTypeToggle}
              firstOption={t('absenceRequest.accept.toggle.same')}
              secondOption={t('absenceRequest.accept.toggle.individual')}
            />

            {Boolean(individualTypeToggle) && getIndividualTypeSelects()}
          </Box>
        )}

        <Box sx={classes.form}>
          {(isSingleDay || !individualTypeToggle) && (
            <Grid item xs={12}>
              <ControlledSelect
                label={t('absenceRequest.accept.absenceType.label')}
                error={Boolean(absenceErrors[0])}
                helperText={absenceErrors[0]}
                name="absenceType"
                value={absenceType}
                onChange={(e) => {
                  resetErrorIndex(e.target.value, 0)
                  setAbsenceType(e.target.value)
                  handleAbsenceTypeForAll(e.target.value)
                }}
                options={absenceTypes}
                required
              ></ControlledSelect>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              name="reviewerComment"
              variant="standard"
              fullWidth
              inputProps={{ maxLength: MAX_LENGTH_REVIEWER_COMMENT, 'data-testid': 'reviewer-comment' }}
              maxRows={7}
              multiline
              label={t('absenceRequest.accept.reviewerComment.label')}
              onChange={(e) => setReviewerComment(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="internalComment"
              variant="standard"
              fullWidth
              inputProps={{ maxLength: MAX_LENGTH_INTERNAL_COMMENT, 'data-testid': 'internal-comment' }}
              maxRows={3}
              multiline
              label={t('absenceRequest.accept.internalComment.label')}
              onChange={(e) => setInternalComment(e.target.value)}
            />
          </Grid>
        </Box>
      </Box>
      <ActionCardButtons fullScreen={fullScreen || isSmallScreen}>
        <Button
          variant="contained"
          data-testid="absence-request_detail-form-accept"
          color="secondary"
          onClick={() => {
            const data = getData()
            if (validData(data)) {
              onSubmit(data)
            }
          }}
        >
          {t('absenceRequest.accept.button.submit')}
        </Button>
        <CheckEmployeeSaldo
          task={task}
          drawerSx={fullScreen || isSmallScreen ? detailClasses.drawerFullScreen : detailClasses.drawer}
        />
      </ActionCardButtons>
    </>
  )
}

export default Form
