import React, { memo, useMemo } from 'react'
import { Box } from '@mui/material'
import useSelectedStatesContext from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { gridClasses } from 'pages/AbsencePlanner/components/Grid/styles'
import { DateRangeT } from 'pages/AbsencePlanner/types'
import MonthlyBarDay from 'pages/AbsencePlanner/components/MonthlyBar/MonthlyBarDay'
import { eachDayOfInterval } from 'date-fns'
import { getFormattedFromDate } from 'utils/date'
import { monthlyBarClasses } from 'pages/AbsencePlanner/components/MonthlyBar/styles'

interface IPreviewCalendarRowProps {
  shiftsDateRange: DateRangeT
}

const PreviewCalendarRow: React.FC<IPreviewCalendarRowProps> = ({ shiftsDateRange }) => {
  const { selectedDays, selectedTaskUuid } = useSelectedStatesContext()

  const daysToDisplay = useMemo(() => {
    if (shiftsDateRange.startDate && shiftsDateRange.endDate) {
      return eachDayOfInterval({ start: shiftsDateRange.startDate, end: shiftsDateRange.endDate })
    }
    return []
  }, [shiftsDateRange.endDate, shiftsDateRange.startDate])
  return (
    <Box sx={[gridClasses.monthlyBarContainer]}>
      <Box sx={[monthlyBarClasses.container]}>
        {daysToDisplay.map((date) => (
          <MonthlyBarDay
            date={date}
            selectedTaskUuid={selectedTaskUuid}
            selectedDays={selectedDays}
            key={getFormattedFromDate(date)}
            isPreview
          />
        ))}
      </Box>
    </Box>
  )
}

export default memo(PreviewCalendarRow)
