import { CheckListOptionT, FilterStateT, FilterType, OptionT } from 'components/Filter/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useRolesViews from 'hooks/useRolesViews'
import { isEqual } from 'lodash/fp'
import { useMemo, useState } from 'react'
import { TFunction } from 'react-i18next'
import {
  personsOnlyTenant,
  personTeamPermissionsQuery,
  PersonTeamPermissionsQueryReturnT,
  taskCountsQuery,
} from 'shared/queries'
import { AuthPersonIdAndPermissions, PersonT, TaskCountsT, TeamT, WorkflowsT } from 'types'
import { getTeamNameByLanguage } from 'types/utils'
import { TaskTypeT, taskTypes } from 'shared/constants'
import { getISOLastDayOfMonth, newDateWithoutTime } from 'utils/date'
import { sortArrayByKey } from 'utils/sort'
import { tabs, taskStates, taskTypeByWorkflow } from './constants'
import { allManagedPersonsQuery } from './queries'
import { TaskStateT } from './types'

const getTeamMenuLabel = (team: TeamT, language: string, t: TFunction<'translation'>, isMultiTenant = true) => {
  const ownerOrTenant = team.tenant?.name ? team.tenant.name : team.owner?.name
  return team.tenantId !== null || isMultiTenant
    ? `${ownerOrTenant} - ${getTeamNameByLanguage(team, language)}`
    : getTeamNameByLanguage(team, language)
}

const getActiveTeamWorkflows = (persons: AuthPersonIdAndPermissions[]) => {
  return persons.reduce((prevPermissions, person) => {
    // For every person and its permissions
    const personPermissions = person?.permissions ?? []
    // Will collect all active workflow permissions
    const personActivePermissions = Object.keys(taskTypeByWorkflow).reduce((prevPersonPermissions, workflow) => {
      type PermissionsKeyType = keyof typeof personPermissions
      const taskType = taskTypeByWorkflow[workflow]
      const currentWorkflowPermission = personPermissions[workflow as PermissionsKeyType]
      return currentWorkflowPermission && !prevPermissions.includes(taskType)
        ? ([taskType, ...prevPersonPermissions] as TaskTypeT[])
        : prevPersonPermissions
    }, [] as TaskTypeT[])
    // And added it to the previous person if didn't exist previously
    return [...prevPermissions, ...personActivePermissions]
  }, [] as TaskTypeT[])
}

const useFilterConfiguration = (defaultState: TaskStateT, defaultType: TaskTypeT | undefined) => {
  const { t, language } = useFavurTranslation()
  const { activeView, roles, persons } = useRolesViews()
  const isOffice = activeView === roles.office
  const [tenantOptionsList, setTenantOptionsList] = useState<CheckListOptionT[]>([])
  const [tenantsLoading, setTenantsLoading] = useState(true)
  const [teamsList, setTeamsList] = useState<CheckListOptionT[]>([])
  const [teamsLoading, setTeamsLoading] = useState(true)
  const [personsList, setPersonsList] = useState<PersonT[]>([])
  const [personsLoading, setPersonsLoading] = useState(true)
  const [taskCounts, setTaskCounts] = useState<TaskCountsT>()
  const [taskCountsLoading, setTaskCountsLoading] = useState(true)

  useJamesApolloQuery<{ findAllPersons: PersonT[] }>(personsOnlyTenant, {
    fetchPolicy: 'no-cache',
    onCompleted(personsWithTenants) {
      const filteredTenantOptions = Array.from(
        personsWithTenants?.findAllPersons
          .reduce(
            (map, person) =>
              map.has(person.tenant?.id)
                ? map
                : map.set(person.tenant?.id, { name: person.tenant?.id, label: person.tenant?.name }),
            new Map(),
          ) // de-duplication of tenants
          .values(),
      ) as CheckListOptionT[] // to checklistOptions

      const sortedTenants = sortArrayByKey(filteredTenantOptions, 'label')
      setTenantOptionsList(sortedTenants)
      setTenantsLoading(false)
    },
  })

  useJamesApolloQuery<{
    findAllPersons: PersonTeamPermissionsQueryReturnT
  }>(personTeamPermissionsQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted(personsWithPermissions) {
      const teamsWithPermissions = personsWithPermissions.findAllPersons
        .reduce((prevTeams, person) => {
          person.teamPermissions.forEach((tp) => {
            const isManagerInTeam = Object.values(WorkflowsT).some((workflow) => {
              type PermissionsKeyType = keyof typeof tp
              const currentWorkflowPermission = tp[workflow as PermissionsKeyType]
              return currentWorkflowPermission as boolean
            })
            const teamId = tp.team.id
            if (isManagerInTeam && !prevTeams.has(teamId)) {
              prevTeams.set(teamId, {
                name: teamId,
                label: getTeamMenuLabel(tp.team, language, t, persons && persons.length > 1),
              })
            }
          }, [])

          return prevTeams
        }, new Map<string, CheckListOptionT>())
        .values()

      setTeamsList(Array.from(teamsWithPermissions))
      setTeamsLoading(false)
    },
  })

  useJamesApolloQuery<{ findAllManagedPersons: PersonT[] }>(allManagedPersonsQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted(managedPersons) {
      setPersonsList(managedPersons.findAllManagedPersons)
      setPersonsLoading(false)
    },
  })

  useJamesApolloQuery<{ taskCounts: TaskCountsT }>(taskCountsQuery, {
    variables: { office: isOffice },
    fetchPolicy: 'cache-and-network',
    onCompleted(result) {
      setTaskCounts(result.taskCounts)
      setTaskCountsLoading(false)
    },
  })
  const taskTypeOptions = useMemo(() => {
    const allWorkflows = Object.values(taskTypes)
    const activeTeamWorkflows = getActiveTeamWorkflows(persons ?? [])
    const activeWorkflows = isOffice ? activeTeamWorkflows : allWorkflows

    return activeWorkflows.map((taskType) => {
      return {
        name: taskType,
        label: t(`filter.task_type.${taskType}`),
      }
    })
  }, [isOffice, persons, t])

  const isTeamManager = useMemo(() => {
    return getActiveTeamWorkflows(persons ?? []).length > 0
  }, [persons])

  const configTimeFrame = useMemo(
    () => [
      {
        name: 'time_frame',
        type: FilterType.DATE_RANGE,
        label: t('filter.task_time_frame.label'),
        maxDate: getISOLastDayOfMonth(newDateWithoutTime()),
        minDate: '2015-01-01',
        fromDate: {
          name: 'start_date',
          label: t('filter.from_date.label'),
        },
        toDate: {
          name: 'end_date',
          label: t('filter.to_date.label'),
        },
      } as OptionT,
    ],
    [t],
  )

  const configTaskStatuses = useMemo(
    () => [
      {
        name: 'task_statuses',
        type: FilterType.MULTIPLE_CHOICE,
        label: t('filter.task_status.label'),
        options: tabs.map((tab) => ({ name: tab.id, label: t(tab.label) })),
        defaultValues: [taskStates.todo],
        initialValues: [defaultState],
        quickFilter: (filters: FilterStateT) => {
          if (taskCounts) {
            // eslint-disable-next-line camelcase
            const openFilter = { task_statuses: [taskStates.todo] }
            if (isEqual(openFilter, filters)) {
              return {
                label: `${taskCounts[taskStates.pending]} ${t(`tasks.tab.pending`)}`,
                // eslint-disable-next-line camelcase
                filters: { task_statuses: [taskStates.pending] },
              }
            }
            // eslint-disable-next-line camelcase
            const pendingFilter = { task_statuses: [taskStates.pending] }
            if (isEqual(pendingFilter, filters)) {
              return {
                label: `${taskCounts[taskStates.todo]} ${t(`tasks.tab.todo`)}`,
                // eslint-disable-next-line camelcase
                filters: { task_statuses: [taskStates.todo] },
              }
            }
          }
          return undefined
        },
      } as OptionT,
    ],
    [t, defaultState, taskCounts],
  )

  const configTaskTypes = useMemo(() => {
    return taskTypeOptions && taskTypeOptions.length > 1
      ? [
          {
            name: 'task_types',
            type: FilterType.MULTIPLE_CHOICE,
            label: t('filter.task_type.label'),
            options: sortArrayByKey(taskTypeOptions, 'label'),
            initialValues: defaultType ? [defaultType] : undefined,
          } as OptionT,
        ]
      : []
  }, [defaultType, t, taskTypeOptions])

  const configTenants = useMemo(() => {
    return !isOffice && tenantOptionsList && tenantOptionsList.length > 1
      ? [
          {
            name: 'tenant_ids',
            type: FilterType.MULTIPLE_CHOICE,
            label: t('filter.tenants.label'),
            options: tenantOptionsList,
          } as OptionT,
        ]
      : []
  }, [isOffice, t, tenantOptionsList])

  const configTeams = useMemo(() => {
    return isTeamManager && teamsList && teamsList.length > 1
      ? [
          {
            name: 'team_ids',
            type: FilterType.MULTIPLE_CHOICE,
            label: t('filter.teams.label'),
            options: sortArrayByKey(teamsList, 'label'),
          } as OptionT,
        ]
      : []
  }, [isTeamManager, t, teamsList])

  const configPersons = useMemo(() => {
    return isOffice && personsList && personsList.length > 1
      ? [
          {
            name: 'person_ids',
            type: FilterType.PERSONS,
            label: t('filter.persons.label'),
            options: personsList,
          } as OptionT,
        ]
      : []
  }, [isOffice, personsList, t])

  const configuration: OptionT[] = useMemo(
    () => [
      ...configTimeFrame,
      ...configTaskStatuses,
      ...configTaskTypes,
      ...configTenants,
      ...configTeams,
      ...configPersons,
    ],
    [configPersons, configTaskStatuses, configTaskTypes, configTeams, configTenants, configTimeFrame],
  )

  return { configuration, loading: tenantsLoading || teamsLoading || personsLoading || taskCountsLoading }
}

export default useFilterConfiguration
