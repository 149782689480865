import React, { useCallback } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { Box } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import Page from 'components/Page'
import { headerClasses } from 'pages/UserSettings/styles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { SimpleLoading } from 'components/LoadingIcon'
import useManagedTenants from 'hooks/useManagedTenants'
import routes from 'services/RoutesProvider/routes'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import palette from 'utils/theme/palette'
import { TenantsM } from 'icons'
import { classes } from './styles'

const TenantsPageContent: React.FC = () => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { tenants, loading } = useManagedTenants()

  const gotoBackLink = useCallback(() => {
    history.goBack()
  }, [history])

  if (!loading && tenants && tenants.length === 1) {
    return <Redirect to={routes.tenantWithUuid(tenants[0].uuid as string)} />
  }

  return (
    <Page
      header={<BackwardLink onClickIcon={gotoBackLink} title={t('page.userSettings.tenants.header')} />}
      sxHeader={headerClasses.subHeader}
    >
      {loading || !tenants ? (
        <SimpleLoading />
      ) : (
        <Box sx={classes.listContainer}>
          {tenants.map((tenant) => (
            <UserSettingsSectionCTA
              key={tenant.uuid}
              title={tenant.name}
              icon={<TenantsM fill={palette.primary.main} />}
              onClick={() => {
                history.push(routes.tenantWithUuid(tenant.uuid as string))
              }}
            />
          ))}
        </Box>
      )}
    </Page>
  )
}

export default TenantsPageContent
