import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Page from 'components/Page'
import NotificationsHeader from './NotificationsHeader'
import NotificationsContent from './Content'

const NotificationsPage: React.FC = () => {
  const { t } = useFavurTranslation()

  return (
    <Page header={<NotificationsHeader title={t('page.notifications.header.title')} />} showHeader={false}>
      <NotificationsContent />
    </Page>
  )
}

export default NotificationsPage
