import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const SideMenuM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path d="M18 12.97a2 2 0 1 0 4 0 2 2 0 0 0-4 0ZM10 12.97a2 2 0 1 0 4 0 2 2 0 0 0-4 0ZM2 12.97a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z" />
    </Svg>
  )
}

export default SideMenuM
