import {
  format,
  parse,
  parseISO,
  Locale,
  isSameDay,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  addDays,
  differenceInCalendarDays,
  isSameYear,
  formatRelative,
} from 'date-fns'
import { balanceDateFormat, dayAndMonthFormat } from 'constants/dateFormats'
import { deadlinesTranslationKeys } from './constants'
import { capitalize } from './string'
import type { DeadlinesT } from 'types/utils'

export const newDateWithoutTime = (dateString?: string, pattern?: string) => {
  // eslint-disable-next-line
  if (dateString && pattern) return parse(dateString, pattern, new Date())
  // eslint-disable-next-line
  return dateString ? new Date(dateString) : new Date()
}

export const getDateFromISOString = (rawDate: string) => {
  if (!rawDate) return newDateWithoutTime()
  try {
    return parseISO(rawDate)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error)
    return newDateWithoutTime()
  }
}

export const getFormattedFromDate = (date: Date, pattern = 'P', locale?: Locale | undefined) => {
  if (!date) return ''
  try {
    return format(date, pattern, { locale })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error)
    return ''
  }
}

export const getFormattedFromISOString = (rawDate: string, pattern: string | undefined, locale: Locale | undefined) => {
  if (!rawDate) return ''
  try {
    const date = getDateFromISOString(rawDate)
    return getFormattedFromDate(date, pattern, locale)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error)
    return ''
  }
}

export const isValidDate = (date: Date): boolean => {
  return date instanceof Date && !Number.isNaN(date.valueOf())
}

export const isValidDateNumbers = (year: number, month: number, day: number): boolean => {
  // eslint-disable-next-line
  const date = new Date(year, month, day)
  if (date.getFullYear() === year && date.getMonth() === month && date.getDate() === day) {
    return true
  }
  return false
}

export const favurDateToString = (date: string) => {
  if (!date) {
    return ''
  }
  const [day, month, year] = date.split('.')
  return `${year}-${month}-${day}`
}

export const stringToFavurDate = (date: string) => {
  if (!date) {
    return ''
  }
  const [year, month, day] = date.split('-')
  return `${day}.${month}.${year}`
}

export const dateToISOStringWithoutTime = (date: Date) => {
  if (!date) return ''
  // Months in JS start in 0
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`
}

export const datePickersToString = (date: string | Date | null | undefined) => {
  if (!date) {
    return ''
  }

  if (date instanceof Date) {
    return dateToISOStringWithoutTime(date)
  }

  return favurDateToString(date)
}

export const dateInstanceToFavurDate = (date: Date) => {
  const dateString = dateToISOStringWithoutTime(date)
  return stringToFavurDate(dateString)
}

export const daysIncludedInRange = (date1: Date | null, date2: Date | null): number => {
  if (!date1 || !date2) {
    return 0
  }

  const date1WithoutTime = newDateWithoutTime(date1.toDateString())
  const date2WithoutTime = newDateWithoutTime(date2.toDateString())

  return Math.abs(differenceInCalendarDays(date2WithoutTime, date1WithoutTime)) + 1
}

export const daysIncludedInRangeISOStrings = (startDateStr: string, endDateStr: string) => {
  const startDate = getDateFromISOString(startDateStr)
  const endDate = getDateFromISOString(endDateStr)
  return daysIncludedInRange(startDate, endDate)
}

export const getTeamplanSubheaderDate = (startDate: string, endDate: string, locale: Locale | undefined): string => {
  if (!startDate || !endDate) return ''
  if (startDate === endDate) {
    return getFormattedFromISOString(startDate, balanceDateFormat, locale)
  }

  return `${getFormattedFromISOString(startDate, dayAndMonthFormat, locale)} - ${getFormattedFromISOString(
    endDate,
    balanceDateFormat,
    locale,
  )}`
}

export const dateIsFirstDayOfTheMonth = (date: Date) => isSameDay(startOfMonth(date), date)

export const getDeadlineTranslationKey = (deadlineKey: DeadlinesT): string =>
  deadlinesTranslationKeys[deadlineKey] || '-'

export const getISOFirstDayOfMonth = (date: Date) => dateToISOStringWithoutTime(startOfMonth(date))

export const getISOLastDayOfMonth = (date: Date) => dateToISOStringWithoutTime(endOfMonth(date))

export const getFavurDateFromISOString = (rawDate: string) => {
  const date = getDateFromISOString(rawDate)
  return stringToFavurDate(dateToISOStringWithoutTime(date))
}

export const getTodayFormatted = (pattern = 'yyyy-MM-dd') => getFormattedFromDate(newDateWithoutTime(), pattern)

export const getWeekDays = (currentDay: Date): Date[] => {
  return eachDayOfInterval({
    start: startOfWeek(currentDay, { weekStartsOn: 1 }),
    end: endOfWeek(currentDay, { weekStartsOn: 1 }),
  })
}
type GetDateFormatByLocaleOptionsT = {
  withYear?: boolean
  withDayOfWeek?: boolean
}

export const getDateFormatByLocale = (
  locale: Locale,
  options: GetDateFormatByLocaleOptionsT = { withDayOfWeek: true, withYear: true },
) => {
  const { withDayOfWeek, withYear } = options
  switch (locale.code) {
    case 'de':
      return `${withDayOfWeek ? 'EEEE, ' : ''}d. MMMM${withYear ? ' yyyy' : ''}`
    case 'it':
    case 'fr':
      return `${withDayOfWeek ? 'EEEE ' : ''} d MMMM${withYear ? ' yyyy' : ''}`
    default:
      return `${withDayOfWeek ? 'EEEE, ' : ''}d MMMM${withYear ? ' yyyy' : ''}`
  }
}

export const getCalendarMonthInterval = (referenceDate: Date, daysToReturn = -1) => {
  if (!referenceDate) return []
  const firstDay = startOfMonth(referenceDate)
  // Week starts on 1 - Monday
  const calendarFirstDay = startOfWeek(firstDay, { weekStartsOn: 1 })
  const lastDay = daysToReturn > 0 ? addDays(calendarFirstDay, daysToReturn - 1) : endOfMonth(referenceDate)
  const calendarLastDay = endOfWeek(lastDay, { weekStartsOn: 1 })
  return eachDayOfInterval({ start: calendarFirstDay, end: calendarLastDay })
}

export const getMonthDates = (referenceDate: Date) => {
  if (!referenceDate) return []
  const firstDay = startOfMonth(referenceDate)
  const lastDay = endOfMonth(referenceDate)
  return eachDayOfInterval({ start: firstDay, end: lastDay })
}

export const getWeekDayNamedByLanguage = (date: Date, language: string) => {
  if (!date) return ''
  const strlen = ['en', 'de'].includes(language) ? 2 : 3
  return capitalize(date.toLocaleDateString(language, { weekday: 'short' }).slice(0, strlen))
}

export const getNamedDaysByLanguage = (language: string) => {
  const today = newDateWithoutTime()
  return getWeekDays(today).map((date) => getWeekDayNamedByLanguage(date, language))
}

const getRelativeDatePattern = (date: Date, today: Date, locale: Locale, capitalizeRelative: boolean) => {
  const dateDistance = differenceInCalendarDays(today, date)
  const hourFormat = 'HH:mm'
  if (dateDistance < 2) {
    const prefix = formatRelative(date, today, { locale }).split(' ').at(0) ?? ''
    const capitalizedPrefix = capitalizeRelative ? capitalize(prefix) : prefix
    return `'${capitalizedPrefix}', ${hourFormat}`
  }
  if (dateDistance > 1 && dateDistance < 8) return `EEEE, ${hourFormat}`
  if (!isSameYear(today, date)) {
    return `${getDateFormatByLocale(locale, { withDayOfWeek: false, withYear: true })}, ${hourFormat}`
  }
  return `${getDateFormatByLocale(locale, { withYear: false, withDayOfWeek: true })}, ${hourFormat}`
}

export const getRelativeDate = (date: Date, locale: Locale, capitalizeRelative = true) => {
  if (!date) return ''
  const today = newDateWithoutTime()

  const pattern = getRelativeDatePattern(date, today, locale, capitalizeRelative)

  return getFormattedFromDate(date, pattern, locale)
}

export const getShortMonthFormat = (locale: Locale) => {
  switch (locale.code) {
    case 'en-GB':
    case 'en':
    case 'it':
      return 'MMM. yyyy'
    default:
      return 'MMM yyyy'
  }
}

export const dateToCorrectTimeZone = (date: Date) => {
  const dateString = dateToISOStringWithoutTime(date)
  return newDateWithoutTime(dateString)
}
