import React, { PropsWithChildren } from 'react'
import { BadgeOrigin, Avatar as MuiAvatar, SxProps, Theme } from '@mui/material'
import { AvatarDataT } from 'types'
import { isNative } from 'utils/platform'
import { useAvatarClasses } from './styles'
import AvatarBadge from './AvatarBadge'

interface IAvatarProps {
  avatar: AvatarDataT
  size?: number
  sx?: SxProps<Theme>
  badge?: React.ReactNode
  badgeAnchorOrigin?: BadgeOrigin
}

const Avatar: React.FC<PropsWithChildren<IAvatarProps>> = ({
  size = 28,
  avatar,
  sx = [],
  badge = undefined,
  children,
  badgeAnchorOrigin,
}) => {
  const classes = useAvatarClasses(size)

  return (
    <AvatarBadge badge={badge} anchorOrigin={badgeAnchorOrigin}>
      <MuiAvatar
        sx={[
          classes.avatar,
          isNative() && avatar.avatarUrl && { pointerEvents: 'none' },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        alt={avatar.avatarName}
        src={avatar.avatarUrl}
      >
        {children || avatar.avatarName}
      </MuiAvatar>
    </AvatarBadge>
  )
}

export default Avatar
