import React from 'react'
import { Typography } from '@mui/material'
import { TextContentBlockT } from 'types'
import { styles } from './styles'

interface ITextBlockProps {
  data: TextContentBlockT
}

const TextBlock: React.FC<ITextBlockProps> = ({ data }) => {
  return (
    <>
      {data.html && (
        <Typography
          sx={styles.textBlock}
          dangerouslySetInnerHTML={{
            __html: data.html,
          }}
        ></Typography>
      )}
    </>
  )
}

export default TextBlock
