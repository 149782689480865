import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const RefreshS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M9.557 2.696a5.657 5.657 0 0 0-3.36.127 5.573 5.573 0 0 0-2.678 2.01A5.452 5.452 0 0 0 2.502 7.86l-.57-.673a.49.49 0 0 0-.772 0 .727.727 0 0 0 0 .91l1.454 1.714a.49.49 0 0 0 .772 0L4.84 8.097a.727.727 0 0 0 0-.909.49.49 0 0 0-.771 0l-.567.668c.03-.875.317-1.723.831-2.442a4.573 4.573 0 0 1 2.198-1.648 4.657 4.657 0 0 1 2.765-.105 4.59 4.59 0 0 1 2.32 1.478.5.5 0 1 0 .767-.642 5.59 5.59 0 0 0-2.826-1.801Zm2.94 5.448-.566.668a.49.49 0 0 1-.771 0 .726.726 0 0 1 0-.91l1.454-1.714a.49.49 0 0 1 .772 0l1.454 1.715a.726.726 0 0 1 0 .909.49.49 0 0 1-.771 0l-.57-.673a5.364 5.364 0 0 1-.99 2.958 5.677 5.677 0 0 1-2.618 2.017 5.916 5.916 0 0 1-3.333.257 5.767 5.767 0 0 1-2.915-1.589.5.5 0 1 1 .714-.701 4.768 4.768 0 0 0 2.41 1.312c.924.196 1.889.122 2.768-.213a4.677 4.677 0 0 0 2.157-1.66 4.365 4.365 0 0 0 .806-2.376Z"
      />
    </Svg>
  )
}

export default RefreshS
