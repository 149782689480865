const classes = {
  container: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
}

export default classes
