import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import { taskCountsQuery } from 'shared/queries'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { RoleViewT, TaskCountsT } from 'types'
import SectionHeader from '../SectionHeader'
import { classes } from './styles'
import DashboardTasksFrontliner from './Frontliner'
import DashboardTasksManager from './Manager'

const DashboardTasks: React.FC = () => {
  const { t } = useFavurTranslation()
  const { hasOfficeView: isManager, setActiveView } = useRolesViews()
  const history = useHistory()

  const { data, loading } = useJamesApolloQuery<{
    taskCounts: TaskCountsT
  }>(taskCountsQuery, {
    variables: {
      office: false,
    },
    fetchPolicy: 'cache-and-network',
  })

  const onClick = useCallback(
    (role: RoleViewT) => {
      setActiveView(role)
      history.push(`${routes.tasks}/todo`)
    },
    [history, setActiveView],
  )

  return (
    <>
      <SectionHeader title={t('page.tasks.header.openTasks')} testId="show-all-tasks" />
      <Box sx={classes.tilesContainer}>
        {isManager ? (
          <DashboardTasksManager loading={loading} counts={data?.taskCounts} onClick={onClick} />
        ) : (
          <DashboardTasksFrontliner loading={loading} counts={data?.taskCounts} onClick={onClick} />
        )}
      </Box>
    </>
  )
}

export default DashboardTasks
