import { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import useFavurForm from 'hooks/useFavurForm'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { checkNameInput } from 'pages/Teams/utils'
import type { CreateTeamFormValuesT, IUseCreateTeamFormProps } from './types'

const useCreateTeamForm = ({ onSubmit }: IUseCreateTeamFormProps) => {
  const { t } = useFavurTranslation()
  const requiredFieldText = t('personalData.error.requiredField')
  const [multiLangCheckboxValue, setMultiLangCheckboxValue] = useState(false)

  const [formFields] = useState([
    {
      init: '',
      label: t('teams.createDialog.germanInputName'),
      name: 'nameDe',
      validation: yup.string().nullable().required(requiredFieldText),
    },
    {
      init: multiLangCheckboxValue,
      label: t('teams.createDialog.multiLangCheckboxName'),
      name: 'isMultiLang',
      validation: yup.boolean(),
    },
    {
      init: null,
      label: t('teams.createDialog.frenchInputName'),
      name: 'nameFr',
      validation: yup.mixed().test({
        name: 'checkIsMultiLangFr',
        test: function test() {
          // eslint-disable-next-line fp/no-this
          const { isMultiLang, nameFr } = this.parent
          return checkNameInput(nameFr, { isMultiLang })
        },
        message: requiredFieldText,
      }),
    },
    {
      init: null,
      label: t('teams.createDialog.italianInputName'),
      name: 'nameIt',
      validation: yup.mixed().test({
        name: 'checkIsMultiLangIt',
        test: function test() {
          // eslint-disable-next-line fp/no-this
          const { isMultiLang, nameIt } = this.parent
          return checkNameInput(nameIt, { isMultiLang })
        },
        message: requiredFieldText,
      }),
    },
    {
      init: null,
      label: t('teams.createDialog.englishInputName'),
      name: 'nameEn',
      validation: yup.mixed().test({
        name: 'checkIsMultiLangEn',
        test: function test() {
          // eslint-disable-next-line fp/no-this
          const { isMultiLang, nameEn } = this.parent
          return checkNameInput(nameEn, { isMultiLang })
        },
        message: requiredFieldText,
      }),
    },
    {
      label: '',
      name: 'tenant',
      init: '0',
      validation: yup.string().required(requiredFieldText),
    },
  ])
  const { favurForm, getFormHandlers } = useFavurForm<CreateTeamFormValuesT>({
    context: 'createTeamDialog',
    formFields,
    onSubmit: onSubmit as SubmitHandler<CreateTeamFormValuesT>,
  })

  return { favurForm, getFormHandlers, isMultiLang: multiLangCheckboxValue, setIsMultiLang: setMultiLangCheckboxValue }
}

export default useCreateTeamForm
