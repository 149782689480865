import { useMemo } from 'react'
import useTeams from 'hooks/useTeams'
import { absenceRequest, shiftTypes } from 'constants/shift'
import useRolesViews from 'hooks/useRolesViews'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AbsencePlannerBadgeTypesT } from 'pages/AbsencePlanner/types'
import { colorModes } from 'pages/AbsencePlanner/components/TaskBar/types'
import { getOwnerMemberTeams } from 'pages/AbsencePlanner/utils'
import { PersonT } from 'types'

export const usePreviewGridFilter = (previewPerson: PersonT | undefined) => {
  const { language } = useFavurTranslation()
  const { canManageAbsences } = useRolesViews()

  const selectedOwnerId = previewPerson?.owner?.id

  const shownTypes = useMemo<AbsencePlannerBadgeTypesT[]>(
    () => [shiftTypes.absence, absenceRequest, ...(canManageAbsences ? [shiftTypes.plannedShift] : [])],
    [canManageAbsences],
  )

  const { teams, loading: loadingTeams, isMultiTenant } = useTeams({
    onlyManager: false,
    queryForUsers: true,
  })

  const ownerMemberTeams = useMemo(() => {
    const ownerTeams = getOwnerMemberTeams(language, teams, selectedOwnerId).filter((team) => {
      return team.teamPermissions?.find((teamPermission) => {
        return teamPermission.person?.id === previewPerson?.id
      })
    })

    return ownerTeams
  }, [language, previewPerson?.id, selectedOwnerId, teams])

  const shownTeams = useMemo(() => ownerMemberTeams.map((team) => team.id), [ownerMemberTeams])

  return {
    greyscaleMode: colorModes.colorful,
    isMultiTenant,
    loading: loadingTeams,
    owners: [],
    allTeams: ownerMemberTeams,
    ownerMemberTeams: ownerMemberTeams,
    ownerPublicTeams: [],
    shownTeams,
    shownTypes,
    setGreyscaleMode: () => {},
    setShownTeams: () => {},
    setShownTypes: () => {},
    teamsLoading: loadingTeams,
  }
}
