import React from 'react'
import { Box, IconButton, MobileStepper, useMediaQuery } from '@mui/material'
import Page from 'components/Page'
import SwipeableViews from 'react-swipeable-views'
import useFavurTranslation from 'hooks/useFavurTranslation'
import WhiteButton from 'components/WhiteButton'
import { ArrowLeftS, ArrowRightS } from 'icons'
import { classes } from 'components/Tutorial/styles'
import { isMobileWeb } from 'utils/platform'
import theme from 'utils/theme'
import { ITutorialProps } from './types'

const Tutorial: React.FC<ITutorialProps> = ({
  introPage,
  stepPages,
  step,
  setStep,
  onSwipeEnd,
  startButtonTextKey,
}) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { t } = useFavurTranslation()

  return (
    <Page hideNativeNavigation hideHamburger noPadding hideWebToolbar removeLastMargin>
      <Box sx={[classes.swipeContainer(isSmallScreen), ...(isMobileWeb() ? [classes.swipeContainerFullWidth] : [])]}>
        {!isSmallScreen && (
          <Box sx={classes.buttonContainer}>
            {step > 0 && ( // We don't show the left arrow in the first step
              <IconButton
                sx={[classes.arrowButtons, classes.arrowMarginRight]}
                onClick={() => {
                  step > 0 && setStep(step - 1)
                }}
                data-testid="calendar__button-prev"
                size="large"
              >
                <ArrowLeftS />
              </IconButton>
            )}
          </Box>
        )}
        <Box sx={classes.pagesContainer}>
          <SwipeableViews
            enableMouseEvents
            onChangeIndex={(newIdx) => {
              // Diff condition is different!! this is for allowing the swipe redirection without the button click
              if (newIdx > stepPages.length) {
                // If the swipe is the empty Box, redirect
                onSwipeEnd()
                return
              }

              setStep(newIdx)
            }}
            index={step}
          >
            <>{introPage}</>
            {stepPages.map((page) => page)}
            <>{/* This empty Box is use to allow swipping to the right in the last view, and close the tutorial */}</>
          </SwipeableViews>
          {step === 0 && (
            <Box sx={classes.buttons}>
              <WhiteButton
                type="submit"
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  setStep(step + 1)
                }}
                sx={classes.buttonStart}
              >
                {t(startButtonTextKey ?? 'tutorial.shiftplan.intro.start')}
              </WhiteButton>
            </Box>
          )}
          {step > 0 && (
            <MobileStepper
              variant="dots"
              steps={stepPages.length}
              activeStep={step - 1}
              backButton={null}
              nextButton={null}
              position="static"
              sx={classes.stepper}
            />
          )}
        </Box>
        {!isSmallScreen && (
          <Box sx={classes.buttonContainer}>
            <IconButton
              sx={[classes.arrowButtons, classes.arrowMarginLeft]}
              onClick={() => {
                if (step >= stepPages.length) {
                  //If we clicked right in the last step, redirect
                  onSwipeEnd()
                  return
                }

                setStep(step + 1)
              }}
              data-testid="calendar__button-next"
              size="large"
            >
              <ArrowRightS />
            </IconButton>
          </Box>
        )}
      </Box>
    </Page>
  )
}

export default Tutorial
