import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Checkbox from 'components/Checkbox'
import useFilterContext from 'pages/AbsencePlanner/contexts/FilterContext'
import { absenceRequest, shiftTypes } from 'constants/shift'
import { AbsencePlannerBadgeTypesT } from 'pages/AbsencePlanner/types'
import FilterContainer from '../FilterContainer'
import { entryTypesClasses } from './styles'

const EntryTypes: React.FC = () => {
  const { t } = useFavurTranslation()
  const { shownTypes, setShownTypes } = useFilterContext()
  const handleCheckChange = (type: AbsencePlannerBadgeTypesT, show: boolean) => {
    if (!show) {
      setShownTypes(shownTypes.concat(type))
    } else {
      setShownTypes(shownTypes.filter((shownType) => shownType !== type))
    }
  }

  return (
    <FilterContainer title={t('absencePlanner.taskbar.filter.showEntryTypes')}>
      <Checkbox
        checked={shownTypes.includes(shiftTypes.absence)}
        label={t('absencePlanner.taskbar.types.absences')}
        sx={entryTypesClasses.checkBox}
        onChange={() => {
          handleCheckChange(shiftTypes.absence, shownTypes.includes(shiftTypes.absence))
        }}
        dataTestId="absencePlanner-filter-absence"
      />
      <Checkbox
        checked={shownTypes.includes(absenceRequest)}
        label={t('absencePlanner.taskbar.types.absenceRequests')}
        sx={entryTypesClasses.checkBox}
        onChange={() => {
          handleCheckChange(absenceRequest, shownTypes.includes(absenceRequest))
        }}
        dataTestId="absencePlanner-filter-absenceRequest"
      />
      <Checkbox
        checked={shownTypes.includes(shiftTypes.plannedShift)}
        label={t('absencePlanner.taskbar.types.shifts')}
        sx={entryTypesClasses.checkBox}
        onChange={() => {
          handleCheckChange(shiftTypes.plannedShift, shownTypes.includes(shiftTypes.plannedShift))
        }}
        dataTestId="absencePlanner-filter-shift"
      />
    </FilterContainer>
  )
}

export default EntryTypes
