import React, { useState, useEffect } from 'react'

import { Typography } from '@mui/material'
import useInterval from 'hooks/useInterval'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { timerClasses } from './styles'

interface ITimerComponentProps {
  timeout: number
  timerReset: boolean
}

const Timer: React.FC<ITimerComponentProps> = ({ timeout, timerReset }) => {
  const { t } = useFavurTranslation()
  const [timer, setTimer] = useState(timeout)

  useInterval(() => {
    setTimer(timer > 0 ? timer - 1 : 0)
  }, 1000)

  useEffect(() => {
    setTimer(timeout)
  }, [timeout, setTimer, timerReset])

  return (
    <Typography variant="caption" sx={timerClasses.timer} aria-label="timerText">
      {t('login.validate.content.timer')}
      <b>&nbsp;{timer}&nbsp;</b>
      {t('login.validate.content.timerUnit')}
    </Typography>
  )
}

export default Timer
