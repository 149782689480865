import React from 'react'
import { FormControl, SxProps, Theme } from '@mui/material'
import nationalities from 'i18n-nationality'
import isArray from 'lodash/fp/isArray'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { FormHandlersT } from 'hooks/useFavurForm'
import Select from 'components/Select'
import { getKebabCaseFromSnakeCase } from 'utils/string'
import { cleanDefaultValue, getSelectOptions, getOptsArray } from './helpers'
import { selectClasses } from '../../styles'

interface IControlledSelectProps {
  adornment?: React.ReactNode
  defaultValue?: string | number | null
  formHandlers: FormHandlersT
  name: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  options?: string[] | nationalities.LocalizedNationalityNames
  required?: boolean
  sortOptions?: boolean
  translate?: boolean
  sx?: SxProps<Theme>
}

const UncontrolledSelect: React.FC<IControlledSelectProps> = ({
  adornment,
  defaultValue,
  formHandlers,
  name,
  onChange: customOnChange,
  options,
  required,
  sortOptions,
  translate,
  sx,
}) => {
  const { t } = useFavurTranslation()
  const { onChange, ...fh } = formHandlers
  const id = getKebabCaseFromSnakeCase(name)
  const selectOptions = options ?? getSelectOptions(name)

  const memoizedTranslateOptions = React.useCallback(
    (optionsToTranslate: string[] | { [key: string]: string }) => {
      if (isArray(optionsToTranslate)) {
        return optionsToTranslate.map((option) => t(option))
      }

      return Object.entries(optionsToTranslate)
        .map(([key, value]) => {
          return {
            label: key,
            value: t(value),
          }
        })
        .reduce((map: { [label: string]: string }, obj) => {
          map[obj.label] = obj.value
          return map
        }, {})
    },
    [t],
  )

  const translatedOptions = translate ? memoizedTranslateOptions(selectOptions) : selectOptions
  const optionsAsArr = getOptsArray({ opts: translatedOptions, sortOptions, capitalize: translate })
  const defaultValueStr = cleanDefaultValue(defaultValue)

  return (
    <FormControl variant="standard" fullWidth>
      <Select
        sx={[...(Array.isArray(sx) ? sx : [sx]), selectClasses.root]}
        required={required}
        fullWidth
        {...fh}
        id={id}
        defaultValue={defaultValueStr}
        onChange={(e) => {
          onChange(e)
          if (customOnChange) {
            customOnChange(e)
          }
        }}
        InputProps={{
          endAdornment: adornment,
        }}
        data-testid={`uncontrolled-select-${name}`}
      >
        {optionsAsArr.map(({ value, label }) => (
          <option value={value} key={value} data-testid={`uncontrolled-select-${name}-${value}`}>
            {t(label)}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}

export default UncontrolledSelect
