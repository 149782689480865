import { Box, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { CmsContentBlocksT, CmsContentT } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { DivisionLine } from 'components/Basics'
import Block from '../Components/Block'
import { CONTENT_TYPES } from '../constants'
import { formatDateRange } from '../EventsList/EventCard/utils'
import { getMetadata } from '../helper'
import { styles } from './styles'
import { getContentViewDate } from './utils'

const ContentView: React.FC<{ content: CmsContentT }> = ({ content }) => {
  const { t, language, locale } = useFavurTranslation()
  const metadata = useMemo(() => getMetadata(content, language), [content, language])
  const blocks = useMemo(
    () =>
      content.blocks
        ? // eslint-disable-next-line
          [...content.blocks].sort(
            (a: CmsContentBlocksT, b: CmsContentBlocksT): number => (a.order ?? 0) - (b.order ?? 0),
          )
        : [],
    [content],
  )
  const { history } = content
  const createdAt = useMemo(() => getContentViewDate({ locale, date: content.createdAt }), [content.createdAt, locale])
  const lastUpdate = useMemo(() => getContentViewDate({ locale, date: history?.[history?.length - 1]?.updatedAt }), [
    history,
    locale,
  ])

  if (!metadata || !blocks) return null

  return (
    <Box sx={styles.contentBlock}>
      <Typography sx={styles.title} variant="h1">
        {metadata?.title}
      </Typography>
      {content.contentType === CONTENT_TYPES.event && (
        <Typography sx={styles.date} variant="body1">
          {formatDateRange({ ...content, locale, t })}
        </Typography>
      )}
      {blocks.map((block) => (
        <Block block={block} key={block.order} />
      ))}
      <Box sx={styles.footer}>
        <DivisionLine />
        <Typography variant="body1" sx={styles.date}>
          {`${t('page.cms.content.createdAt')} ${createdAt}`}
        </Typography>
        <Typography variant="body1" sx={styles.date}>
          {`${t('page.cms.content.lastUpdate')} ${lastUpdate}`}
        </Typography>
      </Box>
    </Box>
  )
}

export default ContentView
