import { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'
import { updateTeamPublicStatusMutation, updateTeamShowTeamplanCostCentersMutation } from './queries'
import type { MutationResult, TeamT } from 'types'

type AllowedFieldsT = 'public' | 'showTeamplanCostCenters'

interface IUseUpdateToggleStatusProps {
  team: TeamT
  fieldName: AllowedFieldsT
  refetchTeam: () => void
}

const mutationsByField = {
  public: updateTeamPublicStatusMutation,
  showTeamplanCostCenters: updateTeamShowTeamplanCostCentersMutation,
}

const useUpdateTeamToggleStatus = ({ team, fieldName, refetchTeam }: IUseUpdateToggleStatusProps) => {
  const [changeStatusLoading, setChangeStatusLoading] = useState(false)

  const mutation = mutationsByField[fieldName]
  const [updateStatus] = useMutation<MutationResult>(mutation)
  const onStatusChange = useCallback(
    (newStatus: boolean) => {
      // We don't use the loading from useMutation because if the switch is spammed, it gets stuck on loading = true forever
      if (!changeStatusLoading) {
        setChangeStatusLoading(true)

        updateStatus({ variables: { id: team.id, [fieldName]: newStatus } }).then(() => {
          refetchTeam()
          setChangeStatusLoading(false)
        })
      }
    },
    [changeStatusLoading, updateStatus, team.id, fieldName, refetchTeam],
  )

  return { onStatusChange }
}

export default useUpdateTeamToggleStatus
