import React from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { AlertS, ArrowRightThinM } from 'icons'
import Card from 'components/Basics/Card'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PersonT } from 'types'
import routes from 'services/RoutesProvider/routes'
import ListItem from 'components/TeamList/ListItem'
import { classes as itemClasses } from 'components/TeamList/ListItem/styles'
import { PERSONS_LIST_STATUS } from 'constants/person'

interface IOwnerPersonsItem {
  activePersons: PersonT[]
  inactivePersons: PersonT[]
  ownerId: number
}

const personsWithoutATeam = (persons: PersonT[]) => {
  return persons.some((person) => person.teamPermissions?.length === 0)
}

const personsInsideATeam = (persons: PersonT[]) => {
  return persons.some((person) => (person.teamPermissions?.length ?? 0) > 0)
}
const PersonsItem: React.FC<IOwnerPersonsItem> = ({ ownerId, activePersons, inactivePersons }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  const icon = (
    <Box component="span" sx={[itemClasses.arrowIcon]}>
      <ArrowRightThinM />
    </Box>
  )

  return (
    <>
      <Card marginBottom={1.125}>
        <ListItem
          onClick={() => {
            history.push(routes.teamOwnerMembers(ownerId, PERSONS_LIST_STATUS.ACTIVE))
          }}
          text={t('page.teams.ownerActiveMembers')}
          subText={t('page.teams.list.numPeople', { numEmployees: activePersons.length })}
          warning={personsWithoutATeam(activePersons) ? t('page.teams.personsWithoutTeam') : undefined}
          alertIcon={AlertS}
          showDivider={true}
          firstItem={true}
          icon={icon}
        />
        <ListItem
          onClick={() => {
            history.push(`${routes.teamOwnerMembers(ownerId, PERSONS_LIST_STATUS.INACTIVE)}`)
          }}
          text={t('page.teams.ownerInactiveMembers')}
          subText={t('page.teams.list.numPeople', { numEmployees: inactivePersons.length })}
          warning={personsInsideATeam(inactivePersons) ? t('page.teams.personsWithTeam') : undefined}
          alertIcon={AlertS}
          showDivider={false}
          firstItem={false}
          icon={icon}
        />
      </Card>
    </>
  )
}

export default PersonsItem
