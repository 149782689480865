import React, { useContext } from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Page from 'components/Page'
import FlashMessagesContext from 'services/FlashMessages/context'
import { classes } from './styles'
import { IInvitationStepProps } from '../types'
import Cross from './Assets/Cross'

const FailureWithoutForm = ({ invitation }: IInvitationStepProps) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { closeLatest } = useContext(FlashMessagesContext)

  closeLatest()

  return (
    <Page
      showHeader
      header={
        <>
          <Typography variant="h2">{t('register.content.title')}</Typography>
          <Typography variant="h2">{invitation?.tenantName}</Typography>
        </>
      }
    >
      <Box component={Paper} sx={classes.failureContainerPaper} p={1}>
        <Cross sx={classes.cross} />
        <Typography sx={classes.title} variant="h2" data-testid="invitation-failure-title">
          {t('invitation.birthdayFailure.content.title')}
        </Typography>
        <Typography sx={classes.terms} variant="body2">
          {t('invitation.birthdayFailure.content.description')}
        </Typography>
      </Box>
      <Box sx={classes.actionButtonsContainer}>
        <Button
          sx={classes.secondaryButton}
          type="submit"
          variant="contained"
          color="secondary"
          onClick={() => history.push(routes.contact)}
          data-testid="contact"
        >
          {t('page.contact.page.title')}
        </Button>
      </Box>
    </Page>
  )
}

export default FailureWithoutForm
