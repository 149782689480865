import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import BackwardLink from 'components/BackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import PinLogin from 'pages/PinLogin'
import { headerClasses } from 'pages/UserSettings/styles'
import routes from 'services/RoutesProvider/routes'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'

import useRedirectByTenantTypes from './hooks/useRedirectByTenantTypes'
import { EmploymentDataTabsName } from './Tabs/helpers'
import EmploymentTabPanel from './Tabs/EmploymentTabPanel'

const EmploymentData: React.FC<RouteComponentProps<{ tab?: EmploymentDataTabsName }>> = ({ match }) => {
  const { tab: tabName } = match.params
  const { t } = useFavurTranslation()
  const { pushOriginLinkOrDefault, originBackLink } = useStateBackLink()
  const { status, isRedirecting } = useRedirectByTenantTypes('high', originBackLink)

  if (status === 'loading' || isRedirecting) {
    return (
      <Page>
        <SimpleLoading />
      </Page>
    )
  }

  return (
    <PinLogin>
      <Page
        header={
          <BackwardLink
            onClickIcon={() => {
              pushOriginLinkOrDefault(routes.userSettings)
            }}
            title={t('page.userSettings.personalData.header')}
          />
        }
        sxHeader={headerClasses.subHeader}
        noPadding
        hideNativeNavigation
      >
        <EmploymentTabPanel tabName={tabName} originPage={originBackLink} />
      </Page>
    </PinLogin>
  )
}

export default EmploymentData
