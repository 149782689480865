import React, { memo, useRef } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { PlannedShiftT } from 'types'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { prepareShowingShifts } from '../utils'
import { classes } from './styles'
import CalendarDayShifts from './CalendarDayShifts'

interface ICalendarDayProps {
  day: Date
  isCurrentMonth: boolean
  isToday: boolean
  shifts: PlannedShiftT[]
  onClick: () => void
  shiftUpdates: TaskT<ShiftUpdateTaskT>[]
}

const CalendarDay: React.FC<ICalendarDayProps> = ({ day, isCurrentMonth, isToday, shifts, onClick, shiftUpdates }) => {
  const sortedShifts = prepareShowingShifts(shifts)
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <Box sx={[classes.dayContainer, ...(!isCurrentMonth ? [classes.differentMonth] : [])]} ref={containerRef}>
      <Button disableRipple onClick={onClick} variant="text" sx={classes.calendarDayButton}>
        <Box sx={classes.insideButton}>
          <Typography variant="body2" sx={[classes.dayNumber, ...(isToday ? [classes.referenceDay] : [])]}>
            {day.getDate()}
          </Typography>
          {containerRef.current && (
            <CalendarDayShifts sortedShifts={sortedShifts} shiftUpdates={shiftUpdates} containerRef={containerRef} />
          )}
        </Box>
      </Button>
    </Box>
  )
}

export default memo(CalendarDay)
