import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const ArrowLeftS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M10.56 2.146l.708.708a.5.5 0 010 .707L6.829 8l4.439 4.44a.5.5 0 010 .706l-.707.708a.5.5 0 01-.707 0l-5.5-5.5a.5.5 0 010-.708l5.5-5.5a.5.5 0 01.707 0z"
      />
    </Svg>
  )
}

export default ArrowLeftS
