import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const QuestionsL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M20 3a1 1 0 0 1 1 .88V16a1 1 0 0 1-.88 1H17v3.07c0 .1-.03.2-.08.27a.5.5 0 0 1-.62.19l-.08-.05L11 17H4a1 1 0 0 1-1-.88V4a1 1 0 0 1 .88-1H20zm-.5 1.5h-15v11h6.65c.2.03.38.08.55.18l.13.07 3.67 2.45V16.86a1.5 1.5 0 0 1 1.36-1.35l.14-.01h2.5v-11zM12 13a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5zm0-7.5a3 3 0 0 1 .85 5.88l-.1.02v.1a.75.75 0 0 1-1.5 0v-.75c0-.38.28-.7.65-.74L12 10a1.5 1.5 0 1 0-1.5-1.64v.14a.75.75 0 0 1-1.5.1v-.1a3 3 0 0 1 3-3z"
      />
    </Svg>
  )
}

export default QuestionsL
