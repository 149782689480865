import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { TenantT } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Dialog from 'components/Dialog'
import { classes } from './styles'

interface IConfirmationDialogProps {
  tenants: TenantT[]
  onAccept: () => void
  onCancel: () => void
}

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({ onAccept, onCancel, tenants }) => {
  const { t } = useFavurTranslation()

  const dialogActions = (
    <Box sx={classes.confirmButtonsBlock}>
      <Button variant="text" onClick={onCancel} color="secondary">
        {t('personalData.sendShareUserDataPage.confirm.cancel')}
      </Button>
      <Button variant="text" onClick={onAccept} color="secondary" data-testid="create-workflows">
        {t('personalData.sendShareUserDataPage.confirm.accept')}
      </Button>
    </Box>
  )
  return (
    <Dialog open title={t('personalData.sendShareUserDataPage.confirm.title')} actions={dialogActions}>
      <Typography variant="body2" sx={classes.confirmBodyText}>
        {t('personalData.sendShareUserDataPage.confirm.body1')}
      </Typography>
      <Typography variant="subtitle2" sx={classes.confirmShareWithText}>
        {t('personalData.sendShareUserDataPage.confirm.shareWith')}
      </Typography>
      <Box sx={classes.confirmTenantList}>
        {tenants.map((tenant) => (
          <Box key={tenant.id}>
            <Typography variant="body2" sx={classes.confirmTenantText}>
              {tenant.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Dialog>
  )
}

export default ConfirmationDialog
