import React from 'react'
import Card from 'components/Basics/Card'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import { CheckListT } from '../../types'
import { classes } from '../../styles'
import useFilterContext from '../../context'

interface ICheckListProps {
  configuration: CheckListT
}

const CheckList: React.FC<ICheckListProps> = ({ configuration }) => {
  const { toggleOption, isCheckedOption } = useFilterContext()

  return (
    <Box sx={classes.cardListItem}>
      <Card data-testid={`filter-card-${configuration.name}`}>
        <Typography variant="caption" sx={classes.cardTitle}>
          {configuration.label}
        </Typography>
        {configuration.options.map((option) => (
          <Box key={option.name} alignItems="center" display="flex" sx={classes.checkBoxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={classes.checkBox}
                  checked={isCheckedOption(configuration.name, option.name)}
                  name={option.name}
                  onChange={(event) => {
                    const optionName = event.target.name
                    toggleOption(configuration.name, optionName)
                  }}
                  data-testid="filter-checkbox-option"
                />
              }
              sx={classes.checkListOption}
              label={option.label}
            />
          </Box>
        ))}
      </Card>
    </Box>
  )
}

export default CheckList
