import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const NewSimL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.25C8.20979 3.25 6.4929 3.96116 5.22703 5.22703C3.96116 6.4929 3.25 8.20979 3.25 10C3.25 11.7902 3.96116 13.5071 5.22703 14.773C6.4929 16.0388 8.20979 16.75 10 16.75C11.7902 16.75 13.5071 16.0388 14.773 14.773C16.0388 13.5071 16.75 11.7902 16.75 10C16.75 8.20979 16.0388 6.4929 14.773 5.22703C13.5071 3.96116 11.7902 3.25 10 3.25ZM6.28769 6.28769C7.27226 5.30312 8.60761 4.75 10 4.75C11.3924 4.75 12.7277 5.30312 13.7123 6.28769C14.6969 7.27225 15.25 8.60761 15.25 10C15.25 11.3924 14.6969 12.7277 13.7123 13.7123C12.7277 14.6969 11.3924 15.25 10 15.25C8.60761 15.25 7.27226 14.6969 6.28769 13.7123C5.30312 12.7277 4.75 11.3924 4.75 10C4.75 8.60761 5.30312 7.27226 6.28769 6.28769ZM18.25 6C18.25 5.58579 18.5858 5.25 19 5.25H22.3107L27.75 10.6893V25.0022C27.75 26.522 26.5177 27.75 25 27.75H15C13.4812 27.75 12.25 26.5188 12.25 25V19C12.25 18.5858 12.5858 18.25 13 18.25C13.4142 18.25 13.75 18.5858 13.75 19V25C13.75 25.6904 14.3096 26.25 15 26.25H25C25.6914 26.25 26.25 25.6915 26.25 25.0022V11.3107L21.6893 6.75H19C18.5858 6.75 18.25 6.41421 18.25 6ZM15.5 16C15.5 15.1716 16.1716 14.5 17 14.5H21H23C23.8284 14.5 24.5 15.1716 24.5 16V23C24.5 23.8284 23.8284 24.5 23 24.5H21H17C16.1716 24.5 15.5 23.8284 15.5 23V19.5V18.5V16ZM17 15.5H20.5V23.5H19V22V21V20C19 19.1716 18.3284 18.5 17.5 18.5H16.5V16C16.5 15.7239 16.7239 15.5 17 15.5ZM23 23.5H21.5V21.5H23.5V23C23.5 23.2761 23.2761 23.5 23 23.5ZM23.5 20.5H21.5V19.5H23.5V20.5ZM23.5 18.5H21.5V17.5H23.5V18.5ZM21.5 15.5V16.5H23.5V16C23.5 15.7239 23.2761 15.5 23 15.5H21.5ZM16.5 19.5V21H18V20C18 19.7239 17.7761 19.5 17.5 19.5H16.5ZM16.5 23V22H18V23.5H17C16.7239 23.5 16.5 23.2761 16.5 23ZM10 6.25C10.4142 6.25 10.75 6.58579 10.75 7V9.25H13C13.4142 9.25 13.75 9.58579 13.75 10C13.75 10.4142 13.4142 10.75 13 10.75H10.75V13C10.75 13.4142 10.4142 13.75 10 13.75C9.58579 13.75 9.25 13.4142 9.25 13V10.75H7C6.58579 10.75 6.25 10.4142 6.25 10C6.25 9.58579 6.58579 9.25 7 9.25H9.25V7C9.25 6.58579 9.58579 6.25 10 6.25Z"
      />
    </Svg>
  )
}

export default NewSimL
