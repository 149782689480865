import React, { useCallback, useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { Locale } from 'date-fns'
import { AvatarDataT, PlannedShiftT, ShiftsByTenantCCT, TenantT } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getCardDate } from 'pages/Shifts/utils'
import { groupShiftsByShortDescription } from 'utils/shifts'
import { getShiftKey } from 'types/utils'
import { ShiftUpdateStatus } from 'components/ShiftsList/types'
import { getTodayFormatted } from 'utils/date'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { shiftTypes } from 'constants/shift'
import ShiftCardAbsence from './ShiftTypes/ShiftCardAbsence'
import ShiftCardPlannedShift from './ShiftTypes/ShiftCardPlannedShift'
import UnacknowledgedUpdate from './ShiftTypes/UnacknowledgedUpdate'
import AvatarSection from './components/AvatarSection'
import EffectiveShiftsContainer from './ShiftTypes/EffectiveShiftsContainer'
import { classes } from './styles'

const filterDayShifts = (shifts: PlannedShiftT[] | undefined, date: string) =>
  (shifts ?? []).filter((shift) => shift.date === date)

const getAreThereShifts = (
  groupedShifts: {
    costCenterName: string
    costCenterUuid: string
    shifts: PlannedShiftT[][]
  }[],
): boolean => {
  if (!groupedShifts || groupedShifts.length === 0) {
    return false
  }

  const numShifts = groupedShifts.reduce((acc, gs) => acc + gs.shifts.length, 0)
  return numShifts > 0
}

const getCanShowEffectiveTimes = (
  isPast: boolean,
  areThereShifts: boolean,
  areThereEffectiveShifts: boolean,
  hasAcknowledgedShiftUpdate: boolean,
  hideEffectiveTimes: boolean,
) => !hideEffectiveTimes && isPast && (areThereShifts || areThereEffectiveShifts) && hasAcknowledgedShiftUpdate

interface IShiftDataProps {
  avatarData?: AvatarDataT
  date?: string
  effectiveShifts?: ShiftsByTenantCCT[]
  effectiveShiftsError?: boolean
  shiftData: ShiftsByTenantCCT[]
  shiftUpdates?: TaskT<ShiftUpdateTaskT>[]
  tenant: TenantT
  hideEffectiveTimes?: boolean
  hideComment?: boolean
  hideHistoricalShifts?: boolean
  hideAbsenceType?: boolean
  hideAddAvatarBadge?: boolean
  showAvatarUserName?: boolean
}

const ShiftData: React.FC<IShiftDataProps> = ({
  avatarData,
  date,
  effectiveShifts,
  effectiveShiftsError,
  shiftData,
  shiftUpdates = [],
  tenant,
  hideEffectiveTimes = false,
  hideComment = false,
  hideHistoricalShifts = false,
  hideAbsenceType = false,
  hideAddAvatarBadge = false,
  showAvatarUserName = false,
}) => {
  const { t, locale } = useFavurTranslation()
  const formattedDate = useMemo(() => getCardDate(date as string, locale as Locale), [date, locale])

  const groupedShifts = useMemo(
    () =>
      shiftData.map((costcenter) => ({
        costCenterName: costcenter.costCenterName,
        costCenterUuid: costcenter.costCenterUuid,
        shifts: groupShiftsByShortDescription(costcenter.shifts as PlannedShiftT[]),
      })),
    [shiftData],
  )
  const areThereShifts = getAreThereShifts(groupedShifts)
  const areThereEffectiveShifts = effectiveShifts !== undefined && effectiveShifts.length > 0
  const getUpdateTask = useCallback(
    (shiftGroup: PlannedShiftT[]) => {
      const taskIds = shiftGroup.map((shift) => `${shift.updateTaskId}`)
      return shiftUpdates.find((task) => task.id && taskIds.includes(`${task.id}`))
    },
    [shiftUpdates],
  )
  const isPast = Boolean(date && date < getTodayFormatted())
  const showAvatarAddBadge = !avatarData?.avatarUrl && !hideAddAvatarBadge

  const { hasAcknowledgedShiftUpdate } = useMemo(() => {
    const allShifts = shiftData.flatMap((costcenter) => costcenter.shifts as PlannedShiftT[])
    const task = getUpdateTask(allShifts)
    const acknowledgedShiftUpdate = task === undefined || task.status === ShiftUpdateStatus.reviewFrontlineSeen

    return { hasAcknowledgedShiftUpdate: acknowledgedShiftUpdate }
  }, [getUpdateTask, shiftData])

  const canShowEffectiveTimes = useMemo(
    () =>
      getCanShowEffectiveTimes(
        isPast,
        areThereShifts,
        areThereEffectiveShifts,
        hasAcknowledgedShiftUpdate,
        hideEffectiveTimes,
      ),
    [isPast, areThereShifts, areThereEffectiveShifts, hasAcknowledgedShiftUpdate, hideEffectiveTimes],
  )

  return (
    <>
      <Typography variant="subtitle1" sx={classes.tenantName} data-testid="shift-card__title">
        {tenant.name}
      </Typography>
      <Typography variant="caption" data-testid="shift-card__date">
        {formattedDate}
      </Typography>
      {groupedShifts.length > 0 && avatarData && (
        <AvatarSection avatarData={avatarData} showAddBadge={showAvatarAddBadge} showUserName={showAvatarUserName} />
      )}
      {canShowEffectiveTimes && (
        <EffectiveShiftsContainer costcenters={effectiveShifts} effectiveShiftsError={Boolean(effectiveShiftsError)} />
      )}
      {areThereShifts &&
        groupedShifts.map((costcenter, i) => {
          const task = getUpdateTask(costcenter.shifts.flat())
          if (task && !task.taskData.acknowledgedAt) {
            return (
              <UnacknowledgedUpdate
                task={task}
                isFirst={i === 0}
                date={date as string}
                key={`${costcenter.costCenterUuid}-unacknowledged-shift-update-${date}`}
              />
            )
          }
          const containerClasses = [
            classes.shiftContainer,
            ...(!isPast && i === 0 && costcenter.shifts.length ? [classes.shiftContainerTopMargin] : []),
          ]

          return (
            <Box sx={containerClasses} key={costcenter.costCenterUuid}>
              {isPast && (
                <Typography sx={classes.plannedTimesTitle} variant="subtitle2">
                  {t('page.shifts.shiftCard.plannedTime')}
                </Typography>
              )}

              {costcenter.shifts.map((shiftGroup) => {
                const shiftGroupFirst = shiftGroup[0] as PlannedShiftT
                if (shiftGroupFirst.type === shiftTypes.absence) {
                  return (
                    <ShiftCardAbsence
                      shift={shiftGroupFirst}
                      key={getShiftKey(shiftGroupFirst)}
                      hideComment={hideComment}
                      hideAbsenceType={hideAbsenceType}
                    />
                  )
                }
                const hasTask = task ? shiftGroup.map((shift) => `${shift.updateTaskId}`).includes(`${task.id}`) : false
                const shiftKey = `${date}-${tenant.id}-${costcenter.costCenterUuid}-${getShiftKey(shiftGroupFirst)}`
                return (
                  <ShiftCardPlannedShift
                    shifts={shiftGroup}
                    costCenterName={costcenter.costCenterName}
                    key={shiftKey}
                    oldShifts={
                      !hideHistoricalShifts && hasTask
                        ? filterDayShifts(task?.shiftsUpdatedHistory, date as string)
                        : undefined
                    }
                    hideComment={hideComment}
                  />
                )
              })}
            </Box>
          )
        })}
    </>
  )
}

export default ShiftData
