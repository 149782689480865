import { useCallback, useState } from 'react'
import { DocumentT } from 'types'
import { DocumentsPaginated } from 'pages/Documents/DocumentList/types'
import { FilterStateT } from 'components/Filter/types'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { documentsPaginatedQuery } from './queries'

const LIMIT = 20

const useDocumentsQueryPaginated = () => {
  const [offset, setOffset] = useState<number>(0)
  const [filters, setFilters] = useState<FilterStateT>({})
  const [documents, setDocuments] = useState<DocumentT[]>([])
  const [documentsLoading, setDocumentsLoading] = useState(true)

  const { data, loading } = useJamesApolloQuery<{ documentsPaginated: DocumentsPaginated }>(documentsPaginatedQuery, {
    variables: {
      filters,
      offset,
      limit: LIMIT,
    },
    fetchPolicy: 'no-cache',
    onCompleted(responseData) {
      setDocuments([...documents, ...responseData.documentsPaginated.list])
      setDocumentsLoading(false)
    },
  })

  const totalCount = data?.documentsPaginated.totalCount
  const hasMoreElements = totalCount && documents && totalCount > documents?.length

  const loadMore = useCallback(() => {
    setOffset(documents.length)
  }, [documents?.length])

  // if this executes after the first call to documents, it will set the documents to []
  const updateFilters = useCallback((filtersUpdate: FilterStateT) => {
    setDocuments([])
    setOffset(0)
    setDocumentsLoading(true)
    setFilters(filtersUpdate)
  }, [])

  return {
    list: documents,
    hasMoreElements,
    loadMore,
    updateFilters,
    loading: loading || documentsLoading,
  }
}

export default useDocumentsQueryPaginated
