import React, { useState } from 'react'
import { Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import { LogoutM } from 'icons'
import palette from 'utils/theme/palette'
import LogoutDialog from 'pages/UserSettings/components/LogoutDialog'

const UserSettingsLogout: React.FC = () => {
  const { t } = useFavurTranslation()
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false)
  return (
    <Box>
      <LogoutDialog isOpen={isLogoutDialogOpen} onClose={() => setIsLogoutDialogOpen(false)} />
      <UserSettingsSectionCTA
        title={t('navigation.main.menu.logout')}
        onClick={() => setIsLogoutDialogOpen(true)}
        icon={<LogoutM stroke={palette.primary.main} />}
        data-testid="logout"
      />
    </Box>
  )
}

export default UserSettingsLogout
