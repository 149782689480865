import { Box, Button } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import React from 'react'
import { ArrowRightThinS, ManagerTasksM, TaskCenterM } from 'icons'
import ComponentBadge from 'components/ComponentBadge'
import palette from 'utils/theme/palette'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import TeaserToAvatar from 'components/TeaserToAvatar'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { classes } from './styles'
import FilterContainer from '../Filters/FilterContainer'
import { taskBarStates } from '../types'
import PlanAbsenceButton from './PlanAbsenceButton'

interface INativeCTAProps {
  taskCountTotal: number
  isAbsenceManager: boolean
}

const NativeCTA: React.FC<INativeCTAProps> = ({ taskCountTotal, isAbsenceManager }) => {
  const { t } = useFavurTranslation()
  const setTaskBarState = useTaskBarStateDispatchContext()
  const { deselectPerson, setSelectedTaskUuid } = useSelectedStatesActionsContext()

  return (
    <Box sx={classes.nativeEntryPointsContainer}>
      <TeaserToAvatar isAbsencePlanner />
      <FilterContainer title={t('absencePlanner.taskbar.menu.views')}>
        {isAbsenceManager && (
          <Button
            sx={classes.buttonAction}
            variant="text"
            color="primary"
            onClick={() => {
              deselectPerson()
              setTaskBarState(taskBarStates.managerTasks)
            }}
          >
            <Box sx={classes.buttonActionBadgeAndText}>
              <ComponentBadge badgeContent={taskCountTotal} max={999}>
                <ManagerTasksM fill={palette.primary[700]} />
              </ComponentBadge>
              <Box sx={classes.buttonActionText}>{t('absencePlanner.taskbar.toolTip.managerTasks')}</Box>
            </Box>
            <Box sx={classes.buttonActionArrow}>
              <ArrowRightThinS />
            </Box>
          </Button>
        )}
        <Button
          sx={classes.buttonAction}
          variant="text"
          color="primary"
          onClick={() => {
            setSelectedTaskUuid(null)
            setTaskBarState(taskBarStates.userTasks)
          }}
        >
          <Box sx={classes.buttonActionBadgeAndText}>
            <TaskCenterM fill={palette.primary[700]} />
            <Box sx={classes.buttonActionText}>{t('absencePlanner.taskbar.toolTip.pendingTasks')}</Box>
          </Box>
          <Box sx={classes.buttonActionArrow}>
            <ArrowRightThinS />
          </Box>
        </Button>
      </FilterContainer>

      <FilterContainer title={t('absencePlanner.taskbar.absencerequest.title')}>
        <PlanAbsenceButton
          onClick={() => {
            setTaskBarState(taskBarStates.newAbsenceRequest)
          }}
        />
      </FilterContainer>
    </Box>
  )
}

export default NativeCTA
