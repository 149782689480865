import theme from 'utils/theme'

export const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  header: {
    display: 'flex',
    height: '24px',
    flexDirection: 'column',
    marginTop: '24px',
  },
  buttonClose: {
    color: theme.palette.primary.main,
    alignSelf: 'flex-end',
  },
  image: {
    width: '392px',
    height: '449px',
    marginBottom: theme.spacing(1.5),
    alignSelf: 'center',
    objectFit: 'contain',
  },
  textTitle: {
    color: theme.palette.grey[600],
    marginBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  text: {
    color: theme.palette.grey[600],
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  buttons: {
    textAlign: 'center',
  },
  buttonGoTo: {
    minWidth: theme.spacing(7.5),
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
}
