import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const ArrowLeftM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M14.354 3.646l-7.647 7.647a1 1 0 000 1.414l7.647 7.647a1 1 0 001.414 0l.707-.708a1 1 0 000-1.414L10.242 12l6.233-6.232a1 1 0 000-1.414l-.707-.708a1 1 0 00-1.414 0z"
      />
    </Svg>
  )
}

export default ArrowLeftM
