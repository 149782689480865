import React, { useState } from 'react'
import { FormControlLabel, FormLabel, Radio, RadioGroup, SxProps, Theme } from '@mui/material'
import { getKebabCaseFromSnakeCase } from 'utils/string'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { RadioCheckedM, RadioUncheckedM } from 'icons'
import { controlledRadioGroupStyles } from './styles'

interface IUncontrolledRadioGroupProps {
  name: string
  value: string
  defaultValue: string
  options: { [key: string]: string }
  onChange?: (newValue: string) => void
  label?: string
  column?: boolean
  radioGroupSx?: SxProps<Theme>
  variant?: 'selected' | 'default'
}

const ControlledRadioGroup: React.FC<IUncontrolledRadioGroupProps> = ({
  value: currentValue,
  name,
  options,
  label,
  onChange,
  defaultValue,
  column = false,
  radioGroupSx = {},
  variant = 'default',
}) => {
  const { t } = useFavurTranslation()
  const id = getKebabCaseFromSnakeCase(name)
  const optionEntries = Object.entries(options)
  const [selected, setSelected] = useState<string>()
  const selectedValue = selected ? selected : defaultValue
  return (
    <>
      {label && <FormLabel id={`${id}-label`}>{label}</FormLabel>}
      <RadioGroup
        value={currentValue}
        defaultValue={defaultValue}
        onChange={(e) => {
          setSelected(e.target.value)
          onChange && onChange(e.target.value)
        }}
        row={!column}
        sx={radioGroupSx}
      >
        {optionEntries.map(([value, labelKey]) => (
          <FormControlLabel
            value={value}
            control={<Radio checkedIcon={<RadioCheckedM />} icon={<RadioUncheckedM />} />}
            label={t(labelKey)}
            key={value}
            sx={variant === 'selected' && selectedValue === value ? controlledRadioGroupStyles.selected : []}
          />
        ))}
      </RadioGroup>
    </>
  )
}

export default ControlledRadioGroup
