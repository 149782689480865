import React, { useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AddM } from 'icons'
import Page from 'components/Page'
import { BoxedLoading } from 'components/LoadingIcon'
import { useOwnersOfUser } from 'hooks/useOwnersOfUser'
import useRolesViews from 'hooks/useRolesViews'
import TeamsList from './components/TeamsList'
import CreateTeamDialog from './components/CreateTeamDialog'
import FloatingBottomButton from '../../components/FloatingBottomButton'
import TeamsHeader from './components/TeamsHeader'
import { getFirstOwner, getOwnerPersonsFromOwnerId, parseUrlOwnerId } from './utils'

const TeamsPage: React.FC<RouteComponentProps<{ ownerId: string }>> = ({ match }) => {
  const { ownerId: ownerIdParam } = match.params
  const { t } = useFavurTranslation()
  const [open, setOpen] = useState(false)
  const { loading, owners } = useOwnersOfUser({ teamManagersOnly: true })
  const { setActiveView, roles, activeView } = useRolesViews()
  if (activeView !== roles.office) setActiveView(roles.office)

  const ownerId = useMemo(() => parseUrlOwnerId(ownerIdParam, owners) ?? getFirstOwner(owners), [ownerIdParam, owners])
  const ownerPersonsSelected = useMemo(() => getOwnerPersonsFromOwnerId(ownerId, owners), [ownerId, owners])

  if (loading || !owners || !ownerId) {
    return (
      <Page header={<Typography variant="h2">{t('page.team.header.title')}</Typography>}>
        <BoxedLoading />
      </Page>
    )
  }

  return (
    <Page header={<TeamsHeader owners={owners} ownerId={ownerId} />} hasFloatingButtons>
      <TeamsList ownerPersons={ownerPersonsSelected} />
      <FloatingBottomButton
        Icon={AddM}
        label={t('page.teams.buttons.newTeam')}
        onClick={() => setOpen(true)}
        testId="create-team-btn"
        nativeBottomMargin
      />
      <CreateTeamDialog open={open} setOpen={setOpen} ownerPersons={ownerPersonsSelected} />
    </Page>
  )
}

export default TeamsPage
