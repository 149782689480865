import React from 'react'
import { Typography } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Card from 'components/Basics/Card'

const NoTaskBox: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <Card>
      <CenteredBox>
        <Typography component="p">{t('page.tasks.content.noTasks')}</Typography>
      </CenteredBox>
    </Card>
  )
}

export default NoTaskBox
