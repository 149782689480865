import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const CloseThinM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.54806 3.39205C4.3528 3.58731 4.34803 3.90852 4.53742 4.10948L11.689 11.6983L4.97667 18.8211C4.78729 19.022 4.79205 19.3433 4.98731 19.5385L5.34087 19.8921C5.53613 20.0873 5.84795 20.0827 6.03733 19.8817L12.7191 12.7914L19.1675 19.6342C19.3569 19.8352 19.6687 19.8398 19.864 19.6445L20.2176 19.291C20.4128 19.0957 20.4176 18.7745 20.2282 18.5736L13.7492 11.6983L20.6674 4.35701C20.8568 4.15605 20.8521 3.83484 20.6568 3.63958L20.3033 3.28602C20.108 3.09076 19.7962 3.09539 19.6068 3.29635L12.7191 10.6052L5.59808 3.04882C5.40869 2.84786 5.09687 2.84323 4.90161 3.03849L4.54806 3.39205Z"
      />
    </Svg>
  )
}

export default CloseThinM
