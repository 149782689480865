import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const AbsencePlanL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L}>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M17.304 4h.003c1.534-.002 3.04.276 4.446.808a11.63 11.63 0 0 0-5.264 2.244 11.376 11.376 0 0 0-3.28 3.96A11.17 11.17 0 0 0 12.032 16c0 1.73.403 3.436 1.177 4.987a11.375 11.375 0 0 0 3.28 3.962 11.63 11.63 0 0 0 5.256 2.242 12.604 12.604 0 0 1-7.476.438 12.406 12.406 0 0 1-5.192-2.706 11.994 11.994 0 0 1-3.302-4.731 11.724 11.724 0 0 1-.68-5.674c.244-1.916.959-3.748 2.087-5.34a12.211 12.211 0 0 1 4.401-3.802A12.554 12.554 0 0 1 17.303 4Zm8.764 23.064.05-.038a14.48 14.48 0 0 0 .575-.464.862.862 0 0 0 .206-1.065.901.901 0 0 0-1.01-.444 9.671 9.671 0 0 1-.637.134l-.041.007a9.74 9.74 0 0 1-3.615-.063 9.629 9.629 0 0 1-3.893-1.772 9.375 9.375 0 0 1-2.704-3.265A9.17 9.17 0 0 1 14.032 16c0-1.419.33-2.82.967-4.095a9.375 9.375 0 0 1 2.704-3.264 9.63 9.63 0 0 1 3.893-1.772 9.739 9.739 0 0 1 3.615-.063l.041.007a9.644 9.644 0 0 1 .636.134.901.901 0 0 0 1.01-.443.862.862 0 0 0-.204-1.065 14.22 14.22 0 0 0-.575-.465l-.048-.037A14.444 14.444 0 0 0 17.304 2c-2.31 0-4.586.548-6.633 1.596a14.211 14.211 0 0 0-5.12 4.425 13.824 13.824 0 0 0-2.44 6.244 13.723 13.723 0 0 0 .796 6.64 13.994 13.994 0 0 0 3.852 5.522 14.405 14.405 0 0 0 6.028 3.143c2.24.556 4.584.573 6.831.049a14.437 14.437 0 0 0 5.45-2.555Zm-2.472-3.703a1 1 0 0 0 1-1v-.688h.688a1 1 0 0 0 0-2h-.688v-.69a1 1 0 1 0-2 0v.69h-.69a1 1 0 0 0 0 2h.69v.688a1 1 0 0 0 1 1Zm3.715-5.404a1 1 0 0 0 1-1v-.689h.69a1 1 0 1 0 0-2h-.69v-.689a1 1 0 1 0-2 0v.69h-.689a1 1 0 1 0 0 2h.69v.688a1 1 0 0 0 1 1Zm-5.743-4.392a1 1 0 0 0 1-1v-.688h.69a1 1 0 0 0 0-2h-.69v-.69a1 1 0 0 0-2 0v.69h-.689a1 1 0 0 0 0 2h.69v.688a1 1 0 0 0 1 1Z"
      />
    </Svg>
  )
}

export default AbsencePlanL
