import React from 'react'
import { Box, Card } from '@mui/material'
import { PersonT } from 'types'
import { DivisionLine } from 'components/Basics'
import { classes } from './styles'
import AvatarBlock from './AvatarBlock'
import TenantBlock from './TenantBlock'
import ValidationBlock from './ValidationBlock'

interface IEmployeeCardProps {
  person: PersonT
}

const EmployeeCard: React.FC<IEmployeeCardProps> = ({ person }) => (
  <Card elevation={0} sx={classes.card}>
    <AvatarBlock person={person} />
    <Box sx={classes.divisionLineContainer}>
      <DivisionLine />
    </Box>
    <TenantBlock person={person} />
    {person.favurUuid && <ValidationBlock favurUuid={person.favurUuid} />}
  </Card>
)

export default EmployeeCard
