import React, { useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material'
import { useMutation } from '@apollo/client'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import Card from 'components/Basics/Card'
import Page from 'components/Page'
import ActionButtons from 'components/Buttons/ActionButtons'
import { SimpleLoading } from 'components/LoadingIcon'
import { TenantT } from 'types'
import FlashMessagesContext from 'services/FlashMessages/context'
import usePersonsWithShareUserDataWorkflow, { PersonWithWorkflow } from './usePersonsWithShareUserDataWorkflow'
import { classes } from './styles'
import {
  getFormattedLastSharedData,
  getSelectedPersonIds,
  getSelectedTenants,
  getUserDataCollectionLastUpdate,
} from './utils'
import ConfirmationDialog from './ConfirmationDialog'
import SuccessScreen from './SuccessScreen'
import { shareOwnUserDataQuery } from './queries'

export type ShareOwnUserDataResult = {
  shareOwnUserData?: {
    personsWithErrors: number[]
    success: boolean
  }
}

const MAX_COMMENT_CHARACTERS = 1000

const maybeAddComment = (comment: string) => {
  return comment !== '' ? { userComment: comment } : {}
}

const EmployersSelection: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { persons, loading } = usePersonsWithShareUserDataWorkflow()
  const [processSucess, setProcessSuccess] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedPersons, setSelectedPersons] = useState<Map<number, boolean>>(new Map())
  const [selectedTenants, setSelectedTenants] = useState<TenantT[]>([])
  const [commentText, setCommentText] = useState<string>('')
  const [errorComment, setErrorComment] = useState<string | undefined>(undefined)
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const onChangeSelPerson = useCallback(
    (personId: number) => {
      const selPersons = new Map(selectedPersons).set(personId, !selectedPersons.get(personId))
      setSelectedPersons(selPersons)
      setSelectedTenants(getSelectedTenants(persons, selPersons))
    },
    [persons, selectedPersons],
  )
  const getChecked = useCallback((id: number): boolean => selectedPersons.get(id) ?? false, [selectedPersons])

  const isValidData = useCallback(() => {
    const tenantListValidation = Array.from(selectedPersons.values()).some((selected) => selected)
    return tenantListValidation && errorComment === undefined
  }, [selectedPersons, errorComment])

  const [shareOwnUserDataMutation] = useMutation<ShareOwnUserDataResult>(shareOwnUserDataQuery)

  const getTenantMessage = useCallback(
    (person: PersonWithWorkflow) => {
      if (person.shareUserDataTask) {
        return t('personalData.sendShareUserDataPage.tenantList.dataUpdated')
      }

      if (!person.lastSharedData) {
        return t('personalData.sendShareUserDataPage.tenantList.notReceivedData')
      }

      return t('personalData.sendShareUserDataPage.tenantList.lastRequest', {
        date: getFormattedLastSharedData(person),
      })
    },
    [t],
  )

  if (processSucess) {
    return <SuccessScreen tenants={selectedTenants} />
  }

  return (
    <Page
      hideNativeNavigation
      header={
        <>
          <Typography sx={classes.sectionTitle} variant="caption">
            {t('personalData.sendShareUserDataPage.sectionTitle')}
          </Typography>
          <Typography variant="h2">{t('personalData.sendShareUserDataPage.title')}</Typography>
        </>
      }
      hasFloatingButtons
    >
      {dialogOpen && (
        <ConfirmationDialog
          tenants={selectedTenants}
          onAccept={() => {
            shareOwnUserDataMutation({
              variables: {
                personIds: getSelectedPersonIds(selectedPersons),
                ...maybeAddComment(commentText),
              },
            })
              .then((_result) => {
                setProcessSuccess(true)
                setDialogOpen(false)
              })
              .catch((_e) => {
                setDialogOpen(false)
                setFlashMessage('common.error.be.default')
              })
          }}
          onCancel={() => {
            setDialogOpen(false)
          }}
        />
      )}
      {loading && <SimpleLoading />}
      {!loading && (
        <>
          <Card>
            <Typography variant="body1" sx={classes.bodyText1}>
              {t('personalData.sendShareUserDataPage.bodyText1', { date: getUserDataCollectionLastUpdate(persons) })}
            </Typography>

            <Box sx={classes.tenantListSection}>
              <Typography variant="subtitle2" sx={classes.tenantHeaderText}>
                {t('personalData.sendShareUserDataPage.tenantList.header')}
              </Typography>

              {persons.map((person) => (
                <Box key={person.id}>
                  <Box alignItems="center" display="flex" sx={classes.checkBoxContainer}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={classes.checkBox}
                          checked={getChecked(person.id as number)}
                          name={person.tenant?.id as string}
                          onChange={(_event) => {
                            onChangeSelPerson(person.id as number)
                          }}
                          disabled={person.shareUserDataTask !== undefined}
                        />
                      }
                      sx={classes.checkListOption}
                      label={person.tenant?.name as string}
                    />
                  </Box>
                  <Box sx={classes.checkboxStatus}>
                    <Typography variant="caption" sx={classes.tenantStatusText}>
                      {getTenantMessage(person)}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>

            <Box sx={classes.commentSection}>
              <TextField
                variant="standard"
                label={t('personalData.sendShareUserDataPage.comment.title')}
                type="text"
                fullWidth
                multiline
                value={commentText}
                onChange={(e) => {
                  const text = e.target.value
                  setCommentText(text)
                  setErrorComment(
                    text.length > MAX_COMMENT_CHARACTERS
                      ? t('common.form.error.maxLength', { numCharacters: MAX_COMMENT_CHARACTERS })
                      : undefined,
                  )
                }}
                error={errorComment !== undefined}
                helperText={errorComment}
              />
              <Typography sx={classes.commentDescription} variant="caption">
                {t('personalData.sendShareUserDataPage.comment.description')}
              </Typography>
            </Box>
          </Card>

          <ActionButtons
            cancelAction={() => {
              history.push(routes.employmentData)
            }}
            cancelLabel={t('personalData.sendShareUserDataPage.button.editData')}
            validateLabel={t('personalData.sendShareUserDataPage.button.shareData')}
            validateAction={() => {
              if (isValidData()) {
                setDialogOpen(true)
              }
            }}
            isValidateButtonActive={isValidData()}
            isFloating
            noNavigation
          />
        </>
      )}
    </Page>
  )
}

export default EmployersSelection
