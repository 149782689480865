import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { ChangeAlertS } from 'icons'
import theme from 'utils/theme'
import useStateBackLink from 'hooks/useStateBackLink'
import routes from 'services/RoutesProvider/routes'
import { badgeClasses } from '../styles'
import { IBadgeProps } from '../types'

const HiddenBadge: React.FC<IBadgeProps> = ({ dataTestId, badge: { shiftUpdateUuid, date } }) => {
  const { setBackLink } = useStateBackLink()
  const history = useHistory()

  const onClick = useCallback(() => {
    if (!shiftUpdateUuid) {
      return
    }
    setBackLink(`${routes.absencePlanner}?startDate=${date}&endDate=${date}`)
    history.push(`${routes.shiftUpdateDetail}/${shiftUpdateUuid}/todo`)
  }, [date, history, setBackLink, shiftUpdateUuid])

  return (
    <Box sx={[badgeClasses.hiddenBadge, badgeClasses.badgeFullWidth]} data-testid={dataTestId} onClick={onClick}>
      <ChangeAlertS fill={theme.palette.primary.main} />
    </Box>
  )
}

export default HiddenBadge
