import React from 'react'

import CenteredBox from 'components/CenteredBox'
import PinDigit from '../PinDigit'
import { classes } from './styles'

export interface IPinDisplay {
  pin: string
  pinLength: number
  secret?: boolean
}

const PinDisplay: React.FC<IPinDisplay> = ({ pin, pinLength, secret }) => {
  const renderCurrentDigit = (digitNumber: number) => {
    if (digitNumber < pin.length) {
      return <PinDigit key={digitNumber} number={pin[digitNumber]} secret={secret} />
    }
    if (digitNumber === pin.length) {
      return <PinDigit key={digitNumber} selected secret={secret} />
    }
    return <PinDigit key={digitNumber} secret={secret} />
  }

  return (
    <CenteredBox sx={classes.root} data-testid="pin-display">
      {Array(pinLength)
        .fill(0)
        .map((_value, digitNumber) => renderCurrentDigit(digitNumber))}
    </CenteredBox>
  )
}

export default PinDisplay
