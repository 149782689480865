import { isPlatform } from '@ionic/react'
import { Capacitor } from '@capacitor/core'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Device } from '@capacitor/device'
import { FF_PLATFORM } from 'constants/featureFlags'
import settings from 'utils/settings'
import { NativeVersionObjectT, capacitorPlatformTypes } from 'components/OldNativeVersionDialog/types'

// Consider change to Capacitor.isNativePlatform() instead of @ionic/react
export const isNative = () => {
  const platform = settings(FF_PLATFORM)
  if (platform === 'native') {
    return true
  }
  if (platform && platform !== 'native') {
    return false
  }
  return Capacitor.isNativePlatform()
}

export const isNativeNoOverride = () => Capacitor.isNativePlatform()

export const isWeb = () => Capacitor.getPlatform() === capacitorPlatformTypes.web

export const isAndroidWeb = () => isWeb() && isPlatform('android')

export const isAndroidNative = () => Capacitor.getPlatform() === capacitorPlatformTypes.android

export const isIphoneWeb = () => isWeb() && isPlatform('iphone')

export const isIphoneNative = () => Capacitor.getPlatform() === capacitorPlatformTypes.ios

export const isMobileWeb = () => isPlatform('mobileweb')

export const isMobile = () => isPlatform('mobile')

export const getNativeVersion = async () => {
  if (!isNative()) {
    return undefined
  }
  const info = await Device.getInfo()

  if (isAndroidNative() && info.androidSDKVersion) {
    return {
      platform: capacitorPlatformTypes.android,
      version: info.androidSDKVersion,
    } as NativeVersionObjectT
  }

  if (isIphoneNative() && info.iOSVersion) {
    return {
      platform: capacitorPlatformTypes.ios,
      version: info.iOSVersion,
    } as NativeVersionObjectT
  }

  return undefined
}

/* istanbul ignore next */
export const isTest = () => false
