import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { Locale } from 'date-fns'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { CalendarNoDataM } from 'icons'
import { getCardDate } from 'pages/Shifts/utils'
import { classes } from './styles'
import AbsenceRequestData from './ShiftCard/AbsenceRequestData'
import EventNotification from './ShiftCard/components/EventNotification'
import type { CmsContentT } from 'types'
export const noShiftTestId = 'no-shifts-available'
export const noShiftTranslationKey = 'page.dashboard.content.noShifts'

interface INoShiftTenantProps {
  displayEvents: boolean
  date: string
  events: CmsContentT[]
  tenantName: string
  displayFull: boolean
  absenceRequests: TaskT<AbsenceRequestTaskT>[]
}

const NoShiftTenant: React.FC<INoShiftTenantProps> = ({
  displayEvents,
  date,
  events,
  tenantName,
  displayFull,
  absenceRequests,
}) => {
  const { locale, t } = useFavurTranslation()
  const formattedDate = useMemo(() => getCardDate(date as string, locale as Locale), [date, locale])

  return (
    <Box mx={displayFull ? 0.75 : 0} mb={1.25} mt={0.75}>
      <Card>
        <Typography variant="subtitle1" sx={classes.tenantName}>
          {tenantName}
        </Typography>
        <Typography variant="caption">{formattedDate}</Typography>
        {displayEvents ? (
          <EventNotification events={events} />
        ) : (
          <Box sx={classes.textContainer}>
            <CalendarNoDataM />
            <Typography variant="body2" sx={classes.noDataText}>
              {t('page.shifts.shiftCard.noData')}
            </Typography>
          </Box>
        )}

        <AbsenceRequestData absenceRequests={absenceRequests} date={date} />
      </Card>
    </Box>
  )
}

export default NoShiftTenant
