import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { ArrowRightThinM } from 'icons'
import Card from 'components/Basics/Card'
import Checkbox from 'components/Checkbox'
import { getNormalizedFirstName, getNormalizedLastName, getUserName, normalizeText } from 'utils/person'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { INVITATION_STATUSES } from 'constants/invitation'
import SearchTextField from 'components/SearchTextField'
import SectionTitle from 'components/SectionTitle'
import { hasResignedManagers } from 'pages/Teams/utils'
import ListItem from './ListItem'
import { classes } from './styles'
import NoPersonsFoundCard from './NoPersonsFoundCard'
import type { InvitationT, PersonT, PersonTeamPermissionT, TeamPermissionT } from 'types'

const getHasPendingInvitationWarning = (checkValidInvitation: boolean, invitation?: InvitationT) =>
  checkValidInvitation && (!invitation || invitation.status !== INVITATION_STATUSES.success)

const getNotTeamAssignedWarning = (showNotTeamAssignedWarning: boolean, teamPermissions?: TeamPermissionT[]) =>
  showNotTeamAssignedWarning && !teamPermissions?.length

const getTeamAssignedWarning = (showTeamAssignedWarning: boolean, teamPermissions?: TeamPermissionT[]) =>
  showTeamAssignedWarning && teamPermissions?.length

interface EmployeeListProps {
  checkbox?: boolean
  employees: PersonT[]
  itemIsChecked?: (employee: PersonT) => boolean
  itemIsDisabled?: (employee: PersonT) => boolean
  onClickItem?: (employee: PersonT) => void
  checkValidInvitation?: boolean
  search?: boolean
  getInfo?: (employee: PersonT) => string
  showTenant?: boolean
  checkResignationDate?: boolean
  showTeamAssignedWarning?: boolean
  showNotTeamAssignedWarning?: boolean
}

const EmployeeList: React.FC<EmployeeListProps> = ({
  checkbox = false,
  employees,
  itemIsChecked = () => false,
  itemIsDisabled = () => false,
  onClickItem = (_employee: PersonT) => {},
  checkValidInvitation = false,
  search = false,
  getInfo,
  showTenant = false,
  checkResignationDate = false,
  showTeamAssignedWarning = false,
  showNotTeamAssignedWarning = false,
}) => {
  const { t } = useFavurTranslation()

  const getWarning = useCallback(
    (person: PersonT) => {
      const hasPendingInvitationWarning = getHasPendingInvitationWarning(checkValidInvitation, person.invitation)

      const hasTeamAssignedWarning = getTeamAssignedWarning(showTeamAssignedWarning, person.teamPermissions)
      const hasNotTeamAssignedWarning = getNotTeamAssignedWarning(showNotTeamAssignedWarning, person.teamPermissions)

      const hasResignedWarning =
        checkResignationDate && hasResignedManagers((person.teamPermissions ?? []) as PersonTeamPermissionT[])

      return [
        ...(hasPendingInvitationWarning ? [t('members.invitation.pending')] : []),
        ...(hasNotTeamAssignedWarning ? [t('members.team.without')] : []),
        ...(hasTeamAssignedWarning ? [t('members.team.assigned')] : []),
        ...(hasResignedWarning ? [t('team.permissions.resignedManager')] : []),
      ].join(', ')
    },
    [checkValidInvitation, showTeamAssignedWarning, showNotTeamAssignedWarning, checkResignationDate, t],
  )

  const [filteredEmployeeList, setFilteredEmployeeList] = useState<PersonT[]>(employees)
  const [filterActive, setFilterActive] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const employeeList = useMemo(() => {
    return employees.map((person) => ({
      firstName: getNormalizedFirstName(person),
      lastName: getNormalizedLastName(person),
      person,
    }))
  }, [employees])

  const filterEmployees = useCallback(
    (text: string) => {
      const normalizedText = normalizeText(text)
      const filteredEmployees = employeeList
        .filter(
          (normPerson) => normPerson.firstName.includes(normalizedText) || normPerson.lastName.includes(normalizedText),
        )
        .map((normPerson) => normPerson.person)

      setFilteredEmployeeList(filteredEmployees)
      setFilterActive(text !== '')
    },
    [employeeList],
  )

  useEffect(() => {
    filterEmployees(searchText)
  }, [filterEmployees, searchText])

  return (
    <>
      {search && employees.length > 0 && (
        <Box sx={classes.searchContainer}>
          <SearchTextField
            label={t('filter.persons.search.label')}
            onChange={(text) => {
              setSearchText(text)
            }}
          />
          {filterActive && (
            <SectionTitle marginLeft={1.0} gutter={0.0}>
              {t('filter.persons.search.results')}
            </SectionTitle>
          )}
          {filterActive && filteredEmployeeList.length === 0 && <NoPersonsFoundCard />}
        </Box>
      )}
      {filteredEmployeeList && filteredEmployeeList.length > 0 && (
        <Box sx={classes.resultContainer}>
          <Card>
            {filteredEmployeeList.map((employee, index, list) => {
              return (
                <ListItem
                  icon={
                    checkbox ? (
                      <Checkbox
                        checked={itemIsChecked(employee)}
                        label=""
                        sx={classes.checkbox}
                        onChange={() => {
                          onClickItem(employee)
                        }}
                        disabled={itemIsDisabled(employee)}
                      />
                    ) : (
                      <ArrowRightThinM />
                    )
                  }
                  key={employee.id}
                  onClick={() => {
                    !checkbox && onClickItem(employee)
                  }}
                  text={getUserName(employee)}
                  subText={showTenant ? employee.tenant?.name : undefined}
                  showDivider={index + 1 < list.length}
                  warning={getWarning(employee)}
                  disabled={itemIsDisabled(employee)}
                  info={getInfo && getInfo(employee)}
                />
              )
            })}
          </Card>
        </Box>
      )}
    </>
  )
}

export default EmployeeList
