import React, { useMemo } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import ControlledRadioGroup from 'components/Forms/components/ControlledRadioGroup'
import { Box } from '@mui/material'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import FilterContainer from '../FilterContainer'
import { ownersClasses } from './styles'

const Owners: React.FC = () => {
  const { t } = useFavurTranslation()
  const { selectedOwnerId, setSelectedOwnerId, owners, isSmallScreen } = useAbsencePlannerStateContext()

  const onChange = (newValue: string) => setSelectedOwnerId(Number(newValue))
  const options = useMemo(() => {
    return (owners ?? []).reduce((acc, owner) => {
      return { ...acc, [owner.owner.id]: owner.owner.name }
    }, {})
  }, [owners])

  if (!isSmallScreen || Object.keys(options).length < 2) return <></>

  return (
    <FilterContainer title={t('absencePlanner.taskbar.filter.owners')}>
      <Box sx={ownersClasses.radioGroupContainer}>
        <ControlledRadioGroup
          value={`${selectedOwnerId}`}
          options={options}
          name="owner"
          defaultValue={`${selectedOwnerId}`}
          onChange={onChange}
          variant="selected"
          column
        />
      </Box>
    </FilterContainer>
  )
}

export default Owners
