import { useCallback, useEffect, useState } from 'react'

const useBlockingPeriod = (initialValue?: number | null) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [editedValue, setEditedValue] = useState(initialValue ?? 0)

  const onChange = useCallback((newValue: number) => setEditedValue(newValue), [])

  useEffect(() => {
    setEditedValue(initialValue ?? 0)
  }, [drawerOpen, initialValue])

  return { drawerOpen, onOpen: () => setDrawerOpen(true), onClose: () => setDrawerOpen(false), editedValue, onChange }
}

export default useBlockingPeriod
