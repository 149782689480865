import React from 'react'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { QueryDates } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { uiDateFormat } from 'utils/constants'
import { BalanceItem, BalanceObject } from 'types/balances'
import TimeBalanceRow from './TimeBalanceRow'
import { classes } from './styles'

interface IBalanceTableProps {
  isCurrent: boolean
  dates: QueryDates
  balance: BalanceObject
  insideDialog?: boolean
}

const TimeBalanceTable: React.FC<IBalanceTableProps> = ({ isCurrent, dates, balance, insideDialog }) => {
  const { t, language } = useFavurTranslation()
  const hasEstimations = balance?.estimations?.vacation !== null || balance?.estimations?.bankHoliday !== null
  const showEstimations = isCurrent && hasEstimations
  const getUnit = (balanceItem: BalanceItem | undefined) => {
    if (!balanceItem || !balanceItem.unit || balanceItem.unit === undefined) {
      return '-'
    }
    const translation = t(`page.shifts.balance.${balanceItem.unit}`)
    return language === 'de' ? `${translation.slice(0, 2)}.` : translation
  }

  return (
    <Table aria-label="balance table">
      <TableHead>
        <TableRow sx={classes.balanceRow}>
          <TableCell sx={classes.balanceCell}>
            <Typography variant="body1">{t('page.shifts.balance.endDate')}</Typography>
          </TableCell>
          <TableCell sx={classes.balanceCell}>
            <Box sx={classes.currentMonthData}>
              <Typography sx={classes.date} variant="caption" data-testid="balances_until-end-of-current-month">
                {getFormattedFromDate(newDateWithoutTime(dates.end), uiDateFormat)}
              </Typography>
            </Box>
            {showEstimations && balance?.estimationDate && (
              <Box sx={classes.estimationsData}>
                <Typography sx={classes.date} variant="caption" data-testid="balances_until-end-of-year">
                  {getFormattedFromDate(newDateWithoutTime(balance.estimationDate), uiDateFormat)}
                </Typography>
              </Box>
            )}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TimeBalanceRow
          isCurrent={showEstimations}
          firstColumn={{
            variant: 'body1',
            text: t('page.shifts.balance.free'),
            unit: `(${getUnit(balance.free)})`,
          }}
          secondColumn={{
            variant: 'body1',
            balance: balance.free,
          }}
          thirdColumn={{}}
        />
        <TimeBalanceRow
          isCurrent={showEstimations}
          firstColumn={{
            variant: 'body1',
            text: t('page.shifts.balance.vacation'),
            unit: `(${getUnit(balance.vacation)})`,
          }}
          secondColumn={{
            variant: 'body1',
            balance: balance.vacation,
          }}
          thirdColumn={
            balance.estimations
              ? {
                  variant: 'body1',
                  balance: balance.estimations.vacation,
                }
              : {}
          }
        />
        <TimeBalanceRow
          isCurrent={showEstimations}
          firstColumn={{
            variant: 'body1',
            text: t('page.shifts.balance.bankHoliday'),
            unit: `(${getUnit(balance.bankHoliday)})`,
          }}
          secondColumn={{
            variant: 'body1',
            balance: balance.bankHoliday,
          }}
          thirdColumn={
            balance.estimations
              ? {
                  variant: 'body1',
                  balance: balance.estimations.bankHoliday,
                }
              : {}
          }
        />
        {!insideDialog && (
          <>
            <TableRow>
              <TableCell sx={classes.line} />
            </TableRow>
            <TimeBalanceRow
              isCurrent={showEstimations}
              firstColumn={{
                variant: 'body1',
                text: t('page.shifts.balance.overtime'),
                unit: `(${getUnit(balance.overtime)})`,
              }}
              secondColumn={{
                variant: 'body1',
                balance: balance.hours,
              }}
              thirdColumn={{}}
            />
          </>
        )}
      </TableBody>
    </Table>
  )
}

export default TimeBalanceTable
