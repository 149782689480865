import { useCallback, useContext } from 'react'
import FlashMessagesContext from 'services/FlashMessages/context'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getFirstErrorCode } from 'utils/apollo'
import { errorCodes } from './constants'
import { TaskDataT, TaskT } from './types'
import usePerformEventToTask from './usePerformEventToTask'

const useSendManualReminder = (task: TaskT<TaskDataT>, onSentReminderSuccess: (() => void) | undefined) => {
  const { t } = useFavurTranslation()
  const { sendReminderToFrontline } = usePerformEventToTask(task.favurUuid)
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)

  const submit = useCallback(() => {
    removeAll()

    sendReminderToFrontline()
      .then(() => {
        onSentReminderSuccess && onSentReminderSuccess()
      })
      .catch((e) => {
        const code = getFirstErrorCode(e)

        if (errorCodes.includes(code)) setFlashMessage(t(code))
        else setFlashMessage(t('common.error.be.default'))
      })
  }, [onSentReminderSuccess, removeAll, sendReminderToFrontline, setFlashMessage, t])

  return { sendManualReminder: submit }
}

export default useSendManualReminder
