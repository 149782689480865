import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const UserDataBankL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M19.3 4.3l6.4 6.4a1 1 0 0 1 .3.71V27a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h11.59a1 1 0 0 1 .7.3zm-1.05 1.2H7.5v21h17V11.75H19a.75.75 0 0 1-.74-.65l-.01-.1V5.5zM22 22.25a.75.75 0 0 1 .1 1.5H11a.75.75 0 0 1-.1-1.5H22zm0-4a.75.75 0 0 1 .1 1.5H16a.75.75 0 0 1-.1-1.5H22zM12 8.5a.5.5 0 0 1 .5.41v.62c.67.1 1.28.47 1.7 1.02a.5.5 0 1 1-.8.6 1.67 1.67 0 0 0-1.4-.65c-.85 0-1.5.49-1.5 1 0 .48.57.94 1.34 1H12c1.36 0 2.5.86 2.5 2 0 1-.88 1.78-2 1.96V17a.5.5 0 0 1-1 .09v-.62a2.66 2.66 0 0 1-1.7-1.02.5.5 0 1 1 .8-.6c.32.43.84.67 1.4.65.85 0 1.5-.49 1.5-1 0-.48-.57-.94-1.34-1H12c-1.35 0-2.5-.86-2.5-2 0-1 .88-1.78 2-1.96V9a.5.5 0 0 1 .5-.5zm7.75-1.63v3.38h3.38l-3.38-3.38z"
      />
    </Svg>
  )
}

export default UserDataBankL
