import { OwnerPersons } from 'hooks/useOwnersOfUser'
import { PERSON_STATUS } from 'constants/person'
import { personRolesCanManageTeams } from './roles'
import type { AuthPersonIdAndPermissions, OwnerT, PersonT } from 'types'

const removeDiacritics = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

export const getFirstName = (person: PersonT) => (person.user ? person.user.firstName : person.firstName) ?? ''
export const getLastName = (person: PersonT) => (person.user ? person.user.lastName : person.lastName) ?? ''

export const getSortingLastName = (person: PersonT) => removeDiacritics(getLastName(person).toLowerCase())
export const getSortingFirstName = (person: PersonT) => removeDiacritics(getFirstName(person).toLowerCase())
export const getSortingName = (person: PersonT, firstNameFirst = false) =>
  firstNameFirst
    ? `${getSortingFirstName(person)} ${getSortingLastName(person)}`
    : `${getSortingLastName(person)} ${getSortingFirstName(person)}`

export const getUserName = (person: PersonT, firstNameFirst = false) =>
  firstNameFirst ? `${getFirstName(person)} ${getLastName(person)}` : `${getLastName(person)} ${getFirstName(person)}`
export const getShortName = (person: PersonT) => {
  const lastName = getLastName(person)
  const lastNameSufix = lastName?.length > 0 ? `${lastName[0]}.` : ''
  return `${getFirstName(person)} ${lastNameSufix}`
}

export const normalizeText = (text: string) => {
  if (!text) return ''
  return removeDiacritics(text.toLowerCase())
}
export const getNormalizedFirstName = (person: PersonT) => normalizeText(getFirstName(person))
export const getNormalizedLastName = (person: PersonT) => normalizeText(getLastName(person))

const getShortLastName = (lastName: string): string => {
  if (!lastName || lastName.trim() === '') {
    return ''
  }

  return `${lastName.charAt(0)}.`
}

export const getShortNameFromName = (userName: string) => {
  if (!userName || userName.trim() === '') {
    return ''
  }

  const result = userName.trim().split(' ')

  const firstName = result[0]
  if (result.length === 2) {
    return `${firstName} ${getShortLastName(result[1])}`.trim()
  }
  if (result.length > 2) {
    return `${firstName} ${getShortLastName(result[2])}`.trim()
  }

  return userName.trim()
}

export const getAvatarName = (firstName: string, lastName: string) => {
  if (!firstName || firstName.length === 0) return ''
  if (!lastName || lastName.length === 0) return firstName.charAt(0)
  return `${firstName.charAt(0)}${lastName.charAt(0)}`
}

export const getAvatarNameFromPerson = (person: PersonT) => {
  const firstName = getFirstName(person)
  const lastName = getLastName(person)
  return getAvatarName(firstName, lastName)
}

export const personIsInactive = (person: PersonT | AuthPersonIdAndPermissions) => {
  return [PERSON_STATUS.INACTIVE, PERSON_STATUS.DISCONNECTED].includes(person.status || '')
}

export const getOwnerPersonsFromPersonList = (persons: PersonT[], teamManagersOnly: boolean, hideInactive = false) => {
  const ownersByOwnerId = persons
    .filter((person) => {
      if (teamManagersOnly && !personRolesCanManageTeams(person.roles)) {
        return false
      }

      if (hideInactive && personIsInactive(person)) {
        return false
      }

      return true
    })
    .reduce((acc, person) => {
      const { owner } = person
      const ownerIdKey = `${owner?.id}`
      const currentOwner = (acc.get(ownerIdKey) ?? { owner: owner as OwnerT, persons: [] }) as OwnerPersons
      currentOwner.persons = [...currentOwner.persons, Number(person.id)]
      return acc.set(ownerIdKey, currentOwner)
    }, new Map<string, OwnerPersons>())

  return Array.from(ownersByOwnerId).map(([_ownerId, ownerObj]) => ownerObj) as {
    owner: OwnerT
    persons: number[]
  }[]
}
