import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const ManagerTasksL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M12.5 4a.796.796 0 0 0-.548.212.664.664 0 0 0-.152.216.599.599 0 0 0-.05.239V8h8.5V4.667a.628.628 0 0 0-.202-.455A.796.796 0 0 0 19.5 4h-7Zm-2.75.667V8H6.714c-1.097 0-1.987.268-2.69.723a4.188 4.188 0 0 0-1.448 1.618A5.326 5.326 0 0 0 2 12.573V12.598c0 .002 0 .002 1 .002H2v10.8c0 1.082.282 1.959.756 2.648a4.233 4.233 0 0 0 1.665 1.402 5.67 5.67 0 0 0 1.553.481 5.701 5.701 0 0 0 .714.069h18.598c1.097 0 1.987-.268 2.69-.723a4.187 4.187 0 0 0 1.448-1.619A5.327 5.327 0 0 0 30 23.476v-.048l.001-.016V23.402c0-.002 0-.002-1-.002h1V12.6c0-1.082-.282-1.959-.756-2.648a4.233 4.233 0 0 0-1.665-1.402 5.669 5.669 0 0 0-1.553-.481A5.691 5.691 0 0 0 25.312 8H25.286v1-1H22.25V4.667c0-.724-.302-1.407-.823-1.903A2.795 2.795 0 0 0 19.5 2h-7c-.714 0-1.408.27-1.927.764-.258.245-.465.54-.607.866-.142.327-.216.68-.216 1.037ZM6.714 27v1-1ZM4 12.605V23.4c0 .719.182 1.191.404 1.515.228.33.541.566.89.735.35.17.715.26 1 .306a3.693 3.693 0 0 0 .41.044h18.582c.76 0 1.263-.182 1.604-.402a2.19 2.19 0 0 0 .757-.857A3.327 3.327 0 0 0 28 23.406V12.6c0-.718-.182-1.191-.404-1.514a2.236 2.236 0 0 0-.89-.736 3.672 3.672 0 0 0-1-.306 3.693 3.693 0 0 0-.41-.044H6.713c-.76 0-1.263.182-1.604.402a2.19 2.19 0 0 0-.757.857A3.328 3.328 0 0 0 4 12.593v.011Zm17.72 2.09a1 1 0 1 0-1.44-1.39l-6.435 6.663-2.126-2.202a1 1 0 1 0-1.438 1.39l2.175 2.252c.362.375.861.592 1.39.592.527 0 1.026-.217 1.389-.592l6.484-6.713Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default ManagerTasksL
