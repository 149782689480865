import React from 'react'
import theme from 'utils/theme'
import { BanS } from 'icons'
import useFavurTranslation from 'hooks/useFavurTranslation'
import TaskBadge from '..'
import { classes } from '../styles'

const Rejected: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <TaskBadge text={t('timeline.badge.rejected')} sx={classes.badge} icon={<BanS fill={theme.palette.error.dark} />} />
  )
}

export default Rejected
