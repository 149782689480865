import React from 'react'
import { useHistory } from 'react-router'
import { Box, Button, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Page from 'components/Page'
import routes from 'services/RoutesProvider/routes'
import SuccessGeneric from 'components/SuccessGeneric'
import { classes } from './styles'

const SuccessDeleteUserPage: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <Page hideNativeNavigation hideHamburger flex hideBackground hideWebToolbar showHeader>
      <SuccessGeneric>
        <Box sx={classes.successContainer}>
          <Box sx={classes.successTexts}>
            <Typography variant="h1" sx={[classes.successTitle, classes.titleTop]} data-testid="success-heading">
              {t('deleteUser.success.line1')}
            </Typography>
            <Typography variant="h1" sx={classes.successTitle}>
              {t('deleteUser.success.line2')}
            </Typography>
          </Box>
          <Button
            type="button"
            size="large"
            variant="contained"
            color="secondary"
            sx={classes.successContainerButton}
            onClick={() => {
              history.push(routes.login)
            }}
          >
            {t('deleteUser.success.button.logout')}
          </Button>
        </Box>
      </SuccessGeneric>
    </Page>
  )
}

export default SuccessDeleteUserPage
