import React from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import Alert from 'components/Alert'
import { ArrowRightThinS } from 'icons'
import { classes } from './styles'

interface IUserSettingsSectionCTA {
  title: string
  subtitle?: string
  icon: React.JSX.Element
  onClick: () => void
  alerts?: string[]
  disabled?: boolean
}

const UserSettingsSectionCTA: React.FC<IUserSettingsSectionCTA> = ({
  onClick,
  title,
  subtitle,
  icon,
  alerts,
  disabled,
  ...props
}) => {
  return (
    <Box sx={classes.root}>
      <Card onClick={onClick} disabled={disabled || undefined} {...props}>
        <Box sx={classes.content}>
          <Box sx={classes.innerContent}>
            {icon}
            <Box sx={classes.textContainer}>
              <Typography variant="body1">{title}</Typography>
              {subtitle && (
                <Typography variant="body2" sx={classes.subtitle}>
                  {subtitle}
                </Typography>
              )}
            </Box>
          </Box>
          <Box sx={classes.alignSelfStart}>
            <ArrowRightThinS />
          </Box>
        </Box>
      </Card>
      {alerts && alerts.map((alert) => <Alert severity="info" key={alert} content={alert} />)}
    </Box>
  )
}

export default UserSettingsSectionCTA
