/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { Typography, Box, TextField, Checkbox, FormControlLabel, Paper, Button } from '@mui/material'
import SessionContext from 'services/Session/context'
import Page from 'components/Page'
import Grid from 'components/Grid'
import WhiteButton from 'components/WhiteButton'
import BirthDateInput, { TBirthDateInputs } from 'components/Forms/components/BirthDateInput'
import useUserDataForm from 'hooks/useUserDataForm'
import useJamesMutation from 'hooks/useJamesMutation'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import FlashMessagesContext from 'services/FlashMessages/context'
import { IInvitationStepProps } from '../types'
import classes from './styles'
import { RegisterUserMutationResponse } from './types'

const Register = ({ invitation, setStep }: IInvitationStepProps) => {
  const { refresh } = useContext(SessionContext)
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const { t } = useFavurTranslation()
  const history = useHistory()
  const birthdateMismatchKey = 'invitation.be.error.birthdateMismatch'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMutationError = (e: any): null | void => {
    if (e.code !== birthdateMismatchKey) {
      return null
    }

    setFlashMessage(t('invitation.error.birthdateMismatch.attempts', { count: e.attempts_left }))

    if (e.attempts_left === 0) {
      setStep('FAILURE')
    }

    return null
  }

  const [registerUserMutation] = useJamesMutation<
    {
      firstName: string
      lastName: string
      birthDate: string
    },
    RegisterUserMutationResponse
  >(`registerUserValidated(
    firstName: "#firstName", lastName: "#lastName", birthDate: "#birthDate", termsAndConditions: #termsAndConditions) 
    { success }`)

  const [disableConnectButton, setDisableConnectButton] = useState(false)

  const form = useUserDataForm({
    defaultValues: { termsAndConditions: false, firstName: '', lastName: '', birthDate: '' },
    onSubmit: (data) => {
      setDisableConnectButton(true)
      registerUserMutation(data)
        .then((res) => res?.registerUserValidated.success && setStep('SUCCESS'))
        .then(refresh)
        .catch((e) => {
          handleMutationError(e)
          setDisableConnectButton(false)
        })
    },
  })

  const {
    register,
    formState: { errors },
    onSubmit,
  } = form

  const favurRegister = (name: 'firstName' | 'lastName' | 'birthDate' | 'termsAndConditions') => {
    const { ref, ...registered } = register(name)
    return {
      ...registered,
      inputRef: ref,
    }
  }

  useEffect(() => {
    if (errors.termsAndConditions) {
      setFlashMessage('page.userSettings.personalData.termsAndConditions.required')
    }
  }, [errors, setFlashMessage])
  return (
    <Page
      showHeader
      header={
        <>
          <Typography variant="h2">{t('register.content.title')}</Typography>
          <Typography variant="h2">{invitation?.tenantName}</Typography>
        </>
      }
    >
      <Box component="form" sx={classes.registerForm} onSubmit={onSubmit}>
        <Box component={Paper} sx={classes.registerContainerPaper} p={1}>
          <Grid container>
            <Grid col={4}>
              <Typography variant="subtitle2">
                <strong>{t('register.content.subTitle')}</strong>
              </Typography>
            </Grid>
            <Grid col={4}>
              <TextField
                variant="standard"
                fullWidth
                id="user-personal-data_first-name"
                label={`${t('register.inputs.firstName')} *`}
                {...favurRegister('firstName')}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName?.message && t(errors.firstName?.message)}
                inputProps={{ 'data-testid': 'user-personal-data_first-name' }}
              />
            </Grid>
            <Grid col={4}>
              <TextField
                variant="standard"
                fullWidth
                id="user-personal-data_last-name"
                label={`${t('register.inputs.lastName')} *`}
                {...favurRegister('lastName')}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName?.message && t(errors.lastName?.message)}
                inputProps={{ 'data-testid': 'user-personal-data_last-name' }}
              />
            </Grid>
            <Grid col={4} style={{ marginBottom: 8 }}>
              <BirthDateInput
                {...((form as unknown) as UseFormReturn<TBirthDateInputs>)}
                label={`${t('register.inputs.birthDate')} *`}
              />
            </Grid>

            <Grid col={4} style={{ marginBottom: 8 }}>
              <FormControlLabel
                {...favurRegister('termsAndConditions')}
                sx={classes.termsCheckboxContainer}
                name="termsAndConditions"
                control={<Checkbox sx={classes.termsCheckbox} data-testid="user-personal_accept-terms-conditions" />}
                // TBD review in the future for possible xss attack changing
                // the value of the translation key for javascript code
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                label={<Trans i18nKey="register.content.termsCheckbox" components={{ a: <a /> }} />}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={classes.actionButtonsContainer}>
          <Button
            sx={classes.connectButton}
            type="submit"
            size="large"
            variant="contained"
            color="secondary"
            data-testid="accept-invitation"
            disabled={disableConnectButton}
          >
            {t('register.actions.connect')}
          </Button>
          <WhiteButton
            size="large"
            variant="contained"
            color="secondary"
            tabIndex={-1}
            onClick={() => history.push(routes.contact)}
            data-testid="contact"
          >
            {t('register.actions.cancelAndContact')}
          </WhiteButton>
        </Box>
      </Box>
    </Page>
  )
}

export default Register
