import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const RadioUncheckedM: React.FC<IconPropsT> = () => {
  return (
    <Svg size={ICON_SIZE_M}>
      <path
        fill={theme.palette.primary.light}
        fillRule="evenodd"
        d="M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24Zm0 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default RadioUncheckedM
