import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const ManagerTasksM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        d="M9.146 3.568c.094-.09.221-.14.354-.14h5c.133 0 .26.05.354.14.093.09.146.21.146.337V6H9V3.905c0-.127.053-.248.146-.337ZM7.5 6V3.905c0-.505.21-.99.586-1.347A2.052 2.052 0 0 1 9.5 2h5c.53 0 1.04.2 1.414.558.375.357.586.842.586 1.347V6h2.107v.75c0-.75.001-.75.002-.75h.017a2.275 2.275 0 0 1 .145.007 4.132 4.132 0 0 1 1.478.378c.416.196.861.505 1.201.988.345.49.55 1.112.55 1.877v7.5h-.75c.75 0 .75.001.75.002V16.77a1.43 1.43 0 0 1-.007.144 3.7 3.7 0 0 1-.41 1.437 2.983 2.983 0 0 1-1.048 1.143c-.506.319-1.144.506-1.928.506H5.393v-.75c0 .75-.001.75-.002.75h-.017l-.034-.001a4.136 4.136 0 0 1-.476-.047 4.133 4.133 0 0 1-1.113-.336 3.038 3.038 0 0 1-1.201-.989c-.345-.49-.55-1.112-.55-1.877v-7.5h.75C2 9.25 2 9.249 2 9.248V9.23a1.928 1.928 0 0 1 .007-.144 3.703 3.703 0 0 1 .41-1.437 2.982 2.982 0 0 1 1.048-1.143C3.97 6.187 4.609 6 5.393 6H7.5Zm-4 10.75V9.248l.003-.049a2.205 2.205 0 0 1 .24-.849c.117-.22.282-.424.522-.575.237-.15.59-.275 1.128-.275h13.22l.057.003a2.636 2.636 0 0 1 .938.237c.245.116.46.276.616.497.15.213.276.528.276 1.013V16.752l-.003.049a2.204 2.204 0 0 1-.24.849c-.117.22-.282.424-.522.575-.237.15-.59.275-1.128.275H5.387l-.057-.003a2.637 2.637 0 0 1-.938-.237 1.542 1.542 0 0 1-.616-.497c-.15-.213-.276-.528-.276-1.013Zm17-.004v.003-.004.001Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M17.3 9.74a.75.75 0 0 1-.04 1.06l-5.189 4.796a1.543 1.543 0 0 1-1.045.404c-.382 0-.758-.14-1.045-.404l-1.74-1.61a.75.75 0 1 1 1.018-1.1l1.738 1.607.006.003a.065.065 0 0 0 .023.004c.01 0 .017-.002.023-.004a.028.028 0 0 0 .006-.003l5.186-4.794a.75.75 0 0 1 1.06.042Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default ManagerTasksM
