import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { AlertS, ArrowRightThinM } from 'icons'
import Card from 'components/Basics/Card'
import Checkbox from 'components/Checkbox'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getTeamNameByLanguage } from 'types/utils'
import { hasResignedManagers, getPersonsByPermissions, isPermissionMissing } from 'pages/Teams/utils'
import SearchTextField from 'components/SearchTextField'
import SectionTitle from 'components/SectionTitle'
import { normalizeText } from 'utils/string'
import ListItem from './ListItem'
import { classes } from './styles'
import { classes as itemClasses } from './ListItem/styles'
import NoTeamsFoundCard from './NoTeamsFoundCard'
import type { TeamT } from 'types'

interface TeamListProps {
  teams: TeamT[]
  showWarnings?: boolean
  onClick: (team: TeamT) => void
  checkbox?: boolean
  checkboxSelected?: (team: TeamT) => boolean
  itemDisabled?: (team: TeamT) => boolean
  search?: boolean
}

const TeamList: React.FC<TeamListProps> = ({
  teams,
  showWarnings,
  onClick,
  checkbox,
  checkboxSelected,
  itemDisabled,
  search = false,
}) => {
  const { t, language } = useFavurTranslation()
  const [searchText, setSearchText] = useState<string>('')
  const [filterActive, setFilterActive] = useState<boolean>(false)
  const [filteredTeamList, setFilteredTeamList] = useState<TeamT[]>(teams)

  const getWarning = useCallback(
    (team: TeamT) => {
      if (!team.teamPermissions?.length) {
        return t('page.teams.noPersonsInTeam')
      }

      const teamHasResignedManagers = hasResignedManagers(team.teamPermissions)
      const personsByPermissions = getPersonsByPermissions(team.teamPermissions)
      const teamHasPermissionMissing = isPermissionMissing(personsByPermissions)

      const errorArray = [
        ...(teamHasResignedManagers ? [t('team.permission.resignedManager')] : []),
        ...(teamHasPermissionMissing ? [t('teams.permissions.missingPermission')] : []),
      ]

      if (!errorArray.length) {
        return undefined
      }

      return errorArray.join(', ')
    },
    [t],
  )

  const getSubText = useCallback(
    (team: TeamT): string | undefined => {
      const numEmployees = team.teamPermissions?.length
      return numEmployees !== undefined ? t('page.teams.list.numPeople', { numEmployees }) : undefined
    },
    [t],
  )

  const getText = useCallback(
    (team: TeamT): string => {
      const tenant = team.tenant ? ` | ${team.tenant.name}` : ''
      return `${getTeamNameByLanguage(team, language)}${tenant}`
    },
    [language],
  )

  const getIcon = useCallback(
    (team: TeamT) => {
      if (checkbox) {
        return (
          <Checkbox
            checked={checkboxSelected ? checkboxSelected(team) : false}
            label=""
            sx={itemClasses.checkbox}
            onChange={() => {
              onClick(team)
            }}
            disabled={itemDisabled ? itemDisabled(team) : false}
            displayBlock
          />
        )
      }

      return (
        <Box component="span" sx={[itemClasses.arrowIcon]}>
          <ArrowRightThinM />
        </Box>
      )
    },
    [checkbox, checkboxSelected, itemDisabled, onClick],
  )

  const filterTeams = useCallback(
    (text: string) => {
      const normalizedText = normalizeText(text)
      const filteredTeams = teams.filter((team) => normalizeText(getText(team)).includes(normalizedText))

      setFilteredTeamList(filteredTeams)
      setFilterActive(text !== '')
    },
    [getText, teams],
  )

  useEffect(() => {
    filterTeams(searchText)
  }, [filterTeams, searchText])

  return (
    <>
      {search && teams.length > 0 && (
        <Box sx={classes.searchContainer}>
          <SearchTextField
            label={t('filter.teams.search.label')}
            onChange={(text) => {
              setSearchText(text)
            }}
          />
          {filterActive && (
            <SectionTitle marginLeft={1.0} gutter={0.0}>
              {t('filter.teams.search.results')}
            </SectionTitle>
          )}
          {filterActive && filteredTeamList.length === 0 && <NoTeamsFoundCard />}
        </Box>
      )}
      {filteredTeamList && filteredTeamList.length > 0 && (
        <Box sx={classes.resultContainer}>
          <Card>
            <Box sx={classes.cardContents}>
              {filteredTeamList.map((team, index, list) => {
                return (
                  <ListItem
                    key={team.id}
                    onClick={() => !checkbox && onClick(team)}
                    text={getText(team)}
                    subText={getSubText(team)}
                    warning={showWarnings ? getWarning(team) : undefined}
                    alertIcon={AlertS}
                    showDivider={index + 1 < list.length}
                    firstItem={index === 0}
                    icon={getIcon(team)}
                    checkbox={checkbox}
                    disabled={itemDisabled ? itemDisabled(team) : false}
                  />
                )
              })}
            </Box>
          </Card>
        </Box>
      )}
    </>
  )
}

export default TeamList
