import React, { useCallback, useContext } from 'react'
import { useHistory } from 'react-router'
import useSessionContext from 'hooks/useSessionContext'
import routes from 'services/RoutesProvider/routes'
import usePSN from 'hooks/usePushNotifications'
import { useMutation } from '@apollo/client'
import { validationQuery } from 'pages/EntryPoint/queries'
import { SimpleLoading } from 'components/LoadingIcon'
import FlashMessagesContext from 'services/FlashMessages/context'
import { setSessionData } from 'utils/login'
import { generateRedirectUrl, isCmsRedirect } from 'services/RoutesProvider/utils'
import ValidateComponent from '../../components/ValidateComponent/AutofillSmsCode'
import type { ILoginStepProps } from '../types'
import type { AuthValidateResponse } from 'types'

const Validate: React.FC<ILoginStepProps> = ({ setStep }) => {
  const history = useHistory()
  const { update } = useSessionContext()
  const goBack = () => setStep('START')
  const { setPushActiveOnDevice } = usePSN({ refresh: false })
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const [mutate, { loading }] = useMutation<AuthValidateResponse>(validationQuery)
  const onConfirm = useCallback(
    async (validationCode: string, rememberMe: boolean) => {
      const queryParams = new URLSearchParams(location.search)

      try {
        removeAll()
        const mutationResult = await mutate({ variables: { validationCode, rememberMe } })
        const { data } = mutationResult
        setSessionData({ data: data?.authValidate, update, setPushActiveOnDevice })

        if (data?.authValidate?.cmsActivated && isCmsRedirect(queryParams)) {
          history.push(generateRedirectUrl(queryParams))
        } else {
          history.push(routes.dashboard)
        }
      } catch (_error) {
        setFlashMessage('login.validate.error.validationCodeNoMatch')
      }
    },
    [history, mutate, removeAll, setFlashMessage, setPushActiveOnDevice, update],
  )

  if (loading) {
    return <SimpleLoading />
  }

  return <ValidateComponent backButton={goBack} withRememberMe onConfirm={onConfirm} />
}

export default Validate
