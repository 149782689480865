import { DocumentT } from 'types'
import { DocumentTypes } from 'constants/documents'

export const getDocumentTranslationLabel = (document: DocumentT) => {
  switch (document.documentType) {
    case DocumentTypes.monthlySheet:
      return 'page.documents.documentType.monthly_sheet'

    case DocumentTypes.payslip:
      return 'page.documents.documentType.payslip'

    case DocumentTypes.salaryStatement:
      return 'page.documents.documentType.salary_statement'

    default:
      return ''
  }
}
