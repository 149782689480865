import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import Avatar from 'components/Avatar'
import routes from 'services/RoutesProvider/routes'
import { AvatarDataT } from 'types'
import useStateBackLink from 'hooks/useStateBackLink'
import { AddM } from 'icons'
import { classes } from './styles'

interface IAvatarSection {
  avatarData: AvatarDataT
  showAddBadge: boolean
  showUserName?: boolean
}

const AvatarSection: React.FC<IAvatarSection> = ({ avatarData, showAddBadge, showUserName = false }) => {
  const history = useHistory()
  const { setBackLink } = useStateBackLink()
  const badge = (
    <Box sx={classes.badgeContainer}>
      <Box sx={classes.badgeIcon}>
        <AddM fill="white" />
      </Box>
    </Box>
  )
  const avatar = <Avatar size={40} avatar={avatarData} badge={showAddBadge ? badge : undefined} />
  return (
    <Box sx={classes.avatarContainer} data-testid="shift-card__avatar">
      {avatarData.avatarUrl ? (
        avatar
      ) : (
        <IconButton
          onClick={() => {
            setBackLink(routes.shifts)
            history.push(routes.profile)
          }}
          sx={classes.avatarButton}
          size="large"
        >
          {avatar}
        </IconButton>
      )}
      {showUserName && (
        <Typography variant="subtitle2" sx={classes.avatarUsername}>
          {avatarData.avatarUserName}
        </Typography>
      )}
    </Box>
  )
}

export default AvatarSection
