import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const UserDataAddressL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M24 22a.75.75 0 0 1 .74.65l.01.1v4.5a.75.75 0 0 1-.65.74L24 28h-8a.75.75 0 0 1-.74-.65l-.01-.1v-4.5a.75.75 0 0 1 1.5-.1v3.85h6.5v-3.75a.75.75 0 0 1 .65-.74L24 22zM12.25 4a8.25 8.25 0 0 1 8.25 8.25.75.75 0 1 1-1.5 0 6.75 6.75 0 1 0-13.5 0c0 .65.23 1.58.63 2.69l.21.56c.19.49.4 1 .64 1.53l.3.65.3.66.34.68.35.69.55 1.04.58 1.05.4.7.4.7.4.68.41.68.42.66.4.65.42.63h.1a.75.75 0 0 1-.1 1.5c-.5 0-.97-.25-1.25-.67l-.54-.83-.55-.86-.65-1.06-.42-.72-.21-.36-.42-.73-.4-.73-.4-.73-.38-.72-.37-.72-.35-.72-.17-.35-.32-.7-.3-.67-.15-.33-.27-.66A12.82 12.82 0 0 1 4 12.25 8.25 8.25 0 0 1 12.25 4zm13.25 9.75a.75.75 0 0 1 .74.65l.01.1v5a.75.75 0 0 1-.19.5l1.47 1.47a.75.75 0 0 1-.98 1.13l-.08-.07L20 16.06l-6.47 6.47a.75.75 0 0 1-.98.07l-.08-.07a.75.75 0 0 1-.07-.98l.07-.08 7-7a.75.75 0 0 1 .98-.07l.08.07 4.22 4.22V14.5a.75.75 0 0 1 .75-.75zm-13-5.25a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"
      />
    </Svg>
  )
}

export default UserDataAddressL
