import { queryCache } from 'react-query'
import { fetchInfo } from 'hooks/useJamesQuery'

type RefreshHighSecSessionValuesT = {
  highSecSessionValidUntil: string
}

export const refreshHighSecSession = async () => {
  const queryName = 'refreshHighSecuritySession'
  const query = `query {
    refreshHighSecuritySession { highSecSessionValidUntil }
  }`

  const result = await queryCache.fetchQuery(
    queryName,
    fetchInfo<{ refreshHighSecuritySession: RefreshHighSecSessionValuesT }>(query),
  )

  return result?.data?.data.refreshHighSecuritySession.highSecSessionValidUntil
}
