import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useHistory } from 'react-router'

import Page from 'components/Page'
import { isAndroidWeb, isIphoneWeb, isMobileWeb } from 'utils/platform'
import CenteredBox from 'components/CenteredBox'
import routes from 'services/RoutesProvider/routes'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { IOS_APP_STORE_LINK, ANDROID_PLAY_STORE_LINK } from 'constants/appStores'
import { classes } from './styles'
import TeaserImage from './TeaserImage'

const getAppStoreLink = () => {
  if (isAndroidWeb()) {
    return ANDROID_PLAY_STORE_LINK
  }
  if (isIphoneWeb()) {
    return IOS_APP_STORE_LINK
  }
  return ''
}

// Set the viewport height to be the size of the inner height of the browser
// This is required on some mobile web browsers which define 100vh as the height of the screen
const vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

const AppTeaserPage: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <Page showHeader={false} hideWebToolbar fitToViewport>
      <CenteredBox sx={classes.root}>
        <Box>
          <Typography variant="h2" sx={classes.title}>
            {t('page.appTeaser.title')}
          </Typography>
          <Typography variant="body1" sx={classes.text}>
            {t('page.appTeaser.text')}
          </Typography>
        </Box>
        <TeaserImage appStoreLink={getAppStoreLink()} />
        <Box>
          {isMobileWeb() ? (
            <>
              <Box
                component="a"
                href={getAppStoreLink()}
                target="_blank"
                rel="noopener noreferrer"
                sx={classes.downloadLink}
              >
                <Button type="button" size="large" variant="contained" color="secondary" sx={classes.downloadButton}>
                  {t('page.appTeaser.button.downloadApp')}
                </Button>
              </Box>
              <br />
            </>
          ) : null}
          <Button size="large" onClick={() => history.push(routes.dashboard)} data-testid="go-to-dashboard">
            {t('page.appTeaser.button.goToDashboard')}
          </Button>
        </Box>
      </CenteredBox>
    </Page>
  )
}

export default AppTeaserPage
