import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const KeyL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26 12.3a6 6 0 0 1 6.33-1.39L18.52.98a2.5 2.5 0 0 1 3.42-.11l.12.11a2.5 2.5 0 0 1 0 3.54l-.36.35 1.77 1.77a2 2 0 0 1 0 2.83l-.35.35a2 2 0 0 1-2.83 0l-1.77-1.77-1.06 1.06 1.77 1.77a2 2 0 0 1 0 2.83l-.36.35a2 2 0 0 1-2.82 0l-1.77-1.76-2.15 2.15a6 6 0 1 1-9.87-2.15Zm1.06 1.06a4.5 4.5 0 1 0 7 .78l3.96-3.97 2.65 2.66a.75.75 0 0 0 1.06-1.06L15.34 9.1l3.18-3.18 2.65 2.65a.75.75 0 1 0 1.06-1.06l-2.65-2.65L21 3.46a1 1 0 0 0-1.33-1.5l-.09.08L8.9 12.73a4.5 4.5 0 0 0-5.58.63ZM4 16.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0Zm1.75-1.3a1.5 1.5 0 1 0 1.5 2.6 1.5 1.5 0 0 0-1.5-2.6Z"
      />
    </Svg>
  )
}

export default KeyL
