import React from 'react'
import { Typography, Button, Box } from '@mui/material'

import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { registerDialogClasses } from './styles'

const RegisterDialog: React.FC<{ isOpen: boolean; closeDialog: () => void }> = ({ isOpen, closeDialog }) => {
  const { t } = useFavurTranslation()
  const handleDialogClose = () => {
    closeDialog()
  }

  return (
    <Dialog
      open={isOpen}
      actions={
        <Box sx={registerDialogClasses.registerDialogButton}>
          <Button onClick={handleDialogClose} color="secondary" size="large" data-testid="closeRegisterDialog">
            {t('common.dialog.button.close')}
          </Button>
        </Box>
      }
      title={t('common.dialog.register.title')}
      sx={registerDialogClasses.dialog}
    >
      <Typography variant="body2" sx={registerDialogClasses.content}>
        {t('common.dialog.register.content1')}
      </Typography>
      <Typography variant="body2" sx={registerDialogClasses.content}>
        {t('common.dialog.register.content2')}
      </Typography>
    </Dialog>
  )
}

export default RegisterDialog
