import React, { useCallback, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { MutationResult, useMutation } from '@apollo/client'
import CenteredBox from 'components/CenteredBox'
import PhoneNumberInput from 'components/Forms/components/PhoneNumberInput'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePhoneNumber from 'hooks/usePhoneNumber'
import { NumpadWithoutState } from 'components/Numpad'
import Snackbar from 'components/Snackbar'
import { isNativeNoOverride } from 'utils/platform'
import { classes as forgotPhoneClasses } from '../../styles'
import { validateOldSimQuery } from '../../queries'

interface IOldSimProps {
  goToStep: (stepName: string) => void
  setOldPhone?: (oldPhone: string) => void
}

const OldSim: React.FC<IOldSimProps> = ({ goToStep, setOldPhone }) => {
  const { t } = useFavurTranslation()
  const { updatePhoneNumber, phone, setPhone, getFullPhoneNumber } = usePhoneNumber('')
  const [error, setError] = useState(false)
  const getHelperText = () => {
    return error ? t('login.start.error.phoneInvalid') : t('forgotPhone.oldSim.helperText')
  }
  const [validateOldSimMutation] = useMutation<MutationResult>(validateOldSimQuery)
  const [serverError, setServerError] = useState(false)
  const snackbarText = {
    title: t('common.error.label.errorTitle'),
    message: t('forgotPhone.errors.generic'),
  }

  const onSubmit = useCallback(() => {
    if (!phone.isValidNumber) {
      setError(true)
      return
    }
    validateOldSimMutation({ variables: { phone: getFullPhoneNumber(false) } })
      .then(() => {
        setOldPhone !== undefined && setOldPhone(getFullPhoneNumber())
        goToStep('HR_PIN')
      })
      .catch((_e) => setServerError(true))
  }, [getFullPhoneNumber, goToStep, phone.isValidNumber, setOldPhone, validateOldSimMutation])

  return (
    <CenteredBox sx={forgotPhoneClasses.root}>
      <Snackbar open={serverError} setOpen={setServerError} flashText={snackbarText} />
      <Box sx={forgotPhoneClasses.textContainer}>
        <Typography variant="h2" color="primary">
          {t('forgotPhone.oldSim.title')}
        </Typography>
        <Typography variant="body1" sx={forgotPhoneClasses.bodyText}>
          {t('forgotPhone.oldSim.body')}
        </Typography>
      </Box>
      <Box sx={forgotPhoneClasses.inputContainer}>
        <PhoneNumberInput
          label={t('forgotPhone.oldSim.inputLabel')}
          phone={phone}
          onChange={(e) => updatePhoneNumber(e.target.value)}
          setPhone={setPhone}
          error={error}
          helperText={getHelperText()}
          readOnly={isNativeNoOverride()}
        />
      </Box>
      <Box sx={forgotPhoneClasses.buttonContainer}>
        <NumpadWithoutState
          numpadType="pin"
          numpadValue={phone.phoneNumber}
          setNumpadValue={updatePhoneNumber}
          onConfirm={onSubmit}
          usedWithPhoneNumberValidation
        />
        <Button
          size="large"
          sx={forgotPhoneClasses.bottomButtons}
          type="submit"
          variant="text"
          color="secondary"
          onClick={() => goToStep('START')}
          data-testid="go-to-start"
        >
          {t('forgotPhone.oldSim.back')}
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default OldSim
