import { gql } from '@apollo/client'
import { authQuery } from 'shared/queries'

export const validationQuery = gql`
  mutation($validationCode: String!, $rememberMe: Boolean) {
    authValidate(validationCode: $validationCode, rememberMe: $rememberMe) {
      ${authQuery}
    }
  }`

export const validateRegisterQuery = gql`
  mutation($smsCode: String!) {
    validateRegister(smsCode: $smsCode) {
      success
    }
  }
`
