import React from 'react'
import { AvatarDataT } from 'types'
import Avatar from 'components/Avatar'
import ComponentBadge from 'components/ComponentBadge'
import { avatarIconClasses } from './styles'

interface IAbsencePlannerAvatarIconProps {
  tasks?: number
  avatarIconData: AvatarDataT
  showBorder?: boolean
}

const AbsencePlannerAvatarIcon: React.FC<IAbsencePlannerAvatarIconProps> = ({ avatarIconData, showBorder, tasks }) => {
  return (
    <ComponentBadge badgeContent={tasks} max={1000}>
      <Avatar
        size={40}
        avatar={avatarIconData}
        sx={[...(showBorder ? [avatarIconClasses.avatarWithBorder] : [avatarIconClasses.sideMargin])]}
      />
    </ComponentBadge>
  )
}

export default AbsencePlannerAvatarIcon
