import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const ArrowRightThinS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.14 2.15c.2-.2.51-.2.7 0l2.7 2.61 2.7 2.62a.87.87 0 0 1 .2.96c-.05.1-.12.2-.2.28l-5.4 5.24a.5.5 0 0 1-.69-.72L10.44 8 5.15 2.86a.5.5 0 0 1 0-.7Z"
      />
    </Svg>
  )
}

export default ArrowRightThinS
