import React from 'react'
import { Box, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { InvitationT, PersonT } from 'types'
import { newDateWithoutTime } from 'utils/date'
import routes from 'services/RoutesProvider/routes'
import { classes } from './styles'
import EmployerCard from '../EmployerCard'

interface IEmployerCardListProps {
  persons: PersonT[]
  invitations?: InvitationT[]
  title?: string
  historical?: boolean
}

const EmployerCardList: React.FC<IEmployerCardListProps> = ({
  persons,
  invitations = [],
  historical = false,
  title,
}) => {
  const history = useHistory()
  return (
    <Box sx={classes.listContainer}>
      {title && (
        <Typography variant="h3" sx={classes.title}>
          {title}
        </Typography>
      )}
      {invitations.map((invitation) => (
        <EmployerCard
          isInvitation
          name={invitation.tenantName}
          key={invitation.code}
          onClickInvitation={() => {
            history.push(`${routes.invite}/${invitation.code}`)
          }}
        />
      ))}
      {persons.map((person) => (
        <EmployerCard
          name={person.tenant?.name as string}
          key={person.id}
          resignationDate={historical ? newDateWithoutTime(person.workWithdrawal) : undefined}
          joiningDate={historical ? newDateWithoutTime(person.workEntry) : undefined}
          onClick={
            historical
              ? undefined
              : () => {
                  history.push(`${routes.employer}/${person.favurUuid}`)
                }
          }
          hasNotSharedDataAlert={!historical && !person.lastSharedData}
        />
      ))}
    </Box>
  )
}

export default EmployerCardList
