import { UseFieldArrayReturn } from 'react-hook-form'
import range from 'lodash/fp/range'
import { reverse } from 'lodash/fp'
import { datePickersToString, newDateWithoutTime } from 'utils/date'
import { civilStatusWithPartnerKeys, defaultPersonValues } from './constants'

export const DOMESTIC_NATIONALITIES = ['CH']

export const isDomestic = (val: string) => DOMESTIC_NATIONALITIES.includes(val)

export const hasPartner = (civilStatusValue: string): boolean => {
  return civilStatusWithPartnerKeys.includes(civilStatusValue)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFamilyMemberData = (data: any, type?: string): string => {
  switch (type) {
    case 'partner': {
      return `{
        firstName: "${data.partnerFirstName}",
        lastName: "${data.partnerLastName}",
        birthDate: "${data?.partnerBirthDate ? datePickersToString(newDateWithoutTime(data.partnerBirthDate)) : ''}",
        gender: "${data.partnerGender}",
        socialInsuranceNumber: "${data.partnerSocialInsuranceNumber}",
      }`
    }
    default: {
      return `{
        firstName: "${data.firstName}",
        lastName: "${data.lastName}",
        birthDate: "${data?.birthDate ? datePickersToString(newDateWithoutTime(data.birthDate)) : ''}",
        gender: "${data.gender}",
        socialInsuranceNumber: "${data?.socialInsuranceNumber || ''}",
      }`
    }
  }
}

interface IRemoveOrAppendKids {
  oldKidsNumber: number
  newKidsNumber: number
  remove: UseFieldArrayReturn['remove']
  append: UseFieldArrayReturn['append']
}

export const removeOrAppendKids = (
  { oldKidsNumber, newKidsNumber, remove, append } = <IRemoveOrAppendKids>{},
): void => {
  const diff = oldKidsNumber - newKidsNumber

  if (diff > 0) {
    remove(range(oldKidsNumber - 1, newKidsNumber - 1))
  }

  if (diff < 0) {
    const inputs = [...Array(Math.abs(diff))].fill(defaultPersonValues)
    append(inputs)
  }
}

export const hasPartnerAndEmpty = (civilStatus: string, textFieldValue: string): boolean => {
  return !(hasPartner(civilStatus) && textFieldValue === '')
}

export const validSocialInsuranceNumber = (number: string) => {
  if (!/^756.[0-9]{4}\.[0-9]{4}\.[0-9]{2}$/.test(number)) {
    return 'personalData.error.invalidSocialInsuranceNumberFormat'
  }
  const removedDots = number.replace(/\./g, '')
  const divided = /^([0-9]{12})([0-9]{1})$/.exec(removedDots)
  if (!divided) {
    return 'personalData.error.invalidSocialInsuranceNumberFormat'
  }
  const numbers = divided[1]
  const validator = parseInt(divided[2])
  const splitted = numbers.split('')
  const added = reverse(splitted).reduce((acc, num, index) => {
    const intNum = parseInt(num)
    if (index % 2 === 0) {
      return acc + intNum * 3
    }
    return acc + intNum
  }, 0)

  const calculatedValidator = Math.ceil(added / 10) * 10 - added

  return validator === calculatedValidator ? undefined : 'personalData.error.invalidSocialInsuranceNumberDigits'
}

export const nonDomesticFieldAndEmpty = (nat: string, val: string) => {
  const domestic = isDomestic(nat)
  return !domestic && (!val || val === '')
}

export const domesticFieldAndEmpty = (nat: string, val: string) => {
  const domestic = isDomestic(nat)
  return domestic && (!val || val === '')
}

export const isValidZipCode = (zipCode: string) => {
  // Zipcodes are so different depending on the country that we only want to test that the zipCode has at least one digit. (Philipp)
  return /\d/g.test(zipCode)
}
