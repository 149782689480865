import React from 'react'
import { Box, Drawer, IconButton, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AllEmployeesM, OneEmployeeM } from 'icons'
import routes from 'services/RoutesProvider/routes'
import { classes } from './styles'

interface ISideMenuProps {
  open: boolean
  onClose: () => void
}

const SideMenu: React.FC<ISideMenuProps> = ({ open, onClose }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  return (
    <Drawer PaperProps={{ sx: classes.sideMenu }} anchor="right" open={open} onClose={onClose}>
      <Box sx={classes.menuContainer}>
        <Typography variant="caption" sx={classes.caption}>
          {t('tasks.sideMenu.caption')}
        </Typography>
        <IconButton
          type="button"
          sx={classes.sideMenuButton}
          onClick={() => history.push(`${routes.requestEmployeeData}/global`)}
          size="large"
        >
          <Box sx={classes.sideMenuButtonIcon}>
            <AllEmployeesM />
          </Box>
          <Typography variant="body2" sx={classes.sideMenuButtonText}>
            {t('tasks.sideMenu.requestAllEmployeeData')}
          </Typography>
        </IconButton>
        <IconButton
          type="button"
          sx={classes.sideMenuButton}
          onClick={() => history.push(`${routes.requestEmployeeData}/single`)}
          size="large"
        >
          <Box sx={classes.sideMenuButtonIcon}>
            <OneEmployeeM />
          </Box>
          <Typography variant="body2" sx={classes.sideMenuButtonText}>
            {t('tasks.sideMenu.requestOneEmployeeData')}
          </Typography>
        </IconButton>
      </Box>
    </Drawer>
  )
}

export default SideMenu
