import React, { useCallback, useMemo } from 'react'
import { Box, Button, Chip, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { groupBy } from 'lodash/fp'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { ArrowRightThinS, ChangeAlertS } from 'icons'
import useStateBackLink from 'hooks/useStateBackLink'
import theme from 'utils/theme'
import { classes } from './styles'

interface IUnacknowledgedUpdateProps {
  task: TaskT<ShiftUpdateTaskT>
  isFirst: boolean
  date: string
}

const UnacknowledgedUpdate: React.FC<IUnacknowledgedUpdateProps> = ({ task, isFirst, date }) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const shiftNumber = useMemo(() => Object.keys(groupBy('date', task.shiftsUpdatedHistory ?? [])).length, [task])
  const { setBackLink } = useStateBackLink()
  const onClick = useCallback(() => {
    setBackLink(routes.shifts)
    history.push(`${routes.shiftUpdateDetail}/${task.favurUuid}/${task.statusFe}?dateref=${date}`)
  }, [date, history, setBackLink, task.favurUuid, task.statusFe])
  return (
    <Box sx={[classes.columnContainer, ...(isFirst ? [classes.hiddenShiftContainer] : [])]}>
      <Box sx={classes.badgeLine} data-testid="shift-card__badge">
        <Chip
          sx={classes.unacknowledgedChip}
          onClick={onClick}
          label={
            <Box sx={classes.hiddenShiftChipContainer}>
              <ChangeAlertS fill={theme.palette.primary.main} />
              <Typography variant="subtitle2" sx={classes.hiddenShiftLabel}>
                {t('page.shifts.shiftCard.shiftUpdate.hidden')}
              </Typography>
            </Box>
          }
          clickable={false}
        />
      </Box>
      <Typography variant="body2" sx={classes.unacknowledgedTitle} data-testid="shift-card__unacknowledged-title">
        {t('page.shifts.shiftCard.shiftUpdate.unacknowledged.title')}
      </Typography>
      <Typography variant="body2" data-testid="shift-card__unacknowledged-body">
        {shiftNumber > 1
          ? t('page.shifts.shiftCard.shiftUpdate.unacknowledged.bodyMultiple', { shiftNumber })
          : t('page.shifts.shiftCard.shiftUpdate.unacknowledged.bodySingle')}
      </Typography>
      <Box sx={classes.ctaBlock}>
        <Button
          variant="text"
          color="secondary"
          sx={classes.showChangesButton}
          onClick={onClick}
          disableRipple
          disableFocusRipple
          data-testid="shift-card__unacknowledged-button"
        >
          {t('page.shifts.shiftCard.shiftUpdate.unacknowledged.showChanges')}
          <ArrowRightThinS />
        </Button>
      </Box>
    </Box>
  )
}

export default UnacknowledgedUpdate
