import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const PinL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M28.75 10c.647 0 1.18.492 1.244 1.122l.006.128v9.5a1.25 1.25 0 0 1-1.122 1.244L28.75 22H3.25a1.25 1.25 0 0 1-1.244-1.122L2 20.75v-9.5c0-.647.492-1.18 1.122-1.243L3.25 10h25.5Zm-.25 1.5h-25v9h25v-9Zm-10.008 2a.5.5 0 0 1 .492.41l.008.09v1.073l.899-.588a.5.5 0 0 1 .619.78l-.07.056-1.042.683 1.035.69a.5.5 0 0 1-.475.875l-.08-.043-.886-.592V18a.5.5 0 0 1-.992.09l-.008-.09v-1.074l-.898.59a.5.5 0 0 1-.619-.781l.07-.056 1.04-.683-1.034-.69a.5.5 0 0 1 .475-.875l.08.043.886.592V14a.5.5 0 0 1 .5-.5Zm5.993 0a.5.5 0 0 1 .492.41l.008.09v1.073l.9-.588a.5.5 0 0 1 .618.78l-.07.056-1.042.683 1.036.69a.5.5 0 0 1-.476.875l-.08-.043-.886-.592V18a.5.5 0 0 1-.992.09l-.008-.09v-1.074l-.897.59a.5.5 0 0 1-.62-.781l.071-.056 1.04-.683-1.035-.69a.5.5 0 0 1 .476-.875l.079.043.886.592V14a.5.5 0 0 1 .5-.5Zm-11.99 0a.5.5 0 0 1 .492.41l.008.09v1.073l.899-.588a.5.5 0 0 1 .62.78l-.072.056-1.04.683 1.035.69a.5.5 0 0 1-.476.875l-.08-.043-.886-.592V18a.5.5 0 0 1-.992.09l-.008-.09v-1.074l-.898.59a.5.5 0 0 1-.619-.781l.07-.056 1.04-.683-1.034-.69a.5.5 0 0 1 .475-.875l.08.043.886.592V14a.5.5 0 0 1 .5-.5Zm-5.993 0a.5.5 0 0 1 .492.41l.008.09v1.073l.899-.588a.5.5 0 0 1 .619.78l-.07.056-1.042.683 1.036.69a.5.5 0 0 1-.476.875l-.08-.043-.886-.592V18a.5.5 0 0 1-.992.09L6.002 18v-1.074l-.898.59a.5.5 0 0 1-.619-.781l.07-.056 1.04-.683-1.034-.69a.5.5 0 0 1 .475-.875l.08.043.886.592V14a.5.5 0 0 1 .5-.5Z"
      />
    </Svg>
  )
}

export default PinL
