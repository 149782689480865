import React from 'react'
import Svg from 'components/Svg'
import palette from 'utils/theme/palette'
import { IconPropsT } from './types'
import { ICON_SIZE_XL } from './constants'

const NoPagesXL: React.FC<IconPropsT> = ({ width = ICON_SIZE_XL, height = ICON_SIZE_XL }) => {
  return (
    <Svg size={ICON_SIZE_XL} width={width} height={height} fill="none">
      <circle cx="40" cy="40" r="40" fill={palette.primary[20]} />
      <path fill="#607BFF" d="M17 51V29a2 2 0 0 1 2-2h42a2 2 0 0 1 2 2v22H17Z" />
      <path fill="#DADEF2" d="M52 27H19a2 2 0 0 0-2 2v22h11l24-24Z" />
      <path fill="#fff" d="M17 51h46v4a2 2 0 0 1-2 2H19a2 2 0 0 1-2-2v-4Z" />
      <path
        stroke="#191919"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M59 27h2a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2H19a2 2 0 0 1-2-2V29a2 2 0 0 1 2-2h2M30 63h20"
      />
      <path fill="#E3E3E3" stroke="#191919" strokeLinecap="round" strokeLinejoin="round" d="M43 57h-6v6h6v-6Z" />
      <path fill="#fff" d="M55 22H25v25h30V22Z" />
      <path
        fill={palette.primary[20]}
        d="M55 22H25v-3.75c0-.331.21-.65.586-.884C25.96 17.132 26.47 17 27 17h26c.53 0 1.04.132 1.414.366.375.235.586.553.586.884V22Z"
      />
      <path
        stroke="#191919"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M25 47V19a2 2 0 0 1 2-2h26a2 2 0 0 1 2 2v28"
      />
      <path
        stroke="#141A33"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M33.528 27a.528.528 0 1 1 0 1.057.528.528 0 0 1 0-1.057ZM46.481 27a.528.528 0 1 1 0 1.056.528.528 0 0 1 0-1.056ZM31.913 37.56a9.503 9.503 0 0 1 15.81 0"
      />
      <path stroke="#191919" strokeLinecap="round" strokeLinejoin="round" d="M17 51h46" />
    </Svg>
  )
}

export default NoPagesXL
