import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Button, Box } from '@mui/material'
import CenteredBox from 'components/CenteredBox'
import routes from 'services/RoutesProvider/routes'
import useFavurTranslation from 'hooks/useFavurTranslation'
import UserIdentificationToken from '../../Common/UserIdentificationTokenSvg'
import { classes as securityClasses } from '../../styles'
import { classes as classes } from './styles'

const Intro: React.FC<{ goToStep: (stepName: string) => void }> = ({ goToStep }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <CenteredBox sx={[securityClasses.root, securityClasses.spaceBetween]}>
      <Box>
        <Typography variant="h2" sx={securityClasses.title}>
          {t('securitySetup.intro.content.title')}
        </Typography>
        <UserIdentificationToken sx={classes.image} />
        <Typography variant="body1" sx={classes.text}>
          {t('securitySetup.intro.content.text1')}
        </Typography>
        <Typography variant="body1" sx={classes.text}>
          <strong>{t('securitySetup.intro.content.text2')}</strong>
        </Typography>
      </Box>
      <Box>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="secondary"
          sx={securityClasses.actionButton}
          onClick={() => goToStep('TOKEN')}
          data-testid="security-intro-submit"
        >
          {t('securitySetup.intro.button.action')}
        </Button>
        <br />
        <Button
          size="large"
          onClick={() => history.push(routes.dashboard)}
          sx={securityClasses.cancelButton}
          data-testid="security-intro-cancel"
        >
          {t('securitySetup.intro.button.cancel')}
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default Intro
