import React from 'react'
import { Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import Card from 'components/Basics/Card'
import FamilyForm from 'components/Forms/Family'
import { formClasses } from 'pages/UserSettings/styles'
import routes from 'services/RoutesProvider/routes'
import type { IEmploymentFormProps } from '../types'

const FamilyData: React.FC<IEmploymentFormProps> = ({ changeTabTrigger, setChangeTabTrigger, setIsFormDirty }) => {
  const history = useHistory()

  return (
    <Box component="form" sx={formClasses.container}>
      <Card>
        <FamilyForm
          changeTabTrigger={changeTabTrigger}
          onCancel={() => history.push(routes.userSettings)}
          setChangeTabTrigger={setChangeTabTrigger}
          setIsFormDirty={setIsFormDirty}
          accessPoint="userSettings"
        />
      </Card>
    </Box>
  )
}

export default FamilyData
