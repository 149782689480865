import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const BanS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83514 4.12893C5.69715 3.42334 6.79914 3 8 3C10.7614 3 13 5.23858 13 8C13 9.20086 12.5767 10.3029 11.8711 11.1649C11.8654 11.1586 11.8596 11.1525 11.8536 11.1464L4.85355 4.14645C4.84753 4.14042 4.84139 4.13458 4.83514 4.12893ZM4.12893 4.83514C3.42334 5.69715 3 6.79914 3 8C3 10.7614 5.23858 13 8 13C9.20086 13 10.3029 12.5767 11.1649 11.8711C11.1586 11.8654 11.1525 11.8596 11.1464 11.8536L4.14645 4.85355C4.14042 4.84753 4.13458 4.84139 4.12893 4.83514ZM8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2Z"
        fill={fill}
      />
    </Svg>
  )
}

export default BanS
