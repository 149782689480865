import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { CmsContentT } from 'types'
import { listContents } from './queries'

const useListCmsContents = () => {
  const { data: contentData, loading: loading, error: error } = useJamesApolloQuery<{ cmsContentList: CmsContentT[] }>(
    listContents,
    {
      fetchPolicy: 'cache-and-network',
    },
  )

  return {
    contents: contentData?.cmsContentList,
    loading,
    error,
  }
}

export default useListCmsContents
