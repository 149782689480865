import React, { useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import { PERSONS_LIST_STATUS } from 'constants/person'
import EmployeeDetail from '../EmployeeDetail'

const PersonDetail: React.FC<RouteComponentProps<{ ownerId: string; favurUuid: string }>> = ({ match }) => {
  const { ownerId, favurUuid } = match.params
  const { t } = useFavurTranslation()
  const urlParams = new URLSearchParams(location.search)

  const status = urlParams.get('status') ?? PERSONS_LIST_STATUS.ACTIVE

  const title = useMemo(() => {
    return status === PERSONS_LIST_STATUS.INACTIVE
      ? t('page.teams.personDetail.ownerInactiveMembers')
      : t('page.teams.personDetail.ownerActiveMembers')
  }, [status, t])

  return (
    <EmployeeDetail
      backwardLink={routes.teamOwnerMembers(parseInt(ownerId), status)}
      favurUuid={favurUuid}
      topTitle={title}
    />
  )
}

export default PersonDetail
