import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { sortBy } from 'lodash/fp'
import EmployeeList from 'components/EmployeeList'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import Card from 'components/Basics/Card'
import ActionButtons from 'components/Buttons/ActionButtons'
import routes from 'services/RoutesProvider/routes'
import { MembersMapT } from 'pages/Teams/types'
import { PersonT, MutationResult } from 'types'
import { TeamTabs } from 'pages/Teams/TeamPage/constants'
import { getSortingLastName } from 'utils/person'
import { normalizeTeamMembers } from 'pages/Teams/utils'
import useFavurTranslation from 'hooks/useFavurTranslation'
import DialogWindow from 'components/Dialog/DialogWindow'
import SelectedInfo from 'pages/Teams/components/SelectedInfo'
import { classes } from 'pages/Teams/styles'
import { useMutation } from '@apollo/client'
import { useTeam, getTeamMembers } from '../../useTeam'
import { updatePermissionsMutation } from './queries'

const EditPermissionMembers: React.FC<RouteComponentProps<{ uuid: string; permission: string }>> = ({ match }) => {
  const { uuid: teamId, permission } = match.params
  const { t } = useFavurTranslation()
  const history = useHistory()
  const [members, setMembers] = useState<MembersMapT>(new Map())
  const [originalMembers, setOriginalMembers] = useState<MembersMapT>(new Map())
  const { team, loading, personsByPermission } = useTeam(teamId)
  const persons = useMemo(() => {
    return getTeamMembers(team)
  }, [team])
  const [mutate] = useMutation<{ updateTeamPermissions: MutationResult }>(updatePermissionsMutation)
  const [showDialog, setShowDialog] = useState(false)

  const onChange = useCallback(({ id }: { id: number }) => {
    setMembers((curr) => new Map(curr).set(id, !curr.get(id)))
  }, [])

  const membersInPermission = useCallback(() => {
    return Array.from(members.values()).some((included) => included)
  }, [members])

  const getChecked = useCallback((id: number): boolean => members.get(id) ?? false, [members])

  const onSubmit = useCallback(() => {
    const changes = normalizeTeamMembers({ currentMembers: members, originalMembers })
    mutate({
      variables: {
        teamId,
        permission,
        changes,
      },
    }).then((result) => {
      if (result.data?.updateTeamPermissions.success) {
        history.push(`${routes.teams}/${teamId}/${permission === 'supervisor' ? TeamTabs.settings : TeamTabs.rights}`)
      }
    })
  }, [members, originalMembers, mutate, teamId, permission, history])

  const reducer = useCallback(
    (prev: MembersMapT, curr: PersonT): MembersMapT => {
      const membersOfPermission = personsByPermission.get(permission)
      const personIds = membersOfPermission?.map((mp: PersonT) => mp.id)
      const hasWorkflowPermission = personIds?.includes(curr.id)
      return prev.set(curr.id as number, hasWorkflowPermission ?? false)
    },
    [permission, personsByPermission],
  )

  const membersListChanged = useCallback(() => {
    const changes = normalizeTeamMembers({ currentMembers: members, originalMembers })
    return changes.add.length > 0 || changes.delete.length > 0
  }, [members, originalMembers])

  const getNumMembersSelected = useCallback(() => {
    return Array.from(members.values()).filter((selected) => selected).length
  }, [members])

  useEffect(() => {
    if (personsByPermission && persons) {
      const membersFromDb = persons.reduce(reducer, new Map() as MembersMapT)
      setMembers(membersFromDb)
      setOriginalMembers(membersFromDb)
    }
  }, [personsByPermission, persons, reducer])

  if (loading || !personsByPermission || !persons) {
    return null
  }

  return (
    <>
      <DialogWindow
        bodyText={t('teams.permissions.noPersonSelected.body')}
        onCancel={() => {
          setShowDialog(false)
        }}
        onConfirm={onSubmit}
        open={showDialog}
        titleText={t('teams.permissions.noPersonSelected.title')}
      />
      {!showDialog && (
        <Page
          growSubheader
          header={
            <WithBackwardLink
              closeIcon
              route={`${routes.teams}/${teamId}/${permission === 'supervisor' ? TeamTabs.settings : TeamTabs.rights}`}
              title={t(`teams.permissions.${permission}.title`)}
              topTitle={t('teams.tabLabel.rights')}
            >
              <SelectedInfo numSelected={getNumMembersSelected()} labelText={t('page.teams.selectedPersons.label')} />
            </WithBackwardLink>
          }
          hasFloatingButtons
          hideNativeNavigation
        >
          {persons.length === 0 ? (
            <Card>
              <Typography variant="body1" sx={classes.noPersonsText} data-testid="no-persons">
                {t('page.teams.noPersonsInTeam')}
              </Typography>
            </Card>
          ) : (
            <Box sx={classes.editTeamMembersForm}>
              <EmployeeList
                employees={sortBy((p) => getSortingLastName(p), persons)}
                checkbox
                onClickItem={(person) => {
                  onChange({ id: person.id as number })
                }}
                itemIsChecked={(person) => getChecked(person.id as number)}
                checkValidInvitation
                showTenant={(team?.owner?.tenants?.length ?? 0) > 1}
                checkResignationDate
              />
              <ActionButtons
                cancelAction={() =>
                  history.push(
                    `${routes.teams}/${teamId}/${permission === 'supervisor' ? TeamTabs.settings : TeamTabs.rights}`,
                  )
                }
                cancelLabel={t('common.cancel')}
                isFloating
                noNavigation
                validateLabel={t('common.confirm')}
                isValidateButtonActive={membersListChanged()}
                validateAction={() => {
                  if (membersInPermission() || permission === 'supervisor') {
                    onSubmit()
                  } else {
                    setShowDialog(true)
                  }
                }}
              />
            </Box>
          )}
        </Page>
      )}
    </>
  )
}

export default EditPermissionMembers
