import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import { headerClasses } from 'pages/UserSettings/styles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Page from 'components/Page'
import { SimpleLoading } from 'components/LoadingIcon'
import usePersonTeams from 'pages/UserSettings/hooks/usePersonTeams'
import { getTeamNameByLanguage } from 'types/utils'
import { TeamT } from 'types'
import { classes } from './styles'
import PermissionsSection from './sections/Permissions'
import MembersSection from './sections/Members'

const EmployerPersonTeamPage: React.FC = () => {
  const { favurUuid, teamId } = useParams<{ favurUuid: string; teamId: string }>()
  const { t, language } = useFavurTranslation()
  const history = useHistory()
  const { person, loading } = usePersonTeams(favurUuid)

  const personTeamPermissions = useMemo(() => {
    if (loading || !person) return undefined
    return person.teamPermissions?.find((ptp) => ptp.teamId === teamId)
  }, [loading, person, teamId])

  const memberList = useMemo(() => {
    if (!personTeamPermissions) return []
    return (personTeamPermissions.team?.teamPermissions ?? []).filter((ptp) => ptp.person?.favurUuid !== favurUuid)
  }, [favurUuid, personTeamPermissions])

  const gotoBackLink = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Page
      header={
        <BackwardLink
          onClickIcon={gotoBackLink}
          title={
            personTeamPermissions
              ? getTeamNameByLanguage(personTeamPermissions.team as TeamT, language)
              : t('navigation.main.menu.teams')
          }
        />
      }
      sxHeader={headerClasses.subHeader}
    >
      {!personTeamPermissions ? (
        <SimpleLoading />
      ) : (
        <Box sx={classes.contentContainer}>
          <PermissionsSection teamPermissions={personTeamPermissions} />
          <MembersSection memberList={memberList} />
        </Box>
      )}
    </Page>
  )
}

export default EmployerPersonTeamPage
