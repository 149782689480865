import React, { useMemo } from 'react'
import { Locale } from 'date-fns'
import theme from 'utils/theme'
import { ExternalLinkM } from 'icons'
import { TenantLinkT } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import CardCarousel, { CardCarouselCardT } from 'components/CardCarousel'

const getLabelByLocale = (link: TenantLinkT, { code }: Locale) => {
  switch (code) {
    case 'de':
      return link.labelDe
    case 'it':
      return link.labelIt
    case 'fr':
      return link.labelFr
    default:
      return link.labelEn
  }
}

export const getCardFromTenantLink = (link: TenantLinkT, locale: Locale) => ({
  key: `link_${link.id}`,
  label: getLabelByLocale(link, locale) ?? link.url,
  onClick: () => window.open(link.url, '_blank'),
  icon: <ExternalLinkM stroke={theme.palette.primary.main} />,
})

interface ITenantLinkCarouselProps {
  linkList: TenantLinkT[]
}

const TenantLinkCarousel: React.FC<ITenantLinkCarouselProps> = ({ linkList }) => {
  const { locale } = useFavurTranslation()

  const cards: CardCarouselCardT[] = useMemo(
    () => linkList.map((link) => getCardFromTenantLink(link, locale as Locale)),
    [linkList, locale],
  )

  return <CardCarousel cards={cards} dataTestid="tenant-link_dashboard-section" />
}
export default TenantLinkCarousel
