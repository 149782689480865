import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const FilledCheckmarkM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <circle cx="12" cy="12" r="10" />
      <path
        fill="#fff"
        d="M16.699 7.01c-.385-.062-.682.174-.84.399-1.3 1.845-3.596 4.693-4.889 6.544l-.09.13c-.237-.263-.475-.524-.713-.785-.72-.79-.448-.574-1.184-1.35-.277-.291-.75-.455-1.175-.2a2.528 2.528 0 0 0-.572.466c-.364.4-.262.956.004 1.293 1.137 1.434 1.366 1.792 2.677 3.064.652.63 1.67.557 2.228-.16.825-1.055 3.327-4.066 5.656-7.483.211-.31.301-.782.033-1.168-.36-.517-.808-.698-1.135-.75Z"
      />
    </Svg>
  )
}

export default FilledCheckmarkM
