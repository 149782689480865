import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const FilledThumbUpM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <circle cx="12" cy="12" r="10" />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M9.74 16.428c.288.138.603.21.922.21h4.582a2.143 2.143 0 0 0 2.117-1.813l.537-3.43a1.714 1.714 0 0 0-1.694-1.98h-2.813v-2.02a1.393 1.393 0 0 0-2.609-.68l-1.78 3.18a1.286 1.286 0 0 0-.164.627v4.665a1.286 1.286 0 0 0 .732 1.16l.171.082H9.74Zm-2.885-6.377a.855.855 0 0 0-.855.854v4.381a.855.855 0 0 0 .855.855h.425a.429.429 0 0 0 .428-.429V10.48a.429.429 0 0 0-.428-.429l-.425.001Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default FilledThumbUpM
