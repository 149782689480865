import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import CenteredBox from 'components/CenteredBox'
import { HandKey } from 'icons'
import palette from 'utils/theme/palette'
import routes from 'services/RoutesProvider/routes'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'
import { classes as forgotPhoneClasses } from '../../styles'

const Start: React.FC<{ goToStep: (stepName: string) => void }> = ({ goToStep }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  return (
    <CenteredBox sx={forgotPhoneClasses.root}>
      <Box sx={forgotPhoneClasses.textContainer}>
        <Typography variant="h2" color="primary">
          {t('forgotPhone.start.title')}
        </Typography>
        <Box sx={classes.centralImage}>
          <HandKey fill={palette.primary[100]} />
        </Box>
        <Typography variant="body1">{t('forgotPhone.start.body1')}</Typography>
        <Typography variant="body1" sx={classes.boldBody}>
          {t('forgotPhone.start.body2')}
        </Typography>
      </Box>
      <Box sx={forgotPhoneClasses.buttonContainer}>
        <Button
          size="large"
          sx={forgotPhoneClasses.acceptButton}
          type="submit"
          variant="contained"
          color="secondary"
          onClick={() => goToStep('OLD_SIM')}
          data-testid="next-step"
        >
          {t('forgotPhone.start.haveKey')}
        </Button>
        <Button
          size="large"
          sx={forgotPhoneClasses.bottomButtons}
          type="submit"
          variant="text"
          color="secondary"
          onClick={() => history.push(routes.login)}
          data-testid="go-back"
        >
          {t('forgotPhone.start.cancel')}
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default Start
