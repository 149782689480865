import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const NewCalendarL: React.FC<IconPropsT> = ({ fill = '#fff' }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M10.752 3.75a1 1 0 0 1 1 1v2h8.5v-2a1 1 0 1 1 2 0v2h3.5a2.5 2.5 0 0 1 2.5 2.5v16.5a2.5 2.5 0 0 1-2.5 2.5h-19.5a2.5 2.5 0 0 1-2.5-2.5V9.25a2.5 2.5 0 0 1 2.5-2.5h3.5v-2a1 1 0 0 1 1-1Zm9.5 5V10a1 1 0 1 0 2 0V8.75h3.5a.5.5 0 0 1 .5.5v3.5h-20.5v-3.5a.5.5 0 0 1 .5-.5h3.5V10a1 1 0 0 0 2 0V8.75h8.5Zm6 6v11a.5.5 0 0 1-.5.5h-19.5a.5.5 0 0 1-.5-.5v-11h20.5Z"
      />
    </Svg>
  )
}

export default NewCalendarL
