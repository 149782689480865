import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import useAbsencePlannerState from 'pages/AbsencePlanner/hooks/useAbsencePlannerState'
import useTaskBarStateContext from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import useAvatarSidebarStateContext from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import { gridClasses } from '../styles'

interface ITableBackgroundContainerProps {
  children: React.ReactNode
}

export const TableBackgroundContainer: React.FC<ITableBackgroundContainerProps> = ({ children }) => {
  const { isSmallScreen } = useAbsencePlannerState()
  const { avatarBarExpanded } = useAvatarSidebarStateContext()
  const { taskBarState } = useTaskBarStateContext()

  const leftMarginClass = useMemo(
    () => (avatarBarExpanded ? gridClasses.openDrawerMargin : gridClasses.closedDrawerMargin),
    [avatarBarExpanded],
  )

  return (
    <Box sx={[gridClasses.tableBackground(avatarBarExpanded, Boolean(taskBarState), isSmallScreen), leftMarginClass]}>
      {children}
    </Box>
  )
}

export default TableBackgroundContainer
