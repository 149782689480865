import React, { ReactNode } from 'react'
import { Box } from '@mui/material'
import { classes } from './styles'
import CarouselCard from './Card'

export type CardCarouselCardT = {
  key: string
  label: string
  onClick: () => void
  icon?: ReactNode
}

interface ICardCarouselProps {
  cards: CardCarouselCardT[]
  dataTestid?: string
}

const CardCarousel: React.FC<ICardCarouselProps> = ({ cards, dataTestid }) => {
  return (
    <Box sx={classes.carousel} data-testid={dataTestid ?? 'card_carousel'}>
      {cards.map(({ key, label, onClick, icon }) => (
        <CarouselCard key={key} label={label} onClick={onClick} icon={icon} testid={key} />
      ))}
    </Box>
  )
}
export default CardCarousel
