import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const AlertSuccessS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M7.441.016a7.993 7.993 0 0 0-3.767 1.251 8.121 8.121 0 0 0-2.553 2.644 8.003 8.003 0 0 0 7.832 12.031 7.973 7.973 0 0 0 4.704-2.287 8.003 8.003 0 0 0 2.055-3.512 8.043 8.043 0 0 0 .267-1.556.676.676 0 0 1 .025-.188c.007 0 .013-.188.013-.417 0-.23-.006-.413-.013-.408-.008.004-.019-.073-.025-.171a7.882 7.882 0 0 0-.274-1.572A8.006 8.006 0 0 0 9.025.063 8.856 8.856 0 0 0 7.441.016Zm.064 1a7.006 7.006 0 0 0-6.46 6.198c-.04.333-.04 1.24.001 1.569a7.014 7.014 0 0 0 3.907 5.513 6.964 6.964 0 0 0 2.272.658c.32.04 1.233.04 1.552 0 .88-.11 1.55-.308 2.32-.684a7.01 7.01 0 0 0 3.859-5.488c.041-.329.041-1.235 0-1.567-.217-1.78-1.017-3.304-2.355-4.487a6.994 6.994 0 0 0-3.236-1.593c-.465-.092-.712-.115-1.276-.12a15.83 15.83 0 0 0-.584.002Zm4.342 3.06a.577.577 0 0 0-.265.199c-.04.046-1.236 1.558-2.66 3.36-2.37 3-2.592 3.277-2.648 3.294-.093.028-.164-.003-.24-.104-.033-.045-.408-.575-.832-1.178a97.09 97.09 0 0 0-.824-1.16.459.459 0 0 0-.355-.165c-.113-.006-.142-.002-.217.033a.469.469 0 0 0-.298.437c-.005.106 0 .143.026.203.027.06 1.423 2.061 1.72 2.466.151.205.407.377.663.444.156.04.412.044.57.007.215-.05.473-.2.607-.35.15-.172 5.323-6.73 5.355-6.792a.55.55 0 0 0 .026-.375.509.509 0 0 0-.628-.32ZM.008 7.999c0 .233.003.329.005.212.003-.117.003-.307 0-.424-.002-.117-.005-.021-.005.212Z"
      />
    </Svg>
  )
}

export default AlertSuccessS
