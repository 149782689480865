import { SecuritySetupProcessT } from './types'
import useJamesMutation from '../../hooks/useJamesMutation'

export const SECURITY_PROCESS: {
  securitySetup: SecuritySetupProcessT
  pinReset: SecuritySetupProcessT
  pinChange: SecuritySetupProcessT
} = {
  securitySetup: 'securitySetup',
  pinReset: 'pinReset',
  pinChange: 'pinChange',
}

export const mutations = {
  setPinAndEstablishSecureConnection: {
    query: 'setPinAndEstablishSecureConnection(pinCode: "#pinCode"){success}',
  },
  changePin: {
    query: 'changePin(newPinCode: "#pinCode"){success}',
  },
}

const getMutation = (processType: SecuritySetupProcessT) => {
  if (processType === SECURITY_PROCESS.securitySetup) {
    return mutations.setPinAndEstablishSecureConnection
  }
  return mutations.changePin
}

const useSecuritySetupMutation = (processType: SecuritySetupProcessT) => {
  const mutationQuery = getMutation(processType)
  const [mutation] = useJamesMutation(mutationQuery.query)

  return [mutation]
}

export default useSecuritySetupMutation
