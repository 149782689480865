import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import { taskStates } from 'pages/Tasks/constants'
import { AlertS } from 'icons'
import palette from 'utils/theme/palette'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import { notificationStatuses, ReminderShiftUpdateNotificationT } from 'types'
import type { NotificationPropsT } from 'components/Notification/types'

interface IShiftUpdateReminder extends NotificationPropsT {
  notification: ReminderShiftUpdateNotificationT
}

const ShiftUpdateReminder: React.FC<IShiftUpdateReminder> = (props) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { notification, dismiss } = props

  const redirectTaskShiftUpdate = useCallback(
    (taskUuid: string) => {
      const status = taskStates.todo
      return history.push(`${routes.shiftUpdateDetail}/${taskUuid}/${status}`)
    },
    [history],
  )

  const handleDetails = useCallback(() => {
    dismiss(notification)
    const { taskUuid } = notification.data

    return redirectTaskShiftUpdate(taskUuid)
  }, [dismiss, notification, redirectTaskShiftUpdate])
  const iconColor = notification.status === notificationStatuses.new ? palette.secondary.main : palette.grey[300]
  const templateProps = {
    ...props,
    title: t('notification.new.shiftchange.reminder.title'),
    body: t('notification.new.shiftchange.reminder.body'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
    dismissText: t('component.notification.button.dismiss'),
    titleIcon: <AlertS fill={iconColor} />,
  }
  return <NotificationTemplate {...templateProps} />
}

export default ShiftUpdateReminder
