import React, { useCallback } from 'react'
import { Typography, Button } from '@mui/material'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useSessionContext from 'hooks/useSessionContext'
import useRolesViews from 'hooks/useRolesViews'

interface ILogoutDialogProps {
  isOpen: boolean
  onClose: () => void
}

const LogoutDialog: React.FC<ILogoutDialogProps> = ({ isOpen, onClose }) => {
  const { t } = useFavurTranslation()
  const { logout: contextLogout } = useSessionContext()
  const { clearActiveView } = useRolesViews()
  const logout = useCallback(() => {
    clearActiveView()
    contextLogout()
  }, [clearActiveView, contextLogout])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} color="secondary" size="large" data-testid="close-logout-dialog">
            {t('common.dialog.button.close')}
          </Button>
          <Button onClick={logout} color="secondary" size="large" data-testid="logout-dialog-button">
            {t('page.userSettings.logout.dialog.button.logout')}
          </Button>
        </>
      }
      title={t('page.userSettings.logout.dialog.title')}
    >
      <Typography variant="body2">{t('page.userSettings.logout.dialog.body')}</Typography>
    </Dialog>
  )
}

export default LogoutDialog
