import React, { useContext, useMemo, useState, useEffect } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { RouteComponentProps } from 'react-router-dom'
import { SideMenuL } from 'icons'
import useRolesViews from 'hooks/useRolesViews'
import { tasksArray } from 'constants/tasks'
import useFavurTranslation from 'hooks/useFavurTranslation'
import PageWithFilter from 'components/Filter/PageWithFilter'
import { SimpleLoading } from 'components/LoadingIcon'
import { FilterProvider } from 'components/Filter/context'
import FlashMessagesContext from 'services/FlashMessages/context'
import { TaskTypeT, taskTypes } from 'shared/constants'
import useFilterConfiguration from './useFilterConfiguration'
import TaskList from './TaskList'
import SideMenu from './components/SideMenu'
import classes from './styles'
import type { TaskStateT } from './types'

const getUrlTaskState = (taskStateUrl: TaskStateT | undefined) => {
  return taskStateUrl && tasksArray.includes(taskStateUrl) ? taskStateUrl : tasksArray[0]
}

const getUrlTaskType = (): TaskTypeT | undefined => {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const taskType = urlParams.get('task_type')
  return taskType &&
    Object.values(taskTypes)
      .map((wTaskType) => wTaskType.toString())
      .includes(taskType)
    ? (taskType as TaskTypeT)
    : undefined
}

const Tasks: React.FC<RouteComponentProps<{ taskstate?: TaskStateT }>> = ({ match }) => {
  const { activeView, roles, persons } = useRolesViews()
  const { removeAll } = useContext(FlashMessagesContext)
  const { taskstate: taskStateUrl } = match.params
  const isOffice = activeView === roles.office
  const { configuration, loading: configLoading } = useFilterConfiguration(
    getUrlTaskState(taskStateUrl),
    getUrlTaskType(),
  )
  const { t } = useFavurTranslation()
  const [sideMenuOpen, setSideMenuOpen] = useState(false)

  const titleTrans = isOffice ? t('page.tasks.page.adminTitle') : t('page.tasks.page.title')

  const isShareUserDataManager = useMemo(() => persons?.some((person) => person.permissions.workflowShareUserData), [
    persons,
  ])

  useEffect(() => {
    removeAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showSideMenuButton = isOffice && (isShareUserDataManager as boolean)

  const headerComponent = (
    <Box sx={classes.taskHeader}>
      <Box sx={classes.emptyHeaderBlock}>&nbsp;</Box>
      <Typography sx={classes.taskHeaderTitle} variant="h2">
        {titleTrans}
      </Typography>
      <Box sx={classes.emptyHeaderBlock}>
        {showSideMenuButton && (
          <>
            <IconButton
              sx={classes.sideMenuIcon}
              onClick={() => setSideMenuOpen(true)}
              data-testid="tasks-open-sidemenu"
              disableRipple
              size="large"
            >
              <SideMenuL />
            </IconButton>
            <SideMenu open={sideMenuOpen} onClose={() => setSideMenuOpen(false)} />
          </>
        )}
      </Box>
    </Box>
  )

  if (configLoading) {
    return (
      <PageWithFilter header={headerComponent} filterPageTitle={titleTrans}>
        <SimpleLoading />
      </PageWithFilter>
    )
  }

  return (
    <FilterProvider configuration={configuration} name="tasks">
      <PageWithFilter header={headerComponent} filterPageTitle={titleTrans}>
        <TaskList office={isOffice} />
      </PageWithFilter>
    </FilterProvider>
  )
}

export default Tasks
