import React, { useMemo } from 'react'
import countries from 'i18n-iso-countries'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { FormHandlersT } from 'hooks/useFavurForm'
import UncontrolledSelect from '../UncontrolledSelect'

countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/de.json'))
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))
countries.registerLocale(require('i18n-iso-countries/langs/it.json'))

interface ICountrySelectProps {
  formHandlers: FormHandlersT
  inputName: string
  defaultValue?: string
}

const CountrySelect: React.FC<ICountrySelectProps> = ({ formHandlers, inputName, defaultValue }) => {
  const { language } = useFavurTranslation()
  const countryValues = useMemo(() => {
    return countries.getNames(language)
  }, [language])

  return (
    <UncontrolledSelect
      required
      formHandlers={formHandlers}
      name={inputName}
      options={countryValues}
      defaultValue={defaultValue}
    />
  )
}

export default CountrySelect
