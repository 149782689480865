import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Chip, Typography } from '@mui/material'
import Badge from 'components/Badge'
import { PlannedShiftT } from 'types'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { getShiftKey } from 'types/utils'
import theme from 'utils/theme'
import { ChangeAlertS } from 'icons'
import { hasAcknowledgedShiftUpdate } from '../utils'
import { classes } from './styles'

interface IShiftContentProps {
  sortedShifts: PlannedShiftT[]
  shiftUpdates: TaskT<ShiftUpdateTaskT>[]
  containerRef: React.RefObject<HTMLDivElement>
}

const getMaxChips = (containerHeight: number | null) => {
  const chipHeight = 18
  const labelHeigth = 25
  if (!containerHeight) return 0
  return Math.floor((containerHeight - labelHeigth) / chipHeight)
}

const CalendarDayShifts: React.FC<IShiftContentProps> = ({ sortedShifts, shiftUpdates, containerRef }) => {
  const [maxChips, setMaxChips] = useState(0)

  const hasAcknowledgedTask = useCallback((shift: PlannedShiftT) => hasAcknowledgedShiftUpdate(shift, shiftUpdates), [
    shiftUpdates,
  ])

  useEffect(() => {
    const maxChipsNum = getMaxChips(containerRef?.current?.clientHeight ?? 0)
    setMaxChips(maxChipsNum)

    const callback = () => setMaxChips(maxChipsNum)

    window.removeEventListener('resize', callback)
    window.addEventListener('resize', callback)

    return () => {
      window.removeEventListener('resize', callback)
    }
  }, [containerRef, sortedShifts])

  const hasOverflow = useMemo(() => sortedShifts.length > maxChips, [maxChips, sortedShifts.length])
  const chipsToShow = useMemo(() => (hasOverflow ? maxChips - 1 : maxChips), [hasOverflow, maxChips])

  return (
    <>
      {sortedShifts.map((shift, i) => {
        if (i < chipsToShow) {
          return hasAcknowledgedTask(shift) ? (
            <Badge shift={shift} key={getShiftKey(shift)} sx={classes.badge} mini />
          ) : (
            <Chip
              key={getShiftKey(shift)}
              label={<ChangeAlertS fill={theme.palette.primary.main} />}
              sx={classes.unacknowledgedChip}
            />
          )
        }
        return null
      })}
      {hasOverflow && <Typography variant="body2">...</Typography>}
    </>
  )
}

export default CalendarDayShifts
