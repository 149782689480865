import React, { useRef, useMemo } from 'react'
import { isPlatform } from '@ionic/react'
import { IconButton, Fade, Box } from '@mui/material'
import { CloseL, MenuBurgerFavurXL } from 'icons'
import { AuthenticationKind } from 'constants/authentication'
import { isNative } from 'utils/platform'
import HeaderLogo from '../Logo'
import HeaderNavigation from './HeaderNavigation'
import { toolbarClasses } from './styles'
import NavigationMenu from './NavigationMenu'
import type { IToolbarProps } from '../types'

const Menu: React.FC<IToolbarProps> = ({ auth, navigate, toggleDrawer, hideHamburger }) => {
  const loggedInAndShowHamburger = auth > AuthenticationKind.ANONYMOUS && !hideHamburger

  const scrollRef = useRef<HTMLDivElement>(null)

  const scroll = useMemo(() => {
    return navigate && (scrollRef.current?.scrollHeight || 0) - (scrollRef.current?.clientHeight || 0) > 0
  }, [navigate])

  return (
    <>
      <Box sx={toolbarClasses.toolbar}>
        {isPlatform('ios') && <Box sx={(toolbarClasses.content, toolbarClasses.native)} />}

        {!isNative() && (
          <Box sx={toolbarClasses.content}>
            <HeaderLogo width={86} height={24} visible={!navigate} />
            {loggedInAndShowHamburger && (
              <IconButton color="inherit" onClick={toggleDrawer} data-testid="navigation-icon" size="large">
                {navigate ? <CloseL /> : <MenuBurgerFavurXL />}
              </IconButton>
            )}
          </Box>
        )}
      </Box>

      {loggedInAndShowHamburger && (
        <Fade in={navigate}>
          <Box
            sx={[
              toolbarClasses.navigation,
              navigate && toolbarClasses.navigationVisible,
              !isNative() && toolbarClasses.navigationWeb,
            ]}
          >
            <HeaderNavigation />
            {scroll && <Box sx={toolbarClasses.bottomScrollRectangle} />}
            <NavigationMenu scrollRef={scrollRef} toggleDrawer={toggleDrawer} />
          </Box>
        </Fade>
      )}
    </>
  )
}

export default Menu
