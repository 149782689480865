import React, { useCallback } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import routes from 'services/RoutesProvider/routes'
import palette from 'utils/theme/palette'
import { AlertS } from 'icons'
import { notificationStatuses, ReminderAbsenceRequestManualForManagerNotificationT } from 'types'
import useHistoryUtils from 'hooks/useHistoryUtils'
import { ROLE_VIEWS } from 'constants/roles'
import type { NotificationPropsT } from 'components/Notification/types'

interface IAbsenceRequestManualForManagerReminder extends NotificationPropsT {
  notification: ReminderAbsenceRequestManualForManagerNotificationT
}

const AbsenceRequestManualForManagerReminder: React.FC<IAbsenceRequestManualForManagerReminder> = (props) => {
  const { pushWithRole } = useHistoryUtils()
  const { t } = useFavurTranslation()
  const { notification, dismiss } = props
  const { frontlinerNameShort, taskUuid } = notification.data

  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithRole(`${routes.absenceRequestDetail}/${taskUuid}`, ROLE_VIEWS.office)
  }, [dismiss, notification, pushWithRole, taskUuid])
  const iconColor = notification.status === notificationStatuses.new ? palette.secondary.main : palette.grey[300]
  const templateProps = {
    ...props,
    title: t('notification.push.headline.manual.frontliner.reminder', { frontlinerNameShort }),
    body: t('notification.new.absence.manual.reminder.body', { frontlinerNameShort }),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
    dismissText: t('component.notification.button.dismiss'),
    titleIcon: <AlertS fill={iconColor} />,
  }
  return <NotificationTemplate {...templateProps} />
}

export default AbsenceRequestManualForManagerReminder
