import React, { RefObject, useMemo } from 'react'
import { Box } from '@mui/material'
import { chunk } from 'lodash/fp'
import { eachDayOfInterval } from 'date-fns'
import { dateToISOStringWithoutTime } from 'utils/date'
import { AbsencePlannerGridBadgesT, AbsencePlannerGridDataT, DateRangeT } from 'pages/AbsencePlanner/types'
import { PersonT, TeamT, TeamWithUserPermissions } from 'types'
import { SimpleLoading } from 'components/LoadingIcon'
import useFilterContext from 'pages/AbsencePlanner/contexts/FilterContext'
import AbsencePlannerGridRow from '../Row'
import LoadingGridRow from './LoadingGridRow'
import PlaceHolderRow from '../TeamNameRow/PlaceHolderRow'
import { gridClasses } from '../styles'
import VirtualizedContainer from './VirtualizedContainer'
import { CELL_WIDTH, TEAM_NAME_ROW_HEIGHT } from '../constants'

interface IAbsencePlannerGridBodyProps {
  teams: TeamT[]
  personData: AbsencePlannerGridDataT
  userPerson: AbsencePlannerGridBadgesT | null
  shiftsDateRange: DateRangeT
  loading: boolean
  persons?: PersonT[]
  isSmallScreen: boolean
  isPreview?: boolean
  firstPersonIds: number[] | undefined
  scrollContainerRef: RefObject<HTMLDivElement>
}

const AbsencePlannerGridBody: React.FC<IAbsencePlannerGridBodyProps> = ({
  teams,
  personData,
  userPerson,
  shiftsDateRange,
  persons,
  firstPersonIds,
  isPreview,
  loading,
  scrollContainerRef,
}) => {
  const { teamsLoading } = useFilterContext()
  const shownDays = useMemo(() => {
    if (!shiftsDateRange.startDate || !shiftsDateRange.endDate) return []
    return eachDayOfInterval({ start: shiftsDateRange.startDate, end: shiftsDateRange.endDate }).map(
      dateToISOStringWithoutTime,
    )
  }, [shiftsDateRange.endDate, shiftsDateRange.startDate])
  const rowPlaceholderWidth = useMemo(() => `${shownDays.length * CELL_WIDTH}px`, [shownDays.length])
  const screenHeight = useMemo(() => window.innerHeight, [])
  const chunkedShownDays = useMemo(() => chunk(10, shownDays), [shownDays])
  const teamsParsed = teams as TeamWithUserPermissions[]

  if (teamsLoading) {
    return (
      <LoadingGridRow
        chunkedShownDays={chunkedShownDays}
        shownDays={shownDays}
        scrollContainerRef={scrollContainerRef}
      />
    )
  }

  return (
    <>
      {loading && (
        <Box sx={gridClasses.floatingLoading} data-testid="loading-absence-planner-grid-body">
          <SimpleLoading small />
        </Box>
      )}
      {persons && (
        <Box sx={[...(isPreview ? [gridClasses.stickyContainer] : [])]}>
          <VirtualizedContainer
            key="person-row_user"
            placeholderWidth={rowPlaceholderWidth}
            rootElement={scrollContainerRef.current}
            horizontalPreloadMargin={0}
            verticalPreloadMargin={screenHeight}
          >
            <AbsencePlannerGridRow
              person={persons[0] as PersonT}
              gridBadges={userPerson}
              chunkedShownDays={chunkedShownDays}
              isLastRow={teams.length < 1}
              userRow
              userIsAbsenceManager
              isPreview={isPreview}
              scrollContainerRef={scrollContainerRef}
              isAlone={teams.length === 0}
            />
          </VirtualizedContainer>
        </Box>
      )}

      {teamsParsed.flatMap((team, teamI) => {
        return [
          <VirtualizedContainer
            key={`${team.id}_name-row`}
            rootElement={scrollContainerRef.current}
            placeholderHeight={`${TEAM_NAME_ROW_HEIGHT}px`}
            placeholderWidth={rowPlaceholderWidth}
            horizontalPreloadMargin={0}
            verticalPreloadMargin={screenHeight}
          >
            <PlaceHolderRow shownDays={shownDays} teamId={team.id} />
          </VirtualizedContainer>,
          (team.teamPermissions ?? []).map((ptp, personI, teamPersons) => {
            if (firstPersonIds?.includes(parseInt(`${ptp.person?.id}`))) return null
            const gridBadges = personData.get(`${ptp.person?.id}`) ?? new Map()
            const person = ptp.person as PersonT
            const isLastRow = teamI === teams.length - 1 && personI === teamPersons.length - 1
            return (
              <VirtualizedContainer
                key={`person-row_${person.id}`}
                placeholderWidth={rowPlaceholderWidth}
                rootElement={scrollContainerRef.current}
                horizontalPreloadMargin={0}
                verticalPreloadMargin={screenHeight}
                isLastRow={isLastRow}
              >
                <AbsencePlannerGridRow
                  person={person}
                  gridBadges={gridBadges}
                  chunkedShownDays={chunkedShownDays}
                  teamId={team.id}
                  isPreview={isPreview}
                  scrollContainerRef={scrollContainerRef}
                  isLastRow={isLastRow}
                  userIsAbsenceManager={team.userPermissions && team.userPermissions.absenceManager}
                />
              </VirtualizedContainer>
            )
          }),
        ]
      })}
    </>
  )
}

export default AbsencePlannerGridBody
