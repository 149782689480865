import React from 'react'
import { Box, Typography } from '@mui/material'
import { badgeClasses } from '../styles'
import { IBadgeProps } from '../types'
import { getBadgeLabel, getLabelColor, getBackgroundColor, getBorder } from './utils'
import { shiftClasses } from './styles'

const ShiftBadge: React.FC<IBadgeProps> = ({ badge, isGreyscale, dataTestId }) => {
  const backgroundColor = getBackgroundColor(badge, isGreyscale)
  const labelColor = getLabelColor(badge, isGreyscale)
  const badgeLabel = getBadgeLabel(badge)
  const border = getBorder(badge, isGreyscale)

  return (
    <Box
      sx={[
        badgeClasses.shiftBadge,
        badgeClasses.badgeFullWidth,
        {
          backgroundColor,
          border,
        },
      ]}
      data-testid={dataTestId}
    >
      <Typography variant="overline" sx={shiftClasses.badgeText(labelColor)}>
        {badgeLabel}
      </Typography>
    </Box>
  )
}

export default ShiftBadge
