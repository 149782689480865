import React from 'react'
import useDialogs from 'hooks/useDialogs'
import SecurityTimeoutDialog from 'components/Dialog/SecurityTimeoutDialog'
import SecuritySetupTimeoutDialog from 'components/Dialog/SecuritySetupTimeoutDialog'
import routes from 'services/RoutesProvider/routes'
import { DialogPathsT } from 'services/Dialogs/types'

const DialogService = ({ subscribed }: { subscribed: DialogPathsT[] }) => {
  const { dialogs } = useDialogs()
  return (
    <>
      {Object.keys(dialogs || {})
        .filter((d1) => subscribed.some((sub) => sub === d1))
        .map((d2) => {
          switch (d2) {
            case 'securityTimeout':
              return <SecurityTimeoutDialog key="securityTimeout" />
            case 'securitySetup':
              return (
                <SecuritySetupTimeoutDialog
                  key="securitySetup"
                  dialogId="securitySetup"
                  restartPath={routes.securitySetup}
                />
              )
            case 'pinReset':
              return <SecuritySetupTimeoutDialog key="pinReset" dialogId="pinReset" restartPath={routes.pinReset} />
            case 'pinChange':
              return <SecuritySetupTimeoutDialog key="pinChange" dialogId="pinChange" restartPath={routes.pinChange} />
            default:
              throw new Error()
          }
        })}
    </>
  )
}

export default DialogService
