import React, { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { Box } from '@mui/material'
import BackwardLink from 'components/BackwardLink'
import Page from 'components/Page'
import { PersonT } from 'types'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import usePackage from 'hooks/usePackage'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { SimpleLoading } from 'components/LoadingIcon'
import EmployeeCard from './components/EmployeeCard'
import { backgroundColor, classes, headerClasses } from './styles'
import { employeeCardDataQuery } from './queries'
import NoAvatarDrawer from './components/NoAvatarDrawer'

export const INITIAL_PERSON_PARAM_NAME = 'person'

const EmployeeCardPage: React.FC = () => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { search } = useLocation()
  const { classicPersonIds } = usePackage()
  const [persons, setPersons] = useState<PersonT[]>()
  const [initialIndex, setInitialIndex] = useState(0)
  const { loading } = useJamesApolloQuery<{ findAllPersons: PersonT[] }>(employeeCardDataQuery, {
    onCompleted(data) {
      const classicPersons = data.findAllPersons.filter((person) => classicPersonIds.includes(`${person.id}`))
      const urlParams = new URLSearchParams(search)
      const initialPerson = urlParams.get(INITIAL_PERSON_PARAM_NAME)
      if (initialPerson !== null) {
        const index = classicPersons.findIndex((person) => person.favurUuid === initialPerson)
        setInitialIndex(index === -1 ? 0 : index)
      }
      setPersons(classicPersons)
    },
  })
  const hasAvatar = useMemo(() => {
    if (!persons?.length) return false
    // using persons[0] because all the persons should have same user
    return persons[0].user?.avatarUrl !== null
  }, [persons])

  return (
    <Page
      header={<BackwardLink onClickIcon={() => history.goBack()} title={t('employeeCard.main.title')} />}
      hideNativeNavigation
      hideHamburger
      hideBackground
      removeLastMargin
      sxHeader={headerClasses.subHeader}
      backgroundColor={backgroundColor}
      growContent={false}
      noPadding
    >
      {loading || !persons ? (
        <SimpleLoading />
      ) : (
        <>
          <SwipeableViews style={classes.slider} containerStyle={classes.slideContainer} index={initialIndex}>
            {persons.map((person) => (
              <Box key={person.id} sx={classes.slide} data-testid={`employee-card_${person.favurUuid}`}>
                <EmployeeCard person={person} />
              </Box>
            ))}
          </SwipeableViews>
          <NoAvatarDrawer open={!hasAvatar} />
        </>
      )}
    </Page>
  )
}

export default EmployeeCardPage
