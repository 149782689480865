import React, { memo, useCallback } from 'react'
import { Box, Typography } from '@mui/material'
import { addDays, format, isFirstDayOfMonth, isMonday, isToday } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { isDaySelected } from 'pages/AbsencePlanner/utils'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { useTaskBarStateDispatchContext } from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { monthlyBarClasses } from './styles'

interface IMonthlyBarDayProps {
  date: Date
  selectedTaskUuid: string | null
  selectedDays: Date[]
  isPreview?: boolean
}

const MonthlyBarDay: React.FC<IMonthlyBarDayProps> = ({ selectedDays, selectedTaskUuid, date, isPreview }) => {
  const { t, locale } = useFavurTranslation()
  const { updateSelectedDays } = useSelectedStatesActionsContext()
  const setTaskBarState = useTaskBarStateDispatchContext()

  const onClick = useCallback(() => {
    if (isPreview) return
    if (selectedTaskUuid) setTaskBarState(null)
    updateSelectedDays(date)
  }, [isPreview, selectedTaskUuid, setTaskBarState, updateSelectedDays, date])

  return (
    <Box
      sx={[
        monthlyBarClasses.dayContainer(isMonday(date)),
        ...(isToday(date) ? [monthlyBarClasses.isToday] : []),
        ...(isToday(addDays(date, -1)) ? [monthlyBarClasses.isDayAfterToday] : []),
        ...(isFirstDayOfMonth(date) ? [monthlyBarClasses.isFirstDay] : []),
        ...(isDaySelected(date, selectedDays) ? [monthlyBarClasses.isSelected] : []),
      ]}
      onClick={onClick}
    >
      <Typography variant="caption" sx={monthlyBarClasses.dayOfWeek}>
        {format(date, 'EEEEEE', { locale })}
      </Typography>
      <Typography variant="body2" sx={monthlyBarClasses.dayOfMonth}>
        {date.getDate()}
      </Typography>
      {isFirstDayOfMonth(date) && (
        <Typography variant="caption" sx={monthlyBarClasses.monthCaption(isToday(date))}>
          {format(date, 'MMM', { locale })}
        </Typography>
      )}
      {!isFirstDayOfMonth(date) && isMonday(date) && (
        <Typography variant="caption" sx={monthlyBarClasses.extraCaption(isToday(date))}>
          {`${t('page.absencePlanner.monthlyBar.week')}${format(date, 'w')}`}
        </Typography>
      )}
    </Box>
  )
}

export default memo(MonthlyBarDay)
