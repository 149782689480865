import React, { createContext, useState } from 'react'
import { Button, Box } from '@mui/material'
import BottomDrawer from 'components/Basics/BottomDrawer'
import SideMenuM from 'icons/SideMenuM'
import theme from 'utils/theme'
import { classes } from './styles'

export const PlusContext = createContext({ setIsOpenDrawer: (_value: boolean) => {} })

interface IPlusProps {
  children?: React.ReactNode
}
const Plus: React.FC<IPlusProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <PlusContext.Provider value={{ setIsOpenDrawer: setIsOpen }}>
      <Button
        variant="contained"
        sx={classes.plusButton}
        onClick={() => {
          setIsOpen(true)
        }}
        data-testid="share-user-data-detail_options"
      >
        <SideMenuM fill={theme.palette.primary.main} />
      </Button>
      <BottomDrawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={classes.buttonsContainer}>{children}</Box>
      </BottomDrawer>
    </PlusContext.Provider>
  )
}

export default Plus
