import { gql } from '@apollo/client'
import { authQuery } from 'shared/queries'

export const validateOldSimQuery = gql`
  mutation($phone: String!) {
    changePhoneStep1(phone: $phone) {
      success
    }
  }
`
export const validateHRPinQuery = gql`
  mutation($token: String!, $personId: Int) {
    changePhoneStep2(token: $token, personId: $personId) {
      success
    }
  }
`
export const sendNewSimQuery = gql`
  mutation($newPhone: String!) {
    changePhoneStep3(newPhone: $newPhone) {
      success
    }
  }
`
export const sendSMSPinQuery = gql`
  mutation($validationCode: String!, $rememberMe: Boolean) {
    changePhoneStep4(validationCode: $validationCode, rememberMe: $rememberMe) {
      ${authQuery}
    }
  }
`
