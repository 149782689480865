import React from 'react'
import { AvatarDataT, DocumentT } from 'types'

// Keep this in sync with BE Favur.Task.Timeline.Badges
// So we don't have to do a mapping
export enum TimelineItemContentBadgeTypeT {
  workTimeAccepted = 'work_time_accepted',
  wtcChangeRequest = 'wtc_change_request',
  acceptedForThisMonth = 'accepted_for_this_month',
  acceptedForNextMonth = 'accepted_for_next_month',
  rejected = 'rejected',
  called = 'called',
  reminder = 'reminder',
  automatedReminder = 'automated_reminder',
  absenceRequested = 'absence_requested',
  absenceRequestAccepted = 'absence_request_accepted',
  absenceRequestRejected = 'absence_request_rejected',
  deletionRequest = 'deletion_request',
  deletionConfirmed = 'deletion_confirmed',
  deletionDenied = 'deletion_denied',
  sharedPersonalData = 'shared_personal_data',
  dataValidatedAndAccepted = 'data_validated_and_accepted',
}

export enum TimelineAvatarTypeT {
  hr = 'hr',
  icon = 'icon',
}

// AVATAR
export type TimeLineAvatarDataBaseT = { type: TimelineAvatarTypeT; userName: string }
export type AvatarWithIconT = Required<TimeLineAvatarDataBaseT> & AvatarDataT & { type: TimelineAvatarTypeT.icon }
export type HrAvatarT = Required<TimeLineAvatarDataBaseT> & { type: TimelineAvatarTypeT.hr }
export type TimeLineAvatarDataT = AvatarWithIconT | HrAvatarT

// HEADER
export type TimelineItemHeaderT = {
  id: string
  text: string
  isFlashing: boolean
  avatarData?: TimeLineAvatarDataT
}

// CONTENT
export type TimelineItemContentT = {
  document?: DocumentT
  tenantName?: string
  badge?: TimelineItemContentBadgeTypeT
  textHeader?: React.ReactNode
  text?: string
  datetime?: string
  formTaskUuid?: string
  clickable?: boolean
}

export type TimelineItemContentTextT = {
  text: string
}
export type TimelineItemContentDocumentT = {
  document: DocumentT
  tenantName?: string
}

export type TimelineItemContentFormT = {
  tenantName?: string
  clickable?: boolean
}

export type TimelineItemContentStatusT = {
  badge: TimelineItemContentBadgeTypeT
  datetime?: string
}

// TIMELINE ITEM
export type TimelineItemComponentT = { header: TimelineItemHeaderT; content?: TimelineItemContentT }
