import React from 'react'
import { Box, Button } from '@mui/material'
import routes from 'services/RoutesProvider/routes'
import { Link } from 'react-router-dom'
import { Logo } from 'icons'
import classes from './styles'

interface IToolbarLogoProps {
  width: number
  height: number
  visible?: boolean
}

const ToolbarLogo: React.FC<IToolbarLogoProps> = ({ width, height, visible = true }) => {
  return (
    <Box sx={[classes.root, !visible && classes.notVisible]}>
      <Box sx={classes.logo}>
        <Button size="small" sx={classes.link} to={routes.dashboard} component={Link}>
          <Logo width={width} height={height} />
        </Button>
      </Box>
    </Box>
  )
}

export default ToolbarLogo
