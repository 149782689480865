import React from 'react'
import { Typography, Link, Box, Paper } from '@mui/material'
import { Bird36, EmployersM, FeedbackM, QuestionsM } from 'icons'
import theme from 'utils/theme'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Nbsp from 'components/Nbsp'
import { classes } from './styles'

const ContactContent: React.FC<{ anonymous?: boolean }> = ({ anonymous }) => {
  const { t } = useFavurTranslation()
  const boxClasses = [...(anonymous ? [classes.anonymous] : []), classes.block]
  return (
    <>
      <Box component={Paper} sx={boxClasses}>
        <Box sx={[classes.icon, classes.bird]}>
          <Bird36 />
        </Box>
        <Box>
          <Typography variant="body2" sx={classes.gutterBottom}>
            <strong>{t('page.contact.favur.title')}</strong>
          </Typography>
          <Typography variant="body2">{t('page.contact.favur.text')}</Typography>
        </Box>
      </Box>
      <Box component={Paper} sx={boxClasses}>
        <Box sx={[classes.icon, classes.sizeM]}>
          <QuestionsM fill={theme.palette.secondary.main} />
        </Box>
        <Box>
          <Typography variant="body2" sx={classes.gutterBottom}>
            <strong>{t('page.contact.questions.title')}</strong>
          </Typography>
          <Typography variant="body2">{t('page.contact.questions.text')}</Typography>
        </Box>
      </Box>
      <Box component={Paper} sx={boxClasses}>
        <Box sx={[classes.icon, classes.sizeM]}>
          <FeedbackM fill={theme.palette.secondary.main} />
        </Box>
        <Box>
          <Typography variant="body2" sx={classes.gutterBottom}>
            <strong>{t('page.contact.feedback.title')}</strong>
          </Typography>
          <Typography variant="body2">
            {t('page.contact.feedback.text')}
            <Nbsp />
            <Link
              href={t('page.contact.url.feedback')}
              target="_blank"
              sx={classes.link}
              data-testid="feedback"
              underline="hover"
            >
              www.favur.ch/feedback
            </Link>
            .
          </Typography>
        </Box>
      </Box>
      <Box component={Paper} sx={[...boxClasses, classes.noFlexGrow]}>
        <Box sx={[classes.icon, classes.sizeM]}>
          <EmployersM fill={theme.palette.secondary.main} />
        </Box>
        <Box>
          <Typography variant="body2" sx={classes.gutterBottom}>
            <strong>{t('page.contact.employers.title')}</strong>
          </Typography>
          <Typography variant="body2">
            {t('page.contact.employers.text')}
            <Nbsp />
            <Link
              href={t('page.contact.url.favur')}
              target="_blank"
              sx={classes.link}
              data-testid="favur-website"
              underline="hover"
            >
              www.favur.ch
            </Link>
            .
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default ContactContent
