import { personPermissionQuery } from 'shared/queries'
import { useQuery } from '@apollo/client'
import { personIsInactive } from 'utils/person'
import type { AuthPersonIdAndPermissions } from 'types'

const useRefreshPermissions = (setPersons: (arg0: AuthPersonIdAndPermissions[]) => void, skip = false) => {
  const { refetch } = useQuery<{ userPersonsAndPermissions: AuthPersonIdAndPermissions[] }>(personPermissionQuery, {
    fetchPolicy: 'no-cache',
    skip,
    onCompleted: (resData) => {
      setPersons(resData.userPersonsAndPermissions.filter((person) => !personIsInactive(person)))
    },
  })

  return { refetch }
}

export default useRefreshPermissions
