import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const TeamsM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path d="M7.9015 3a4.5068 4.5068 0 1 0 0 9.0135 4.5068 4.5068 0 0 0 0-9.0135ZM5.7754 5.3807a3.0067 3.0067 0 1 1 4.2522 4.2521 3.0067 3.0067 0 0 1-4.2522-4.2521ZM4.0809 16.7803a5.3975 5.3975 0 0 1 9.2139 3.8165.75.75 0 0 0 1.5 0 6.8974 6.8974 0 0 0-13.7948 0 .75.75 0 0 0 1.5 0 5.3973 5.3973 0 0 1 1.5809-3.8165Zm10.43-9.9277a3.8234 3.8234 0 0 1 5.4075 0 3.8236 3.8236 0 1 1-5.4075 0Zm2.7037.38a2.3237 2.3237 0 1 0 0 4.6474 2.3237 2.3237 0 0 0 0-4.6474Zm-1.4823 9.3481a4.2827 4.2827 0 0 1 5.7637 4.0161.75.75 0 0 0 1.5-.0008 5.782 5.782 0 0 0-5.046-5.7324 5.783 5.783 0 0 0-2.7364.3097.75.75 0 1 0 .5187 1.4074Z" />
    </Svg>
  )
}

export default TeamsM
