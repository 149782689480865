import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import EmployeeDetail from 'pages/Teams/components/EmployeeDetail'
import routes from 'services/RoutesProvider/routes'
import { TeamTabs } from '../../constants'

const MemberDetail: React.FC<RouteComponentProps<{ uuid: string; favurUuid: string }>> = ({ match }) => {
  const { uuid: teamId, favurUuid } = match.params

  return <EmployeeDetail backwardLink={`${routes.teams}/${teamId}/${TeamTabs.members}`} favurUuid={favurUuid} />
}

export default MemberDetail
