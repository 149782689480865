import React, { useState } from 'react'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import { classes } from './styles'

interface ITaskStatusProps {
  icon: React.ReactNode
  sx: SxProps<Theme>
  text: string
  testId?: string
  tooltipText?: string
}

const TaskBadge: React.FC<ITaskStatusProps> = ({ icon, sx, text, testId, tooltipText }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const badge = (
    <Box sx={[classes.container, ...(Array.isArray(sx) ? sx : [sx])]} data-testid={testId}>
      <Box>
        <Typography sx={classes.text} variant="body2">
          {text}
        </Typography>
      </Box>
      <Box>{icon}</Box>
    </Box>
  )

  if (tooltipText) {
    return (
      <Tooltip
        arrow
        title={tooltipText}
        placement="top"
        slotProps={{
          tooltip: { sx: classes.tooltipTooltip },
          arrow: { sx: classes.tooltipArrow },
        }}
        sx={{ m: 1 }}
        disableHoverListener
        disableTouchListener
        open={tooltipOpen}
        onClose={() => setTooltipOpen(false)}
      >
        <Button
          onClick={() => setTooltipOpen(true)}
          variant="text"
          sx={classes.tooltipButton}
          disableRipple
          disableFocusRipple
          disableTouchRipple
        >
          {badge}
        </Button>
      </Tooltip>
    )
  }

  return badge
}

export default TaskBadge

export { default as RequestedChange } from './MonthlySheet/RequestedChange'
export { default as AcceptedForNextMonth } from './MonthlySheet/AcceptedForNextMonth'
export { default as AcceptedForThisMonth } from './MonthlySheet/AcceptedForThisMonth'
export { default as CalledAt } from './MonthlySheet/CalledAt'
export { default as Rejected } from './MonthlySheet/Rejected'
export { default as SentAReminder } from './MonthlySheet/SentAReminder'
export { default as SentAutomatedReminder } from './MonthlySheet/SentAutomatedReminder'
export { default as WorkTimeAccepted } from './MonthlySheet/WorkTimeAccepted'
export { default as AbsenceRequested } from './AbsenceRequest/AbsenceRequested'
export { default as AbsenceRequesAccepted } from './AbsenceRequest/AbsenceAccepted'
export { default as AbsenceRequestRejected } from './AbsenceRequest/AbsenceRejected'
export { default as DeletionRequest } from './AbsenceRequest/DeletionRequest'
export { default as DeletionConfirmed } from './AbsenceRequest/DeletionConfirmed'
export { default as DeletionDenied } from './AbsenceRequest/DeletionDenied'
export { default as DataValidatedAndAccepted } from './ShareUserData/DataValidatedAndAccepted'
export { default as SharedPersonalData } from './ShareUserData/SharedPersonalData'
