import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const CloseS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    // @ts-ignore
    <Svg size={ICON_SIZE_S} fill={fill || theme.palette.primary[900]}>
      <path
        fillRule="evenodd"
        d="M12.313 2.98l.707.707c.116.116.129.295.039.425l-.039.046L9.178 8l3.842 3.842c.116.115.129.295.039.425l-.039.046-.707.707c-.116.116-.295.129-.425.039l-.046-.039L8 9.178 4.158 13.02c-.115.116-.295.129-.425.039l-.046-.039-.707-.707c-.116-.116-.129-.295-.039-.425l.039-.046L6.822 8 2.98 4.158c-.116-.115-.129-.295-.039-.425l.039-.046.707-.707c.116-.116.295-.129.425-.039l.046.039L8 6.822l3.842-3.842c.115-.116.295-.129.425-.039l.046.039z"
        transform="translate(-323 -1151) translate(28 1147) translate(8 4) translate(287)"
      />
    </Svg>
  )
}

export default CloseS
