import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { Box } from '@mui/material'
import useSelectedStatesContext from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { absenceRequestListClasses as classes } from '../styles'
import SmallScreenPageHeader from '../SmallScreenPageHeader'
import UserTasks from '.'

interface IUserTasksPageProps {
  refetchAll: () => void
  refetchTask: (personId: string, dateFrom: string, dateTo: string) => void
}

const UserTasksPage: React.FC<IUserTasksPageProps> = ({ refetchAll, refetchTask }) => {
  const { t } = useFavurTranslation()
  const { selectedTaskUuid } = useSelectedStatesContext()

  return (
    <Box sx={classes.page}>
      {selectedTaskUuid === null && (
        <SmallScreenPageHeader
          title={t('absencePlanner.taskbar.frontlinerTasks.title')}
          subtitle={t('absencePlanner.taskbar.frontlinerTasks.subtitle')}
        />
      )}
      <UserTasks
        hideTitle
        refetchAll={refetchAll}
        refetchTask={(personId: string, dateFrom: string, dateTo: string) => refetchTask(personId, dateFrom, dateTo)}
      />
    </Box>
  )
}

export default UserTasksPage
