import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getFormattedFromISOString } from 'utils/date'
import ControlledSelect from 'components/Forms/components/ControlledSelect'
import { absenceTypes } from 'pages/Tasks/AbsenceRequest/constants'
import { AbsenceDateT } from 'pages/Tasks/types'
import { classes } from './styles'

interface IIndividualAbsenceType {
  absenceDate: AbsenceDateT
  onChange: (value: string) => void
  error?: boolean
  helperText?: string
}

const IndividualAbsenceType: React.FC<IIndividualAbsenceType> = ({ absenceDate, onChange, error, helperText }) => {
  const { t, locale } = useFavurTranslation()

  return (
    <Box sx={classes.componentContainer}>
      <Box sx={classes.dayInfoContainer}>
        <Typography variant="body2" sx={classes.dayLabel}>
          {t('absenceRequest.accept.label.day')}
        </Typography>
        <Typography variant="body2" sx={classes.dateValue}>
          {getFormattedFromISOString(absenceDate.date, 'EEEEEE, dd.MM.yyyy', locale)}
        </Typography>
      </Box>
      <Box sx={classes.selectContainer}>
        <ControlledSelect
          label={t('absenceRequest.accept.absenceType.label')}
          name="absenceType"
          helperText={helperText || ' '}
          value={absenceDate.type}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          options={absenceTypes}
          error={error}
        />
      </Box>
    </Box>
  )
}

export default IndividualAbsenceType
