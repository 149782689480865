import React from 'react'
import { Box } from '@mui/material'
import { TimelineItemContentStatusT, TimelineItemContentBadgeTypeT } from 'components/Timeline/types'
import * as TaskBadge from 'components/TaskBadge'
import useRolesViews from 'hooks/useRolesViews'
import { ROLE_VIEWS } from 'constants/roles'
import { classes } from './styles'

// eslint-disable-next-line complexity
const getBadgeComponent = (
  badge: TimelineItemContentBadgeTypeT,
  datetime: string | undefined,
  isOffice: boolean,
): React.ReactNode => {
  switch (badge) {
    case TimelineItemContentBadgeTypeT.acceptedForNextMonth:
      return <TaskBadge.AcceptedForNextMonth withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.acceptedForThisMonth:
      return <TaskBadge.AcceptedForThisMonth withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.called:
      return <TaskBadge.CalledAt datetime={datetime ?? ''} withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.rejected:
      return <TaskBadge.Rejected />
    case TimelineItemContentBadgeTypeT.wtcChangeRequest:
      return <TaskBadge.RequestedChange withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.reminder:
      return <TaskBadge.SentAReminder withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.automatedReminder:
      return <TaskBadge.SentAutomatedReminder withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.workTimeAccepted:
      return <TaskBadge.WorkTimeAccepted withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.absenceRequested:
      return <TaskBadge.AbsenceRequested withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.absenceRequestAccepted:
      return <TaskBadge.AbsenceRequesAccepted withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.absenceRequestRejected:
      return <TaskBadge.AbsenceRequestRejected withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.deletionRequest:
      return <TaskBadge.DeletionRequest withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.deletionConfirmed:
      return <TaskBadge.DeletionConfirmed withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.deletionDenied:
      return <TaskBadge.DeletionDenied withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.dataValidatedAndAccepted:
      return <TaskBadge.DataValidatedAndAccepted withTooltip isOffice={isOffice} />
    case TimelineItemContentBadgeTypeT.sharedPersonalData:
      return <TaskBadge.SharedPersonalData withTooltip isOffice={isOffice} />
    default:
      return <></>
  }
}

const TimeLineItemBadge: React.FC<TimelineItemContentStatusT> = ({ badge, datetime }) => {
  const { activeView } = useRolesViews()
  const isOffice = activeView === ROLE_VIEWS.office
  const badgeComponent = getBadgeComponent(badge, datetime, isOffice)
  return <Box sx={classes.container}>{badgeComponent}</Box>
}

export default TimeLineItemBadge
