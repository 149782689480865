import React, { useState } from 'react'
import { Box } from '@mui/material'
import Page from 'components/Page'
import BackwardLink from 'components/BackwardLink'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import useUsername from 'hooks/useUsername'
import routes from 'services/RoutesProvider/routes'
import { User } from 'types'
import { headerClasses } from 'pages/UserSettings/styles'
import usePinStatus from 'hooks/usePinStatus'
import { SimpleLoading } from 'components/LoadingIcon'
import AvatarDeleteDialog from './components/AvatarDeleteDialog'
import CropImage from './components/CropImage'
import ProfileSettings from './components/ProfileSettings'
import LoginSettings from './components/LoginSettings'
import OtherSettings from './components/OtherSettings'
import { classes } from './styles'
import useProfilePicture from './hooks/useProfilePicture'

const ProfilePage: React.FC = () => {
  const { t } = useFavurTranslation()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const { loading, ...rest } = useUsername()
  const user = rest as User
  const [avatarDeleteConfirmation, showAvatarDeleteConfirmation] = useState<boolean>(false)
  const { selectedImageBase64, setSelectedImageBase64, onFileChanged, onDeleteAvatar } = useProfilePicture()
  const [pinStatusQuery, pinStatusLoading] = usePinStatus({ queryName: 'pin_status_user_settings' })

  const pinStatus = pinStatusQuery?.pinStatus || ''
  return selectedImageBase64 ? (
    <CropImage
      base64Image={selectedImageBase64}
      onCancel={() => {
        setSelectedImageBase64(undefined)
      }}
    />
  ) : (
    <Page
      header={
        <BackwardLink
          onClickIcon={() => {
            pushBackLinkOrDefault(routes.userSettings)
          }}
          title={t('page.userSettings.profile.header')}
        />
      }
      sxHeader={headerClasses.subHeader}
    >
      {loading ? (
        <SimpleLoading />
      ) : (
        <Box sx={classes.cardsContainer}>
          <ProfileSettings
            user={user}
            onFileChanged={onFileChanged}
            onClickDeleteAvatar={() => showAvatarDeleteConfirmation(true)}
          />
          <LoginSettings pinStatus={pinStatus} pinStatusLoading={pinStatusLoading} />
          <OtherSettings />
        </Box>
      )}

      {avatarDeleteConfirmation && (
        <AvatarDeleteDialog onCancel={() => showAvatarDeleteConfirmation(false)} onDelete={onDeleteAvatar} />
      )}
    </Page>
  )
}

export default ProfilePage
