import { RoleT, ROLES, RoleViewT } from 'types'

export const ROLE_VIEWS: Record<RoleViewT, RoleViewT> = {
  frontliner: 'frontliner',
  office: 'office',
}

export const isRoleViewValid = (roleView: string | boolean | void): boolean => {
  return roleView === ROLE_VIEWS.frontliner || roleView === ROLE_VIEWS.office
}

export const ROLES_MANAGE_TEAMS: RoleT[] = [ROLES.manageGlobalTeams, ROLES.manageTeams]
export const ROLES_MANAGE_TENANTS: RoleT[] = [ROLES.manageGlobalTenants, ROLES.manageTenant]
