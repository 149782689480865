import React from 'react'
import theme from 'utils/theme'
import { FilledHourglassM } from 'icons'
import useFavurTranslation from 'hooks/useFavurTranslation'
import TaskBadge from '..'
import { getClasses } from '../styles'
import { ITaskBadgeProps } from '../types'

const getTooltipKey = (withTooltip: boolean, isOffice: boolean) => {
  if (!withTooltip) return undefined
  return isOffice
    ? `workTimeControl.requestedChange.tooltipManager`
    : `workTimeControl.requestedChange.tooltipFrontliner`
}

const getTextKey = (isActing: boolean) =>
  isActing ? `timeline.badge.workTimeControlChangeRequesting` : `timeline.badge.workTimeControlChangeRequest`

const RequestedChange: React.FC<ITaskBadgeProps> = ({ withTooltip = false, isOffice = false, isActing = false }) => {
  const { t } = useFavurTranslation()
  const tooltipKey = getTooltipKey(withTooltip, isOffice)
  return (
    <TaskBadge
      text={t(getTextKey(isActing))}
      sx={getClasses(withTooltip)}
      icon={<FilledHourglassM fill={theme.palette.primary.main} />}
      tooltipText={tooltipKey && t(tooltipKey)}
      testId="monthly-sheet-detail_claim_badge"
    />
  )
}

export default RequestedChange
