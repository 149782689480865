import React, { useEffect } from 'react'
import axios from 'axios'
import useRejectGraphql from './useRejectGraphql'
import useHandleFlashMessages from './useHandleFlashMessages'
import useRedirect from './useRedirect'

const AxiosInterceptorService = ({ children }: { children?: React.ReactNode }) => {
  const [graphqlSuccess, graphqlReject] = useRejectGraphql()
  const [flashMessageSuccess, flashMessageReject] = useHandleFlashMessages()
  const [redirectSuccess, redirectReject] = useRedirect()

  // order matters
  // rejection on one interceptor will affect input res in following interceptors
  // not rejecting again in a reject, will result in a "recover" of that promise
  // from failed to succees
  useEffect(() => {
    const res = axios.interceptors.response.use(graphqlSuccess, graphqlReject)
    return () => axios.interceptors.response.eject(res)
  }, [graphqlSuccess, graphqlReject])

  useEffect(() => {
    const res = axios.interceptors.response.use(flashMessageSuccess, flashMessageReject)
    return () => axios.interceptors.response.eject(res)
  }, [flashMessageSuccess, flashMessageReject])

  useEffect(() => {
    const res = axios.interceptors.response.use(redirectSuccess, redirectReject)
    return () => axios.interceptors.response.eject(res)
  }, [redirectSuccess, redirectReject])

  return <>{children}</>
}

export default AxiosInterceptorService
