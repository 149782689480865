import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const BellL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M17.5 2.77a1 1 0 1 0-2 0v2.275a11.15 11.15 0 0 0-6.849 3.173A10.947 10.947 0 0 0 5.395 16v8.125c0 .229-.092.45-.259.615A.905.905 0 0 1 4.5 25a1 1 0 1 0 0 2h7.713a5.44 5.44 0 0 0 1.32 2.22c.806.807 1.851 1.28 2.967 1.28s2.162-.473 2.967-1.28a5.44 5.44 0 0 0 1.32-2.22H28.5a1 1 0 1 0 0-2 .905.905 0 0 1-.636-.26.865.865 0 0 1-.259-.615V16c0-2.92-1.172-5.72-3.256-7.782A11.15 11.15 0 0 0 17.5 5.045V2.769ZM12.982 25h12.761a2.857 2.857 0 0 1-.138-.875V16a8.948 8.948 0 0 0-2.663-6.36A9.16 9.16 0 0 0 16.5 7a9.16 9.16 0 0 0-6.442 2.64A8.948 8.948 0 0 0 7.395 16v8.125c0 .3-.047.595-.138.875h5.725Zm1.965 2.806a3.225 3.225 0 0 1-.58-.806h4.267c-.16.31-.357.582-.58.806-.47.47-1.022.694-1.554.694s-1.083-.224-1.553-.694ZM21.19 9.683a1 1 0 1 0-.889 1.791 3.737 3.737 0 0 1 2.076 3.348 1 1 0 0 0 2 0 5.736 5.736 0 0 0-3.187-5.14Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default BellL
