import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Box } from '@mui/material'
import { useMutation } from '@apollo/client'
import { sortBy, uniqBy } from 'lodash/fp'
import routes from 'services/RoutesProvider/routes'
import FlashMessagesContext from 'services/FlashMessages/context'
import useFavurTranslation from 'hooks/useFavurTranslation'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import EmployeeList from 'components/EmployeeList'
import { getSortingLastName } from 'utils/person'
import ActionButtons from 'components/Buttons/ActionButtons'
import SelectedInfo from 'pages/Teams/components/SelectedInfo'
import { getTeamNameByLanguage } from 'types/utils'
import { updateMembers } from 'pages/Teams/queries'
import { useTeam } from '../../useTeam'
import { classes } from '../../../styles'
import { MembersMapT } from '../../../types'
import { TeamTabs } from '../../constants'
import { normalizeTeamMembers } from '../../../utils'
import { useEditTeamMembers } from './useEditTeamMembers'
import type { MutationResult, PersonT } from 'types'

const EditMembers: React.FC<RouteComponentProps<{ uuid: string }>> = ({ match }) => {
  const { uuid: teamId } = match.params
  const { t, language } = useFavurTranslation()
  const history = useHistory()
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const { team, loading: loadingTeam } = useTeam(teamId)
  const { members: editTeamMembers, loading: loadingMembers } = useEditTeamMembers(team)
  const backLink = useMemo(() => `${routes.teams}/${teamId}/${TeamTabs.members}`, [teamId])
  const [members, setMembers] = useState<MembersMapT>(new Map())
  const [originalMembers, setOriginalMembers] = useState<MembersMapT>(new Map())
  const onChange = useCallback(({ id }: { id: number }) => {
    setMembers((curr) => new Map(curr).set(id, !curr.get(id)))
  }, [])

  const personsTeam = useMemo(() => {
    return (team?.teamPermissions ?? []).map((ptp) => ptp.person) as PersonT[]
  }, [team?.teamPermissions])

  const personsTeamIds = useMemo(() => {
    return personsTeam.map((person) => person.id)
  }, [personsTeam])

  const actualAndEditableMembers = useMemo(() => {
    return uniqBy('id', [...personsTeam, ...editTeamMembers])
  }, [editTeamMembers, personsTeam])

  const reducer = useCallback(
    (prev: MembersMapT, curr: PersonT): MembersMapT => {
      const isInTeam = personsTeamIds.includes(curr.id)
      return prev.set(curr.id as number, isInTeam)
    },
    [personsTeamIds],
  )

  const getChecked = useCallback((id: number): boolean => members.get(id) ?? false, [members])
  const [mutate] = useMutation<{ updateTeamMembers: MutationResult }>(updateMembers)
  const onSubmit = useCallback(async () => {
    const result = await mutate({
      variables: {
        changes: normalizeTeamMembers({ currentMembers: members, originalMembers }),
        teamId,
      },
    })

    if (result.data?.updateTeamMembers.success) {
      history.push(backLink)
    } else {
      setFlashMessage(t('teams.editMembers.error'))
    }
  }, [backLink, history, members, mutate, originalMembers, setFlashMessage, t, teamId])

  const membersListChanged = useCallback(() => {
    const changes = normalizeTeamMembers({ currentMembers: members, originalMembers })
    return changes.add.length > 0 || changes.delete.length > 0
  }, [members, originalMembers])

  useEffect(() => {
    if (actualAndEditableMembers) {
      const membersFromDb = actualAndEditableMembers.reduce(reducer, new Map() as MembersMapT)
      setMembers(membersFromDb)
      setOriginalMembers(membersFromDb)
    }
  }, [actualAndEditableMembers, reducer])

  const getNumMembersSelected = useCallback(() => {
    return Array.from(members.values()).filter((selected) => selected).length
  }, [members])

  return (
    <Page
      growSubheader
      header={
        <WithBackwardLink
          closeIcon
          route={`${routes.teams}/${teamId}/${TeamTabs.members}`}
          title={t('teams.editMembers.title')}
          topTitle={team ? getTeamNameByLanguage(team, language) : ''}
        >
          <SelectedInfo numSelected={getNumMembersSelected()} labelText={t('teams.editMembers.selectedTitle')} />
        </WithBackwardLink>
      }
      hasFloatingButtons
      hideNativeNavigation
    >
      {(loadingTeam || loadingMembers) && <SimpleLoading />}
      {actualAndEditableMembers !== undefined && (
        <Box sx={classes.editTeamMembersForm}>
          <EmployeeList
            employees={sortBy((p) => getSortingLastName(p), actualAndEditableMembers)}
            checkbox
            onClickItem={(person) => {
              onChange({ id: person.id as number })
            }}
            itemIsChecked={(person) => getChecked(person.id as number)}
            showNotTeamAssignedWarning
            checkValidInvitation
            search
            showTenant={(team?.owner?.tenants?.length ?? 0) > 1}
            checkResignationDate
          />
          <ActionButtons
            isFloating
            noNavigation
            cancelAction={() => history.push(backLink)}
            cancelLabel={t('common.cancel')}
            validateLabel={t('common.confirm')}
            isValidateButtonActive={membersListChanged()}
            validateAction={onSubmit}
          />
        </Box>
      )}
    </Page>
  )
}

export default EditMembers
