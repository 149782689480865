import React, { useMemo } from 'react'
import { useHistory } from 'react-router'

import useFavurTranslation from 'hooks/useFavurTranslation'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import routes from 'services/RoutesProvider/routes'
import palette from 'utils/theme/palette'
import { PIN_STATUSES } from 'constants/highSecurityConnection'

import { KeyM } from 'icons'

interface IPinNumber {
  pinStatus: string
}

const PinNumber: React.FC<IPinNumber> = ({ pinStatus }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  const pinContents = useMemo(() => {
    switch (pinStatus) {
      case PIN_STATUSES.set:
        return {
          title: 'page.userSettings.security.pinNumberChange',
          route: routes.pinChange,
          testId: 'user-settings-security-change-pin',
        }

      case PIN_STATUSES.blocked:
        return {
          title: 'page.userSettings.security.pinBlocked.title',
          route: routes.pinReset,
          testId: 'user-settings-security-start-token-process',
        }

      default:
        return {
          title: 'page.userSettings.security.startTokenProcess.title',
          route: routes.securitySetup,
          testId: 'user-settings-security-start-token-process',
        }
    }
  }, [pinStatus])

  return (
    <UserSettingsSectionCTA
      title={t(pinContents.title)}
      onClick={() => {
        history.push(pinContents.route)
      }}
      data-testid={pinContents.testId}
      icon={<KeyM fill={palette.primary.main} />}
    />
  )
}

export default PinNumber
