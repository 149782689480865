import React from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { sortBy } from 'lodash/fp'
import Card from 'components/Basics/Card'
import { PersonsT } from 'components/Filter/types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useFilterContext from 'components/Filter/context'
import { CloseM } from 'icons'
import { PersonT } from 'types'
import { getSortingLastName, getUserName } from 'utils/person'
import theme from 'utils/theme'
import { classes } from '../../styles'

interface IPersonsProps {
  configuration: PersonsT
}

const Persons: React.FC<IPersonsProps> = ({ configuration }) => {
  const { t } = useFavurTranslation()
  const { openPersonsList, getValue, clearPersonList, clearPersonListElement } = useFilterContext()

  const selectedPersonIds = (getValue(configuration.name) as string[]) || []
  const selectedPersons = selectedPersonIds.map(
    (personId) => configuration.options.find((pp) => `${pp.id}` === personId) as PersonT,
  )

  return (
    <Box sx={classes.cardListItem}>
      <Card id={configuration.name}>
        <Typography variant="caption" sx={classes.cardTitle}>
          {configuration.label}
        </Typography>

        {selectedPersons.length > 0 && (
          <Box sx={classes.personListElems}>
            {sortBy((p) => getSortingLastName(p), selectedPersons).map((person) => {
              return (
                <Box key={person.id as number} sx={classes.personListElemContainer}>
                  <IconButton
                    sx={classes.personListIconButton}
                    onClick={() => {
                      clearPersonListElement(configuration.name, person.id as number)
                    }}
                    size="large"
                  >
                    <CloseM fill={theme.palette.primary.main} />
                  </IconButton>
                  <Typography sx={classes.personListElemText} variant="caption">
                    {getUserName(person)}
                  </Typography>
                </Box>
              )
            })}
          </Box>
        )}

        <Box sx={classes.personListSelectButtonContainer}>
          {selectedPersons.length > 0 && (
            <Button
              sx={classes.personListSelectButton}
              size="small"
              type="button"
              onClick={() => clearPersonList(configuration.name)}
            >
              {t('filter.persons.card.clearAll')}
            </Button>
          )}
          <Button
            sx={classes.personListSelectButton}
            size="small"
            type="button"
            onClick={() => openPersonsList(configuration.name)}
          >
            {t('filter.persons.card.button')}
          </Button>
        </Box>
      </Card>
    </Box>
  )
}

export default Persons
