import React, { useContext } from 'react'
import { Button, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import BannerMessagesContext from 'services/BannerMessages/context'
import routes from 'services/RoutesProvider/routes'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Banner from '../Banner'
import { BannerActionTypes } from './types'

const BannerWithContext = () => {
  const { t } = useFavurTranslation()
  const { setBannerMessage, bannerMessage } = useContext(BannerMessagesContext)

  const history = useHistory()

  const onAction = (actionType: BannerActionTypes) => {
    switch (actionType) {
      case 'CLOSE':
        setBannerMessage(undefined)
        break
      case 'CONTACT':
        history.push(routes.contact)
        setBannerMessage(undefined)
        break
      case 'SECURITY_SETUP':
        history.push(routes.securitySetup)
        setBannerMessage(undefined)
        break
      default:
        break
    }
  }
  return (
    <Banner
      actions={
        <>
          {bannerMessage?.bannerActions?.map(({ action, text }) => (
            <Button key={text} onClick={() => onAction(action)} color="secondary" size="large">
              {t(text)}
            </Button>
          ))}
        </>
      }
      open={Boolean(bannerMessage)}
    >
      <Typography variant="body2">{t(bannerMessage?.message ?? '')}</Typography>
    </Banner>
  )
}

export default BannerWithContext
