import React, { useCallback, useState } from 'react'
import { Button, TextField, Typography } from '@mui/material'
import { UseFormSetValue } from 'react-hook-form'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePhoneNumber from 'hooks/usePhoneNumber'
import InfoDialog from 'components/Forms/components/InfoDialog'
import Grid from 'components/Grid'
import { classes } from 'components/Forms/styles'
import {
  ContactKeysT,
  ContactFieldT,
  AddressT,
  ContactFieldKeysT,
  addressTypes,
  ContactFormValuesT,
} from '../../Contact/types'
import { removeAddress } from '../../Contact/helpers'
import CountrySelect from '../CountrySelect'
import { getAddressIndex, getAddressSubtitleKey, getAddressDeleteButtonKey, getDialogText } from './helpers'
import PhoneNumberInput from '../PhoneNumberInput'
import InfoAdornment from '../InfoAdornment'
import type { FormValuesKeysT, FormHandlersT } from 'components/Forms/types'

interface IAddressFormProps {
  addressArray: ContactFieldT[]
  formHandlers: FormHandlersT
  inputKeys: ContactKeysT
  addressType: AddressT
  remove: (index: number) => void
  setHasForeignAddress: (bool: boolean) => void
  setHasEmergencyAddress: (bool: boolean) => void
  setValue: UseFormSetValue<ContactFormValuesT>
}

const AddressForm: React.FC<IAddressFormProps> = ({
  formHandlers,
  inputKeys,
  addressType,
  addressArray,
  remove,
  setHasEmergencyAddress,
  setHasForeignAddress,
  setValue,
}) => {
  const { t } = useFavurTranslation()
  const addressIndex = getAddressIndex(addressArray, addressType)
  const [openAddressDialog, setOpenAddressDialog] = useState(false)
  const { phone, setPhone, updatePhoneNumber } = usePhoneNumber(addressArray[addressIndex].phoneNumber as string)
  const getFullName = useCallback(
    (nestedInputName: string) => {
      return `addresses.${addressIndex}.${nestedInputName}`
    },
    [addressIndex],
  )
  const formHandlersWithFullName = useCallback(
    (nestedInputName: string) => {
      const fullName = getFullName(nestedInputName)
      const handlers = formHandlers(fullName as FormValuesKeysT)
      if (addressIndex !== -1) {
        return {
          defaultValue: addressArray[addressIndex][`${nestedInputName}` as ContactFieldKeysT] || '',
          ...handlers,
        }
      }
      return handlers
    },
    [getFullName, formHandlers, addressIndex, addressArray],
  )

  return (
    <>
      <Grid sx={classes.gridForSubtitle} col={4}>
        <Typography variant="subtitle1">{t(getAddressSubtitleKey(addressType))}</Typography>
        <InfoAdornment type="title" open={openAddressDialog} setOpen={setOpenAddressDialog} />
        <InfoDialog
          open={openAddressDialog}
          action={() => setOpenAddressDialog(false)}
          text={t(getDialogText(addressType))}
        />
        {addressType !== addressTypes.main && (
          <Button
            sx={classes.pinkDeleteButton}
            onClick={() =>
              removeAddress(addressType, addressArray, remove, setHasForeignAddress, setHasEmergencyAddress)
            }
            size="small"
            data-testid={`address-block_remove-${addressType}`}
          >
            {t(getAddressDeleteButtonKey(addressType))}
          </Button>
        )}
      </Grid>
      {addressType === addressTypes.emergency && (
        <>
          <Grid sx={classes.gridForFormField} col={4}>
            <TextField
              variant="standard"
              required
              fullWidth
              {...formHandlersWithFullName(inputKeys.firstname as FormValuesKeysT)}
              inputProps={{ 'data-testid': `address-block_${addressType}-first-name` }}
            />
          </Grid>
          <Grid sx={classes.gridForFormField} col={4}>
            <TextField
              variant="standard"
              required
              fullWidth
              {...formHandlersWithFullName(inputKeys.lastname as FormValuesKeysT)}
              inputProps={{ 'data-testid': `address-block_${addressType}-last-name` }}
            />
          </Grid>
        </>
      )}
      <Grid sx={classes.gridForFormField} col={4}>
        <TextField
          variant="standard"
          required
          fullWidth
          {...formHandlersWithFullName(inputKeys.address as FormValuesKeysT)}
          inputProps={{ 'data-testid': `address-block_${addressType}-address` }}
        />
      </Grid>
      <Grid sx={classes.gridForFormField} col={4}>
        <TextField
          variant="standard"
          fullWidth
          {...formHandlersWithFullName(inputKeys.additionaladdress as FormValuesKeysT)}
          inputProps={{ 'data-testid': `address-block_${addressType}-additional-address` }}
        />
      </Grid>
      <Grid sx={classes.gridForFormField} col={4}>
        <TextField
          variant="standard"
          required
          fullWidth
          {...formHandlersWithFullName(inputKeys.zipcode as FormValuesKeysT)}
          inputProps={{ 'data-testid': `address-block_${addressType}-zip-code` }}
        />
      </Grid>
      <Grid sx={classes.gridForFormField} col={4}>
        <TextField
          variant="standard"
          required
          fullWidth
          {...formHandlersWithFullName(inputKeys.city as FormValuesKeysT)}
          inputProps={{ 'data-testid': `address-block_${addressType}-city` }}
        />
      </Grid>
      <Grid sx={classes.gridForFormField} col={4}>
        <CountrySelect
          formHandlers={formHandlersWithFullName(inputKeys.country as FormValuesKeysT)}
          inputName={getFullName(inputKeys.country)}
          defaultValue={addressArray[addressIndex]?.country}
        />
      </Grid>
      <Grid sx={classes.gridForFormField} col={4}>
        <PhoneNumberInput
          required={addressType === addressTypes.emergency}
          phone={phone}
          setPhone={setPhone}
          fullWidthNoMargin
          {...formHandlersWithFullName(inputKeys.phonenumber as FormValuesKeysT)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = !e.target.value ? '' : `+${phone.countryCode}${e.target.value}`.replace('++', '+')
            setValue(
              // @ts-ignore
              getFullName(inputKeys.phonenumber),
              value,
            )
            updatePhoneNumber(e.target.value)
          }}
          error={
            (phone.phoneNumber?.length === 0 && addressType === addressTypes.emergency) ||
            (phone.phoneNumber?.length > 0 && !phone.isValidNumber)
          }
          helperText={
            phone.phoneNumber?.length > 0 && !phone.isValidNumber ? t('personalData.error.invalidPhoneNumber') : ''
          }
          label={t(`personalData.address.${addressType}.phoneNumber.label`)}
        />
        <TextField
          variant="standard"
          fullWidth
          {...formHandlersWithFullName(inputKeys.type as FormValuesKeysT)}
          sx={{ display: 'none' }}
        />
      </Grid>
    </>
  )
}

export default AddressForm
