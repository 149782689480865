import React, { useCallback, useEffect, useMemo } from 'react'
import flatten from 'lodash/fp/flatten'
import { Box } from '@mui/material'
import { useKeyPress } from 'hooks/useKeyPress'
import { classes } from './styles'
import { NumpadT } from './types'
import NumpadInput from './NumpadInput'
import NumpadDelete from './NumpadDelete'
import NumpadConfirm from './NumpadConfirm'
import { BACKSPACE_KEY, ENTER_KEY, NUMBERS } from './constants'
import NumpadInputBiometric from './NumpadInputBiometric'

export interface INumpad {
  numpadType: NumpadT
  outputLength?: number
  onNumpadValueChange?: (pin: string) => void
  onConfirm?: () => void
  numpadValue: string
  setNumpadValue: (input: string) => void
  usedWithPhoneNumberValidation?: boolean
  allowEnter?: boolean
}

export const NumpadWithoutState: React.FC<INumpad> = ({
  outputLength,
  onNumpadValueChange,
  onConfirm,
  numpadType,
  numpadValue,
  setNumpadValue,
  usedWithPhoneNumberValidation,
  allowEnter = false,
}) => {
  const validKeyboardKeys = useMemo(() => {
    const keyList = numpadType === 'phone' ? [...NUMBERS, '0', '+', BACKSPACE_KEY] : [...NUMBERS, '0', BACKSPACE_KEY]

    return allowEnter ? [...keyList, ENTER_KEY] : keyList
  }, [allowEnter, numpadType])
  const ignoredKeys = usedWithPhoneNumberValidation ? [BACKSPACE_KEY] : []

  const handleInputClick = useCallback(
    (input: string) => {
      if (input === BACKSPACE_KEY) {
        return setNumpadValue(numpadValue.slice(0, numpadValue.length - 1))
      }
      if (allowEnter && input === ENTER_KEY && onConfirm) {
        return onConfirm()
      }
      if (outputLength) {
        return numpadValue.length < outputLength && setNumpadValue(numpadValue.concat(input))
      }
      return setNumpadValue(numpadValue.concat(input))
    },
    [allowEnter, numpadValue, onConfirm, outputLength, setNumpadValue],
  )

  const renderBottomRightButton = useCallback(() => {
    if (numpadType === 'pin' && onConfirm) {
      return <NumpadConfirm onClick={onConfirm} />
    }
    if (numpadType === 'phone') {
      return <NumpadInput input="+" onClick={handleInputClick} />
    }
    if (numpadType === 'pinLogin') {
      return <NumpadInputBiometric setNumpadValue={setNumpadValue} />
    }
    return null
  }, [handleInputClick, numpadType, onConfirm, setNumpadValue])

  const handleDeleteClick = useCallback(() => {
    numpadValue.length > 0 && setNumpadValue(numpadValue.slice(0, -1))
  }, [numpadValue, setNumpadValue])

  useKeyPress(flatten(validKeyboardKeys), ignoredKeys, handleInputClick)

  useEffect(() => {
    !usedWithPhoneNumberValidation && setNumpadValue('')
  }, [outputLength, setNumpadValue, usedWithPhoneNumberValidation])

  useEffect(() => {
    if (onNumpadValueChange) {
      onNumpadValueChange(numpadValue)
    }
  }, [numpadValue, onNumpadValueChange])

  return (
    <Box sx={classes.componentContainer}>
      {NUMBERS.map((row, i) => {
        return (
          <Box key={'row_'.concat(i.toString())} sx={classes.numpadRow}>
            {row.map((number) => {
              return <NumpadInput aria-label="Numpad input" key={number} input={number} onClick={handleInputClick} />
            })}
          </Box>
        )
      })}

      <Box sx={classes.numpadRow}>
        <NumpadDelete aria-label="Numpad delete" onClick={handleDeleteClick} />
        <NumpadInput aria-label="Numpad input" input="0" onClick={handleInputClick} />
        {renderBottomRightButton()}
      </Box>
    </Box>
  )
}
