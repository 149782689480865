import React from 'react'
import { Box, Typography } from '@mui/material'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import { DivisionLine } from 'components/Basics'
import CompanySettings from './sections/CompanySettings'
import UserSettingsLogout from './sections/Logout'
import { classes } from './styles'
import PersonalSettings from './sections/PersonalSettings'

const UserSettingsPage: React.FC = () => {
  const { t } = useFavurTranslation()
  const { canManageTeams, canManageTenants } = useRolesViews()
  return (
    <Page header={<Typography variant="h2">{t('page.userSettings.page.title')}</Typography>}>
      <Box sx={classes.root}>
        {(canManageTeams || canManageTenants) && <CompanySettings />}
        <PersonalSettings showTitleSection={canManageTeams} />
        <DivisionLine />
        <UserSettingsLogout />
      </Box>
    </Page>
  )
}

export default UserSettingsPage
