import { useTranslation } from 'react-i18next'
import { getLocale } from 'constants/i18n'

const useFavurTranslation = () => {
  const { t, i18n } = useTranslation()
  const language = i18n.languages[0]
  const locale = getLocale(language)

  return { t, language, locale, i18n }
}

export default useFavurTranslation
