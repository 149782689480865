import { Box, Typography } from '@mui/material'
import { Locale } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import React, { useMemo } from 'react'
import { getDateFormatByLocale, getFormattedFromISOString } from 'utils/date'
import { absencePeriods, absencePeriodsTranslationMap } from 'shared/constants'
import { capitalize } from 'utils/string'
import { daysIncludedInRange, newDateWithoutTime } from 'utils/date'
import { getUserName } from 'utils/person'
import { classes } from '../styles'

interface ISummaryProps {
  task: TaskT<AbsenceRequestTaskT>
  withEmployee?: boolean
  greyVariant?: boolean
}

const Summary: React.FC<ISummaryProps> = ({ task, withEmployee = false, greyVariant = false }) => {
  const { t, locale } = useFavurTranslation()
  const taskData = task.taskData
  const singleDay = taskData.dateFrom === taskData.dateTo

  const dayTime = useMemo(() => {
    if (!singleDay || taskData.dates.length === 0) return ''

    const period = taskData.dates[0].period
    const translatedPeriod = absencePeriodsTranslationMap[period]
    return capitalize(t(absencePeriods[translatedPeriod]))
  }, [singleDay, t, taskData.dates])

  const absenceType = useMemo(() => {
    if (singleDay || !taskData.dateFrom || !taskData.dateTo) return ''

    return `${daysIncludedInRange(newDateWithoutTime(taskData.dateFrom), newDateWithoutTime(taskData.dateTo))} ${t(
      'page.shifts.balance.days',
    )}`
  }, [singleDay, t, taskData.dateFrom, taskData.dateTo])

  return (
    <>
      <Box sx={[classes.container, ...(greyVariant ? [classes.greyVariant] : [])]}>
        {withEmployee && (
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" sx={classes.section}>
              {t('absenceRequest.detail.employee')}:
            </Typography>
            <Typography variant="body2">{task.person ? getUserName(task.person) : ''}</Typography>
          </Box>
        )}
        {singleDay && (
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" sx={classes.section}>
              {t('absenceRequest.detail.date')}:
            </Typography>
            <Typography variant="body2">
              {getFormattedFromISOString(taskData.dateFrom, getDateFormatByLocale(locale as Locale), locale)}
            </Typography>
          </Box>
        )}
        {!singleDay && (
          <>
            <Box display="flex" justifyContent="flex-start">
              <Typography variant="body2" sx={classes.section}>
                {t('absenceRequest.detail.from')}:
              </Typography>
              <Typography variant="body2">
                {getFormattedFromISOString(taskData.dateFrom, getDateFormatByLocale(locale as Locale), locale)}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-start">
              <Typography variant="body2" sx={classes.section}>
                {t('absenceRequest.detail.until')}:
              </Typography>
              <Typography variant="body2">
                {getFormattedFromISOString(taskData.dateTo, getDateFormatByLocale(locale as Locale), locale)}
              </Typography>
            </Box>
          </>
        )}

        <Box display="flex" justifyContent="flex-start">
          <Typography variant="body2" sx={classes.section}>
            {t('absenceRequest.detail.duration')}:
          </Typography>
          <Typography variant="body2">{singleDay ? dayTime : absenceType}</Typography>
        </Box>
      </Box>
    </>
  )
}

export default Summary
