export const seenTutorialBase = 'SEEN_TUTORIAL_'

export const tutorialTypes = {
  absencePlanFrontliner: 'absenceplan-frontliner',
  absencePlanManager: 'absenceplan-manager',
  absecenRequestFrontliner: 'absence-request-frontliner',
  absenceRequestManager: 'absence-request-manager',
  resignation: 'resignation',
  shiftPlan: 'shiftplan',
  workTimeControlFronliner: 'work-time-control-frontliner',
  workTimeControlManager: 'work-time-control-manager',
}
