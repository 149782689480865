import React, { useCallback, useMemo } from 'react'
import { tutorialTypes } from 'constants/tutorials'
import { NotificationT, ResignationInfoNotificationT } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import { compareAsc } from 'date-fns'
import { newDateWithoutTime } from 'utils/date'
import { useAppStatesContext } from 'services/AppStates'
import useHistoryUtils from 'hooks/useHistoryUtils'
import { resignationTenantNameKey } from 'pages/Tutorial/Resignation'
import routes from 'services/RoutesProvider/routes'
import { ROLE_VIEWS } from 'constants/roles'

interface IResignationInfoProps {
  notification: ResignationInfoNotificationT
  dismiss: (notification: NotificationT) => void
}

const ResignationInfo: React.FC<IResignationInfoProps> = (props) => {
  const { t } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()
  const { set } = useAppStatesContext()

  const { notification, dismiss } = props

  const handleDetails = useCallback(() => {
    dismiss(notification)
    const { tenantName } = notification.data
    set(resignationTenantNameKey, tenantName)
    pushWithRole(`${routes.tutorial}/${tutorialTypes.resignation}`, ROLE_VIEWS.frontliner)
  }, [dismiss, notification, pushWithRole, set])

  const templateProps = useMemo(() => {
    const hasResignationPassed =
      compareAsc(newDateWithoutTime(notification.data.resignationDate), newDateWithoutTime()) > 0

    return {
      ...props,
      title: t(
        hasResignationPassed
          ? 'notification.resignation.info.future.title'
          : 'notification.resignation.info.past.title',
        {
          tenantName: notification.data.tenantName,
        },
      ),
      body: t('notification.resignation.info.body'),
      details: handleDetails,
    }
  }, [handleDetails, notification.data.resignationDate, notification.data.tenantName, props, t])

  return <NotificationTemplate {...templateProps} />
}

export default ResignationInfo
