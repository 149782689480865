import { Box } from '@mui/material'
import React, { memo } from 'react'
import useAvatarSidebarStateContext from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import TopBox from '../../AvatarSideBar/TopBox'
import { topBoxBlockClasses } from './styles'

const TopBoxBlock: React.FC<{ isScrolled: boolean }> = ({ isScrolled }) => {
  const { avatarBarExpanded } = useAvatarSidebarStateContext()
  return (
    <Box sx={topBoxBlockClasses.container}>
      <Box sx={topBoxBlockClasses.blockContainer(avatarBarExpanded)}>
        <TopBox isScrolled={isScrolled} />
      </Box>
    </Box>
  )
}

export default memo(TopBoxBlock)
