import React from 'react'
import { Typography, Button, Box } from '@mui/material'
import { useHistory } from 'react-router-dom'

import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useDialogs from 'hooks/useDialogs'
import { classes } from './styles'

const SecurityTimeoutDialog: React.FC = () => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { closeDialog, isOpen } = useDialogs()
  const handleDialogClose = () => {
    closeDialog('securityTimeout')
  }
  const handleSecuritySetupRedirect = () => {
    handleDialogClose()
    history.goBack()
  }

  return (
    <Dialog
      open={isOpen('securityTimeout')}
      actions={
        <Box sx={classes.timeoutDialogButtons}>
          <Button onClick={handleDialogClose} color="secondary" size="large" data-testid="closeSecurityTimeoutDialog">
            {t('common.dialog.button.close')}
          </Button>
          <Button
            onClick={handleSecuritySetupRedirect}
            color="secondary"
            size="large"
            data-testid="pinEntryAfterTimeout"
          >
            {t('common.dialog.timeout.pinEntry')}
          </Button>
        </Box>
      }
      title={t('common.dialog.timeout.title')}
    >
      <Typography variant="body2">{t('common.dialog.timeout.content')}</Typography>
    </Dialog>
  )
}

export default SecurityTimeoutDialog
