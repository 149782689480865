import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { StepT } from 'types'
import { SecurityPinEnterL, SecurityPinInfoL } from 'icons'
import PinLogin from 'pages/PinLogin'
import PhoneChangeProcess from './PhoneChangeProcess'
import ConfirmSMS from './ConfirmSMS'
import NewPhone from './NewPhone'

type PhoneChangeStepName = 'NEW_NUMBER' | 'CONFIRM_SMS'

const phoneChangeSteps: StepT[] = [
  { name: 'NEW_NUMBER', index: 0, icon: SecurityPinEnterL, component: NewPhone },
  { name: 'CONFIRM_SMS', index: 1, icon: SecurityPinInfoL, component: ConfirmSMS },
]

const PhoneChange: React.FC<RouteComponentProps<{ step?: PhoneChangeStepName }>> = ({ match }) => {
  const { step: urlStep } = match.params

  return (
    <PinLogin>
      <PhoneChangeProcess steps={phoneChangeSteps} urlStep={urlStep} />
    </PinLogin>
  )
}

export default PhoneChange
