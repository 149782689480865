import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_XL } from './constants'

const CheckMarkCircleXL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_XL} fill={fill}>
      <path
        fillRule="evenodd"
        d="M24.5 9c-8.2833 0-15 6.719-15 15 0 8.2862 6.7161 15 15 15s15-6.7138 15-15c0-8.281-6.7167-15-15-15Zm-18 15c0-9.9375 8.0596-18 18-18s18 8.0625 18 18c0 9.9433-8.0596 18-18 18s-18-8.0567-18-18Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M31.3604 16.7713c.6786.4752.8436 1.4105.3685 2.0891L23.6822 30.354l-.0015.0021a2.67348 2.67348 0 0 1-2.1486 1.1437 2.67343 2.67343 0 0 1-2.1854-1.0724c-.0004-.0006-.0009-.0012-.0013-.0018l-2.0452-2.7262c-.4971-.6627-.3629-1.603.2998-2.1001.6626-.4971 1.6029-.3629 2.1.2998l1.7788 2.3712 7.7925-11.1305c.4751-.6786 1.4104-.8436 2.0891-.3685Zm-9.6107 11.8601-.0009-.0012.0009.0012Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default CheckMarkCircleXL
