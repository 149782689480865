import theme from 'utils/theme'

export const classes = {
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    width: '100%',
    gap: '16px',
    marginTop: '16px',
    '& .MuiButton-root': {
      margin: '0 12px',
    },
  },
  regularButton: {
    color: theme.palette.primary.main,
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    '&:hover, &:focus': {
      // @ts-ignore
      backgroundColor: theme.palette.primary[50],
      // @ts-ignore
      borderColor: theme.palette.primary[50],
    },
    '&:hover:before, &:before': {
      background: 'transparent',
    },
  },
  plusButton: {
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    '&:hover': {
      // @ts-ignore
      backgroundColor: theme.palette.primary[25],
    },
  },
}
