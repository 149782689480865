import { useMutation } from '@apollo/client'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { useCallback, useMemo } from 'react'
import { updateUserSettingsMutation, userSettingsQuery } from 'shared/queries'
import { GraphQLResponse, SettingsT, UpdateUserSettingsResultT } from 'types'
import { removeTypenameFromObject } from 'utils/apollo'

const getSettingsFromResponse = (data: GraphQLResponse) => {
  const settings = data.me?.user?.settings
  if (!settings) return undefined

  const inAppNotifications = settings.inAppNotifications
    ? removeTypenameFromObject(settings.inAppNotifications)
    : undefined
  const pushNotifications = settings.pushNotifications
    ? removeTypenameFromObject(settings.pushNotifications)
    : undefined

  return removeTypenameFromObject({
    ...settings,
    inAppNotifications: inAppNotifications,
    pushNotifications: pushNotifications,
  }) as SettingsT
}

interface UserSettingsParams {
  onComplete?: (settings?: SettingsT) => void
}

const useUserSettings = ({ onComplete }: UserSettingsParams = {}) => {
  const [saveSettings] = useMutation<UpdateUserSettingsResultT>(updateUserSettingsMutation)
  const { loading, data, refetch } = useJamesApolloQuery<GraphQLResponse>(userSettingsQuery, {
    fetchPolicy: 'network-only',
    onCompleted(userData) {
      if (!userData) return

      onComplete && onComplete(getSettingsFromResponse(userData))
    },
  })

  const settings = useMemo(() => (data ? getSettingsFromResponse(data) : undefined), [data])

  const updateUserSettings = useCallback(
    async (newSettings: Partial<SettingsT>) => {
      if (!settings) {
        return undefined
      }

      return saveSettings({
        variables: {
          settings: settings ? { ...settings, ...newSettings } : newSettings,
        },
      })
    },
    [saveSettings, settings],
  )

  return { updateUserSettings, loading, settings, refetch }
}

export default useUserSettings
