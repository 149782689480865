export const classes = {
  drawer: {
    height: `calc(100vh - var(--ion-safe-area-top))`,
  },
  paper: {
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    maxWidth: '600px',
    margin: '0 auto',
  },
  fullHeightPaper: {
    height: 'calc(100% - 36px)',
  },
  content: {
    padding: '16px 12px 0 11px',
  },
  topButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    '& :first-of-type': {
      paddingLeft: 0,
    },
    '& :last-of-type': {
      paddingRight: 0,
    },
  },
}
