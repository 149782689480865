import { shiftFields } from 'shared/queries'
import { gql } from '@apollo/client'
import type { QueryDates } from 'types'

export const shiftListQueryKey = (dates: QueryDates): string => {
  return `allShiftsFavur:${dates.start}-${dates.end}`
}

const shiftsFavurPart = (dates: QueryDates, personIds: number[] | undefined): string => {
  return `
    shiftsFavurByDate(startDate: "${dates.start}", endDate: "${dates.end}", personIds: [${personIds}], types: ["shift", "absence"]) {
      date
      tenants {
        tenantName
        tenantId
        costCenterUuid
        costCenterName
        ${shiftFields}
      }
  }`
}

export const shiftListQuery = (dates: QueryDates, personIds: number[] | undefined): string => {
  return `query{
      isMultiTenant{isMultiTenant},
      ${shiftsFavurPart(dates, personIds)}
    }`
}

export const shiftFavurByDateQuery = (dates: QueryDates, personIds: number[] | undefined) => {
  return gql`query{
    ${shiftsFavurPart(dates, personIds)}
  }`
}

export const effectiveShiftListQueryKey = (dates: QueryDates): string => {
  return `allEffectiveShiftsFavur:${dates.start}-${dates.end}`
}
export const effectiveShiftListQuery = (dates: QueryDates): string => {
  return `query{
      effectiveShiftsFavur(startDate: "${dates.start}", endDate: "${dates.end}") {
          date
          tenants {
            tenantName      
            tenantId
            costCenterUuid
            costCenterName
            hideUnverifiedEffectiveTimes
            shifts {
              from
              until
              timeType
              validated
            }
          }
      }
      isMultiTenant{isMultiTenant}
    }`
}
