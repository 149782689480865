import React, { useState } from 'react'
import { IonActionSheet } from '@ionic/react'
import { close, globeOutline } from 'ionicons/icons'
import { Tooltip, Button } from '@mui/material'
import { LANGUAGES } from 'constants/i18n'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { LanguageSwitcherM } from 'icons'
import theme from 'utils/theme'
import seti18nCookie from 'i18n/setCookie'

const LanguageSwitcher: React.FC = () => {
  const [showActionSheet, setShowActionSheet] = useState(false)
  const { t, language, i18n } = useFavurTranslation()
  const label = t('i18n.switcher.button.languageSwitcher')

  const languagesOptions = LANGUAGES.map((lng) => {
    return {
      text: t(lng.label),
      icon: globeOutline,
      handler: () => {
        i18n.changeLanguage(lng.code).then(() => {
          i18n.reloadResources([language])
          seti18nCookie(language)
        })
      },
    }
  })

  return (
    <>
      <Tooltip title={label}>
        <Button
          size="small"
          aria-label={label}
          color="secondary"
          startIcon={<LanguageSwitcherM fill={theme.palette.secondary.main} />}
          onClick={() => setShowActionSheet(true)}
        >
          {t(LANGUAGES.find((lng) => lng.code === language)?.label ?? '')}
        </Button>
      </Tooltip>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        buttons={[
          ...languagesOptions,
          {
            text: t('i18n.switcher.button.cancelAction'),
            icon: close,
            role: 'cancel',
            handler: () => {},
          },
        ]}
      />
    </>
  )
}

export default LanguageSwitcher
