import featureFlags, { FeatureFlagType } from 'constants/featureFlags'
import Cookies from 'universal-cookie'
import { getAPIURL, getEnvironmentValue } from 'constants/environment'

// this is to take
// - local.favur.ch from http://api.local.favur.ch:8888 for web
// - 192.168.1.1 from http://192.168.1.1:8899 for mobile

export const getCookiedomain = () => {
  const apiUrl = getAPIURL() || 'https://api.favur.ch'
  if (getEnvironmentValue('APPLICATION_ENV') === 'LOCAL') {
    return apiUrl.includes('api') ? apiUrl.slice(11, -5) : apiUrl.slice(7, -5)
  }

  // In stg/wip/live we will be replacing https://api and we will get:
  // 'favur.ch' for https://api.favur.ch
  // 'stg.favur.xyz' for https://api.stg.favur.xyz
  // 'wip.favur.xyz' for https://api.wip.favur.xyz
  return apiUrl.replace('https://api.', '')
}
const settings = (featureFlag: FeatureFlagType, value: boolean | string | void = undefined) => {
  if (!featureFlags[featureFlag]) {
    return undefined
  }
  if (typeof value === 'undefined') {
    try {
      return JSON.parse(localStorage.getItem(featureFlag) ?? '')
    } catch (e) {
      return undefined
    }
  }
  /* istanbul ignore next */
  try {
    if (featureFlag.includes('__BE__')) {
      const cookie = new Cookies()
      cookie.set(featureFlag, JSON.stringify(value), { domain: getCookiedomain() })
    }
    return localStorage.setItem(featureFlag, JSON.stringify(value))
  } catch (e) {
    return undefined
  }
}

export default settings
