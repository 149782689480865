import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const WorkTimeControlM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M11.3.77a11.29 11.29 0 0 0-10.46 9.8 11.39 11.39 0 0 0 1.81 7.7 11.27 11.27 0 0 0 17.06 1.92 11.22 11.22 0 0 0 0-16.39A11.21 11.21 0 0 0 11.28.77Zm.06 1.5a9.8 9.8 0 0 0-7.58 4.5 9.77 9.77 0 0 0 2.99 13.45 9.79 9.79 0 0 0 10.46 0 9.8 9.8 0 0 0 3-13.46 9.78 9.78 0 0 0-8.86-4.5Zm.5 5.25a.74.74 0 0 0-.55.44l-.05.12v4.09l.05.12c.04.12.33.4 2.45 2.54 1.32 1.32 2.46 2.44 2.53 2.49a.74.74 0 0 0 1.14-.62c0-.35.16-.17-2.4-2.73l-2.29-2.3v-3.6l-.05-.12a.8.8 0 0 0-.31-.35.79.79 0 0 0-.52-.08Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default WorkTimeControlM
