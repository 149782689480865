import React, { useEffect } from 'react'
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { StatusBar, Style } from '@capacitor/status-bar'
import theme from 'utils/theme'
import { isNativeNoOverride, isAndroidNative } from 'utils/platform'

import '@ionic/react/css/core.css'
import '@ionic/react/css/normalize.css'
import '../../assets/fonts/index.css'

interface Props {
  children?: React.ReactNode
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    if (isNativeNoOverride()) {
      // setBackgroundColor and setOverlaysWebView are currently not supported on iOS devices.
      // https://capacitorjs.com/docs/apis/status-bar
      if (isAndroidNative()) {
        StatusBar.setBackgroundColor({ color: theme.palette.primary.dark })
      }
      StatusBar.setStyle({ style: Style.Dark })
    }
  }, [])
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProvider
