import React from 'react'
import { Box } from '@mui/material'
import theme from 'utils/theme'
import SectionTitle from 'components/SectionTitle'
import { headerClasses } from './styles'

interface ISectionHeaderProps {
  title: string
  testId?: string
}

const SectionHeader: React.FC<ISectionHeaderProps> = ({ title, testId }) => {
  return (
    <Box sx={headerClasses.headerContainer}>
      <SectionTitle textColor={theme.palette.primary.dark} gutter={0} testId={testId}>
        {title}
      </SectionTitle>
    </Box>
  )
}

export default SectionHeader
