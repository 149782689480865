import React, { SyntheticEvent, useCallback, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Tabs from 'components/Tabs'
import FlashMessagesContext from 'services/FlashMessages/context'
import { FormProvider } from 'components/Forms/provider'
import TabPanel from './TabPanel'
import { getTab, tabs } from './helpers'

interface EmploymentTabPanelProps {
  tabName?: string
  originPage?: string | null
}

const EmploymentTabPanel: React.FC<EmploymentTabPanelProps> = ({ tabName, originPage = null }) => {
  const { t } = useFavurTranslation()
  const tab = getTab(tabName)
  const history = useHistory()
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const [currentTab, setCurrentTab] = useState(tab)
  const [newTabValue, setNewTabValue] = useState(0)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [changeTabTrigger, setChangeTabTrigger] = useState(false)
  const tabChangeCallback = useCallback(
    (newValue?: number) => {
      const newTabIndex = newValue ?? newTabValue
      setCurrentTab(tabs[newTabIndex])
      setIsFormDirty(false)
      history.push(`${routes.employmentData}/${tabs[newTabIndex].name.toLowerCase()}`, { origin: originPage })
    },
    [history, newTabValue, originPage],
  )
  const handleChange = useCallback(
    (_event: SyntheticEvent<Element, Event>, newValue: number): void => {
      setNewTabValue(newValue)

      if (!changeTabTrigger && isFormDirty) {
        setChangeTabTrigger(true)
      } else {
        tabChangeCallback(newValue)
      }
    },
    [changeTabTrigger, isFormDirty, tabChangeCallback],
  )

  const handleUpdateData = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setFlashMessage('peronalData.flash.PersonalDataUpdated')
      setIsFormDirty(false)
      if (e === undefined) {
        tabChangeCallback()
      }
    },
    [setFlashMessage, tabChangeCallback],
  )

  return (
    <FormProvider
      includeFiles={false}
      onUpdateBankData={handleUpdateData}
      onUpdateContactData={handleUpdateData}
      onUpdateFamilyData={handleUpdateData}
      onUpdatePersonalData={handleUpdateData}
    >
      <Tabs
        currentTabIndex={currentTab.index}
        handleChange={handleChange}
        tabs={tabs.map((tabElem) => ({ id: tabElem.index, label: t(tabElem.label) }))}
      >
        {Object.values(tabs).map((tabElem) => (
          <TabPanel key={tabElem.index} value={currentTab.index} index={tabElem.index}>
            <tabElem.component
              changeTabTrigger={changeTabTrigger}
              setChangeTabTrigger={setChangeTabTrigger}
              tabChangeCallback={tabChangeCallback}
              setIsFormDirty={setIsFormDirty}
            />
          </TabPanel>
        ))}
      </Tabs>
    </FormProvider>
  )
}

export default EmploymentTabPanel
