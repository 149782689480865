import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'
import RegisterDialog from './RegisterDialog'
import { ILoginStepProps } from '../types'

const Splash: React.FC<ILoginStepProps> = ({ setStep }) => {
  const { t } = useFavurTranslation()
  const [dialogOpen, setDialogOpen] = useState(false)

  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)

  return (
    <>
      <Box sx={classes.heading}>
        <Typography variant="h1" color="primary" gutterBottom>
          {t('login.splash.content.title')}
        </Typography>
        <Typography component="p">{t('login.splash.content.description')}</Typography>
      </Box>
      <Box sx={classes.buttonContainer}>
        <RegisterDialog isOpen={dialogOpen} closeDialog={closeDialog} />
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => setStep('START')}
          data-testid="call-to-action"
        >
          {t('login.splash.content.callToAction')}
        </Button>
        <Button size="small" onClick={openDialog} sx={classes.registerButton} data-testid="go-to-dashboard">
          {t('login.splash.button.register')}
        </Button>
      </Box>
    </>
  )
}

export default Splash
