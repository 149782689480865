import React, { useCallback } from 'react'
import Card from 'components/Basics/Card'
import { PersonT } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TENANT_CONNECTION_STATUS } from 'constants/highSecurityConnection'
import { Box, Grid, Typography } from '@mui/material'
import { getFormattedFromISOString } from 'utils/date'
import { uiDateFormat } from 'utils/constants'
import { classes } from './styles'

interface ITenantDataProps {
  person: PersonT
}

const TenantData: React.FC<ITenantDataProps> = ({ person }) => {
  const { t } = useFavurTranslation()

  const getSecurityConectionText = useCallback(
    (connectionStatus: string | undefined) => {
      switch (connectionStatus) {
        case TENANT_CONNECTION_STATUS.high:
          return t('page.userSettings.employers.active')
        case TENANT_CONNECTION_STATUS.low:
          return t('page.userSettings.employers.deactivated')
        default:
          return '-'
      }
    },
    [t],
  )

  const securityConectionStatusText = getSecurityConectionText(person.tenantConnectionStatus)

  return (
    <>
      <Card marginBottom={1.125}>
        <Box sx={classes.tenantDataContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="caption">{t('component.employeeDetail.employer')}</Typography>
              <Typography variant="subtitle1">{person.tenant?.name}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="caption">{t('component.employeeDetail.activeSince')}</Typography>
              <Typography variant="subtitle1">
                {person.tenantConnectionEstablished
                  ? getFormattedFromISOString(person.tenantConnectionEstablished, uiDateFormat, undefined)
                  : '-'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="caption">{t('component.employeeDetail.secureConnection')}</Typography>
              <Typography variant="subtitle1">{securityConectionStatusText}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  )
}

export default TenantData
