import { useCallback, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { OwnerPersons } from 'hooks/useOwnersOfUser'
import theme from 'utils/theme'
import { useAppStatesContext } from 'services/AppStates'
import { SELEC_OWNER_FILTER_NAME } from './useFilter'

const useAbsencePlannerState = () => {
  const { get, set } = useAppStatesContext()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedOwnerId, setSelectedOwnerId] = useState<number | undefined>(get(SELEC_OWNER_FILTER_NAME))
  const [owners, setOwners] = useState<OwnerPersons[] | undefined>()

  const storeAndSetSelOwnerId = useCallback(
    (value: number | undefined) => {
      set(SELEC_OWNER_FILTER_NAME, value)
      setSelectedOwnerId(value)
    },
    [set, setSelectedOwnerId],
  )
  return {
    isSmallScreen,
    owners,
    setOwners,
    selectedOwnerId,
    setSelectedOwnerId: storeAndSetSelOwnerId,
  }
}

export default useAbsencePlannerState
