import { useState } from 'react'
import { PersonT, TenantT } from 'types'
import { personsWithShareUserData, tasksPaginatedQuery } from 'pages/Tasks/queries'
import { ShareUserDataTaskT, TasksPaginated, TaskT } from 'pages/Tasks/types'
import { taskStates } from 'pages/Tasks/constants'
import { FF_PACKAGE } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import usePackage from 'hooks/usePackage'
import { taskTypes } from 'shared/constants'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import type { PersonPackageT } from 'types'

type ShareUserDataTask = TaskT<ShareUserDataTaskT>
export type PersonWithWorkflow = PersonT & { shareUserDataTask?: ShareUserDataTask }

const usePersonsWithShareUserDataWorkflow = () => {
  const { checkHasClassicAccess } = usePackage()
  const [personsLoading, setPersonsLoading] = useState(true)
  const [tasksLoading, setTasksLoading] = useState(true)
  const [personsList, setPersonsList] = useState<PersonWithWorkflow[]>([])
  const packageFF = useFeatureFlag(FF_PACKAGE)

  useJamesApolloQuery<{ findAllPersons: PersonT[] }>(personsWithShareUserData, {
    fetchPolicy: 'cache-and-network',
    onCompleted(managedPersons) {
      const persons = managedPersons.findAllPersons.filter((person) => {
        const tenant = person?.tenant as TenantT
        const personPackages = {
          [person.id as number]: tenant.package as PersonPackageT,
        } as PersonPackageT

        return checkHasClassicAccess({ packageFF, personPackages })
      })
      setPersonsList(persons)
      setPersonsLoading(false)
    },
  })

  useJamesApolloQuery<{ tasksPaginated: TasksPaginated }>(tasksPaginatedQuery, {
    skip: personsLoading,
    variables: {
      filters: {
        taskTypes: [taskTypes.shareUserData],
        personIds: personsList.map((person) => person.id),
        taskStatuses: [taskStates.todo, taskStates.pending],
      },
      office: false,
    },
    fetchPolicy: 'no-cache',
    onCompleted(responseData) {
      const tasks = responseData.tasksPaginated.list
      const personTasks = tasks.reduce((state, task) => {
        return state.set(task.person?.id as number, task as ShareUserDataTask)
      }, new Map<number, ShareUserDataTask>())

      setPersonsList(
        personsList.map((person) => {
          const task = personTasks.get(person.id as number)
          if (task) {
            return { ...person, shareUserDataTask: task }
          }

          return person
        }),
      )

      setTasksLoading(false)
    },
  })

  return {
    loading: personsLoading || tasksLoading,
    persons: personsList,
  }
}

export default usePersonsWithShareUserDataWorkflow
