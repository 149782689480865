import i18next, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { FALLBACK_LANGUAGE, SUPPORTED_LANGUAGES } from 'constants/i18n'
import seti18nCookie from 'i18n/setCookie'
import enTranslations from './Translations/en/translation.json'
import frTranslations from './Translations/fr/translation.json'
import deTranslations from './Translations/de/translation.json'
import itTranslations from './Translations/it/translation.json'

const init: InitOptions = {
  react: {
    useSuspense: true,
    bindI18n: 'languageChanging languageChanged loaded',
  },
  ns: 'translation',
  fallbackLng: FALLBACK_LANGUAGE,
  supportedLngs: SUPPORTED_LANGUAGES,
  debug: false,
  load: 'languageOnly',
  interpolation: {
    escapeValue: true,
  },
  resources: {
    en: {
      translation: enTranslations,
    },
    de: {
      translation: deTranslations,
    },
    fr: {
      translation: frTranslations,
    },
    it: {
      translation: itTranslations,
    },
  },
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(init)
  .then(() => {
    seti18nCookie(i18next.languages[0])
    i18next.reloadResources()
  })

export default i18next
