import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const UserSettingsL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M13.67 4.2c1.66-.3 3.39-.27 5.06.09.66.13 1.16.69 1.23 1.35v1.64l.08.03c.49.2.96.46 1.4.74l.34.22.08.06 1.41-.78a1.58 1.58 0 0 1 1.66.1l.12.09.1.1a11.88 11.88 0 0 1 2.78 4.6c.2.64-.04 1.34-.6 1.71l-.12.08-1.37.75.01.05c.06.51.07 1.03.03 1.55l-.03.4v.04l1.36.75c.55.31.85.91.78 1.52l-.03.14-.03.15c-.56 1.7-1.5 3.27-2.77 4.58-.46.47-1.17.6-1.76.35l-.13-.07-1.4-.78-.1.07c-.43.3-.89.57-1.37.8l-.36.16-.08.03v1.5c0 .63-.39 1.18-.95 1.4l-.13.05-.14.04c-1.78.38-3.66.39-5.47 0a1.54 1.54 0 0 1-1.23-1.35v-1.64l-.08-.03c-.49-.2-.96-.46-1.4-.74l-.34-.22-.09-.06-1.4.78c-.54.29-1.18.24-1.66-.1l-.12-.09-.11-.1a11.93 11.93 0 0 1-2.77-4.6c-.2-.64.04-1.34.6-1.71l.12-.08 1.37-.75-.01-.05a9.2 9.2 0 0 1-.03-1.55l.03-.4v-.04l-1.36-.75c-.55-.31-.85-.91-.78-1.52l.03-.14.03-.15c.56-1.7 1.5-3.27 2.77-4.58A1.58 1.58 0 0 1 8.6 7.5l.13.07 1.4.77.1-.06c.43-.3.89-.57 1.37-.8l.36-.16.08-.03v-1.5c0-.63.39-1.18.96-1.4l.13-.06.14-.03.4-.08zm4.3 1.98h-.04a11.04 11.04 0 0 0-3.83-.02l-.06.02v2.5l-.68.23c-.8.28-1.56.67-2.23 1.17l-.28.22-.52.43-2.27-1.26-.04.04A9.97 9.97 0 0 0 6.38 12l-.15.34-.13.33 2.27 1.25-.14.71c-.15.8-.17 1.6-.06 2.4l.06.34.14.71-2.27 1.25.13.32c.38.92.9 1.78 1.54 2.55l.25.29.04.04 2.27-1.26.52.43c.65.53 1.38.95 2.17 1.26l.34.13.68.23v2.5h.03c1.14.21 2.31.23 3.45.07l.44-.07v-2.5l.68-.23c.8-.28 1.56-.67 2.23-1.17l.28-.22.52-.43 2.27 1.26.04-.04A9.97 9.97 0 0 0 25.62 20l.15-.34.12-.33-2.26-1.25.14-.71c.15-.8.17-1.6.06-2.4l-.06-.34-.14-.71 2.26-1.25-.12-.33a9.89 9.89 0 0 0-1.55-2.55l-.28-.32-2.27 1.25-.52-.42a8.01 8.01 0 0 0-2.17-1.26l-.34-.13-.68-.23v-2.5zM16 10.77a5.24 5.24 0 0 1 0 10.46 5.23 5.23 0 0 1 0-10.46zm0 2a3.23 3.23 0 1 0 0 6.45 3.23 3.23 0 0 0 0-6.45z"
      />
    </Svg>
  )
}

export default UserSettingsL
