import React from 'react'
import { IconButton, Box, SwipeableDrawer, SxProps, Theme } from '@mui/material'
import CloseM from 'icons/CloseM'
import { isNative } from 'utils/platform'
import Puller from 'components/BaseDrawer/Puller'
import theme from 'utils/theme'
import { classes } from './styles'

interface IBottomDrawerProps {
  isOpen: boolean
  children?: React.ReactNode
  onClose: () => void
  paperSx?: SxProps<Theme>
}

const BottomDrawer: React.FC<IBottomDrawerProps> = ({ isOpen, children, onClose, paperSx = {} }) => {
  const isNativeDrawer = isNative()
  return (
    <SwipeableDrawer
      open={isOpen}
      anchor="bottom"
      onClose={onClose}
      PaperProps={{
        sx: [
          classes.paper,
          ...(Array.isArray(paperSx) ? paperSx : [paperSx]),
          ...(isNativeDrawer ? [classes.paperNative] : []),
        ],
      }}
      onOpen={() => {}}
      swipeAreaWidth={0}
    >
      {isNativeDrawer && <Puller />}
      <Box sx={classes.container}>
        {!isNativeDrawer && (
          <IconButton sx={classes.iconButtonClose} onClick={onClose}>
            <CloseM fill={theme.palette.primary.main} />
          </IconButton>
        )}
        {children}
      </Box>
    </SwipeableDrawer>
  )
}

export default BottomDrawer
