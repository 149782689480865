import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const EmployersM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M11.586 1.014a5.267 5.267 0 0 0-3.81 2.094c-.445.598-.79 1.354-.925 2.031l-.063.305-.012.053-2.488.007-2.489.008-.192.053C.81 5.788.283 6.345.052 7.21L0 7.406V20.14l.055.216c.23.902.968 1.504 1.984 1.619.286.032 19.622.032 19.953 0a1.94 1.94 0 0 0 .773-.215c.421-.204.726-.509.947-.948.119-.236.24-.633.24-.789.001-.057.007-.066.024-.039.015.024.024-2.175.024-6.228 0-4.15-.008-6.27-.024-6.28-.013-.008-.024-.05-.024-.093 0-.134-.092-.449-.193-.662-.274-.572-.763-.955-1.481-1.158-.183-.052-.2-.052-2.637-.06l-2.453-.007-.012-.053-.062-.305c-.216-1.074-.88-2.178-1.741-2.896a5.266 5.266 0 0 0-3.787-1.23Zm.038 1.513a3.788 3.788 0 0 0-2.13.928c-.44.387-.828.961-1.027 1.516a6.442 6.442 0 0 0-.155.521c0 .004 1.652.007 3.672.007 3.486 0 3.671-.002 3.66-.042l-.052-.186a3.744 3.744 0 0 0-3.286-2.745 2.77 2.77 0 0 0-.682 0Zm-9.7 4.522c-.15.046-.284.181-.356.36l-.057.14-.006 1.973-.007 1.973h7.529l.042-.174c.272-1.111 1.169-1.97 2.314-2.217.284-.06.914-.06 1.198 0a3.016 3.016 0 0 1 2.317 2.229l.04.162h7.528l-.006-2.009-.007-2.008-.057-.116c-.07-.144-.2-.241-.405-.304-.153-.048-.238-.048-10.053-.046-8.647.001-9.913.006-10.014.037ZM0 13.773c0 3.45.003 4.861.006 3.137.004-1.725.004-4.548 0-6.272C.003 8.913 0 10.324 0 13.774Zm11.547-3.156a1.541 1.541 0 0 0-.986.948c-.05.149-.053.207-.06 1.012-.007.708-.002.88.029 1.014.1.432.419.818.83 1.007.22.1.378.135.622.135a1.3 1.3 0 0 0 .636-.144c.41-.193.717-.569.817-.998.03-.133.036-.306.03-1.014-.008-.805-.012-.863-.062-1.012a1.654 1.654 0 0 0-.665-.812c-.321-.193-.81-.25-1.191-.136ZM1.499 16.47c0 3.405.001 3.467.048 3.593.082.217.258.342.569.4.1.02 2.868.025 9.986.02 9.501-.007 9.851-.008 9.961-.051.136-.053.274-.2.342-.366.047-.115.048-.18.054-3.59l.007-3.471H14.98v.246a3.007 3.007 0 0 1-1.442 2.554 3.006 3.006 0 0 1-4.058-.91 2.97 2.97 0 0 1-.497-1.644v-.246H1.499v3.465Z"
      />
    </Svg>
  )
}

export default EmployersM
