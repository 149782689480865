import React from 'react'
import { Box, Typography } from '@mui/material'
import { AvatarWithIconT, HrAvatarT, TimelineAvatarTypeT, TimelineItemHeaderT } from 'components/Timeline/types'
import { classes } from './styles'
import HrAvatar from './HrAvatar'
import AvatarWithIcon from './AvatarWithIcon'

interface IItemHeaderProps {
  header: TimelineItemHeaderT
}

const ItemHeader: React.FC<IItemHeaderProps> = ({ header }) => {
  const hasAvatar = Boolean(header.avatarData)
  const isHrAvatar = hasAvatar && header.avatarData?.type === TimelineAvatarTypeT.hr
  return (
    <Box sx={classes.headerContainer}>
      <Typography sx={classes.headerText} variant="caption">
        {header.text}
      </Typography>
      {hasAvatar && (
        <Box sx={classes.avatarRow}>
          {isHrAvatar ? (
            <HrAvatar avatar={header.avatarData as HrAvatarT} />
          ) : (
            <AvatarWithIcon avatar={header.avatarData as AvatarWithIconT} />
          )}
          <Typography sx={classes.avatarName} variant="caption">
            {header.avatarData?.userName}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default ItemHeader
