import { useCallback, useContext, useEffect, useState } from 'react'
import { Area } from 'react-easy-crop/types'
import { useHistory } from 'react-router-dom'
import { MutationResult, useMutation } from '@apollo/client'
import { avatarFields, getAllImageSizes, getCroppedImg, getImageSize, ImageSizeT, uploadImage } from 'utils/avatar'
import FlashMessagesContext from 'services/FlashMessages/context'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { AvatarIndexT, AvatarLinksT } from '../components/CropImage/types'
import { getAvatarUploadLinkQuery, updateUserAvatarUrlMutation } from '../queries'
import { ICropImageProps } from '../components/CropImage'

const useCropImage = ({ base64Image, onCancel }: ICropImageProps) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const [uploadingAvatars, setUploadingAvatars] = useState(false)
  const [imageSize, setImageSize] = useState<ImageSizeT>()
  const [pixelCrop, setPixelCrop] = useState<Area>()
  const [links, setLinks] = useState<AvatarLinksT>()
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)

  useJamesApolloQuery<{ getAvatarUploadLinks: AvatarLinksT }>(getAvatarUploadLinkQuery, {
    onCompleted: (data) => {
      const uploadLinks = data.getAvatarUploadLinks
      setLinks(uploadLinks)
    },
    onError: (error) => {
      setFlashMessage(error.toString())
      // This will make this component to not be rendered anymore
      // and exit the loading status when there is no link
      onCancel()
    },
  })

  useEffect(() => {
    getImageSize(base64Image).then(setImageSize)
  }, [base64Image])

  const [updateUserAvatarUrl] = useMutation<MutationResult>(updateUserAvatarUrlMutation)

  const onCropComplete = (_croppedArea: Area, croppedAreaPixels: Area) => setPixelCrop(croppedAreaPixels)

  const submitImage = useCallback(async () => {
    if (!pixelCrop || !links) return
    setUploadingAvatars(true)
    removeAll()
    getCroppedImg(base64Image, pixelCrop)
      .then((croppedImage) => getAllImageSizes(croppedImage as string))
      .then((images) => {
        Promise.all(
          Object.entries(links)
            .filter(([key, _prop]) => avatarFields.includes(key as AvatarIndexT))
            .map((value) => {
              const [key, link] = value
              const image = images[key as AvatarIndexT]
              return uploadImage(image, link)
            }),
        )
          .then(() => updateUserAvatarUrl({ variables: { imageUuid: links.imageUuid } }))
          .then(() => {
            setFlashMessage(t('page.userSettings.profile.settingsSection.profileImage.upload.success'))
            setTimeout(() => {
              history.go(0)
            }, 1000)
          })
          .catch((_err) => {
            setUploadingAvatars(false)
            setFlashMessage(t('page.userSettings.profile.settingsSection.profileImage.upload.error'))
          })
      })
  }, [base64Image, history, links, pixelCrop, removeAll, setFlashMessage, t, updateUserAvatarUrl])

  return { uploadingAvatars, imageSize, links, onCropComplete, submitImage }
}

export default useCropImage
