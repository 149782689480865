import useJamesQuery from 'hooks/useJamesQuery'
import { numberOfShiftsOnDashboard } from 'constants/shift'
import { getFirstNShifts } from 'utils/shifts'
import {
  effectiveShiftListQuery,
  effectiveShiftListQueryKey,
  shiftListQuery,
  shiftListQueryKey,
} from '../components/ShiftsList/queries'
import type { QueryDates } from 'types'
import type { ShiftsByDateT } from '../components/ShiftsList/types'

const useShifts = ({
  dates,
  displayFull = true,
  personIds = [],
  isEffective = false,
  enabled = true,
}: {
  dates: QueryDates
  displayFull?: boolean
  personIds?: number[]
  isEffective?: boolean
  enabled?: boolean
}) => {
  const query = isEffective ? effectiveShiftListQuery(dates) : shiftListQuery(dates, personIds)
  const queryKey = isEffective ? effectiveShiftListQueryKey(dates) : shiftListQueryKey(dates)
  const { data, status } = useJamesQuery<{
    shiftsFavurByDate?: ShiftsByDateT[]
    effectiveShiftsFavur?: ShiftsByDateT[]
    isMultiTenant: { isMultiTenant: boolean }
  }>(queryKey, query, { enabled: enabled })
  // we get the error this way due of how the hook useJamesQuery works
  const error = status === 'success' && data === undefined
  const shiftsByDate = isEffective ? data?.effectiveShiftsFavur || [] : data?.shiftsFavurByDate || []
  const shifts = displayFull
    ? shiftsByDate
    : getFirstNShifts({ shiftsByDate, numberOfShifts: numberOfShiftsOnDashboard })
  const isMultitenant = data?.isMultiTenant?.isMultiTenant ?? false

  return { shifts, isMultitenant, status, error }
}

export default useShifts
