import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { Box, Button, Typography } from '@mui/material'
import { AuthenticationKind } from 'constants/authentication'
import { SimpleLoading } from 'components/LoadingIcon'
import useSessionContext from 'hooks/useSessionContext'
import useFavurTranslation from 'hooks/useFavurTranslation'
import usePhoneNumber from 'hooks/usePhoneNumber'
import { NumpadWithoutState } from 'components/Numpad'
import PhoneNumberInput from 'components/Forms/components/PhoneNumberInput'
import { AuthStartResponseT } from 'types'
import routes from 'services/RoutesProvider/routes'
import { useMutation } from '@apollo/client'
import { isMobile } from 'utils/platform'
import { ILoginStepProps } from '../types'
import { authStartMutationApollo as mutation } from '../mutations'
import { classes } from '../styles'
import type { ApiMeResponseT } from 'services/Session/context'

type AuthStartResponse = {
  authStart?: AuthStartResponseT
}

const Start = ({ setStep }: ILoginStepProps) => {
  const { t } = useFavurTranslation()
  const { update, phone: sessionPhone = '' } = useSessionContext()
  const { updatePhoneNumber, phone, setPhone } = usePhoneNumber(sessionPhone)
  const [error, setError] = useState(false)
  const history = useHistory()
  const getHelperText = () => {
    return error ? t('login.start.error.phoneInvalid') : t('login.start.content.phoneHelperText')
  }
  const [authStart, { loading }] = useMutation<AuthStartResponse>(mutation)

  const fullPhoneNumber = `${phone.countryCode ? `+${phone.countryCode}` : ''}${phone.phoneNumber}`

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updatePhoneNumber(e.target.value)
    },
    [updatePhoneNumber],
  )

  const onConfirm = useCallback(async () => {
    error === phone.isValidNumber && setError(!phone.isValidNumber)

    if (!phone.isValidNumber) {
      return
    }

    const mutationResult = await authStart({ variables: { phone: fullPhoneNumber, appSignature: null } })

    if (mutationResult.data) {
      const { data } = mutationResult

      const invitationCode = data?.authStart?.invitationCode
      if (invitationCode) {
        history.push(`${routes.invite}/${invitationCode}`)
      } else {
        const sessionData: ApiMeResponseT = {
          auth: data?.authStart?.auth || AuthenticationKind.ANONYMOUS,
          cmsActivated: false,
          phone: data?.authStart?.user?.phoneNumber,
        }
        setStep('VALIDATE')
        update(sessionData)
      }
    }
  }, [authStart, error, fullPhoneNumber, history, phone.isValidNumber, setStep, update])

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onConfirm()
  }

  if (loading) {
    return <SimpleLoading />
  }

  return (
    <Box sx={classes.fullPageContainer}>
      <Box sx={classes.heading}>
        <Typography variant="h2" color="primary" sx={classes.headingText}>
          {t('login.start.content.title')}
        </Typography>
        <PhoneNumberInput
          label={t('login.start.content.phoneNumber')}
          phone={phone}
          onChange={onChange}
          setPhone={setPhone}
          error={error}
          helperText={getHelperText()}
          readOnly={isMobile()}
        />
      </Box>
      <Box sx={classes.numpadContainer}>
        <NumpadWithoutState
          numpadType="phone"
          numpadValue={phone.phoneNumber}
          setNumpadValue={updatePhoneNumber}
          onConfirm={onConfirm}
          usedWithPhoneNumberValidation
          allowEnter
        />
        <Button
          size="large"
          sx={classes.confirmButton}
          type="submit"
          variant="contained"
          color="secondary"
          onClick={onSubmit}
          data-testid="submit-phone"
        >
          {t('login.start.content.callToAction')}
        </Button>

        <Button
          size="large"
          sx={classes.confirmButton}
          type="submit"
          variant="text"
          color="secondary"
          onClick={() => history.push(routes.forgotPhone)}
          data-testid="change-phone"
        >
          {t('login.start.content.changePhone')}
        </Button>
      </Box>
    </Box>
  )
}

export default Start
