import { addDays, differenceInDays, endOfMonth, Locale, startOfMonth } from 'date-fns'
import { TFunction } from 'i18next'
import { hourAndMinute, shiftDateFormat } from 'constants/dateFormats'
import { daysInMonthWhenLastMonthCanBeViewed, shiftTypes } from 'constants/shift'
import { QueryDates, PlannedShiftT, ShiftT, ShiftUpdateType, ShiftsByTenantCCT, EffectiveShiftT } from 'types'
import { uiDateFormat } from './constants'
import { getFormattedFromDate, isValidDate, newDateWithoutTime } from './date'
import type { ShiftsByDateT } from 'components/ShiftsList/types'
import type { DateGetterT } from 'types/utils'

export const shiftText = (t: TFunction, type: string): string =>
  type
    ? t(`page.shifts.content.${type}`, {
        defaultValue: t('page.shifts.content.defaultAbsenceType'),
      })
    : ''

export const parseShiftTime = (datetimeString: string): Date => {
  const dateTime = datetimeString.includes('T') ? datetimeString.split('T') : datetimeString.split(' ')
  const timeString = dateTime.length > 1 ? dateTime[1] : dateTime[0]
  const time = timeString.split(':')
  const date = newDateWithoutTime()
  date.setHours(parseInt(time[0]), parseInt(time[1]), 0)
  return date
}

export const getAbsenceText = (t: TFunction, shift: ShiftT): string => {
  if (shift.type === shiftTypes.absence) {
    return t(`page.shifts.content.${shift.percent === 100 ? 'fullDay' : 'halfDay'}`)
  }

  return ''
}

export const getHours = (t: TFunction, shift: ShiftT): string => {
  if (shift.type === shiftTypes.absence) {
    return getAbsenceText(t, shift)
  }

  const parsedTimeFrom = parseShiftTime(shift.from || '')
  const parsedTimeUntil = parseShiftTime(shift.until || '')

  const dateFromValid = isValidDate(parsedTimeFrom)
  const dateUntilValid = isValidDate(parsedTimeUntil)
  if (dateFromValid && dateUntilValid) {
    return `${getFormattedFromDate(parsedTimeFrom, hourAndMinute)} - ${getFormattedFromDate(
      parsedTimeUntil,
      hourAndMinute,
    )}`
  }

  if (dateFromValid && !dateUntilValid) {
    return `${getFormattedFromDate(parsedTimeFrom, hourAndMinute)} - ${t('page.shifts.content.notSpecified')}`
  }

  if (!dateFromValid && dateUntilValid) {
    return `${t('page.shifts.content.notSpecified')} - ${getFormattedFromDate(parsedTimeUntil, hourAndMinute)}`
  }

  return t('page.shifts.content.notSpecified')
}

export const dayContainsUnacknowledgedUpdatedShifts = (shifts: PlannedShiftT[]): boolean =>
  Object.values(shifts)
    .map((shift) => shift?.updated === ShiftUpdateType.updated)
    .includes(true)

export const dayContainsAcknoledgedUpdatedShifts = (shifts: PlannedShiftT[]): boolean =>
  Object.values(shifts)
    .map((shift) => shift?.updated === ShiftUpdateType.acknowledged)
    .includes(true)

export const getDashboardDates = (): QueryDates => ({
  start: getFormattedFromDate(newDateWithoutTime(), shiftDateFormat),
  end: getFormattedFromDate(addDays(newDateWithoutTime(), 7), shiftDateFormat),
})

export const getDashboardNextShiftDates = (): QueryDates => {
  const currentDateTime = newDateWithoutTime()

  if (currentDateTime.getHours() > 19) {
    return {
      start: getFormattedFromDate(addDays(newDateWithoutTime(), 1), shiftDateFormat),
      end: getFormattedFromDate(addDays(newDateWithoutTime(), 2), shiftDateFormat),
    }
  }

  return {
    start: getFormattedFromDate(newDateWithoutTime(), shiftDateFormat),
    end: getFormattedFromDate(addDays(newDateWithoutTime(), 1), shiftDateFormat),
  }
}

export const canViewPreviousMonth = (currentMonth: Date): boolean =>
  differenceInDays(newDateWithoutTime(), startOfMonth(currentMonth)) < daysInMonthWhenLastMonthCanBeViewed

export const pastMonthsToRender = (swipeIndex: number, baseDate: string): number => {
  if (
    differenceInDays(newDateWithoutTime(), startOfMonth(newDateWithoutTime(baseDate))) <
      daysInMonthWhenLastMonthCanBeViewed &&
    swipeIndex === 0
  ) {
    return 1
  }

  if (swipeIndex > 2) {
    return 2
  }

  return swipeIndex > 0 ? swipeIndex : 0
}

export const getShiftListStartDate = ({ sliderIndex, baseDate, month }: DateGetterT): string =>
  sliderIndex === 0 ? baseDate : getFormattedFromDate(month, shiftDateFormat)

export const getShiftListEndDate = ({ month }: Pick<DateGetterT, 'month'>): string =>
  getFormattedFromDate(endOfMonth(month), shiftDateFormat)

export const getEffectiveShiftsStartDate = ({ month }: Pick<DateGetterT, 'month'>): string =>
  getFormattedFromDate(month, shiftDateFormat)

export const getEffectiveShiftsEndDate = ({ baseDate }: Pick<DateGetterT, 'baseDate'>) => {
  const [y, m, d] = baseDate.split('-')
  const yesterday = parseInt(d) - 1

  if (yesterday > 0) {
    return `${y}-${m}-${yesterday.toLocaleString('en', { minimumIntegerDigits: 2 })}`
  }

  return baseDate
}

export const getManualShiftLabel = ({ isManual, prefix, text }: { isManual: boolean; prefix?: string; text: string }) =>
  isManual ? `*${prefix ? `${prefix} ` : ''}${text}` : text

export const getDateString = (date: Date, locale: Locale | undefined, isShiftToday: boolean, t: TFunction) => {
  const dateString = getFormattedFromDate(date, uiDateFormat, locale)

  if (isShiftToday) {
    return `${t('page.shifts.content.today')}, ${dateString}`
  }

  return `${getFormattedFromDate(date, 'EEEE', locale)}, ${dateString}`
}

export const getDailyShiftType = ({ tenantShifts }: { tenantShifts: ShiftsByTenantCCT }) =>
  tenantShifts.shifts.some((s) => s.type === shiftTypes.plannedShift) ? shiftTypes.plannedShift : shiftTypes.absence

export const isPlannedShift = (shift: PlannedShiftT | EffectiveShiftT): shift is PlannedShiftT => {
  return (shift as PlannedShiftT).isManual !== undefined
}

export const containsValidatedEffectiveShifts = ({ shifts }: { shifts: EffectiveShiftT[] }) =>
  shifts.some((shift) => shift.validated)

export const getFirstNShifts = ({
  shiftsByDate,
  numberOfShifts,
}: {
  shiftsByDate: ShiftsByDateT[]
  numberOfShifts: number
}): ShiftsByDateT[] => {
  const indexesForFirstThreeShifts = shiftsByDate.reduce(
    (accByDate, byDate, dateIndex) => {
      if (accByDate.shiftNumber < numberOfShifts) {
        return byDate.tenants.reduce((accByTenant, _byTenant, tenantIndex) => {
          if (accByTenant.shiftNumber < numberOfShifts) {
            return { tenantIndex, dateIndex, shiftNumber: accByTenant.shiftNumber + 1 }
          }
          return accByTenant
        }, accByDate)
      }
      return accByDate
    },
    { dateIndex: 0, tenantIndex: 0, shiftNumber: 0 },
  )

  const datesToDisplay = shiftsByDate.filter((_date, index) => index <= indexesForFirstThreeShifts.dateIndex)
  return datesToDisplay
}

export const canViewDialog = ({ shift, personIds }: { shift: PlannedShiftT; personIds: number[] }): boolean => {
  if (shift.type !== shiftTypes.absence) {
    return true
  }

  return personIds.includes(shift.personId)
}

export const getDayFromIndex = (baseDate: string, index: number): string => {
  const currentDate = newDateWithoutTime(baseDate, shiftDateFormat)
  const updatedDate = addDays(currentDate, index)
  return getFormattedFromDate(updatedDate, shiftDateFormat)
}

// planned shift format: 10:20:30
// effective shift format: 2023-01-01T10:20:30Z
// desired output: 10:20
export const getFromUntil = (shift: PlannedShiftT | EffectiveShiftT, t: TFunction, effective = false) => {
  const start = effective ? 11 : 0
  const end = effective ? -4 : -3
  if (shift.from && shift.until) {
    return `${shift.from.slice(start, end)} - ${shift.until.slice(start, end)}`
  }

  if (shift.from && !shift.until) {
    return `${shift.from.slice(start, end)} - ${t('page.shifts.content.notSpecified')}`
  }

  if (!shift.from && shift.until) {
    return `${t('page.shifts.content.notSpecified')} - ${shift.until.slice(start, end)}`
  }

  return t('page.shifts.content.notSpecified')
}

export const getTimeType = (shift: PlannedShiftT, t: TFunction) =>
  shift.isManual
    ? `*${t('shift.manual.prefix')} ${t(`page.shifts.content.${shift.timeType ?? shift.type}`)}`
    : t(`page.shifts.content.${shift.timeType ?? shift.type}`)

const defaultShortDescription = 'default-shift-key'

export const groupShiftsByShortDescription = (shifts: PlannedShiftT[]): PlannedShiftT[][] =>
  Array.from(
    shifts
      .reduce((acc, shift, i) => {
        if (shift.type === shiftTypes.absence) return acc.set(`absence${i}`, [shift])
        const shortDescription = shift.shortDescription ? shift.shortDescription : defaultShortDescription
        const currentShifts = acc.get(shortDescription)
        if (currentShifts) return acc.set(shortDescription, [...currentShifts, shift])
        return acc.set(shortDescription, [shift])
      }, new Map<string, PlannedShiftT[]>())
      .values(),
  )
