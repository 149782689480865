import React from 'react'
import Svg from 'components/Svg'
import palette from 'utils/theme/palette'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const GroupS: React.FC<IconPropsT> = ({ fill = palette.primary.main }) => {
  return (
    <Svg size={ICON_SIZE_S} viewBox="0 0 10 3">
      <path
        d="M8 1.33398C8 1.88627 8.44772 2.33398 9 2.33398C9.55228 2.33398 10 1.88627 10 1.33398C10 0.7817 9.55228 0.333984 9 0.333984C8.44772 0.333984 8 0.7817 8 1.33398Z"
        fill={fill}
      />
      <path
        d="M4 1.33398C4 1.88627 4.44772 2.33398 5 2.33398C5.55228 2.33398 6 1.88627 6 1.33398C6 0.7817 5.55228 0.333984 5 0.333984C4.44772 0.333984 4 0.7817 4 1.33398Z"
        fill={fill}
      />
      <path
        d="M-4.37114e-08 1.33398C-6.78525e-08 1.88627 0.447715 2.33398 1 2.33398C1.55228 2.33398 2 1.88627 2 1.33398C2 0.7817 1.55228 0.333984 1 0.333984C0.447715 0.333984 -1.95703e-08 0.7817 -4.37114e-08 1.33398Z"
        fill={fill}
      />
    </Svg>
  )
}

export default GroupS
