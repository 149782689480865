import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const ArrowUpS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M2.7 10.78a.67.67 0 0 1 0-.94l5.1-5.1a.67.67 0 0 1 .95 0l5.1 5.1c.25.26.25.68 0 .94l-.48.48a.67.67 0 0 1-.94 0L8.27 7.1l-4.15 4.16a.67.67 0 0 1-.94 0l-.47-.48Z"
      />
    </Svg>
  )
}

export default ArrowUpS
