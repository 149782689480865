import React, { useContext } from 'react'
import Card from 'components/Basics/Card'
import { TimelineItemContentFormT } from 'components/Timeline/types'
import { ArrowRightThinS, FormL } from 'icons'
import { Box, IconButton, Typography } from '@mui/material'
import theme from 'utils/theme'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { ShareUserDataFormContext } from 'pages/Tasks/ShareUserData/Details'
import { classes } from './styles'

const TimeLineItemForm: React.FC<TimelineItemContentFormT> = ({ tenantName, clickable }) => {
  const { t } = useFavurTranslation()
  const { setShowForm } = useContext(ShareUserDataFormContext)

  return (
    <Card
      onClick={() => {
        if (clickable) {
          setShowForm(true)
        }
      }}
    >
      <IconButton sx={classes.iconButton} disableRipple>
        <Box sx={classes.iconContainer}>
          <Box sx={classes.icon}>
            <FormL stroke={theme.palette.primary.main} />
          </Box>
        </Box>
        <Box sx={classes.contentContainer}>
          <Box sx={classes.textContainer}>
            <Typography variant="subtitle1" sx={classes.title}>
              {t('shareUserData.timeline.formCta')}
            </Typography>
            {tenantName && (
              <Typography variant="caption" sx={classes.subtitle}>
                {tenantName}
              </Typography>
            )}
          </Box>
          {clickable && (
            <div>
              <ArrowRightThinS />
            </div>
          )}
        </Box>
      </IconButton>
    </Card>
  )
}

export default TimeLineItemForm
