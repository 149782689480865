import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { isToday } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { ShiftsByTenantCCT } from 'types'
import { CalendarNoDataM } from 'icons'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import { capitalize } from 'utils/string'
import { useAppStatesContext } from 'services/AppStates'
import routes from 'services/RoutesProvider/routes'
import { DASHBOARD_NEXT_SHIFT_DATE } from 'constants/shift'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { classes } from './styles'
import Badges from './Badges'

interface INextShiftProps {
  date: string
  shiftsByTenants: ShiftsByTenantCCT[]
  isMultitenant: boolean
  shiftUpdates: TaskT<ShiftUpdateTaskT>[]
}

const NextShift: React.FC<INextShiftProps> = ({ date, shiftsByTenants, isMultitenant, shiftUpdates }) => {
  const { t, locale } = useFavurTranslation()
  const history = useHistory()
  const { set } = useAppStatesContext()

  const dateIsToday = useMemo(() => isToday(newDateWithoutTime(date)), [date])

  const { dayOfWeek, dayOfMonth } = useMemo(() => {
    const dateObject = newDateWithoutTime(date)
    const weekDayName = capitalize(getFormattedFromDate(dateObject, 'EEEE', locale))
    return { dayOfWeek: weekDayName, dayOfMonth: dateObject.getDate() }
  }, [date, locale])

  const shiftCards = useMemo(() => {
    if (shiftsByTenants.length === 0) {
      return (
        <Box sx={classes.noData}>
          <CalendarNoDataM />
          <Typography variant="body2" sx={classes.noDataText}>
            {t('page.dashboard.content.noUseShift')}
          </Typography>
        </Box>
      )
    }

    if (isMultitenant) {
      return shiftsByTenants.map((tenant, index) => (
        <Box sx={classes.tenantAndShiftsBoxList} key={`tenant-${tenant.tenantId}-${dayOfMonth}`}>
          <Box sx={classes.tenantAndShiftsBox}>
            <Typography variant="caption" sx={classes.tenantName}>
              {tenant.tenantName}
            </Typography>
            <Badges tenant={tenant} shiftUpdates={shiftUpdates} />
          </Box>
          {index + 1 < shiftsByTenants.length && <DivisionLine />}
        </Box>
      ))
    }

    return <Badges tenant={shiftsByTenants[0]} shiftUpdates={shiftUpdates} />
  }, [shiftsByTenants, t, dayOfMonth, isMultitenant, shiftUpdates])

  return (
    <Box sx={classes.card}>
      <Card
        data-testid={`dashboard-shifts__card--${dayOfMonth}`}
        onClick={() => {
          set(DASHBOARD_NEXT_SHIFT_DATE, date)
          history.push(routes.shifts)
        }}
      >
        <Typography variant="overline" sx={classes.dayOfWeek}>
          {dayOfWeek}
        </Typography>
        <Typography variant="h3" color={dateIsToday ? 'secondary' : 'primary'} sx={classes.dayOfMonth}>
          {dayOfMonth}
        </Typography>
        <Box>{shiftCards}</Box>
      </Card>
    </Box>
  )
}

export default NextShift
