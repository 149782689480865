import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { RoleViewT, TaskCountsT } from 'types'
import { cardVariants } from 'constants/componentsBasics'
import { ROLE_VIEWS } from 'constants/roles'
import Card from 'components/Basics/Card'
import { SimpleLoading } from 'components/LoadingIcon'
import { ManagerTasksL, TaskCenterL } from 'icons'
import { classes } from './styles'

interface IDashboardTasksManager {
  loading: boolean
  onClick: (role: RoleViewT) => void
  counts?: TaskCountsT
}

const DashboardTasksManager: React.FC<IDashboardTasksManager> = ({ loading, onClick, counts }) => {
  const { t } = useFavurTranslation()

  return (
    <>
      <Box
        sx={[classes.taskBox]}
        onClick={() => {
          onClick(ROLE_VIEWS.office)
        }}
      >
        <Card variant={cardVariants.office}>
          {loading || !counts ? (
            <SimpleLoading small smallContainer />
          ) : (
            <Box sx={classes.innerContainer}>
              <ManagerTasksL />
              <Box sx={classes.countContainer}>
                <Typography variant="h3">{counts.secondView}</Typography>
              </Box>
              <Typography variant="body2">{t('page.dashboard.tasks.manager')}</Typography>
            </Box>
          )}
        </Card>
      </Box>

      <Box
        sx={[classes.taskBox]}
        onClick={() => {
          onClick(ROLE_VIEWS.frontliner)
        }}
      >
        <Card>
          {loading || !counts ? (
            <SimpleLoading small smallContainer />
          ) : (
            <Box sx={classes.innerContainer}>
              <TaskCenterL />
              <Box sx={classes.countContainer}>
                <Typography variant="h3">{counts.todo}</Typography>
              </Box>
              <Typography variant="body2">{t('page.dashboard.tasks.frontliner')}</Typography>
            </Box>
          )}
        </Card>
      </Box>
    </>
  )
}

export default DashboardTasksManager
