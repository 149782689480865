import React, { useEffect, useMemo } from 'react'
import { IonApp, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import { ReactQueryConfigProvider } from 'react-query'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from '@mui/x-date-pickers'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { ErrorBoundary } from 'react-error-boundary'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'
import { apiPath, getAPIURL } from 'constants/environment'
import ThemeProvider from 'components/ThemeProvider'
import Services from 'services'
import RoutesProvider from 'services/RoutesProvider'
import AxiosInterceptor from 'services/AxiosInterceptor'
import useFavurTranslation from 'hooks/useFavurTranslation'
import ErrorPage from 'components/Splash/Error'
import UpdateApp from 'components/Splash/UpdateApp'
import { absencePlannerTypePolicies } from 'pages/AbsencePlanner/types'

setupIonicReact({
  mode: 'md',
})

const App: React.FC = () => {
  const client = useMemo(
    () =>
      new ApolloClient({
        link: createHttpLink({
          credentials: 'include',
          uri: `${getAPIURL()}${apiPath}`,
        }),
        cache: new InMemoryCache({
          typePolicies: {
            ...absencePlannerTypePolicies,
          },
        }),
      }),
    [],
  )
  const queryConfig = {
    queries: {
      refetchOnWindowFocus: false,
    },
  }
  const { locale } = useFavurTranslation()

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      SplashScreen.hide()
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)
    }
  }, [])

  return (
    <IonApp>
      <ThemeProvider>
        <ApolloProvider client={client}>
          {/* implicit children problem, must upgrade/change this dependency */}
          {/* @ts-ignore */}
          <ReactQueryConfigProvider config={queryConfig}>
            <Services>
              <IonReactRouter>
                <AxiosInterceptor>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                    <ErrorBoundary FallbackComponent={ErrorPage}>
                      <UpdateApp>
                        <RoutesProvider />
                      </UpdateApp>
                    </ErrorBoundary>
                  </LocalizationProvider>
                </AxiosInterceptor>
              </IonReactRouter>
            </Services>
          </ReactQueryConfigProvider>
        </ApolloProvider>
      </ThemeProvider>
    </IonApp>
  )
}

export default App
