import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import theme from 'utils/theme'
import { IdCardM } from 'icons'
import routes from 'services/RoutesProvider/routes'
import CardCarousel, { CardCarouselCardT } from 'components/CardCarousel'
import { PersonTenant } from './types'

export const getCardFromPersonTenant = (personTenant: PersonTenant, onClick: (favurUuid: string) => void) => ({
  key: personTenant.personFavurUuid,
  label: personTenant.tenantName,
  onClick: () => onClick(personTenant.personFavurUuid),
  icon: <IdCardM stroke={theme.palette.primary.main} />,
})

interface IEmployeeCardCarouselProps {
  personTenantList: PersonTenant[]
}

const EmployeeCardCarousel: React.FC<IEmployeeCardCarouselProps> = ({ personTenantList }) => {
  const history = useHistory()

  const cardOnClick = useCallback((favurUuid: string) => history.push(`${routes.employeeCard}?person=${favurUuid}`), [
    history,
  ])

  const cards: CardCarouselCardT[] = useMemo(
    () => personTenantList.map((personTenant) => getCardFromPersonTenant(personTenant, cardOnClick)),
    [cardOnClick, personTenantList],
  )

  return <CardCarousel cards={cards} dataTestid="employee-card_dashboard-section" />
}
export default EmployeeCardCarousel
