import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PinPointS } from 'icons'
import { classes } from './styles'

const ConflictsSmall: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <Box sx={classes.containerSmall}>
      <IconButton onClick={() => {}} disableFocusRipple disableRipple disableTouchRipple sx={classes.pinPointButton}>
        <PinPointS />
      </IconButton>
      <Typography sx={classes.headerSmall} variant="caption">
        {t('component.absenceRequestConflicts.header.title')}
      </Typography>
    </Box>
  )
}

export default ConflictsSmall
