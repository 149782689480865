import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const FilterM: React.FC<IconPropsT> = ({ stroke }) => {
  return (
    <Svg size={ICON_SIZE_M} fill="none">
      <path
        // @ts-ignore
        stroke={stroke || theme.palette.primary[900]}
        strokeWidth="2"
        strokeLinecap="round"
        d="M17 5h2M13 19h6M4 5h8M4 19h4M16 7V3M12 21v-4M7 12H4M19 12h-7M8 10v4"
      />
    </Svg>
  )
}

export default FilterM
