import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const ArrowLeftThinM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2796 20.7811c-.2933.2925-.7682.2918-1.0607-.0015l-7.83889-7.861a1.3017 1.3017 0 0 1-.28133-.4217A1.30018 1.30018 0 0 1 7 12c0-.1705.03351-.3393.09868-.4969a1.29935 1.29935 0 0 1 .28095-.4214l7.83927-7.86128c.2925-.29331.7674-.29398 1.0607-.00149.2933.29248.294.76735.0015 1.06065L8.5823 12l7.6988 7.7204c.2925.2933.2918.7682-.0015 1.0607Z"
      />
    </Svg>
  )
}

export default ArrowLeftThinM
