import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { PushNotifications } from '@capacitor/push-notifications'
import { IonContent } from '@ionic/core/components/ion-content'
import Page from 'components/Page'
import ShiftsWeeklyCalendar from 'components/ShiftsList/components/ShiftsWeeklyCalendar'
import useShiftsContext from 'hooks/useShiftsContext'
import useStateBackLink from 'hooks/useStateBackLink'
import useTutorials from 'hooks/useTutorials'
import routes from 'services/RoutesProvider/routes'
import theme from 'utils/theme'
import { DASHBOARD_NEXT_SHIFT_DATE, defaultDailyShiftDates } from 'constants/shift'
import { isNativeNoOverride } from 'utils/platform'
import useShiftDaysReducer from 'components/ShiftsList/reducer'
import usePSN from 'hooks/usePushNotifications'
import { useAppStatesContext } from 'services/AppStates'
import { TeamplanDetailContext } from './TeamplanDetailPage/context'
import ShiftsPageHeader from './components/ShiftsPageHeader'
import { SideMenuContext } from './components/SideMenu/context'
import useSideMenuData from './useSideMenuData'
import ShiftsPageBody from './components/ShiftsPageBody'
import DateHeader from './TeamplanDetailPage/DateHeader'
import type { TeamPlanTeamDataT } from './TeamplanDetailPage/types'

const ShiftsPage: React.FC = () => {
  const { start: startContext, end: endContext, setShiftsContext } = useShiftsContext()
  const [loadingGoToDay, setLoadingGoToDay] = useState(false)
  const getShiftDates = useCallback((start: string, end: string) => {
    return start && end ? { start, end } : defaultDailyShiftDates
  }, [])

  const { get, clear } = useAppStatesContext()

  const shiftDates = getShiftDates(startContext, endContext)

  const currentDate = get(DASHBOARD_NEXT_SHIFT_DATE) ?? shiftDates.start

  const sideMenuData = useSideMenuData()
  const { pushActiveOnDevice } = usePSN({ refresh: false })
  const [selectedTeamData, setSelectedTeamData] = useState<TeamPlanTeamDataT>()

  const history = useHistory()
  const { beenShownBefore, isReady } = useTutorials()
  const { setBackLink } = useStateBackLink()

  const {
    state,
    handleIndexChange,
    handleIndexWeekChange,
    goToToday: reducerGoToToday,
    goToDay: reducerGoToDay,
    handleIndexMonthChange,
    toggleCalendarOpen,
    closeCalendar,
  } = useShiftDaysReducer(shiftDates, currentDate)

  const goToDay = useCallback(
    (date: Date) => {
      setLoadingGoToDay(true)
      reducerGoToDay(date)
    },
    [reducerGoToDay],
  )

  const goToToday = useCallback(() => {
    setLoadingGoToDay(true)
    reducerGoToToday()
  }, [reducerGoToToday])

  useEffect(() => {
    return function cleanup() {
      setShiftsContext({ start: '', end: '' })
    }
  }, [setShiftsContext])

  useEffect(() => {
    if (isNativeNoOverride() && pushActiveOnDevice) {
      PushNotifications.removeAllDeliveredNotifications()
    }
  }, [pushActiveOnDevice])

  useEffect(() => {
    clear(DASHBOARD_NEXT_SHIFT_DATE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // @ts-ignore
    const mainParent = document.getElementById('ionContent') as IonContent
    mainParent?.scrollToTop()
  }, [state.currentDay])

  useEffect(() => {
    if (!isReady) return
    if (!beenShownBefore('shiftplan')) {
      setBackLink(routes.shifts)
      history.push(`${routes.tutorial}/shiftplan`)
    }
  }, [history, isReady, beenShownBefore, setBackLink])

  return (
    <SideMenuContext.Provider value={sideMenuData}>
      <TeamplanDetailContext.Provider value={{ selectedTeamData, setSelectedTeamData }}>
        <Page
          header={<ShiftsPageHeader />}
          noPadding
          removeLastMargin
          hideNativeNavigation={state.calendarOpen}
          backgroundColor={state.calendarOpen ? theme.palette.common.white : undefined}
        >
          {!selectedTeamData ? (
            <ShiftsWeeklyCalendar
              state={state}
              goToDay={goToDay}
              goToToday={goToToday}
              closeCalendar={closeCalendar}
              toggleCalendarOpen={toggleCalendarOpen}
              handleIndexWeekChange={handleIndexWeekChange}
            />
          ) : (
            <DateHeader baseDate={state.currentDay} goToDay={goToDay} />
          )}
          <ShiftsPageBody
            state={state}
            goToDay={goToDay}
            closeCalendar={closeCalendar}
            handleIndexChange={handleIndexChange}
            handleIndexMonthChange={handleIndexMonthChange}
            loadingGoToDay={loadingGoToDay}
            finishLoadingGoToDay={() => setLoadingGoToDay(false)}
          />
        </Page>
      </TeamplanDetailContext.Provider>
    </SideMenuContext.Provider>
  )
}

export default ShiftsPage
