import React, { memo } from 'react'
import { Box } from '@mui/material'
import useSelectedStatesContext from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { gridClasses } from '../styles'
import MonthlyBar from '../../MonthlyBar'

interface ICalendarRowProps {
  shownMonthsIndexes: number[]
  baseDate: Date
}

const CalendarRow: React.FC<ICalendarRowProps> = ({ shownMonthsIndexes, baseDate }) => {
  const { selectedDays, selectedTaskUuid } = useSelectedStatesContext()
  return (
    <Box sx={[gridClasses.monthlyBarContainer]}>
      {shownMonthsIndexes.map((index) => (
        <MonthlyBar
          baseDate={baseDate}
          index={index}
          key={`${index}_monthly-bar`}
          selectedDays={selectedDays}
          selectedTaskUuid={selectedTaskUuid}
        />
      ))}
    </Box>
  )
}

export default memo(CalendarRow)
