import theme from 'utils/theme'
import palette from 'utils/theme/palette'

export const styles = {
  title: {
    color: palette.grey[600],
    margin: '16px 0',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  contentCard: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
    borderColor: palette.primary[50],
    marginBottom: '8px',
  },
  contentCardText: {
    width: '100%',
  },
  contentCardLink: {
    textDecoration: 'none',
  },
  tabLabel: {
    display: 'flex',
    gap: theme.spacing(0.375),
    alignItems: 'center',
  },
  eventGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.0),
  },
  eventDateGroup: {
    fontWeight: 600,
    size: 18,
    lineHeight: theme.spacing(1.5),
    color: palette.text.primary,
  },
  eventsGroupList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  noContentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  noContentText: {
    weight: 700,
    size: theme.spacing(1),
    color: '#141A33',
  },
  noContentIconText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}
