import { createTheme } from '@mui/material/styles'
import MuiButton from './MuiButton'
import MuiIconButton from './MuiIconButton'
import MuiInput from './MuiInput'
import MuiSelect from './MuiSelect'
import MuiToggleButton from './MuiToggleButton'
import MuiToggleButtonGroup from './MuiToggleButtonGroup'
import palette from './palette'
import shadows from './shadows'
import MuiAlert from './MuiAlert'

const theme = createTheme({
  shadows,
  shape: {
    borderRadius: 4,
  },
  spacing: 16,
  typography: {
    fontFamily: 'FiraSansCondensed, sans-serif',
    h1: {
      fontFamily: 'Signika, sans-serif',
      fontWeight: 'normal',
      fontSize: 33,
      lineHeight: 1.25,
      letterSpacing: -0.4,
    },
    h2: {
      fontFamily: 'Signika, sans-serif',
      fontWeight: 'bold',
      fontSize: 23,
      lineHeight: 1.25,
      letterSpacing: 0.2,
    },
    h3: {
      fontFamily: 'FiraSans, sans-serif',
      fontSize: 19,
      lineHeight: 1.25,
      letterSpacing: 0.3,
    },
    subtitle1: {
      fontFamily: 'FiraSans, sans-serif',
      fontSize: 16,
      lineHeight: 1.25,
      letterSpacing: 0.2,
    },
    subtitle2: {
      fontFamily: 'FiraSans, sans-serif',
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: 1.25,
      letterSpacing: 0.4,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: 0.3,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.5,
      letterSpacing: 0.2,
    },
    button: {
      fontFamily: 'FiraSans, sans-serif',
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: 1.25,
      letterSpacing: 0.9,
    },
    caption: {
      fontSize: 12,
      lineHeight: 1.25,
      letterSpacing: 0.2,
    },
    overline: {
      fontWeight: 'bold',
      fontSize: 10,
      lineHeight: 1.25,
      letterSpacing: 0.3,
      textTransform: 'none',
    },
  },
  palette,
  components: {
    MuiButtonBase: {
      defaultProps: {
        centerRipple: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '*, *::before, *::after': {
            transition: 'none !important',
            animation: 'none !important',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
      },
    },
    MuiButton,
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 12,
        },
        paperScrollPaper: {
          maxHeight: 'calc(100% - 24px)',
          boxSizing: 'border-box',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            paddingTop: 0,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: palette.text.secondary,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 2,
          paddingLeft: 12,
        },
      },
    },
    MuiIconButton,
    MuiInput,
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: 1.25,
        },
        formControl: {
          transform: 'translate(12px, 20px) scale(1)',
        },
        shrink: {
          transform: 'translate(14px, 1.5px) scale(0.75)',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 6,
        },
      },
    },
    MuiSelect,
    // remove when this is updated in lab library: https://github.com/mui-org/material-ui/issues/19427
    // @ts-ignore
    MuiToggleButton,
    // @ts-ignore
    MuiToggleButtonGroup,
    MuiTouchRipple: {
      styleOverrides: {
        rippleVisible: {
          transform: 'scale(1.5)',
        },
        // @ts-ignore
        '@keyframes enter': {
          '100%': {
            transform: 'scale(1.5)',
          },
        },
        '@keyframes pulsate': {
          '0%': {
            transform: 'scale(1.5)',
          },
          '50%': {
            transform: 'scale(0.92)',
          },
          '100%': {
            transform: 'scale(1.5)',
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        daySelected: {
          backgroundColor: palette.primary.main,
          '&:focus': {
            backgroundColor: palette.primary.main,
          },
        },
      },
    },
    MuiAlert,
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontFamily: 'FiraSans, sans-serif',
          fontWeight: 500,
          letterSpacing: '0.2px',
        },
      },
    },
  },
})

export default theme
