import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { isNativeNoOverride } from 'utils/platform'
import { BoxedLoading } from 'components/LoadingIcon'
import UserSettingsSection from '../../../../components/Section'
import PinNumber from './PinNumber'
import BiometricOption from './BiometricOption'
import PhoneNumber from './PhoneNumber'

interface ILoginSettings {
  pinStatusLoading: string
  pinStatus: string
}

const LoginSettings: React.FC<ILoginSettings> = ({ pinStatusLoading, pinStatus }) => {
  const { t } = useFavurTranslation()

  return (
    <UserSettingsSection title={t('page.userSettings.profile.loginSettings')}>
      {pinStatusLoading !== 'success' ? (
        <BoxedLoading />
      ) : (
        <>
          <PhoneNumber pinStatus={pinStatus} />
          <PinNumber pinStatus={pinStatus} />
          {isNativeNoOverride() && <BiometricOption />}
        </>
      )}
    </UserSettingsSection>
  )
}

export default LoginSettings
