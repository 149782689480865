import React from 'react'
import EventCard from 'pages/Cms/EventsList/EventCard'
import { Box } from '@mui/material'
import classes from './styles'
import type { EventNotificationProps } from './types'

const EventNotification: React.FC<EventNotificationProps> = ({ date, events }) => {
  if (!events.length) {
    return null
  }

  return (
    <Box sx={classes.container}>
      {events?.map((event) => (
        <EventCard key={event.uuid} content={event} date={date} />
      ))}
    </Box>
  )
}

export default EventNotification
