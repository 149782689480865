import React, { useCallback, useState } from 'react'
import settings from 'utils/settings'
import { FeatureFlagType } from 'constants/featureFlags'
import FeatureFlagContext, { FeatureFlagsType, IFeatureFlagContextType } from './context'

interface Props {
  children?: React.ReactNode
}

const FeatureFlagService: React.FC<Props> = ({ children }) => {
  const [, setFeatureFlags] = useState({} as FeatureFlagsType)

  const featureFlags = useCallback(
    (name: FeatureFlagType, value: boolean | string | void) => {
      if (typeof value !== 'undefined') {
        settings(name, value)
        return setFeatureFlags((curr) => {
          return { ...curr, [name]: value }
        })
      }
      return settings(name)
    },
    [setFeatureFlags],
  )

  return (
    <FeatureFlagContext.Provider value={{ settings: featureFlags } as IFeatureFlagContextType}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export default FeatureFlagService
