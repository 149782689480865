import { useCallback, useState } from 'react'
import { NotificationT } from 'types'
import { notificationsPaginatedQuery } from 'pages/Notifications/queries'
import { NotificationsPaginatedReturnT } from 'pages/Notifications/types'
import { useJamesApolloQuery } from './useJamesApolloQuery'

const LIMIT = 10

const useNotificationsPaginated = (isManager: boolean) => {
  const [offset, setOffset] = useState<number>(0)
  const [notifications, setNotifications] = useState<NotificationT[]>([])
  const [notificationsLoading, setNotificationsLoading] = useState(true)
  const { data, loading, refetch } = useJamesApolloQuery<NotificationsPaginatedReturnT>(notificationsPaginatedQuery, {
    variables: {
      offset,
      limit: LIMIT,
      isManager,
    },
    onCompleted(res) {
      const newNotifications = res.notificationsPaginated.list
      setNotifications([...notifications, ...newNotifications])
      setNotificationsLoading(false)
    },
    fetchPolicy: 'no-cache',
  })
  const totalCount = data?.notificationsPaginated.totalCount
  const hasMoreElements = Boolean(totalCount && notifications && totalCount > notifications?.length)

  const loadMore = useCallback(() => {
    setOffset(notifications.length)
  }, [notifications.length])

  const reload = () => {
    setNotifications([])
    setOffset(0)
    setNotificationsLoading(true)
    refetch()
  }

  return {
    loading: loading || notificationsLoading,
    notifications,
    totalCount,
    hasMoreElements,
    loadMore,
    refetch,
    reload,
  }
}

export default useNotificationsPaginated
