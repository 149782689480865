import React, { useMemo } from 'react'
import { Box, Typography, Chip } from '@mui/material'
import { Locale } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PlannedShiftT } from 'types'
import { ChangeAlertS } from 'icons'
import theme from 'utils/theme'
import { getCardDate } from 'pages/Shifts/utils'
import { ShiftUpdatedValues } from 'pages/Tasks/types'
import ShiftsGroup from './ShiftsGroup'
import { classes } from './styles'

interface IShiftsDayProps {
  date: string
  newShifts?: PlannedShiftT[]
  oldShifts?: PlannedShiftT[]
  showDetails?: boolean
}

const DayShiftUpdates: React.FC<IShiftsDayProps> = ({ date, oldShifts, newShifts, showDetails = true }) => {
  const { t, locale } = useFavurTranslation()

  const formattedDate = useMemo(() => getCardDate(date, locale as Locale), [date, locale])

  const changeType = useMemo(() => {
    const noNewShifts = !newShifts || newShifts.length === 0
    const haveDeletedShifts =
      oldShifts && oldShifts.filter((shift) => shift.updated === ShiftUpdatedValues.deleted).length > 0

    return noNewShifts && haveDeletedShifts ? ShiftUpdatedValues.deleted : ShiftUpdatedValues.updated
  }, [newShifts, oldShifts])

  return (
    <>
      <Box sx={classes.dateContainer}>
        <Typography variant="subtitle2" sx={classes.date} data-testid="shift-udpate-detail__date">
          {formattedDate}
        </Typography>
        {!showDetails && (
          <Chip
            sx={classes.unacknowledgedChip}
            label={
              <Box sx={classes.hiddenContainer}>
                <ChangeAlertS fill={theme.palette.primary.main} />
                <Typography
                  variant="subtitle2"
                  sx={classes.hiddenLabel}
                  data-testid="shift-udpate-detail__label--hidden"
                >
                  {t('page.tasks.shiftUpdate.detail.label.hidden')}
                </Typography>
              </Box>
            }
          />
        )}
      </Box>
      {showDetails && (
        <Box sx={classes.groups}>
          <ShiftsGroup shifts={newShifts} title={t('component.shifthistory.newshifts')} changeType={changeType} />
          <ShiftsGroup shifts={oldShifts} title={t('component.shifthistory.oldshifts')} disabled />
        </Box>
      )}
    </>
  )
}

export default DayShiftUpdates
