import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const ArrowRightM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M9.648 3.646l7.647 7.647a1 1 0 010 1.414l-7.647 7.647a1 1 0 01-1.414 0l-.707-.708a1 1 0 010-1.414L13.76 12 7.527 5.768a1 1 0 010-1.414l.707-.708a1 1 0 011.414 0z"
      />
    </Svg>
  )
}

export default ArrowRightM
