import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const ArrowDownS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M13.84 5.22c.26.26.26.68 0 .94l-5.1 5.1a.67.67 0 0 1-.94 0l-5.1-5.1a.67.67 0 0 1 0-.94l.48-.48a.67.67 0 0 1 .94 0L8.27 8.9l4.16-4.16a.67.67 0 0 1 .94 0l.47.48Z"
      />
    </Svg>
  )
}

export default ArrowDownS
