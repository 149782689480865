import React from 'react'
import { Box, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import BaseDrawer from 'components/BaseDrawer'
import { classes } from './styles'

interface IExplanationDrawerProps {
  open: boolean
  onClose: () => void
}

const ExplanationDrawer: React.FC<IExplanationDrawerProps> = ({ open, onClose }) => {
  const { t } = useFavurTranslation()
  return (
    <BaseDrawer open={open} onClose={onClose} paperSx={classes.paper}>
      <Box sx={classes.content}>
        <Typography variant="body1" sx={classes.title}>
          {t('page.userSettings.tenant.blockingAbsenceExplanationTitle')}
        </Typography>
        <Typography variant="body2">{t('page.userSettings.tenant.blockingAbsenceExplanationBody')}</Typography>
      </Box>
    </BaseDrawer>
  )
}

export default ExplanationDrawer
