import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const CloseM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M18.47 4.47l1.06 1.06c.174.174.193.443.058.638l-.058.07L13.767 12l5.763 5.763c.174.173.193.443.058.637l-.058.07-1.06 1.06c-.174.174-.443.193-.638.058l-.07-.058L12 13.767 6.237 19.53c-.173.174-.443.193-.637.058l-.07-.058-1.06-1.06c-.174-.174-.193-.443-.058-.638l.058-.07L10.233 12 4.47 6.237c-.174-.173-.193-.443-.058-.637l.058-.07 1.06-1.06c.174-.174.443-.193.638-.058l.07.058L12 10.233l5.763-5.763c.173-.174.443-.193.637-.058l.07.058z"
      />
    </Svg>
  )
}

export default CloseM
