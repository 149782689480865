import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { FF_SECURITY_TIMEOUT_FE__BE__ } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import useSessionContext from 'hooks/useSessionContext'
import useJamesMutation from 'hooks/useJamesMutation'
import CenteredBox from 'components/CenteredBox'
import FlashMessagesContext from 'services/FlashMessages/context'
import routes from 'services/RoutesProvider/routes'
import { NumpadWithoutState } from 'components/Numpad'
import PinDisplay from 'components/Numpad/PinDisplay'
import Timer from 'components/Timer'
import Success, { defaultSuccessPageTimeout } from 'components/Success'
import { SMS_CODE_LENGTH, SMS_TIMEOUT } from 'constants/sms'
import ResendSMSDialog from 'pages/EntryPoint/components/ValidateComponent/ResendSMSDialog'
import { classes } from './styles'
import { confirmSmsMutation } from './queries'

const ConfirmSMS: React.FC<{
  hideStepper: (hide: boolean) => void
  goToStep: (stepName: string) => void
}> = ({ hideStepper, goToStep }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { refresh: refreshSession } = useSessionContext()
  const [smsCode, setSmsCode] = useState('')
  const [dialogOpen, setDialogOpen] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const { removeAll } = useContext(FlashMessagesContext)

  const timeout = useFeatureFlag(FF_SECURITY_TIMEOUT_FE__BE__) ? SMS_TIMEOUT.short : SMS_TIMEOUT.long
  const [timerReset, resetTimer] = React.useReducer((reset) => !reset, false)

  const [doConfirmSmsMutation] = useJamesMutation(confirmSmsMutation)

  const onSubmit = useCallback(() => {
    doConfirmSmsMutation({ smsCode }).then(
      (data) => {
        if (data.updateUserPhoneNumber.success) {
          removeAll()
          setShowSuccess(true)
          hideStepper(true)
          refreshSession()
        }
      },
      () => {},
    )
  }, [smsCode, doConfirmSmsMutation, removeAll, hideStepper, refreshSession])

  useEffect(() => {
    if (!showSuccess) return undefined
    const successTimeout = setTimeout(() => {
      history.push(routes.profile)
    }, defaultSuccessPageTimeout)
    return () => {
      clearTimeout(successTimeout)
    }
  }, [showSuccess, history])

  return (
    <>
      {!showSuccess && (
        <CenteredBox sx={[classes.root, classes.spaceBetween]}>
          <Box>
            <Typography aria-label="Confirm sms title" variant="h2" sx={classes.title}>
              {t('page.changePhoneNumber.confirmSMS.title')}
            </Typography>
            <Typography variant="body1" sx={classes.bodyText}>
              {t('page.changePhoneNumber.confirmSMS.body')}
            </Typography>
          </Box>
          <Box>
            <PinDisplay pinLength={SMS_CODE_LENGTH} pin={smsCode} />
            <Box sx={classes.captionContainer}>
              <Timer timeout={timeout} timerReset={timerReset} />
              <ResendSMSDialog
                isOpen={dialogOpen}
                closeDialog={() => setDialogOpen(false)}
                goBackAStep={() => goToStep('NEW_NUMBER')}
                resetTimer={resetTimer}
              />
              <Button
                size="small"
                onClick={() => setDialogOpen(true)}
                sx={classes.resendSMSDialogButton}
                data-testid="change-phone-number_no-sms-received"
              >
                {t('login.validate.button.noCode')}
              </Button>
            </Box>
          </Box>
          <Box>
            <Box>
              <NumpadWithoutState
                numpadType="pin"
                numpadValue={smsCode}
                outputLength={SMS_CODE_LENGTH}
                setNumpadValue={setSmsCode}
                onConfirm={onSubmit}
              />
            </Box>
            <Button
              onClick={() => history.push(routes.profile)}
              sx={classes.cancelButton}
              data-testid="change-phone-number_cancel-enter-sms"
            >
              {t('securitySetup.token.button.cancel')}
            </Button>
          </Box>
        </CenteredBox>
      )}
      {showSuccess && (
        <Success translationKey="page.changePhoneNumber.success.title" dataTestId="pin-set-success-message" />
      )}
    </>
  )
}

export default ConfirmSMS
