import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, Button } from '@mui/material'
import { AuthenticationKind } from 'constants/authentication'
import Page from 'components/Page'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useSessionContext from 'hooks/useSessionContext'
import ContactContent from './Content'
import { contactClasses } from './styles'

const ContactPage: React.FC = () => {
  const { t } = useFavurTranslation()
  const { auth } = useSessionContext()
  const history = useHistory()
  const contactLabel = t('page.contact.page.title')

  return (
    <Page
      header={
        <>
          <Typography variant="h2">{contactLabel}</Typography>
        </>
      }
    >
      {auth > AuthenticationKind.ANONYMOUS ? (
        <ContactContent />
      ) : (
        <Box p={1}>
          <Dialog
            open
            PaperProps={{
              elevation: 6,
              sx: contactClasses.root,
            }}
            hideBackdrop
            actions={
              <Button onClick={() => history.push('/')} color="secondary" size="large" data-testid="close-contact">
                {t('common.dialog.button.close')}
              </Button>
            }
          >
            <Typography variant="h3" color="primary" sx={contactClasses.title} paragraph>
              {contactLabel}
            </Typography>
            <ContactContent anonymous />
          </Dialog>
        </Box>
      )}
    </Page>
  )
}

export default ContactPage
