import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const AlertWarningS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M7.7.03a1.8 1.8 0 0 0-.77.36C6.86.44 5.36 1.93 3.6 3.7 1.09 6.2.36 6.95.29 7.04c-.39.61-.39 1.32 0 1.93.07.1.8.83 3.3 3.34 1.77 1.77 3.27 3.27 3.35 3.32.16.13.43.27.64.33.14.03.18.04.42.04s.28 0 .42-.05c.18-.04.38-.13.55-.24.08-.06.83-.8 3.33-3.3 1.77-1.76 3.27-3.27 3.32-3.34A1.86 1.86 0 0 0 16 7.82c-.01 0-.02 0-.02-.03 0-.1-.08-.37-.14-.5-.14-.3-.02-.17-3.43-3.58A340.59 340.59 0 0 0 9.06.38a1.9 1.9 0 0 0-.64-.32C8.22.01 7.9 0 7.71.03Zm.1 1-.14.07c-.1.05-6.52 6.47-6.57 6.57-.11.22-.11.46 0 .68.05.1 6.47 6.52 6.57 6.57.22.1.46.1.68 0 .1-.05 6.52-6.47 6.57-6.57.11-.22.11-.46 0-.68-.05-.1-6.47-6.52-6.57-6.57l-.15-.06a.95.95 0 0 0-.4 0Zm.05 2.5a.57.57 0 0 0-.33.33c-.01.04-.02.94-.02 2.65 0 2.93 0 2.68.13 2.83.1.12.21.16.37.16s.26-.04.37-.16c.14-.15.13.1.12-2.86V3.86l-.04-.07a.61.61 0 0 0-.23-.23.71.71 0 0 0-.37-.03ZM0 8v.09a1.78 1.78 0 0 0 0-.1Zm7.83 2.51c-.19.04-.4.2-.5.38-.16.3-.1.65.13.88a.7.7 0 0 0 .53.23.7.7 0 0 0 .53-.23.74.74 0 0 0-.08-1.12.93.93 0 0 0-.19-.1.96.96 0 0 0-.42-.04Z"
      />
    </Svg>
  )
}

export default AlertWarningS
