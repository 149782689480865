import Cookies from 'universal-cookie'
import { APPLICATION_ENV } from 'env'
import { getCookiedomain } from 'utils/settings'

const seti18nCookie = (language: string) => {
  const valid3months = 3 * 30 * 24 * 60 * 60
  const i18nCookie = {
    cookie: new Cookies(),
    key: '',
    settings: {},
  }

  if (APPLICATION_ENV === 'LOCAL') {
    i18nCookie.key = 'i18nLanguage'
    i18nCookie.settings = {
      path: '/',
      sameSite: 'lax',
      maxAge: valid3months,
    }
  } else {
    i18nCookie.key = '__Secure-i18nLanguage'
    i18nCookie.settings = {
      secure: true,
      domain: getCookiedomain(),
      path: '/',
      sameSite: 'lax',
      maxAge: valid3months,
    }
  }

  i18nCookie.cookie.set(i18nCookie.key, language, i18nCookie.settings)
}

export default seti18nCookie
