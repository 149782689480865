import React from 'react'
import { Box, Button, SwipeableDrawer, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import Puller from 'components/BaseDrawer/Puller'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import { classes } from './styles'

interface INoAvatarDrawerProps {
  open: boolean
}

const NoAvatarDrawer: React.FC<INoAvatarDrawerProps> = ({ open }) => {
  const history = useHistory()
  const { t } = useFavurTranslation()

  return (
    <SwipeableDrawer
      open={open}
      onOpen={() => {}}
      onClose={() => {
        history.goBack()
      }}
      anchor="bottom"
      disableAutoFocus
      disableDiscovery
      PaperProps={{ sx: classes.paper }}
      data-testid="employee-card_no-avatar-drawer"
    >
      <Puller />
      <Box sx={classes.content}>
        <Box sx={classes.textContainer}>
          <Typography variant="body1" sx={classes.title}>
            {t('employeeCard.noAvatarDrawer.title')}
          </Typography>
          <Typography variant="body2">{t('employeeCard.noAvatarDrawer.body')}</Typography>
        </Box>
        <Box sx={classes.buttonsContainer}>
          <Button
            variant="contained"
            sx={[classes.button, classes.lightButton]}
            onClick={() => {
              history.push(routes.profile)
            }}
          >
            {t('employeeCard.noAvatarDrawer.buttons.toUpload')}
          </Button>
          {/* button to be uncommented when the tutorial is done */}
          {/* <Button variant="text" color="primary" sx={classes.button}>
            {t('employeeCard.noAvatarDrawer.buttons.toTutorial')}
          </Button> */}
        </Box>
      </Box>
    </SwipeableDrawer>
  )
}

export default NoAvatarDrawer
