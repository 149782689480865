import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { addDays, eachDayOfInterval } from 'date-fns'
import useNewAbsenceRequest from 'pages/Tasks/AbsenceRequest/New/useNewAbsenceRequest'
import NewContent from 'pages/Tasks/AbsenceRequest/New/NewContent'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { CreateAbsenceRequestResultT } from 'types'
import { PinPointingSearchValueT } from 'pages/AbsencePlanner/types'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import usePersonsOfUser from 'hooks/usePersonsOfUser'
import { absenceRequestListClasses } from '../styles'
import AbsenceRequestListHeader from '../Header'
import { detailClasses } from '../AbsenceRequestDetail/styles'
import { getDaysBefore } from '../utils'

interface INewAbsenceRequestProps {
  title: string
  subtitle: string
  goBack: () => void
  office?: boolean
  refetchAll: () => void
  hideHeader?: boolean
  drawerFullScreen?: boolean
}

const NewAbsenceRequest: React.FC<INewAbsenceRequestProps> = ({
  title,
  subtitle,
  goBack,
  refetchAll,
  hideHeader = false,
  drawerFullScreen = false,
}) => {
  const { setSelectedTaskUuid, pinPointDateRange } = useSelectedStatesActionsContext()
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { persons: userPersons, loading: loadingUserPersons } = usePersonsOfUser()
  const onSuccess = useCallback(
    (result: CreateAbsenceRequestResultT) => {
      refetchAll()
      setSelectedTaskUuid(result.createAbsence.taskUuid)
      goBack()
    },
    [goBack, refetchAll, setSelectedTaskUuid],
  )
  const props = useNewAbsenceRequest(onSuccess)

  const { formFields } = props
  const {
    personUuid: { value: selectedPersonUuid },
    fromDate: { value: fromDate },
    toDate: { value: toDate },
  } = formFields

  const pinPointAndOpenTask = useCallback(() => {
    if (loadingUserPersons || !selectedPersonUuid) return
    const selectedUserPerson = userPersons?.find((userPerson) => userPerson.favurUuid === selectedPersonUuid)
    const startDate = fromDate ? getFormattedFromDate(fromDate as Date, 'yyyy-MM-dd') : undefined
    const endDate = toDate ? getFormattedFromDate(toDate as Date, 'yyyy-MM-dd') : startDate
    const daysBefore = getDaysBefore(isSmallScreen)
    const firstTaskDay = newDateWithoutTime(startDate)
    const dayToScroll = addDays(firstTaskDay, daysBefore)
    const searchValue = {
      date: dayToScroll,
      personId: `${selectedUserPerson?.id}`,
    } as PinPointingSearchValueT

    const start = newDateWithoutTime(startDate)
    const end = newDateWithoutTime(endDate)
    const daysRequested = eachDayOfInterval({ start, end })

    pinPointDateRange(searchValue, daysRequested)
  }, [fromDate, isSmallScreen, loadingUserPersons, pinPointDateRange, selectedPersonUuid, toDate, userPersons])

  return (
    <Box sx={absenceRequestListClasses.container}>
      {!hideHeader && (
        <Box sx={absenceRequestListClasses.header(false)}>
          <AbsenceRequestListHeader title={title} subtitle={subtitle} goBack={goBack} />
        </Box>
      )}
      <Box
        sx={[
          absenceRequestListClasses.formContainer,
          ...(isSmallScreen ? [absenceRequestListClasses.avoidButtons] : []),
        ]}
      >
        <NewContent
          {...props}
          fullScreen={false}
          drawerSx={!drawerFullScreen ? detailClasses.drawer : {}}
          onClickConflict={!isSmallScreen ? pinPointAndOpenTask : undefined}
          showAbsencePlannerPreview={isSmallScreen}
        />
      </Box>
    </Box>
  )
}

export default NewAbsenceRequest
