import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router'
import { Box, Button, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useSessionContext from 'hooks/useSessionContext'
import SectionTitle from 'components/SectionTitle'
import Card from 'components/Basics/Card'
import Dialog from 'components/Dialog'
import { useMutation } from '@apollo/client'
import { ApiMeResponseT } from 'services/Session/context'
import routes from 'services/RoutesProvider/routes'
import { deleteUserQuery } from '../mutations'
import { classes } from './styles'

type IDeleteUserResponse = {
  deleteUser: ApiMeResponseT
}

interface IDeleteUserProps {
  allowDelete?: boolean
}

const DeleteUser: React.FC<IDeleteUserProps> = ({ allowDelete = false }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { cleanSession } = useSessionContext()
  const [showDialog, setShowDialog] = useState(false)
  const [deleteUser, { loading: loadingDeleteUser }] = useMutation<IDeleteUserResponse>(deleteUserQuery)

  const onDeleteUser = useCallback(async () => {
    const result = await deleteUser()
    cleanSession(result?.data?.deleteUser)
    setShowDialog(false)
    history.push(routes.deleteAccountSuccess)
  }, [cleanSession, deleteUser, history])

  return (
    <>
      <SectionTitle marginLeft={1.0}>{t('label.deleteAccount')}</SectionTitle>

      <Card>
        <Box sx={classes.noticeBlock}>
          <Typography sx={classes.deleteAccountText} variant="body2">
            {t('text.confirmDeleteAccount')}
          </Typography>
          <Box sx={classes.deactivateButtonBlcok}>
            <Button
              sx={classes.deactivateButton}
              size="small"
              color="secondary"
              onClick={() => setShowDialog(true)}
              data-testid="user-settings-profile-button-confirm-deactivate-account"
            >
              {t('button.confirmDeactivateAccount')}
            </Button>
          </Box>
        </Box>
      </Card>

      <Dialog
        sx={classes.deleteUserDialog}
        open={showDialog}
        title={allowDelete ? t('settings.deleteAccount.user.delete.title') : t('label.deleteAccount')}
        actions={
          <Box sx={classes.deleteUserDialogButtons}>
            <Button color="secondary" size="large" onClick={() => setShowDialog(false)} disabled={loadingDeleteUser}>
              {allowDelete
                ? t('settings.deleteAccount.user.delete.button.cancel')
                : t('settings.deleteAccount.user.button.close')}
            </Button>
            {allowDelete && (
              <Button color="secondary" size="large" disabled={loadingDeleteUser} onClick={onDeleteUser}>
                {t('settings.deleteAccount.user.delete.button.confirm')}
              </Button>
            )}
          </Box>
        }
      >
        <Typography variant="body2">
          {allowDelete ? t('settings.deleteAccount.user.delete.body') : t('settings.deleteAccount.user.popup.body')}
        </Typography>
      </Dialog>
    </>
  )
}

export default DeleteUser
