import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { isSameDay } from 'date-fns'
import { groupBy } from 'lodash/fp'
import { dateToISOStringWithoutTime, getWeekDayNamedByLanguage, getWeekDays, newDateWithoutTime } from 'utils/date'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useShifts from 'hooks/useShifts'
import useSessionContext from 'hooks/useSessionContext'
import { ShiftsByDateT } from 'components/ShiftsList/types'
import { PlannedShiftT } from 'types'
import useShiftUpdates from 'pages/Shifts/useShiftUpdates'
import DayNumberContent from './DayNumberContent'
import { classes } from './styles'
import { getTaskIds, prepareShowingShifts } from '../utils'

interface IWeekDaysProps {
  currentDay: Date
  onClickDay: (day: Date) => void
}

const preProcessShifts = (shifts: ShiftsByDateT[]) => {
  const groupedByDay = groupBy(
    'date',
    shifts.map((day) => day.tenants.flatMap((tenant) => tenant.shifts as PlannedShiftT[])).flat(),
  )
  return Object.entries(groupedByDay).reduce((object: { [key: string]: PlannedShiftT[] }, pair) => {
    const [key, value] = pair
    return { ...object, [key]: prepareShowingShifts(value) }
  }, {})
}

const WeekDays: React.FC<IWeekDaysProps> = ({ currentDay, onClickDay }) => {
  const { language } = useFavurTranslation()
  const { personIds } = useSessionContext()
  const weekDays = getWeekDays(currentDay)
  const start = dateToISOStringWithoutTime(weekDays[0])
  const end = dateToISOStringWithoutTime(weekDays[weekDays.length - 1])
  const { shifts, status: statusShifts } = useShifts({ dates: { start, end }, personIds })
  const taskIds = getTaskIds(shifts)
  const { tasks, loading: loadingShiftUpdates } = useShiftUpdates({ taskIds })
  const groupedShifts = useMemo(() => preProcessShifts(shifts), [shifts])
  const loadingShifts = statusShifts === 'loading' || loadingShiftUpdates
  return (
    <Box sx={classes.headerDays}>
      {weekDays.map((day) => {
        const stringDay = dateToISOStringWithoutTime(day)
        return (
          <Box key={stringDay} sx={classes.dayOfWeek}>
            <Box sx={classes.dayOfWeekContainer}>
              <Typography sx={classes.dayOfWeekTitle} variant="subtitle2">
                {getWeekDayNamedByLanguage(day, language)}
              </Typography>
            </Box>
            <DayNumberContent
              day={newDateWithoutTime(stringDay)}
              isCurrentDay={isSameDay(day, currentDay)}
              onClickDay={onClickDay}
              shifts={groupedShifts[stringDay] ?? []}
              shiftUpdates={tasks ?? []}
              loadingShifts={loadingShifts}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default WeekDays
