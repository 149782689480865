import React, { useCallback } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import { getShortNameFromName } from 'utils/person'
import { ROLE_VIEWS } from 'constants/roles'
import useHistoryUtils from 'hooks/useHistoryUtils'
import NotificationTemplate from '../Templates/Notification'
import type { ReceivedUserDataNotificationT } from 'types'
import type { NotificationPropsT } from '../types'

interface IRecieveUserData extends NotificationPropsT {
  notification: ReceivedUserDataNotificationT
}

const ReceivedUserData: React.FC<IRecieveUserData> = (props) => {
  const { t } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()
  const { notification, dismiss } = props
  const handleDetails = useCallback(() => {
    dismiss(notification)
    const { taskUuid } = notification.data
    pushWithRole(`${routes.userDataDetails}/${taskUuid}`, ROLE_VIEWS.office)
  }, [dismiss, notification, pushWithRole])

  const templateProps = {
    ...props,
    title: t('notification.new.sharedata.sent.title', {
      frontlinerNameShort: notification.data.userNameShort ?? getShortNameFromName(notification.data.userName),
    }),
    body: '',
    dismissText: t('component.notification.button.dismiss'),
    detailsText: t('component.notification.button.viewDetails'),
    details: handleDetails,
  }

  return <NotificationTemplate {...templateProps} />
}

export default ReceivedUserData
