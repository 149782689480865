import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

type ISvgProps = SvgIconProps & {
  id?: string
  width?: number | string
  height?: number | string
  size?: number
  fill?: string
  'data-testid'?: string
}

const Svg: React.FC<ISvgProps> = ({ children, fill, width, height, size, viewBox, sx, ...props }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox={viewBox}
      inheritViewBox={!viewBox}
      sx={{ width: width || size, height: height || size, fill: fill || 'currentcolor', ...sx }}
      {...props}
    >
      {children}
    </SvgIcon>
  )
}

export default Svg
