import theme from 'utils/theme'

export const classes = {
  componentContainer: {
    padding: theme.spacing(0.75, 1.25),
    paddingBottom: theme.spacing(1),
    marginBottom: '0px !important',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  title: {
    // @ts-ignore
    color: theme.palette.primary[900],
    lineHeight: 1.26,
    fontWeight: 600,
    paddingTop: theme.spacing(0.75),
  },
  actionButton: {
    marginTop: theme.spacing(3.75),
    marginBottom: theme.spacing(1.375),
  },
  cancelButton: {
    padding: theme.spacing(0.125, 0.5),
    paddingBottom: theme.spacing(0.25),
    // @ts-ignore
    color: theme.palette.primary[700],
  },
  numpad: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  subtext: {
    marginTop: theme.spacing(1.5),
  },
  checkmark: {
    marginTop: theme.spacing(2.5),
  },
  dropdown: {
    width: theme.spacing(18.5),
  },
  birdContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cancelButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  cancelButtonPinChange: {
    margin: theme.spacing(1, 0),
    marginBottom: 0,
    padding: theme.spacing(0.5625, 0.75),
    // @ts-ignore
    color: theme.palette.primary[700],
  },
}
