import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const TimebalanceL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path d="M15 8.3V5.75h-1.25a1 1 0 1 1 0-2h4.5a1 1 0 1 1 0 2H17V8.3a9.999 9.999 0 0 1 4.906 1.88l.638-.638a1 1 0 0 1 1.663-1l.375.376.375.375a1 1 0 0 1-1 1.664l-.562.562a10.002 10.002 0 0 1-.324 13.802A10 10 0 1 1 15 8.301Zm1 1.95a7.999 7.999 0 1 0 0 15.998 7.999 7.999 0 0 0 0-15.998Zm-3.008 3.929a1 1 0 1 0-1.484 1.34l3.75 4.151a1 1 0 1 0 1.484-1.34l-3.75-4.151Z" />
    </Svg>
  )
}

export default TimebalanceL
