import palette from 'utils/theme/palette'

const commonTextStyles = {
  fontWeight: 'regular',
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: -0.3,
  margin: 0,
  overflowWrap: 'break-word',
}
export const styles = {
  wrapper: {
    marginBottom: '16px',
  },
  separator: {
    backgroundColor: palette.primary[50],
    height: '2px',
    width: '100%',
    borderRadius: '1px',
  },
  textBlock: {
    ...commonTextStyles,
    '& h2': {
      ...commonTextStyles,
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '24px', // You can add or override any specific styles here
    },
    '& h3': {
      ...commonTextStyles,
      fontWeight: 'bold',
      fontSize: '18px',
    },
    '& h4': {
      ...commonTextStyles,
      fontWeight: 'bold',
    },
    '& p': commonTextStyles,
    '& b': commonTextStyles,
    '& a': commonTextStyles,
  },
}

export const imageBlockStyles = {
  image: {
    borderRadius: '8px',
  },
}
