import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const CheckMarkS: React.FC<IconPropsT> = ({ fill, ...props }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill} {...props}>
      <path
        fillRule="evenodd"
        d="M13.646 3.646l.708.708a.5.5 0 010 .707l-7.5 7.5a.502.502 0 01-.708 0l-4.5-4.5a.5.5 0 010-.707l.708-.708a.5.5 0 01.707 0L6.5 10.085l6.44-6.439a.5.5 0 01.706 0z"
      />
    </Svg>
  )
}

export default CheckMarkS
