import React from 'react'
import Page from 'components/Page'
import { Box, Typography } from '@mui/material'
import SuccessGeneric from 'components/SuccessGeneric'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'

const SuccessDeleteConnection: React.FC<{ tenantName: string }> = ({ tenantName }) => {
  const { t } = useFavurTranslation()
  return (
    <Page hideNativeNavigation hideHamburger flex hideBackground>
      <SuccessGeneric testId="success-delete-connection">
        <Box sx={classes.sucessDeleteConnectionContainer}>
          <Typography variant="h2" sx={[classes.successTitle, classes.titleTop]}>
            {t('deleteConnection.success.title', { tenantName })}
          </Typography>
          <Typography variant="body1" sx={classes.subtitle}>
            {t('deleteConnection.success.subtitle', { tenantName })}
          </Typography>
        </Box>
      </SuccessGeneric>
    </Page>
  )
}

export default SuccessDeleteConnection
