import React from 'react'
import { useHistory } from 'react-router'
import { Box, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import useStateBackLink from 'hooks/useStateBackLink'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import { ArrowRightThinS, EventM } from 'icons'
import { classes } from '../styles'

const EventsBlock: React.FC = () => {
  const { setBackLink } = useStateBackLink()
  const { t } = useFavurTranslation()
  const history = useHistory()

  return (
    <Box sx={classes.eventsBlock}>
      <Box
        sx={classes.eventsMenu}
        onClick={() => {
          setBackLink(routes.shifts)
          history.push(routes.cmsEventIndex)
        }}
      >
        <Box>
          <EventM sx={classes.eventsIcon} />
          <Typography variant="caption" sx={classes.eventsText}>
            {t('page.shifts.sideMenu.events')}
          </Typography>
        </Box>
        <Box sx={classes.eventsArrow}>
          <ArrowRightThinS />
        </Box>
      </Box>
      <DivisionLine />
    </Box>
  )
}

export default EventsBlock
