/* eslint-disable fp/no-this */
import React, { useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import axios from 'axios'
import { queryCache } from 'react-query'
import { isValidIBAN, isValidBIC } from 'ibantools'
import useFavurForm from 'hooks/useFavurForm'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { fetchInfo } from 'hooks/useJamesQuery'
import { USER_CANCEL } from 'services/AxiosInterceptor/useRejectGraphql'
import useTenantsAndPermissions from 'hooks/useTenantsAndPermissions'
import { TENANT_CONNECTION_STATUS } from 'constants/highSecurityConnection'
import { isBicRequired } from './helpers'
import { bankDataQuery, bankDataQueryName } from './queries'
import type { BankDataValuesT } from './types'

const useBankDataForm = ({
  onSubmit,
}: {
  onSubmit: (data: BankDataValuesT, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  const { t } = useFavurTranslation()
  const [loading, setLoading] = useState<boolean>()
  const [formReady, setFormReady] = useState(false)
  const [apiData, setApiData] = useState<BankDataValuesT>()
  const { tenantsAndPermissions } = useTenantsAndPermissions()

  const hasOneHighSecurityConnection = tenantsAndPermissions?.some(
    (tenant) => tenant.tenantConnectionStatus === TENANT_CONNECTION_STATUS.high,
  )

  useEffect(() => {
    const source = axios.CancelToken.source()
    const cancelToken = source.token
    setLoading(true)

    queryCache
      .fetchQuery(bankDataQueryName, fetchInfo<{ bankInfo: BankDataValuesT }>(bankDataQuery, cancelToken))
      .then((response) => {
        if (response && !axios.isCancel(response) && response?.data?.data?.bankInfo !== undefined) {
          setLoading(false)
          setApiData(response.data.data.bankInfo)
        }
      })
    return () => {
      source.cancel(USER_CANCEL)
    }
  }, [setLoading, setApiData])

  const [formFields] = useState([
    {
      name: 'iban',
      init: '',
      validation: yup.mixed().test({
        name: 'iban',
        test: function test() {
          const { parent } = this
          return isValidIBAN(parent.iban)
        },
        message: t('personalData.error.invalidIBAN'),
      }),
    },
    {
      name: 'bic',
      init: '',
      validation: yup.string().test({
        name: 'bic',
        test: function test() {
          const { parent } = this
          return (parent.bic.length === 0 && !isBicRequired(parent.iban)) || isValidBIC(parent.bic)
        },
        message: t('personalData.error.invalidBIC'),
      }),
    },
  ])

  const { favurForm, getFormHandlers } = useFavurForm<BankDataValuesT>({
    context: 'personalData',
    prolongHighSecuritySession: hasOneHighSecurityConnection,
    formFields,
    onSubmit: onSubmit as SubmitHandler<BankDataValuesT>,
    // eslint-disable-next-line no-console
    onError: console.error,
  })

  const formHandlers = (name: keyof BankDataValuesT) => {
    const handlers = getFormHandlers(name)
    return {
      ...handlers,
      id: `user-employment-data_bank_${String(name)}`,
    }
  }

  const { reset } = favurForm
  useEffect(() => {
    if (!loading && apiData !== undefined) {
      const withDefaults = {
        bic: apiData?.bic || '',
        iban: apiData?.iban || '',
      }

      reset(withDefaults, {
        keepDirty: true,
      })
      setFormReady(true)
    }
  }, [loading, apiData, reset])

  return { favurForm, formHandlers, loading: !formReady }
}

export default useBankDataForm
