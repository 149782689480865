import React, { useEffect, useMemo } from 'react'
import { getUserName } from 'utils/person'
import useTasksQueryPaginated from 'hooks/useTasksQueryPaginated'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { taskStates } from 'pages/Tasks/constants'
import useFilterContext from 'pages/AbsencePlanner/contexts/FilterContext'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import useSelectedStatesContext from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { taskTypes } from 'shared/constants'
import AbsenceRequestTab from '..'

export const TASK_PAGE_LIMIT = 10
interface ManagerTasksProps {
  refetchAll: () => void
  refetchTask: (personId: string, dateFrom: string, dateTo: string) => void
  hideTitle?: boolean
}

const ManagerTasks: React.FC<ManagerTasksProps> = ({ refetchAll, refetchTask, hideTitle = false }) => {
  const { t } = useFavurTranslation()
  const { selectedPerson, selectedTaskUuid } = useSelectedStatesContext()
  const { shownTeams } = useFilterContext()

  const initialFilters = useMemo(
    () => ({
      // eslint-disable-next-line camelcase
      task_types: [taskTypes.absenceRequest],
      // eslint-disable-next-line camelcase
      task_statuses: [taskStates.todo],
      // eslint-disable-next-line camelcase
      team_ids: shownTeams,
    }),
    [shownTeams],
  )

  const {
    list: tasks,
    loading,
    loadMore,
    hasMoreElements,
    updateFilters,
    handleRefetch,
    reload,
  } = useTasksQueryPaginated({
    initialFilters,
    initialOffice: true,
    limit: TASK_PAGE_LIMIT,
    skip: Boolean(selectedTaskUuid),
  })

  useEffect(() => {
    const updatedFilters = {
      ...initialFilters,
      ...(selectedPerson
        ? {
            // eslint-disable-next-line camelcase
            person_ids: [selectedPerson?.id as number],
          }
        : {}),
    }
    updateFilters(updatedFilters)
  }, [initialFilters, selectedPerson, updateFilters])

  const subtitle = useMemo(() => {
    if (!selectedPerson) return t('absencePlanner.taskbar.managerTasks.subtitle')
    return getUserName(selectedPerson, true)
  }, [selectedPerson, t])

  return (
    <AbsenceRequestTab
      tasks={tasks as TaskT<AbsenceRequestTaskT>[]}
      title={t('absencePlanner.taskbar.managerTasks.title')}
      hideTitle={hideTitle}
      subtitle={subtitle}
      loading={loading}
      loadMore={loadMore}
      hasMoreElements={hasMoreElements}
      office
      reloadTaskList={reload}
      refetchTask={(personId: string, dateFrom: string, dateTo: string) => {
        handleRefetch()
        refetchTask(personId, dateFrom, dateTo)
      }}
      refetchAll={() => {
        handleRefetch()
        refetchAll()
      }}
    />
  )
}

export default ManagerTasks
