import React from 'react'
import { IconButton } from '@mui/material'

import { FingerprintAndroidL, FingerprintIosL } from 'icons'
import { isAndroidNative } from 'utils/platform'
import { classes } from '../../styles'

export interface INumpadInputTouchId {
  onClick: () => void
}

const NumpadInputTouchId: React.FC<INumpadInputTouchId> = ({ onClick }) => {
  return (
    <IconButton
      aria-label="Numpad TouchId"
      sx={classes.iconButton}
      onClick={onClick}
      data-testid="numpad-touchid"
      size="large"
    >
      {isAndroidNative() ? <FingerprintAndroidL /> : <FingerprintIosL />}
    </IconButton>
  )
}

export default NumpadInputTouchId
