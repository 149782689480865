import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { ListItemIcon, ListItemText, List, MenuItem, Typography, Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import NewTutorialChip from 'components/NewTutorialChip'
import useRolesViews from 'hooks/useRolesViews'
import useHistoryUtils from 'hooks/useHistoryUtils'
import { ROLE_VIEWS } from 'constants/roles'
import { navigationClasses } from './styles'
import { NavPointT } from '../types'

interface NavigationGroupProps {
  toggleDrawer: () => void
  navPoints: NavPointT[]
}

const NavigationGroup: React.FC<NavigationGroupProps> = ({ toggleDrawer, navPoints }) => {
  const { t } = useFavurTranslation()
  const { pathname } = useLocation()
  const { activeView, setActiveView } = useRolesViews()
  const { pushWithRole } = useHistoryUtils()

  const onClick = useCallback(
    (navPoint: NavPointT) => {
      if (navPoint.to !== pathname) {
        pushWithRole(navPoint.to, navPoint.role)
        return
      }

      toggleDrawer()
      if (activeView !== navPoint.role) {
        setActiveView(navPoint.role ?? ROLE_VIEWS.frontliner)
      }
    },
    [activeView, pathname, pushWithRole, setActiveView, toggleDrawer],
  )

  return (
    <List component="nav" sx={navigationClasses.root}>
      {navPoints.map((navPoint, index) => {
        const Icon = navPoint.icon
        return (
          <MenuItem
            component={Box}
            key={navPoint.label}
            sx={navigationClasses.item}
            data-testid={navPoint.testId}
            onClick={() => onClick(navPoint)}
            tabIndex={index}
            disableRipple
          >
            {Icon && (
              <ListItemIcon sx={navigationClasses.icon}>
                <Icon />
              </ListItemIcon>
            )}
            <ListItemText sx={navigationClasses.text}>
              <Typography variant="body1">{t(navPoint.label)}</Typography>
              <NewTutorialChip id={navPoint.id} />
            </ListItemText>
          </MenuItem>
        )
      })}
    </List>
  )
}

export default NavigationGroup
