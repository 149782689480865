import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const EditM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M10.2 17.9l-5.7 1.6 1.6-5.7L16.6 3.4a1.3 1.3 0 0 1 1.8 0l2.2 2.2a1.3 1.3 0 0 1 0 1.8L10.2 18z"
      />
    </Svg>
  )
}

export default EditM
