import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import Page from 'components/Page'
import { ShareUserDataTaskT, TaskT } from 'pages/Tasks/types'
import useRolesViews from 'hooks/useRolesViews'
import { ROLE_VIEWS } from 'constants/roles'
import { SimpleLoading } from 'components/LoadingIcon'
import BackwardLink from 'components/BackwardLink'
import useFilterUtils from 'components/Filter/useFilterUtils'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { headerClasses } from 'pages/Tasks/styles'
import Content from './DetailPageContent'

interface IDetailPageProps {
  task: TaskT<ShareUserDataTaskT> | undefined
  loading: boolean
}

const DetailPage: React.FC<IDetailPageProps> = ({ task, loading }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { activeView } = useRolesViews()
  const { setLoadFilterTasks } = useFilterUtils()
  const isOffice = useMemo(() => activeView === ROLE_VIEWS.office, [activeView])

  const gotoBackLink = useCallback(() => {
    setLoadFilterTasks()
    history.goBack()
  }, [history, setLoadFilterTasks])

  return (
    <Page
      hideNativeNavigation
      hideHamburger
      hideBackground
      removeLastMargin
      header={<BackwardLink onClickIcon={gotoBackLink} title={t('tasks.shareUserData.details.title')} />}
      sxHeader={headerClasses.subHeader}
    >
      {loading || !task ? <SimpleLoading /> : <Content task={task} isOffice={isOffice} />}
    </Page>
  )
}

export default DetailPage
