import React, { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Box, Button, SxProps, Theme, useMediaQuery } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import InfoDialog from 'components/Forms/components/InfoDialog'
import { getFormattedFromDate } from 'utils/date'
import { absenceRequestDateFormat } from 'utils/constants'
import { TenantNamesT } from 'types'
import AbsenceRequestConflictsCard from 'components/AbsenceRequestConflicts/AbsenceRequestConflictsCard'
import theme from 'utils/theme'
import usePersonsOfUser from 'hooks/usePersonsOfUser'
import ShowBalancesButton from './ShowBalancesButton'
import NewForm from './NewForm'
import { FormErrorsT, FormFieldsT } from './useNewAbsenceRequestForm'
import { newAbsenceRequestClasses } from './styles'
import { AbsenceRequestContext } from '../AbsenceRequestContext'

interface INewContentProps {
  showDialogBlockedPeriod: boolean
  setShowDialogBlockedPeriod: Dispatch<SetStateAction<boolean>>
  handlePersonUuidChange: (personUuid: string) => void
  getTenantOptions: () => Record<string, string>
  selectedPersonTenant: TenantNamesT | null
  firstAvailableDate: Date | null
  classicPersonIds: string[]
  loadingMutation: boolean
  formFields: FormFieldsT
  formErrors: FormErrorsT
  setValue: (field: string, value: string | Date | null) => void
  onSubmit: () => Promise<void>
  fullScreen?: boolean
  drawerSx?: SxProps<Theme>
  onClickConflict?: () => void
  showAbsencePlannerPreview?: boolean
}

const NewContent: React.FC<INewContentProps> = ({
  showDialogBlockedPeriod,
  setShowDialogBlockedPeriod,
  selectedPersonTenant,
  firstAvailableDate,
  classicPersonIds,
  getTenantOptions,
  handlePersonUuidChange,
  formFields,
  formErrors,
  setValue,
  onSubmit,
  fullScreen = true,
  drawerSx,
  onClickConflict,
  showAbsencePlannerPreview,
}) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { t, locale } = useFavurTranslation()
  const [showAbsencePlannerDrawer, setShowAbsencePlannerDrawer] = useState<boolean>(false)

  const {
    personUuid: { value: selectedPersonUuid },
    fromDate: { value: fromDate },
    toDate: { value: toDate },
  } = formFields

  const { persons } = usePersonsOfUser()

  const selectedPerson = useMemo(() => (persons ?? []).find(({ favurUuid }) => selectedPersonUuid === favurUuid), [
    persons,
    selectedPersonUuid,
  ])

  const startDate = fromDate ? getFormattedFromDate(fromDate as Date, 'yyyy-MM-dd') : undefined
  const endDate = toDate ? getFormattedFromDate(toDate as Date, 'yyyy-MM-dd') : startDate

  return (
    <AbsenceRequestContext.Provider
      value={{
        showAbsencePlannerDrawer,
        setShowAbsencePlannerDrawer: setShowAbsencePlannerDrawer,
      }}
    >
      <Box>
        <Box sx={fullScreen && isSmallScreen ? newAbsenceRequestClasses.formContainer : []}>
          <NewForm
            personIds={classicPersonIds}
            getTenantOptions={getTenantOptions}
            handlePersonUuidChange={handlePersonUuidChange}
            formFields={formFields}
            formErrors={formErrors}
            setValue={setValue}
            onSideBar={!fullScreen && !isSmallScreen}
          />
          {startDate && endDate && (
            <AbsenceRequestConflictsCard
              startDate={startDate}
              endDate={endDate}
              noPadding
              onClick={onClickConflict}
              previewPerson={selectedPerson}
              showAbsencePlannerPreview={showAbsencePlannerPreview}
              setShowAbsencePlannerDrawer={setShowAbsencePlannerDrawer}
              showAbsencePlannerDrawer={showAbsencePlannerDrawer}
            />
          )}
        </Box>
        <ActionCardButtons fullScreen={fullScreen || isSmallScreen}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onSubmit()}
            data-testid="absence-request_new_request"
          >
            {t('absenceRequest.new.sendRequest')}
          </Button>
          <ShowBalancesButton selectedPersonUuid={selectedPersonUuid as string} drawerSx={drawerSx} />
        </ActionCardButtons>
        {showDialogBlockedPeriod && (
          <InfoDialog
            open
            title={t('label.notice!')}
            action={() => setShowDialogBlockedPeriod(false)}
            text={t('absenceRequest.new.blockedPeriod', {
              tenantName: selectedPersonTenant?.tenantName,
              blockedDays: selectedPersonTenant?.tenantAbsencesBlockingPeriod,
              firstDate: firstAvailableDate
                ? getFormattedFromDate(firstAvailableDate as Date, absenceRequestDateFormat, locale)
                : '',
            })}
          />
        )}
      </Box>
    </AbsenceRequestContext.Provider>
  )
}

export default NewContent
