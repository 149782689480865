import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const HomeNewL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M24.07 16.9a1 1 0 0 0-1 1V25H19.2v-4.89a2.47 2.47 0 0 0-2.47-2.47h-1.46a2.46 2.46 0 0 0-2.47 2.47V25H8.93v-7.1a1 1 0 1 0-2 0V26a1 1 0 0 0 1 1h5.87a1 1 0 0 0 1-1v-5.89c0-.12.05-.25.14-.33.09-.1.2-.14.33-.14h1.46a.47.47 0 0 1 .47.47V26a1 1 0 0 0 1 1h5.87a1 1 0 0 0 1-1v-8.1a1 1 0 0 0-1-1Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M16 7a.46.46 0 0 1 .33.14l9.96 10a1 1 0 0 0 1.42-1.41l-9.96-10a2.47 2.47 0 0 0-3.5 0l-9.96 10a1 1 0 1 0 1.42 1.4l9.96-10A.47.47 0 0 1 16 7Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default HomeNewL
