export const DEFAULT_PAGE_SIZE = 10

export const CONTENT_TYPES = {
  page: 'page',
  event: 'event',
} as const

export const EVENT_TIMELINE_FILTERS = {
  PAST: 'PAST',
  UPCOMING: 'UPCOMING',
} as const

export const BLOCK_TYPE = {
  divider: 'divider',
  image: 'image',
  text: 'text',
} as const
