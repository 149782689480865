import { gql } from '@apollo/client'

const teamProperties = `
  id
  ownerId
  tenantId
  nameDe
  nameEn
  nameFr
  nameIt
  public
  showTeamplanCostCenters
  tenant {
    id
    name
    package
  }
  owner {
    id
    name
    tenants {
      id
    }
  }
  teamPermissions {
    personId
    absenceManager
    monthlySheetManager
    shareUserDataManager
    shiftUpdateManager
    supervisor
    person {
      id
      favurUuid
      firstName
      lastName
      user {
        firstName
        lastName
      }
      invitation {
        status
      }
      roles{
        role
      }
      tenant{
        name
      }
      workWithdrawal
      teamPermissions {
        absenceManager
        monthlySheetManager
        shareUserDataManager
        shiftUpdateManager
        person {
          workWithdrawal
        }
      }
    }
  }
`

export const teamsQuery = gql`
query listTeamsUser ($personIds: [ID]) {
    findAllTeams(personIds: $personIds) {
        ${teamProperties}
    }
}
`

export const teamWithPermissionsQuery = `
query teamWithPermissions($id: String!) {
  getTeam(id: $id) {
    ${teamProperties}
  }
}
`

export const updateMembers = gql`
  mutation($changes: MemberChanges!, $teamId: String!) {
    updateTeamMembers(changes: $changes, teamId: $teamId) {
      success
    }
  }
`

export const teamMemberProperties = `
  firstName
  lastName
  id
  favurUuid
  lastSharedData
  status
  user {
    firstName
    lastName
    userDataCollectionLastUpdate
  }
  teamPermissions {
    teamId
    absenceManager
    monthlySheetManager
    shareUserDataManager
    shiftUpdateManager
    person {
      workWithdrawal
    }
  }
  invitation {
    status
  }
  tenant{
    id
    name
    package
  }
`

export const ownerPersonsQuery = gql`
  query ownerTeam($ownerId: ID!, $notStatusIn: [String]) {
    getOwner(ownerId: $ownerId) {
      persons(notStatusIn: $notStatusIn) {
        ${teamMemberProperties}
      }
      tenants{
        id
      }
    }
  }
`

export const ownerTenantsQuery = gql`
  query ownerTenants($ownerId: ID!, $tenantIds: [ID]) {
    getOwner(ownerId: $ownerId) {
      id
      tenants(tenantIds: $tenantIds) {
        id
        name
      }
    }
  }
`

export const ownerTenantsPersonsQuery = gql`
  query ownerTenants($ownerId: ID!, $tenantIds: [ID], $notStatusIn: [String]) {
    getOwner(ownerId: $ownerId) {
      id
      tenants(tenantIds: $tenantIds) {
        id
        name
        persons(notStatusIn: $notStatusIn) {
          ${teamMemberProperties}
        }
      }
    }
  }
`

export const tenantPersonsQuery = gql`
  query tenantTeam($tenantId: ID!, $notStatusIn: [String]) {
    getTenant(tenantId: $tenantId) {
      persons(notStatusIn: $notStatusIn) {
        ${teamMemberProperties}
      }
    }
  }
`

export const addToTeamsMutation = gql`
  mutation($personId: ID!, $teamIds: [String]!) {
    addToTeams(personId: $personId, teamIds: $teamIds) {
      success
    }
  }
`
