import React, { useCallback, useContext, useState } from 'react'
import { Box } from '@mui/material'
import { useMutation } from '@apollo/client'
import useFavurTranslation from 'hooks/useFavurTranslation'
import AcceptAbsenceRequest from 'components/TaskBadge/AbsenceRequest/AcceptAbsenceRequest'
import { ApproveAbsenceRequestResultT } from 'types'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { SimpleLoading } from 'components/LoadingIcon'
import FlashMessagesContext from 'services/FlashMessages/context'
import { updateAbsenceRequestMutationApollo as mutation } from '../../mutations'
import Form from './Form'
import { AcceptAbsenceRequestFieldsT } from './types'
import { absenceRequestStatus } from '../../constants'
import Summary from '../Summary'
import { classes } from './styles'

interface IAcceptContentProps {
  task: TaskT<AbsenceRequestTaskT>
  onAccept: () => void
  fullScreen?: boolean
  isSmallScreen?: boolean
}

const AcceptContent: React.FC<IAcceptContentProps> = ({
  task,
  onAccept,
  fullScreen = false,
  isSmallScreen = false,
}) => {
  const { t } = useFavurTranslation()
  const [approveAbsenceRequest] = useMutation<ApproveAbsenceRequestResultT>(mutation)
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(
    (approveData: AcceptAbsenceRequestFieldsT) => {
      setLoading(true)
      removeAll()
      approveAbsenceRequest({
        variables: {
          dates: approveData.datesData, //getFrom the form
          internalComment: approveData.internalComment ?? '',
          reviewerComment: approveData.reviewerComment ?? '',
          status: absenceRequestStatus.approvedOffice,
          taskUuid: task.favurUuid,
        },
      })
        .then(() => {
          setLoading(false)
          onAccept()
        })
        .catch((_error) => {
          setLoading(false)
          setFlashMessage(t('common.error.be.default'))
        })
    },
    [approveAbsenceRequest, onAccept, removeAll, setFlashMessage, t, task.favurUuid],
  )

  return (
    <>
      {loading && (
        <Box sx={classes.loadingBox}>
          <SimpleLoading />
        </Box>
      )}
      {!loading && (
        <Box>
          <AcceptAbsenceRequest />
          <Box sx={classes.summary}>
            <Summary task={task} greyVariant withEmployee />
          </Box>
          <Form
            taskDates={task.taskData.dates}
            onSubmit={onSubmit}
            fullScreen={fullScreen}
            isSmallScreen={isSmallScreen}
            task={task}
          />
        </Box>
      )}
    </>
  )
}

export default AcceptContent
