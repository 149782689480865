import theme from 'utils/theme'
import favurTheme from 'utils/theme'
export const classes = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '48px',
    backgroundColor: 'white',
  },
  root: {
    width: '100%',
    maxWidth: '576px',
    display: 'flex',
    margin: '0 auto',
    padding: '8px',
  },
  leftContainer: {
    width: '100%',
    display: 'flex',
  },
  filterButtonContainer: {
    margin: '4px',
  },
  filterButton: {
    '&:hover': { background: 'none' },
  },
  allChipsContainer: {
    margin: 0,
    padding: '0 8px',
    display: 'flex',
    listStyle: 'none',
    flexWrap: 'wrap',
  },
  scrollingAllChipsContainer: {
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
  },
  deleteIconButton: {
    width: 'auto',
    height: 'auto',
    marginRight: 0,
    padding: '4px 8px 4px 0',
  },
  chip: {
    margin: theme.spacing(0.25),
    // @ts-ignore
    backgroundColor: theme.palette.primary[500],
    color: 'white',
    borderRadius: '12px',
    paddingLeft: '4px',
    gap: '4px',
  },
  chipNative: {
    '&:hover': {
      // @ts-ignore
      backgroundColor: theme.palette.primary[500],
    },
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '15px',
    textAlign: 'center',
    letterSpacing: '0.7px',
    textTransform: 'uppercase',
  },
  labelSmall: {
    padding: 0,
  },
  checkListOption: {
    fontWeight: 350,
    paddingLeft: '10px',
  },
  checkBox: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    '&:hover': { background: 'none' },
  },
  checkBoxContainer: {
    paddingTop: theme.spacing(1.0),
    paddingLeft: '6px',
  },
  noFilterLabel: {
    color: theme.palette.text.disabled,
    marginLeft: theme.spacing(1.0),
  },
  dateInputIcon: {
    marginRight: theme.spacing(0.5),
    cursor: 'pointer',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  personListSelectButton: {
    fontSize: '14px',
    color: theme.palette.secondary.main,
    justifyContent: 'flex-start',
    zIndex: 0,
  },
  personListSelectButtonContainer: {
    textAlign: 'left',
    width: '100%',
    paddingTop: theme.spacing(1.0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  personListElems: {
    paddingTop: '10px',
    paddingLeft: '6px',
    fontStyle: 'normal',
    fontWeight: 350,
  },
  personListElemContainer: {
    marginBottom: theme.spacing(1.0),
    display: 'flex',
  },
  personListIconButton: {
    paddingBottom: theme.spacing(0.25),
  },
  personListElemText: {
    paddingLeft: '8px',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.5',
  },
  datePickerContainer: {
    height: '96px',
  },
  datePickerFrom: {
    paddingLeft: theme.spacing(0.5),
  },
  datePickerTo: {
    position: 'relative',
    right: theme.spacing(0.5),
  },
  quickFilterCTA: {
    color: theme.palette.info.main,
    '&:hover': { background: 'none' },
    '&:focus': { background: 'none' },
  },
  searchContainer: {
    paddingBottom: theme.spacing(0.5),
  },
  openPickerIcon: {
    position: 'relative',
    right: theme.spacing(1.0),
  },
  datePickerWithValue: {
    paddingTop: '47px !important',
  },
  datePickerWithoutValue: {
    paddingTop: '32px !important',
  },
  cardTitle: {
    paddingLeft: favurTheme.spacing(0.5),
    paddingTop: favurTheme.spacing(0.5),
    fontWeight: 350,
  },
  cardListItem: {
    flexDirection: 'column',
    gap: '2px',
    '&:last-child': {
      flexGrow: '0 !important',
      paddingBottom: '18px',
    },
    padding: favurTheme.spacing(0.5),
  },
}
