import React from 'react'
import Card from 'components/Basics/Card'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { Typography } from '@mui/material'
import { classes } from './styles'

const NoPersonsFoundCard: React.FC = () => {
  const { t } = useFavurTranslation()
  return (
    <Card marginTop="8px">
      <Typography variant="body1" sx={classes.noPersonsText} data-testid="no-persons">
        {t('filter.persons.search.no_results')}
      </Typography>
    </Card>
  )
}

export default NoPersonsFoundCard
