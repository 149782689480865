import React from 'react'
import { GraphQLResponse, UserObject } from 'types'
import Page from 'components/Page'
import useJamesQuery from 'hooks/useJamesQuery'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import LoadingPage from 'pages/Loading'
import useRedirectByTenantTypes from 'pages/UserSettings/pages/EmploymentData/hooks/useRedirectByTenantTypes'
import { headerClasses } from 'pages/UserSettings/styles'
import routes from 'services/RoutesProvider/routes'
import BackwardLink from 'components/BackwardLink'
import PersonalDataForm from './PersonalDataForm'

const PersonalDataPage: React.FC = () => {
  const { t } = useFavurTranslation()
  const { pushOriginLinkOrDefault, originBackLink } = useStateBackLink()
  const { status: statusTenants, isRedirecting } = useRedirectByTenantTypes('low', originBackLink)

  const { data, status } = useJamesQuery(
    'user_personal_data',
    `query{
      me{
        user{
          firstName
          lastName
          birthDate
          phoneNumber
        }
      }
    }`,
  )

  if (status === 'loading' || statusTenants === 'loading' || isRedirecting) {
    return <LoadingPage />
  }

  const {
    user: { phoneNumber, firstName, lastName, birthDate },
  } = (data as GraphQLResponse).me as UserObject

  return (
    <Page
      header={
        <BackwardLink
          onClickIcon={() => {
            pushOriginLinkOrDefault(routes.userSettings)
          }}
          title={t('page.userSettings.personalData.header')}
        />
      }
      sxHeader={headerClasses.subHeader}
      noPadding
      hideNativeNavigation
    >
      <PersonalDataForm {...{ phone: phoneNumber, firstName, lastName, birthDate }} />
    </Page>
  )
}

export default PersonalDataPage
