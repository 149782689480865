import React, { RefObject } from 'react'
import { Box } from '@mui/material'
import { SimpleLoading } from 'components/LoadingIcon'
import { PersonT } from 'types'
import { gridClasses } from '../styles'
import AbsencePlannerGridRow from '../Row'
import { PLACEHOLDER_ROWS_COUNT } from '../constants'
import PlaceHolderRow from '../TeamNameRow/PlaceHolderRow'

interface ILoadingGridRowProps {
  chunkedShownDays: string[][]
  scrollContainerRef: RefObject<HTMLDivElement>
  shownDays: string[]
}

const LoadingGridRow: React.FC<ILoadingGridRowProps> = ({ chunkedShownDays, scrollContainerRef, shownDays }) => {
  return (
    <>
      <Box sx={gridClasses.floatingLoading} data-testid="loading-absence-planner-grid-row">
        <SimpleLoading small />
      </Box>
      <AbsencePlannerGridRow
        person={{ id: -1 } as PersonT}
        gridBadges={new Map()}
        chunkedShownDays={chunkedShownDays}
        key="person-row_user-person"
        scrollContainerRef={scrollContainerRef}
      />
      <PlaceHolderRow shownDays={shownDays} teamId="loading-placeholder" />

      {Array.from({ length: PLACEHOLDER_ROWS_COUNT }, (_, index) => (
        <AbsencePlannerGridRow
          person={{ id: index } as PersonT}
          gridBadges={new Map()}
          chunkedShownDays={chunkedShownDays}
          key={`placeholder-person-row_${index}`}
          isLastRow={index === PLACEHOLDER_ROWS_COUNT - 1}
          scrollContainerRef={scrollContainerRef}
        />
      ))}
    </>
  )
}

export default LoadingGridRow
