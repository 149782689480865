import { TypePolicies } from '@apollo/client'
import { absenceRequest, shiftTypes } from 'constants/shift'
import { AvatarDataT, PersonT, ShiftTimeOfDayT, TenantT } from 'types'

export type AbsencePlannerBadgeTypesT =
  | typeof shiftTypes['absence']
  | typeof shiftTypes['plannedShift']
  | typeof absenceRequest

export type AbsencePlannerBadgeT = {
  badgeId: number
  date: string
  type: AbsencePlannerBadgeTypesT
  timeOfDay?: ShiftTimeOfDayT
  hexBackgroundColor?: string
  hexTextColor?: string
  shortDescription?: string
  isManual?: boolean
  taskUuid?: string
  daysRequested?: string[]
  personId?: number
  shiftUpdateUuid?: string
}

export type AbsencePlannerGridDayT = AbsencePlannerBadgeT[]
export type IsoDateKeyT = string
export type AbsencePlannerGridBadgesT = Map<IsoDateKeyT, AbsencePlannerGridDayT>

export type PersonIdKeyT = string
export type AbsencePlannerGridDataT = Map<PersonIdKeyT, AbsencePlannerGridBadgesT>

export type SelectedShiftDataT = {
  person: PersonT
  tenant: TenantT
  date: string
  avatarData: AvatarDataT
}

export type DateRangeT = { startDate: Date | null; endDate: Date | null }

export type TaskCountsT = { [personId: string]: number }

export type AbsencePlannerDayBadgeT = {
  date: string
  personId: string
  badges: AbsencePlannerBadgeT[]
}

export type AbsencePlannerPersonT = {
  personId: string
  dayBadges: AbsencePlannerDayBadgeT[]
}

export type AbsencePlannerQueryReturnT = {
  absencePlanner: AbsencePlannerPersonT[]
}

export type SelectedStateActionT =
  | 'setPinPointingSearchValue'
  | 'setSelectedTaskUuid'
  | 'setSelectedPerson'
  | 'setSelectedDays'
  | 'deselectPerson'
  | 'deselectDays'
  | 'selectNewPerson'
  | 'updateSelectedDays'
  | 'selectTaskFromGrid'
  | 'selectTaskFromTaskBar'
  | 'pinPointDateRange'

export const selectedStatesActions: Record<SelectedStateActionT, SelectedStateActionT> = {
  setPinPointingSearchValue: 'setPinPointingSearchValue',
  setSelectedTaskUuid: 'setSelectedTaskUuid',
  setSelectedDays: 'setSelectedDays',
  setSelectedPerson: 'setSelectedPerson',
  deselectPerson: 'deselectPerson',
  deselectDays: 'deselectDays',
  selectNewPerson: 'selectNewPerson',
  updateSelectedDays: 'updateSelectedDays',
  selectTaskFromGrid: 'selectTaskFromGrid',
  selectTaskFromTaskBar: 'selectTaskFromTaskBar',
  pinPointDateRange: 'pinPointDateRange',
}

export type SelectedStatesActionsContextT = {
  setPinPointingSearchValue: (pinPointingSearchValue: PinPointingSearchValueT | null) => void
  setSelectedTaskUuid: (selectedTaskUuid: string | null) => void
  setSelectedPerson: (selectedPerson: PersonT | null) => void
  setSelectedDays: (selectedDays: Date[]) => void
  selectNewPerson: (selectedPerson: PersonT | null) => void
  deselectPerson: () => void
  deselectDays: () => void
  updateSelectedDays: (selectedDay: Date) => void
  selectTaskFromGrid: (selectedTaskUuid: string, selectedPerson: PersonT, selectedDays: Date[]) => void
  selectTaskFromTaskBar: (
    selectedTaskUuid: string,
    pinPointingSearchValue: PinPointingSearchValueT,
    selectedDays: Date[],
    pinPoint: boolean,
  ) => void
  pinPointDateRange: (pinPointingSearchValue: PinPointingSearchValueT, selectedDays: Date[]) => void
}

export type PinPointingSearchValueT = {
  date: Date
  personId: string
}

export type SelectedStatesContextT = {
  selectedPerson: PersonT | null
  selectedTaskUuid: string | null
  selectedDays: Date[]
  pinPointingSearchValue: PinPointingSearchValueT | null
}

export type SetPinPointingSearchValueActionT = {
  pinPointingSearchValue: PinPointingSearchValueT | null
}

export type SetSelectedTaskUuidActionT = {
  selectedTaskUuid: string | null
}

export type SetSelectedPersonActionT = {
  selectedPerson: PersonT | null
}

export type SetSelectedDaysActionT = {
  selectedDays: Date[]
}

export type UpdateSelectedDaysActionT = {
  selectedDay: Date
}

export type SelectTaskFromGridActionT = {
  selectedDays: Date[]
  selectedTaskUuid: string
  selectedPerson: PersonT
}

export type SelectTaskFromTaskBarActionT = {
  selectedDays: Date[]
  selectedTaskUuid: string
  pinPointingSearchValue: PinPointingSearchValueT
  pinPoint: boolean
}

export type PinPointDateRangeActionT = {
  selectedDays: Date[]
  pinPointingSearchValue: PinPointingSearchValueT
}

export type ReducerActionT = {
  type: string
  value?:
    | SetPinPointingSearchValueActionT
    | SetSelectedTaskUuidActionT
    | SetSelectedPersonActionT
    | UpdateSelectedDaysActionT
    | SelectTaskFromGridActionT
    | SelectTaskFromTaskBarActionT
    | SetSelectedDaysActionT
}

export type ActionDispatchT = {
  [x: string]: (state: SelectedStatesContextT, _action: ReducerActionT) => SelectedStatesContextT
}

export const absencePlannerTypePolicies: TypePolicies = {}
