export const TOKEN_STATUSES = {
  successGoToPin: 'waiting_for_pin',
  connectionEstablished: 'connection_set',
  error: 'error',
}

export const TOKEN_LENGTH = 6

export const PIN_STATUSES = {
  notSet: 'not_set',
  set: 'set',
  blocked: 'blocked',
}

export const PIN_LENGTHS = {
  default: 6,
  long: 6,
  short: 4,
}

export const TENANT_CONNECTION_STATUS = {
  low: 'low',
  high: 'high',
}

export const PIN_KEYCHAIN_SERVER = 'pin.favur.ch'
