import React, { useMemo } from 'react'
import sortBy from 'lodash/fp/sortBy'
import { Box } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import { TeamplanPersonsT } from 'types/teamplan'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getTeamplanPersonsName } from 'components/ShiftsList/utils'
import TeamsPlaceholder from '../Placeholders/TeamsPlaceholder'
import { sortTeamPlanShifts } from './utils'
import TeamBlock from './TeamBlock'
import { classes } from '../styles'

interface ITeamsSectionProps {
  teamplan: 'loading' | TeamplanPersonsT[] | undefined
  showTopSeparator?: boolean
}

const TeamsSection: React.FC<ITeamsSectionProps> = ({ teamplan, showTopSeparator = true }) => {
  const { language } = useFavurTranslation()

  const { attendances, vacationAbsences } = useMemo(() => sortTeamPlanShifts(teamplan), [teamplan])

  if (!teamplan) {
    return null
  }

  if (teamplan === 'loading') {
    return (
      <Box sx={classes.fullMargin}>
        <DivisionLine />
        <TeamsPlaceholder />
      </Box>
    )
  }

  return (
    <>
      {sortBy((teamplanPersons) => getTeamplanPersonsName(teamplanPersons, language), teamplan).map(
        (teamplanPersons, idx) => (
          <TeamBlock
            key={teamplanPersons.id}
            teamplanPersons={teamplanPersons}
            attendances={attendances[teamplanPersons.id] ?? []}
            vacationAbsences={vacationAbsences[teamplanPersons.id] ?? []}
            showTopSeparator={idx > 0 || showTopSeparator}
            reducedMargin={idx === 0}
          />
        ),
      )}
    </>
  )
}

export default TeamsSection
