import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Box from '@mui/material/Box'
import Page from 'components/Page'
import useStepper from 'hooks/useStepper'
import routes from 'services/RoutesProvider/routes'
import { StepT } from 'types'
import { ForgotPhoneStartL, KeyL, NewSimL, OldSimL, PinL } from 'icons'
import { classes } from './styles'
import Start from './Steps/1Start'
import OldSim from './Steps/2OldSim'
import HRPin from './Steps/3HRPin'
import NewSim from './Steps/4NewSim'
import SMSPin from './Steps/5SMSPin'
import { Step } from './types'

const forgotPhoneSteps: StepT[] = [
  { name: 'START', index: 0, icon: ForgotPhoneStartL, component: Start },
  { name: 'OLD_SIM', index: 1, icon: OldSimL, component: OldSim },
  { name: 'HR_PIN', index: 2, icon: KeyL, component: HRPin },
  { name: 'NEW_SIM', index: 3, icon: NewSimL, component: NewSim },
  { name: 'SMS_PIN', index: 4, icon: PinL, component: SMSPin },
]

const ForgotPhonePage: React.FC<RouteComponentProps<{ step?: Step }>> = ({ match }) => {
  const { step } = match.params
  const { activeStep, goToStep, Stepper } = useStepper({
    route: routes.forgotPhone,
    step,
    steps: forgotPhoneSteps,
  })
  const [stepperHidden, setStepperHidden] = useState(false)
  const [oldPhone, setOldPhone] = useState('')
  return (
    <Page paddingX={0}>
      {stepperHidden ? null : (
        <Box
          sx={{
            backgroundColor: 'common.white',
          }}
        >
          <Stepper activeStep={activeStep.index} steps={forgotPhoneSteps} />
        </Box>
      )}
      <Box sx={classes.componentContainer}>
        <activeStep.component
          goToStep={goToStep}
          hideStepper={setStepperHidden}
          oldPhone={oldPhone}
          setOldPhone={setOldPhone}
        />
      </Box>
    </Page>
  )
}

export default ForgotPhonePage
