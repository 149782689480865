import { uniqBy, sortBy } from 'lodash/fp'
import { addDays, differenceInDays, eachDayOfInterval, format } from 'date-fns'
import { PersonT, TeamT, TeamWithUserPermissions } from 'types'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import { ManagedTaskCountsReturnT } from 'shared/queries'
import { dateToISOStringWithoutTime, newDateWithoutTime } from 'utils/date'
import { getTeamNameByLanguage } from 'types/utils'
import { getSortingName } from 'utils/person'
import {
  TaskCountsT,
  AbsencePlannerGridDayT,
  PersonIdKeyT,
  AbsencePlannerQueryReturnT,
  AbsencePlannerGridBadgesT,
  IsoDateKeyT,
} from './types'

export const getDaysRequested = (task: TaskT<AbsenceRequestTaskT>) => {
  const start = newDateWithoutTime(task.taskData.dateFrom)
  const end = newDateWithoutTime(task.taskData.dateTo)
  return eachDayOfInterval({ start, end })
}

export const addNewDataToAbsencePlanner = (
  gridData: Map<PersonIdKeyT, AbsencePlannerGridBadgesT>,
  newData: AbsencePlannerQueryReturnT,
): Map<PersonIdKeyT, AbsencePlannerGridBadgesT> =>
  newData.absencePlanner.reduce((acc, person) => {
    const { personId: id, dayBadges } = person
    const personObject = acc.get(id) ?? new Map<IsoDateKeyT, AbsencePlannerGridDayT>()
    const updatedPersonObject = dayBadges.reduce((dayAcc, dayList) => {
      const { date, badges } = dayList
      const badgeList = dayAcc.get(date) ?? []
      return dayAcc.set(date, uniqBy('shortDescription', [...badgeList, ...badges]))
    }, personObject)
    return acc.set(id, updatedPersonObject)
  }, gridData)

export const mapTaskCounts = (taskCounts: ManagedTaskCountsReturnT): TaskCountsT => {
  const entries = taskCounts.map((taskCount) => [taskCount.personId, taskCount.todo + taskCount.pending])
  return Object.fromEntries(entries)
}

export const isDaySelected = (date: Date, dateList: Date[]) =>
  dateList.some((d) => {
    const date1 = dateToISOStringWithoutTime(d)
    const date2 = dateToISOStringWithoutTime(date)
    return date1 === date2
  })

export const getDatesBetween: (dateFrom: string, dateTo: string) => string[] = (dateFrom, dateTo) => {
  // eslint-disable-next-line favur/no-date
  const startDate = new Date(dateFrom)
  // eslint-disable-next-line favur/no-date
  const endDate = new Date(dateTo)
  const daysBetween = differenceInDays(endDate, startDate)

  const datesArray: string[] = Array.from({ length: daysBetween + 1 }, (_, index) =>
    format(addDays(startDate, index), 'yyyy-MM-dd'),
  )

  return datesArray
}

export const sortTeams = (teams: TeamT[], lang: string) => sortBy((team) => getTeamNameByLanguage(team, lang), teams)

export const prepareTeamList = (teamlist: TeamT[], language: string, selectedOwnerId?: number) => {
  const ownerTeams = teamlist.reduce((acc, team) => {
    if (Number(team.owner?.id) !== Number(selectedOwnerId)) return acc
    const updatedTeam = {
      ...team,
      teamPermissions: sortBy([(ptp) => getSortingName(ptp.person as PersonT, true)], team.teamPermissions),
    }

    return [...acc, updatedTeam]
  }, [] as TeamT[])
  return sortBy([(team) => getTeamNameByLanguage(team, language)], ownerTeams)
}

export const getOwnerMemberTeams = (language: string, teams?: TeamT[], selectedOwnerId?: number) => {
  if (!teams || !selectedOwnerId) return []
  const teamsWithPermissions = teams as TeamWithUserPermissions[]
  const managerTeams = teamsWithPermissions.filter(
    (team) => team.userPermissions && team.userPermissions.absenceManager,
  )
  const frontlinerTeams = teamsWithPermissions.filter(
    (team) => !team.userPermissions || !team.userPermissions.absenceManager,
  )

  return [
    ...prepareTeamList(managerTeams, language, selectedOwnerId),
    ...prepareTeamList(frontlinerTeams, language, selectedOwnerId),
  ]
}
