import React, { useCallback, useState } from 'react'
import { Button, ButtonProps } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { EventsOfTask } from 'pages/Tasks/types'
import SendComment from '../SendComment'

interface IAddCommentProps {
  taskUuid: string
  event: EventsOfTask.monthlySheetSendCommentToManager | EventsOfTask.monthlySheetSendCommentToFrontline
  onAction: () => void
  sendCommentHeader?: React.ReactNode
  variant?: 'text' | 'outlined'
}

const AddComment: React.FC<IAddCommentProps> = ({
  taskUuid,
  event,
  onAction,
  sendCommentHeader,
  variant = 'outlined',
}) => {
  const { t } = useFavurTranslation()
  const [openDrawer, setOpenDrawer] = useState(false)
  const onCompleted = useCallback(() => {
    setOpenDrawer(false)
    onAction()
  }, [onAction])

  const buttonProps = variant === 'outlined' ? { variant: 'outlined' } : { variant: 'text', color: 'primary' }

  return (
    <>
      <Button
        {...(buttonProps as ButtonProps)}
        onClick={() => setOpenDrawer(true)}
        data-testid="monthly-sheet-detail_add_comment"
      >
        {t('workTimeControl.buttons.addComment')}
      </Button>
      <SendComment
        isOpen={openDrawer}
        onCancel={() => setOpenDrawer(false)}
        taskUuid={taskUuid}
        eventOnSuccess={event}
        eventCompleted={onCompleted}
        required
      >
        {sendCommentHeader}
      </SendComment>
    </>
  )
}

export default AddComment
