import React from 'react'
import palette from 'utils/theme/palette'
import { Box } from '@mui/material'
import { ManagerTasksL, SideMenuL, TaskCenterL } from 'icons'
import ComponentBadge from 'components/ComponentBadge'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useTaskBarStateContext from 'pages/AbsencePlanner/contexts/TaskBarStateContext'
import { taskBarStates } from '../types'
import TabButton from './TabButton'
import { iconTabsClasses } from './styles'

const ManagerTasksButton: React.FC<{ taskCountTotal: number }> = ({ taskCountTotal }) => {
  const { taskBarState } = useTaskBarStateContext()
  const { t } = useFavurTranslation()
  return (
    <TabButton tabOption={taskBarStates.managerTasks} toolTipText={t('absencePlanner.taskbar.toolTip.managerTasks')}>
      <ComponentBadge badgeContent={taskCountTotal} max={999}>
        <ManagerTasksL
          fill={taskBarState === taskBarStates.managerTasks ? palette.secondary.main : palette.primary[700]}
        />
      </ComponentBadge>
    </TabButton>
  )
}

const UserTasksButton: React.FC = () => {
  const { taskBarState } = useTaskBarStateContext()
  const { t } = useFavurTranslation()
  return (
    <TabButton tabOption={taskBarStates.userTasks} toolTipText={t('absencePlanner.taskbar.toolTip.pendingTasks')}>
      <TaskCenterL fill={taskBarState === taskBarStates.userTasks ? palette.secondary.main : palette.primary[700]} />
    </TabButton>
  )
}

const FiltersButton: React.FC = () => {
  const { taskBarState } = useTaskBarStateContext()
  const { t } = useFavurTranslation()

  return (
    <TabButton tabOption={taskBarStates.filters} toolTipText={t('absencePlanner.taskbar.toolTip.filters')}>
      <Box sx={iconTabsClasses.menuIconWrapper}>
        <SideMenuL fill={taskBarState === taskBarStates.filters ? palette.secondary.main : palette.primary[700]} />
      </Box>
    </TabButton>
  )
}

interface IIconTabsProps {
  isAbsenceManager?: boolean
  taskCountTotal: number
}

const IconTabs: React.FC<IIconTabsProps> = ({ isAbsenceManager, taskCountTotal }) => {
  return (
    <Box sx={iconTabsClasses.iconContainer}>
      {isAbsenceManager ? (
        <>
          <ManagerTasksButton taskCountTotal={taskCountTotal} />
          <UserTasksButton />
          <FiltersButton />
        </>
      ) : (
        <>
          <FiltersButton />
          <UserTasksButton />
        </>
      )}
    </Box>
  )
}

export default IconTabs
