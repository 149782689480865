import useJamesQuery from 'hooks/useJamesQuery'
import { InvitationT } from 'types'
import { useHistory } from 'react-router'
import { useContext } from 'react'
import BannerMessagesContext from 'services/BannerMessages/context'

const useInvitation = (inviteCode?: string) => {
  const history = useHistory()
  const { setBannerMessage } = useContext(BannerMessagesContext)

  const { data, status } = useJamesQuery<{ invitation: InvitationT }>(
    `invitation_${inviteCode}`,
    `query {
          invitation(code: "${inviteCode}") {
              tenantName
              code
              status
              updatedAt
              phoneNumber
              userId
          }
        }`,
    {
      enabled: Boolean(inviteCode),
      retry: false,
      refetchInterval: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        if (e.code === 'invitation.error.be.noInvitationWithCode') {
          setBannerMessage({
            message: e.code,
            bannerActions: [
              { text: 'common.dialog.button.contact', action: 'CONTACT' },
              { text: 'common.dialog.button.close', action: 'CLOSE' },
            ],
          })
        }
        history.replace('/')
      },
    },
  )

  return { invitation: data?.invitation, status }
}

export default useInvitation
