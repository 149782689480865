import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Card from 'components/Basics/Card'
import CenteredBox from 'components/CenteredBox'
import { SimpleLoading } from 'components/LoadingIcon'
import useFilterContext from 'components/Filter/context'
import LoadMoreButton from 'components/LoadMoreButton'
import Document from './Document'
import useDocumentsQueryPaginated from './useDocumentsQueryPaginated'
import { MonthlySheetDialogDataT } from './types'

interface IDocumentListContentsProps {
  setDialog: (value: MonthlySheetDialogDataT) => void
}

const DocumentListContents: React.FC<IDocumentListContentsProps> = ({ setDialog }) => {
  const { t } = useFavurTranslation()
  const { getFilters } = useFilterContext()
  const filters = getFilters()
  const {
    updateFilters,
    hasMoreElements,
    list: documents,
    loading: documentLoading,
    loadMore,
  } = useDocumentsQueryPaginated()

  useEffect(() => {
    updateFilters(filters)
  }, [filters, updateFilters])

  return (
    <>
      {!documentLoading && documents?.length === 0 ? (
        <Card>
          <CenteredBox>
            <Typography component="p">{t('page.documents.content.noDocuments')}</Typography>
          </CenteredBox>
        </Card>
      ) : (
        <>
          {documents?.map((document) => (
            <Document key={document.favurUuid} document={document} openMonthlySheetDialog={setDialog} />
          ))}
          {documentLoading && <SimpleLoading />}
          {hasMoreElements && !documentLoading && <LoadMoreButton onClick={loadMore} testId="documents-load-more" />}
        </>
      )}
    </>
  )
}

export default DocumentListContents
