import { useState } from 'react'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { GetLastUpdatedDateReturnT } from 'types'
import { newDateWithoutTime } from 'utils/date'
import { getLastUpdateDateQuery } from 'shared/queries'

const useLastUpdateDate = () => {
  const [lastUpdateDate, setLastUpdateDate] = useState<Date>()

  useJamesApolloQuery<GetLastUpdatedDateReturnT>(getLastUpdateDateQuery, {
    onCompleted(data) {
      const date = data.getLastUpdatedDate.lastUpdatedDate
      if (date) {
        setLastUpdateDate(newDateWithoutTime(date))
      }
    },
  })

  return { lastUpdateDate }
}

export default useLastUpdateDate
