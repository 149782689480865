import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { TextField, Typography } from '@mui/material'
import { useMutation } from '@apollo/client'
import FlashMessagesContext from 'services/FlashMessages/context'
import routes from 'services/RoutesProvider/routes'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Checkbox from 'components/Checkbox'
import Select from 'components/Select'
import DialogWindow from 'components/Dialog/DialogWindow'
import { ownerTenantsQuery } from 'pages/Teams/queries'
import useRolesViews from 'hooks/useRolesViews'
import { userCanManageTenantTeams } from 'utils/roles'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useCreateTeamForm from './useCreateTeamForm'
import { createTeam } from './mutation'
import { teamDialogClasses as classes } from './styles'
import type { CreateTeamFormValuesT, ICreateTeamDialogProps } from './types'
import type { OwnerT, TeamDataT } from 'types'

export const translations = {
  cancel: 'common.cancel',
  error: 'teams.createDialog.errorMessage',
  next: 'common.next',
  title: 'teams.createDialog.title',
}

const CreateTeamDialog: React.FC<ICreateTeamDialogProps> = ({ open, setOpen, ownerPersons }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const { persons } = useRolesViews()
  const [mutate] = useMutation<{ createTeam: TeamDataT }>(createTeam)
  const { data: dataOwnerTenants, loading: loadingOwnerTenants } = useJamesApolloQuery<{
    getOwner: OwnerT
  }>(ownerTenantsQuery, {
    fetchPolicy: 'no-cache',
    skip: !ownerPersons || !ownerPersons?.owner,
    variables: {
      ownerId: ownerPersons?.owner?.id,
    },
  })

  const [tenantOptions, setTenantOptions] = useState<{ label: string; value: string }[]>([])
  const [showTenantSelect, setShowTenantSelect] = useState(false)

  useEffect(() => {
    const canManageTenants = userCanManageTenantTeams(persons, ownerPersons?.persons ?? [])
    setShowTenantSelect(canManageTenants)

    if (!loadingOwnerTenants && dataOwnerTenants?.getOwner.tenants && canManageTenants) {
      const options = dataOwnerTenants?.getOwner.tenants
        .filter((tenant) => persons?.some((person) => person.roles?.some((role) => role.tenantId === tenant.id)))
        .map((tenant) => ({ label: tenant.name, value: tenant.id }))

      setTenantOptions(options)

      if (options.length <= 1) {
        setShowTenantSelect(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingOwnerTenants, ownerPersons?.persons, persons])

  const onCancel = () => {
    setOpen(false)
  }
  const onError = () => {
    onCancel()
    setFlashMessage(t(translations.error))
  }
  const onSubmit = async (data: CreateTeamFormValuesT, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.preventDefault()

    const defaultTenant = tenantOptions.length > 0 ? tenantOptions[0].value : null
    const tenantId = data.tenant && data.tenant !== '0' ? data.tenant : defaultTenant
    const personId = ownerPersons?.persons[0] ?? 0

    try {
      const result = await mutate({ variables: { ...data, personId: Number(personId), tenantId } })
      const newTeam = result.data?.createTeam

      if (newTeam) {
        history.push(`${routes.teams}/${newTeam.id}/${routes.teamEditMembers}`)
      } else {
        onError()
      }
    } catch (err) {
      onError()
    }
  }
  const { favurForm, getFormHandlers, isMultiLang, setIsMultiLang } = useCreateTeamForm({ onSubmit })
  const multiLangCheckboxProps = {
    ...getFormHandlers('isMultiLang'),
    onChange: () => {
      setIsMultiLang((currentValue) => {
        const newValue = !currentValue
        favurForm.setValue('isMultiLang', newValue)
        return newValue
      })
    },
  }
  // eslint-disable-next-line fp/no-mutating-methods
  const [nameDeInput] = favurForm.watch(['nameDe'])

  useEffect(() => {
    if (nameDeInput && nameDeInput.trim() !== '') {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [nameDeInput])

  return (
    <DialogWindow
      cancelText={t(translations.cancel)}
      confirmDisabled={submitDisabled}
      confirmText={t(translations.next)}
      onCancel={onCancel}
      onConfirm={favurForm.handleSubmit}
      open={open}
      titleText={t(translations.title)}
    >
      {showTenantSelect && (
        <>
          <Typography variant="body2">{t('teams.createDialog.selectTenant.title')}</Typography>
          <Select
            fullWidth
            defaultValue={tenantOptions.length > 0 ? tenantOptions[0].value : '0'}
            {...getFormHandlers('tenant')}
            sx={classes.select}
            disabled={tenantOptions.length === 1}
          >
            {tenantOptions.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </>
      )}
      <Typography variant="body2">{t('teams.createDialog.description')}</Typography>
      <TextField
        variant="standard"
        inputProps={{ 'data-testid': 'new-team-name-de' }}
        sx={classes.input}
        fullWidth
        required
        {...getFormHandlers('nameDe')}
      />
      <Checkbox checked={isMultiLang} sx={classes.checkbox} {...multiLangCheckboxProps} />
      <Typography variant="caption">{t('teams.createDialog.multiLangCheckboxDescription')}</Typography>
      {isMultiLang && (
        <>
          <TextField
            variant="standard"
            inputProps={{ 'data-testid': 'new-team-name-fr' }}
            sx={classes.input}
            fullWidth
            required
            {...getFormHandlers('nameFr')}
          />
          <TextField
            variant="standard"
            inputProps={{ 'data-testid': 'new-team-name-it' }}
            sx={classes.input}
            fullWidth
            required
            {...getFormHandlers('nameIt')}
          />
          <TextField
            variant="standard"
            inputProps={{ 'data-testid': 'new-team-name-en' }}
            sx={classes.input}
            fullWidth
            required
            {...getFormHandlers('nameEn')}
          />
        </>
      )}
    </DialogWindow>
  )
}

export default CreateTeamDialog
