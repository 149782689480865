import React from 'react'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import { taskStates } from 'pages/Tasks/constants'
import { EventsOfTask, MonthlySheetTaskT, TaskT, TimelineItemT } from 'pages/Tasks/types'
import { TimelineItemContentBadgeTypeT } from 'components/Timeline/types'
import Accept from './Accept'
import RequestChange from './RequestChange'
import Plus from './Plus'
import AddComment from './AddComment'
import Call from './Call'
import DeleteWorkflow from './DeleteWorkflow'
import SendReminder from './SendReminder'
import AcceptManager from './AcceptManager'
import AcceptThisMonth from './AcceptThisMonth'
import AcceptNextMonth from './AcceptNextMonth'
import { MonthlySheetStatus } from '../../types'
import AcceptMultiple from './AcceptMultiple'

const getRequestChangeButton = (task: TaskT<MonthlySheetTaskT>, onAction: () => void) => {
  if (task.status === MonthlySheetStatus.markedChangeNextMonth) {
    return null
  }

  const taskHasTimeline = Boolean(task.timeline?.length)
  if (!taskHasTimeline) {
    return null
  }

  const taskHasRequestChange = Boolean(
    task.timeline?.find((timelineItem: TimelineItemT) => {
      return timelineItem.badge === TimelineItemContentBadgeTypeT.wtcChangeRequest
    }),
  )

  const taskUuid = task.favurUuid

  if (taskHasRequestChange) {
    return (
      <AddComment
        variant="text"
        event={EventsOfTask.monthlySheetSendCommentToManager}
        taskUuid={taskUuid}
        onAction={onAction}
      />
    )
  }

  return <RequestChange taskUuid={taskUuid} onAction={onAction} />
}

const getButtonsFromStateFrontline = (
  task: TaskT<MonthlySheetTaskT>,
  onAction: () => void,
  prevMonthTask?: TaskT<MonthlySheetTaskT>,
) => {
  const taskUuid = task.favurUuid
  if (task.statusFe === taskStates.todo) {
    return (
      <ActionCardButtons>
        {prevMonthTask ? (
          <AcceptMultiple taskUuid={taskUuid} prevMonthTaskUuid={prevMonthTask?.favurUuid} onAction={onAction} />
        ) : (
          <Accept taskUuid={taskUuid} onAction={onAction} />
        )}
        {getRequestChangeButton(task, onAction)}
      </ActionCardButtons>
    )
  }

  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons>
        <Accept taskUuid={taskUuid} onAction={onAction} />
        <Plus>
          <AddComment event={EventsOfTask.monthlySheetSendCommentToManager} taskUuid={taskUuid} onAction={onAction} />
          <SendReminder taskUuid={taskUuid} onAction={onAction} />
        </Plus>
      </ActionCardButtons>
    )
  }

  return <></>
}

const getButtonsFromStateOffice = (task: TaskT<MonthlySheetTaskT>, onAction: () => void) => {
  const taskUuid = task.favurUuid
  if (task.statusFe === taskStates.todo) {
    return (
      <ActionCardButtons>
        <AcceptManager>
          <AcceptThisMonth taskUuid={taskUuid} onAction={onAction} />
          <AcceptNextMonth taskUuid={taskUuid} onAction={onAction} />
        </AcceptManager>
        <Plus>
          <AddComment event={EventsOfTask.monthlySheetSendCommentToFrontline} taskUuid={taskUuid} onAction={onAction} />
          <Call />
          <DeleteWorkflow task={task} />
        </Plus>
      </ActionCardButtons>
    )
  }

  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons>
        <Plus>
          <AddComment event={EventsOfTask.monthlySheetSendCommentToFrontline} taskUuid={taskUuid} onAction={onAction} />
          <SendReminder taskUuid={taskUuid} onAction={onAction} isOffice />
          <DeleteWorkflow task={task} />
        </Plus>
      </ActionCardButtons>
    )
  }
  return <></>
}
interface IActionButtonsProps {
  task: TaskT<MonthlySheetTaskT>
  isOffice: boolean
  onAction: () => void
  prevMonthTask?: TaskT<MonthlySheetTaskT>
}

const getButtonsFromTaskAndView = ({ task, isOffice, prevMonthTask, onAction }: IActionButtonsProps) => {
  if (task.statusFe === taskStates.closed) {
    return <></>
  }

  if (isOffice) {
    return getButtonsFromStateOffice(task, onAction)
  }

  return getButtonsFromStateFrontline(task, onAction, prevMonthTask)
}

const ActionButtons: React.FC<IActionButtonsProps> = ({ task, isOffice, prevMonthTask, onAction }) => {
  return <>{getButtonsFromTaskAndView({ task, isOffice, prevMonthTask, onAction })}</>
}

export default ActionButtons
