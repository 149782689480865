import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { TenantT } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import SuccessGeneric, { defaultSuccessPageTimeout } from 'components/SuccessGeneric'
import { taskStates } from 'pages/Tasks/constants'
import { classes } from './styles'

const SuccessScreen: React.FC<{ tenants: TenantT[] }> = ({ tenants }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  useEffect(() => {
    const successTimeout = setTimeout(
      () => history.push(`${routes.tasks}/${taskStates.pending}`),
      defaultSuccessPageTimeout,
    )
    return () => {
      clearTimeout(successTimeout)
    }
  }, [history])

  return (
    <SuccessGeneric
      headerTitle={
        <Typography sx={classes.successTitle} variant="h2" color="primary">
          {t('personalData.sendShareUserDataPage.success.title')}
        </Typography>
      }
    >
      <Box sx={classes.successContainer}>
        <Typography sx={classes.successSharedWithText} variant="body1" color="primary">
          {t('personalData.sendShareUserDataPage.success.tenantsList')}
        </Typography>
        <Box sx={classes.confirmTenantList}>
          {tenants.map((tenant) => (
            <Box key={tenant.id}>
              <Typography variant="body2" sx={classes.confirmTenantText}>
                {tenant.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </SuccessGeneric>
  )
}

export default SuccessScreen
