import React, { useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { tutorialTypes } from 'constants/tutorials'
import PinLogin from 'pages/PinLogin'
import useTutorials from 'hooks/useTutorials'
import routes from 'services/RoutesProvider/routes'
import useStateBackLink from 'hooks/useStateBackLink'
import useRolesViews from 'hooks/useRolesViews'
import { ROLE_VIEWS } from 'constants/roles'
import DetailsPage from './DetailsPage'

const DetailsAbsenceRequestPage: React.FC<RouteComponentProps<{ taskUuid: string }>> = ({ match }) => {
  const {
    params: { taskUuid },
  } = match
  const { activeView } = useRolesViews()
  const isOffice = activeView === ROLE_VIEWS.office

  const history = useHistory()
  const { setBackLink } = useStateBackLink()
  const { beenShownBefore, isReady } = useTutorials()

  useEffect(() => {
    if (!isOffice || !isReady || Boolean(beenShownBefore('absenceRequestManager'))) return
    setBackLink(`${routes.absenceRequestDetail}/${taskUuid}`)
    history.push(`${routes.tutorial}/${tutorialTypes.absenceRequestManager}`)
  }, [history, isReady, beenShownBefore, isOffice, setBackLink, taskUuid])

  return (
    <PinLogin>
      <DetailsPage taskUuid={taskUuid} />
    </PinLogin>
  )
}

export default DetailsAbsenceRequestPage
