import React from 'react'
import theme from 'utils/theme'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const ExternalLinkM: React.FC<IconPropsT> = ({ stroke = theme.palette.grey[500] }) => {
  return (
    <Svg size={ICON_SIZE_M} fill="none" stroke={stroke}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        fillRule="evenodd"
        d="M9.8 14.02 21 3M21 9.3V3h-6.4M12.1 7H3.7a.7.7 0 0 0-.7.7v12.6a.7.7 0 0 0 .7.7h12.6a.7.7 0 0 0 .7-.7v-8.4"
      />
    </Svg>
  )
}

export default ExternalLinkM
