import React from 'react'
import { Box } from '@mui/material'
import { bindKeyboard, virtualize } from 'react-swipeable-views-utils'
import SwipeableViews from 'react-swipeable-views'
import WeekDays from './WeekDays'
import { classes } from './styles'
import useDelay from './useDelay'

interface IWeeklyCalendarBottomProps {
  currentDay: Date
  weekIndex: number
  showDelay: boolean
  onClickDay: (day: Date) => void
  onChangeWeek: (value: number) => void
}

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews))

const WeeklyCalendarBottom: React.FC<IWeeklyCalendarBottomProps> = ({
  currentDay,
  weekIndex,
  showDelay,
  onClickDay,
  onChangeWeek,
}) => {
  const { delayedCurrentDay, delayedWeekIndex } = useDelay(currentDay, weekIndex, showDelay, 500)

  return (
    <Box sx={classes.main(false)}>
      <VirtualizeSwipeableViews
        enableMouseEvents
        index={delayedWeekIndex}
        onChangeIndex={(newIndex, indexLatest) => {
          onChangeWeek(newIndex < indexLatest ? -1 : 1)
        }}
        overscanSlideAfter={1}
        overscanSlideBefore={1}
        slideRenderer={(params) => (
          <WeekDays key={params.index} currentDay={delayedCurrentDay} onClickDay={onClickDay} />
        )}
      />
    </Box>
  )
}

export default WeeklyCalendarBottom
