import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const EmployersM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M14.665 2.556a2.06 2.06 0 0 0-1.491-.265l-3.69.748a2.04 2.04 0 0 0-.715.292 1.978 1.978 0 0 0-.552.542 1.919 1.919 0 0 0-.287 1.491l.096.424-4.175.846a.75.75 0 1 0 .298 1.47l4.89-.99a.746.746 0 0 0 .037-.007l6.153-1.248a.724.724 0 0 0 .022-.005l4.898-.993a.75.75 0 1 0-.298-1.47l-4.2.852-.104-.457a1.95 1.95 0 0 0-.882-1.23ZM14.18 4.54l-.096-.426v-.001a.452.452 0 0 0-.21-.283.56.56 0 0 0-.402-.07L9.78 4.51a.541.541 0 0 0-.19.077.479.479 0 0 0-.133.131.396.396 0 0 0-.063.318l.102.455 4.684-.95ZM5.25 9A.75.75 0 0 1 6 8.25h12a.75.75 0 0 1 .75.75v10.8c0 .544-.24 1.048-.639 1.406a2.162 2.162 0 0 1-1.444.544H7.333c-.528 0-1.049-.189-1.444-.544A1.892 1.892 0 0 1 5.25 19.8V9Zm1.5.75V19.8c0 .092.04.199.142.29a.663.663 0 0 0 .441.16h9.334a.663.663 0 0 0 .44-.16.394.394 0 0 0 .143-.29V9.75H6.75Zm3.25 1.5a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0v-6a.75.75 0 0 1 .75-.75Zm4.75.75a.75.75 0 0 0-1.5 0v6a.75.75 0 0 0 1.5 0v-6Z"
        clipRule="evenodd"
      />
    </Svg>
  )
}

export default EmployersM
