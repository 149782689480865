import React from 'react'
import theme from 'utils/theme'
import { FilledDeniedM } from 'icons'
import useFavurTranslation from 'hooks/useFavurTranslation'
import TaskBadge from '..'
import { getClasses } from '../styles'
import { ITaskBadgeProps } from '../types'

export const getTooltipKey = (withTooltip: boolean, isOffice: boolean) => {
  if (!withTooltip) return undefined
  return isOffice ? `absenceRequest.deletionDenied.tooltipManager` : `absenceRequest.deletionDenied.tooltipFrontliner`
}

const getTextKey = (isActing: boolean) => (isActing ? `timeline.badge.deletionDeny` : `timeline.badge.deletionDenied`)

const DeletionDenied: React.FC<ITaskBadgeProps> = ({ withTooltip = false, isOffice = false, isActing = false }) => {
  const { t } = useFavurTranslation()
  const tooltipKey = getTooltipKey(withTooltip, isOffice)
  return (
    <TaskBadge
      text={t(getTextKey(isActing))}
      sx={getClasses(withTooltip)}
      icon={<FilledDeniedM fill={theme.palette.primary.main} />}
      tooltipText={tooltipKey && t(tooltipKey)}
      testId="task-status_deletion-denied"
    />
  )
}

export default DeletionDenied
