import React, { useCallback, useMemo } from 'react'
import { NotificationT, ShiftUpdateSeenNotificationT } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { stringToFavurDate } from 'utils/date'
import { taskStates } from 'pages/Tasks/constants'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import NotificationTemplate from '../Templates/Notification'

interface IShiftUpdateSeenNotificationProps {
  notification: ShiftUpdateSeenNotificationT
  dismiss: (notification: NotificationT) => void
}

const ShiftUpdateSeenNotification: React.FC<IShiftUpdateSeenNotificationProps> = (props) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { notification, dismiss } = props

  const handleRedirect = useCallback(() => {
    dismiss(notification)

    const { taskUuid } = notification.data
    if (taskUuid) {
      const status = taskStates.closed
      history.push(`${routes.shiftUpdateDetail}/${taskUuid}/${status}`)
    } else {
      history.push(`${routes.tasks}/${taskStates.closed}`)
    }
  }, [dismiss, history, notification])

  const textBody = useMemo(() => {
    const { dateTo, dateFrom, count } = notification.data
    if (count > 1) {
      return t('notification.new.shiftchangeseen.multiday.body', {
        dateFrom: stringToFavurDate(dateFrom),
        dateTo: stringToFavurDate(dateTo),
        count,
      })
    }

    return t('notification.new.shiftchangeseen.singleday.body', { dateFrom: stringToFavurDate(dateFrom) })
  }, [notification.data, t])

  const templateProps = {
    ...props,
    title: t('notification.new.shiftchangeseen.title', { frontlinerNameShort: notification.data.userNameShort }),
    body: textBody,
    dismissText: t('component.notification.button.dismiss'),
    details: handleRedirect,
    detailsText: t('component.notification.button.viewDetails'),
  }

  return <NotificationTemplate {...templateProps} />
}

export default ShiftUpdateSeenNotification
