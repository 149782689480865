import React, { useState } from 'react'
import { Locale } from 'date-fns'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { ArrowDownS, ArrowUpS, CalendarDayM, SideMenuL } from 'icons'
import useFavurTranslation from 'hooks/useFavurTranslation'
import theme from 'utils/theme'
import { getFormattedFromDate, getShortMonthFormat } from 'utils/date'
import SideMenu from 'pages/Shifts/components/SideMenu'
import { classes, shiftsWeeklyCalendarClasses } from './styles'

interface IWeeklyCalendarTopProps {
  referenceDay: Date
  onClickToday: () => void
  toggleCalendarOpen: () => void
  isCalendarOpen: boolean
}

const WeeklyCalendarTop: React.FC<IWeeklyCalendarTopProps> = ({
  referenceDay,
  onClickToday,
  toggleCalendarOpen,
  isCalendarOpen,
}) => {
  const { t, locale } = useFavurTranslation()
  const [sideMenuOpen, setSideMenuOpen] = useState(false)

  return (
    <Box sx={classes.main(isCalendarOpen)}>
      <Box sx={classes.topContainer}>
        <Box sx={classes.headerActions}>
          <Box sx={classes.monthButtonsContainer}>
            <Button
              endIcon={
                isCalendarOpen ? (
                  <ArrowUpS fill={theme.palette.secondary.main} />
                ) : (
                  <ArrowDownS fill={theme.palette.secondary.main} />
                )
              }
              sx={classes.headerActionMonthButton}
              disableRipple
              onClick={toggleCalendarOpen}
              data-testid="calendar-top__month-button"
            >
              <Typography variant="body2" sx={shiftsWeeklyCalendarClasses.dateText}>
                {getFormattedFromDate(referenceDay, getShortMonthFormat(locale as Locale), locale)}
              </Typography>
            </Button>

            <IconButton
              disableRipple
              disableFocusRipple
              sx={shiftsWeeklyCalendarClasses.iconButtons}
              onClick={onClickToday}
              data-testid="calendar-top__today-button"
            >
              <CalendarDayM fill={theme.palette.primary.main} />
              <Typography sx={classes.calendarIconText} variant="body2">
                {t('page.shifts.content.today')}
              </Typography>
            </IconButton>
          </Box>
          <Box>
            <IconButton
              sx={classes.sideMenuIcon}
              onClick={() => setSideMenuOpen(true)}
              disableRipple
              data-testid="header__button--side-menu"
              size="large"
            >
              <SideMenuL />
            </IconButton>
            <SideMenu open={sideMenuOpen} onClose={() => setSideMenuOpen(false)} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default WeeklyCalendarTop
