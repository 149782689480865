import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const BlockingAbsenceM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M9.43 12v.26l1.52-.66a6.67 6.67 0 0 1 8.27-6.07.62.62 0 0 0 .57-1.07 10.19 10.19 0 0 0-4.25-2.19A10 10 0 0 0 3.63 14.82l1.38-.6A8.5 8.5 0 0 1 16.05 3.97a8.11 8.11 0 0 0-5.79 4.44A8.17 8.17 0 0 0 9.43 12Zm3.8 8.5c.96 0 1.92-.16 2.82-.48a8.16 8.16 0 0 1-5.73-4.32l1.38-.6a6.67 6.67 0 0 0 6.89 3.5l.06-.02a6.7 6.7 0 0 0 .57-.11.63.63 0 0 1 .57 1.07A9.92 9.92 0 0 1 13.22 22a10 10 0 0 1-8.03-4.04l1.41-.62a8.5 8.5 0 0 0 6.62 3.16Zm9.9-12.14A.75.75 0 1 0 22.5 7L.68 17.21a.75.75 0 1 0 .64 1.36L23.14 8.36Z"
      />
    </Svg>
  )
}

export default BlockingAbsenceM
