import React from 'react'
import Svg from 'components/Svg'
import theme from 'utils/theme'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const PinPointS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill="none">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={fill ?? theme.palette.primary.main}
        d="M6.25 8a1.75 1.75 0 1 0 3.5 0 1.75 1.75 0 0 0-3.5 0Z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={fill ?? theme.palette.primary.main}
        d="M3.625 8a4.375 4.375 0 1 0 8.75 0 4.375 4.375 0 0 0-8.75 0ZM8 1.5v2M1.5 8h2M8 14.5v-2M14.5 8h-2"
      />
    </Svg>
  )
}

export default PinPointS
