import theme from 'utils/theme'

const getExternalMargins = (isSmallScreen: boolean) => (isSmallScreen ? 0 : '-48px')

export const classes = {
  swipeContainer: (isSmallScreen: boolean) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: isSmallScreen ? 'column' : 'row',
    '&>:nthOfType(2)': {
      width: '100%',
    },
    marginLeft: getExternalMargins(isSmallScreen),
    marginRight: getExternalMargins(isSmallScreen),
    flexGrow: 1,
    maxWidth: theme.breakpoints.values.sm + 96,
  }),
  swipeContainerFullWidth: {
    width: '100%',
  },
  arrowButtons: {
    height: '40px',
    width: '40px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    boxShadow: theme.shadows[2],
    color: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.white,
    },
    top: '252px',
  },
  arrowMarginLeft: {
    marginLeft: theme.spacing(0.5),
  },
  arrowMarginRight: {
    marginRight: theme.spacing(0.5),
  },
  buttonContainer: {
    height: '300px',
    position: 'sticky',
    top: '60px',
  },
  stepper: {
    justifyContent: 'center',
    background: 'none',
    marginBottom: theme.spacing(2),
  },
  buttons: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  pagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  buttonStart: {
    minWidth: theme.spacing(7.5),
  },
}
