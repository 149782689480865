import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const UserDataFamilyL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M25.26 14.47a4.5 4.5 0 0 1 2.48 3.8l.01.23v2.25a.75.75 0 0 1-.65.74l-.1.01h-.84l-.67 5.34a.75.75 0 0 1-.64.65l-.1.01h-3a.75.75 0 0 1-.72-.56l-.03-.1-.18-1.5a.75.75 0 0 1 1.47-.28l.02.1.1.84h1.68l.67-5.34a.75.75 0 0 1 .64-.65l.1-.01h.75v-1.5a3 3 0 0 0-1.48-2.59l-.18-.1a3 3 0 0 0-3.14.29.75.75 0 1 1-.9-1.2 4.5 4.5 0 0 1 4.71-.43zm-13.81.43a.75.75 0 0 1-.9 1.2 3 3 0 0 0-4.8 2.2V20h.75a.75.75 0 0 1 .72.56l.02.1.67 5.34H9.6l.1-.84a.75.75 0 0 1 .74-.66h.1a.75.75 0 0 1 .66.74v.1l-.2 1.5a.75.75 0 0 1-.64.65l-.1.01h-3a.75.75 0 0 1-.73-.56l-.02-.1-.66-5.34H5a.75.75 0 0 1-.74-.65l-.01-.1V18.5a4.5 4.5 0 0 1 7.2-3.6zM16 16.5a4.4 4.4 0 0 1 4.5 4.04v.96a.5.5 0 0 1-.41.5h-1.27l-.73 4.09a.5.5 0 0 1-.4.4l-.09.01h-3.2a.5.5 0 0 1-.47-.33l-.02-.08-.73-4.09H12a.5.5 0 0 1-.5-.41v-.84c0-2.35 2.02-4.25 4.5-4.25zm0 1c-1.88 0-3.4 1.37-3.5 3.07V21h1.1a.5.5 0 0 1 .47.33l.02.08.73 4.09h2.36l.73-4.09a.5.5 0 0 1 .4-.4l.09-.01h1.1v-.25c0-1.73-1.46-3.15-3.3-3.25H16zm0-7a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm8-6.25a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5zm-16 0a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5zm16 1.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5zm-16 0a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5z"
      />
    </Svg>
  )
}

export default UserDataFamilyL
