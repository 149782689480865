import { TFunction } from 'i18next'
import { PersonT } from 'types'
import { getAvatarName, getFirstName, getLastName } from 'utils/person'
import { getFullAvatarUrl } from 'utils/avatar'
import { createAvatarIcon } from 'components/Timeline/factory'
import { TimelineItemT } from '../types'

export const getItsMe = (authorId: string, myPersonIds: number[]): boolean => {
  const itsMe = myPersonIds.find((personId) => {
    return personId.toString() === authorId
  })

  return Boolean(itsMe)
}

export const getAvatarFromPerson = (person: PersonT, t: TFunction, itsMe = false) => {
  const firstName = getFirstName(person)
  const lastName = getLastName(person)
  const userName = itsMe ? t('timeline.avatar.you') : `${firstName} ${lastName}`
  const avatarName = getAvatarName(firstName, lastName)

  const user = person.user
  const avatarUrl = user?.avatarUrl ? getFullAvatarUrl(user.avatarUrl) : undefined
  return createAvatarIcon(avatarName, avatarUrl, userName)
}

export const getAvatarFromTimeline = (timeline: TimelineItemT, t: TFunction, itsMe = false) => {
  const userName = itsMe ? t('timeline.avatar.you') : `${timeline.authorFirstName} ${timeline.authorLastName}`
  const avatarName = getAvatarName(timeline.authorFirstName || '', timeline.authorLastName || '')

  const avatarUrl = timeline.authorAvatarUrl ? getFullAvatarUrl(timeline.authorAvatarUrl) : undefined

  return createAvatarIcon(avatarName, avatarUrl, userName)
}
