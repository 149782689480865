import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Dialog from 'components/Dialog'
import { UndoS } from 'icons'
import palette from 'utils/theme/palette'
import { classes } from './styles'

interface IResetToDefaultButtonProps {
  onClick: () => void
}

const ResetToDefaultButton: React.FC<IResetToDefaultButtonProps> = ({ onClick }) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const { t } = useFavurTranslation()

  const dialogActions = (
    <Box>
      <Button variant="text" onClick={() => setShowConfirmDialog(false)} color="secondary">
        {t('common.cancel')}
      </Button>
      <Button variant="text" onClick={() => onClick()} color="secondary" data-testid="delete-task">
        {t('workTimeControl.confirmationDialog.accept')}
      </Button>
    </Box>
  )

  return (
    <>
      <Button color="error" variant="text" onClick={() => setShowConfirmDialog(true)} sx={classes.resetToDefaultButton}>
        <UndoS fill={palette.error.main} />
        {t('page.userSettings.tenantReminderList.resetToDefault')}
      </Button>
      <Dialog
        open={showConfirmDialog}
        actions={dialogActions}
        title={t('page.userSettings.tenantReminderList.resetToDefaultTitle')}
      >
        <Typography variant="body2">{t('page.userSettings.tenantReminderList.resetToDefaultBody')}</Typography>
      </Dialog>
    </>
  )
}

export default ResetToDefaultButton
