import React, { useCallback } from 'react'
import { Select, MenuItem, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { DropdownDownS } from 'icons'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { teamsHeaderClasses as classes } from '../../styles'
import type { OwnerPersons } from 'hooks/useOwnersOfUser'

interface TeamsHeaderProps {
  owners: OwnerPersons[]
  ownerId: number | undefined
}

const TeamsHeader: React.FC<TeamsHeaderProps> = ({ owners, ownerId }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()

  const ownerObjToKey = useCallback((ownerObj: OwnerPersons) => `${ownerObj.owner.id}`, [])

  return (
    <>
      <Typography variant="h2">{t('page.team.header.title')}</Typography>
      {owners.length > 1 && ownerId && (
        <Select
          variant="standard"
          value={`${ownerId}`}
          onChange={(e) => {
            const value = e.target.value as string
            history.push(routes.teamsWithOwner(value))
          }}
          sx={classes.selectBox}
          IconComponent={() => <DropdownDownS fill="white" sx={classes.selectIcon} />}
        >
          {owners.map((ownerData, _index) => (
            <MenuItem value={ownerObjToKey(ownerData)} key={ownerObjToKey(ownerData)}>
              {ownerData.owner.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  )
}

export default TeamsHeader
