import React from 'react'
import { Box, Typography } from '@mui/material'
import { PersonT } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'

interface ITenantBlockProps {
  person: PersonT
}

const TenantBlock: React.FC<ITenantBlockProps> = ({ person }) => {
  const { t } = useFavurTranslation()
  return (
    <Box sx={classes.block}>
      <Typography variant="body2">{person.tenant?.name}</Typography>
      {person.costCenter && <Typography variant="body2">{person.costCenter?.name}</Typography>}
      {person.employeeNumber && (
        <Typography variant="body2">{`${t('employeeCard.personalNumber.id')} ${person.employeeNumber}`}</Typography>
      )}
    </Box>
  )
}

export default TenantBlock
