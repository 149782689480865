import React from 'react'
import { QueryDates } from 'types'
import { isFuture, isThisMonth } from 'date-fns'
import { Box, IconButton, Typography } from '@mui/material'
import { enGB } from 'date-fns/locale'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { ArrowLeftM, ArrowRightM } from 'icons'
import { classes } from './styles'

export const arialLabels = {
  next: 'Next month',
  previous: 'Previous month',
}
export const testIds = {
  month: 'month',
  next: 'next-month',
  previous: 'previous-month',
}
export type MonthSelectorT = {
  dates: QueryDates
  handleDateChange: (amount: number) => void
  isBalances?: boolean
}

const MonthSelector: React.FC<MonthSelectorT> = ({ dates, handleDateChange, isBalances }) => {
  const { t } = useFavurTranslation()
  const startDate = newDateWithoutTime(dates.start)
  const month = getFormattedFromDate(startDate, 'MMMM', enGB).toLowerCase()

  return (
    <Box sx={classes.container}>
      <Box sx={classes.selector}>
        <IconButton
          aria-label={arialLabels.previous}
          data-testid={testIds.previous}
          onClick={() => handleDateChange(-1)}
          color="secondary"
          sx={classes.selectorIcon}
        >
          <ArrowLeftM />
        </IconButton>
        <Box sx={classes.monthText}>
          <Typography variant="body1" component="span" align="center">
            <strong data-testid={testIds.month}>{t(`component.monthSelector.${month}`)}</strong>
          </Typography>
        </Box>
        <IconButton
          aria-label={arialLabels.next}
          data-testid={testIds.next}
          onClick={() => handleDateChange(1)}
          color="secondary"
          disabled={isBalances && (isFuture(startDate) || isThisMonth(startDate))}
          sx={classes.selectorIcon}
        >
          <ArrowRightM />
        </IconButton>
      </Box>
    </Box>
  )
}

export default MonthSelector
