import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const AlertErrorS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path d="M2.34 2.34a8 8 0 1 1 11.32 11.32A8 8 0 0 1 2.34 2.34ZM8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1Zm3.35 3.65c.2.2.2.5 0 .7L8.71 8l2.64 2.65a.5.5 0 1 1-.7.7L8 8.71l-2.65 2.64a.5.5 0 1 1-.7-.7L7.29 8 4.64 5.35a.5.5 0 1 1 .71-.7L8 7.29l2.64-2.64c.2-.2.52-.2.71 0Z" />
    </Svg>
  )
}

export default AlertErrorS
