import React, { PropsWithChildren } from 'react'
import { Box, Button, SxProps, Theme } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { classes } from './styles'
import BaseDrawer from '.'

interface IBaseDrawerProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  successButtonText: string
  testIdName?: string
  paperSx?: SxProps<Theme>
  focusingTextField?: boolean
  successButtonDisabled?: boolean
}

const BaseInputDrawer: React.FC<PropsWithChildren<IBaseDrawerProps>> = ({
  onClose,
  onSuccess,
  children,
  testIdName,
  successButtonDisabled,
  successButtonText,
  ...props
}) => {
  const { t } = useFavurTranslation()
  return (
    <BaseDrawer {...props} onClose={onClose} testIdName={testIdName} fullHeight>
      <Box sx={classes.content} data-testid={`input-drawer-${testIdName}`}>
        <Box sx={classes.topButtons}>
          <Button color="primary" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button
            color="secondary"
            data-testid={`input-drawer_add-${testIdName}`}
            onClick={() => onSuccess()}
            disabled={successButtonDisabled}
          >
            {successButtonText}
          </Button>
        </Box>
        {children}
      </Box>
    </BaseDrawer>
  )
}

export default BaseInputDrawer
