import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const InfoL: React.FC<IconPropsT> = ({ fill, ...props }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill} {...props}>
      <path d="M16 4a12 12 0 100 24 12 12 0 000-24zm0 5.3a2 2 0 110 4 2 2 0 010-4zm2.7 12.3c0 .3-.2.6-.6.6H14a.6.6 0 01-.6-.6v-1.1c0-.4.3-.6.6-.6h.6v-3.1h-.6a.6.6 0 01-.6-.6V15c0-.3.3-.5.6-.5h3c.4 0 .6.2.6.5V20h.6c.3 0 .6.2.6.6v1.1z" />
    </Svg>
  )
}

export default InfoL
