import React from 'react'
import { SwipeableDrawer } from '@mui/material'
import { AbsencePlannerStateProvider } from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { SelectedStatesProvider } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { PersonT } from 'types'
import { absencePlannerDrawerClasses } from './styles'
import PreviewGrid from './PreviewGrid'

interface IAbsencePlannerDrawerProps {
  isOpen: boolean
  onCancel: () => void
  previewPerson?: PersonT
  startDate: string
  endDate: string
}

const AbsencePlannerDrawer: React.FC<IAbsencePlannerDrawerProps> = ({
  isOpen,
  onCancel,
  previewPerson,
  startDate,
  endDate,
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onCancel}
      onOpen={() => {}}
      sx={absencePlannerDrawerClasses.drawer}
      PaperProps={{ sx: absencePlannerDrawerClasses.paper }}
      disableDiscovery
      disableAutoFocus
      swipeAreaWidth={0}
    >
      <AbsencePlannerStateProvider>
        <SelectedStatesProvider>
          <PreviewGrid
            onCancel={onCancel}
            previewPerson={previewPerson}
            startDate={startDate}
            endDate={endDate}
            isOpen={isOpen}
          />
        </SelectedStatesProvider>
      </AbsencePlannerStateProvider>
    </SwipeableDrawer>
  )
}

export default AbsencePlannerDrawer
