import React, { useCallback } from 'react'
import useFilterContext from 'pages/AbsencePlanner/contexts/FilterContext'
import { TeamT } from 'types'
import TeamsManager from './TeamsManager'
import TeamsFrontliner from './TeamsFrontliner'

const TeamFilter: React.FC<{ isAbsenceManager: boolean }> = ({ isAbsenceManager }) => {
  const { shownTeams, setShownTeams, ownerMemberTeams, ownerPublicTeams, isMultiTenant } = useFilterContext()

  const handleCheckChange = useCallback(
    (team: TeamT, show: boolean) => {
      if (!show) {
        setShownTeams(shownTeams.concat(team.id))
      } else {
        setShownTeams(shownTeams.filter((shownTeam) => shownTeam !== team.id))
      }
    },
    [setShownTeams, shownTeams],
  )

  return (
    <>
      {isAbsenceManager ? (
        <TeamsManager
          onChange={handleCheckChange}
          ownerMemberTeams={ownerMemberTeams}
          ownerPublicTeams={ownerPublicTeams}
          shownTeams={shownTeams}
          isMultiTenant={isMultiTenant}
        />
      ) : (
        <TeamsFrontliner
          onChange={handleCheckChange}
          ownerMemberTeams={ownerMemberTeams}
          ownerPublicTeams={ownerPublicTeams}
          shownTeams={shownTeams}
          isMultiTenant={isMultiTenant}
        />
      )}
    </>
  )
}

export default TeamFilter
