import React, { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import useStoredFilters from 'pages/Shifts/useStoredFilters'
import { sortBy } from 'lodash/fp'
import { getFullTeamLabel } from 'utils/team'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FilterContainer from 'pages/AbsencePlanner/components/TaskBar/Filters/FilterContainer'
import FiltersCheckBox from 'pages/AbsencePlanner/components/TaskBar/Filters/FiltersCheckBox'
import { useSideMenuContext } from '../../context'

const PublicTeams: React.FC = () => {
  const { t, language } = useFavurTranslation()
  const { publicTeams, selectedPublicTeams, setSelectedPublicTeams, isMultiTenant } = useSideMenuContext()
  const { updateStoredFilter } = useStoredFilters()

  const getChangedselectedPublicTeams = useCallback(
    (teamId: string, checked: boolean) => {
      if (!selectedPublicTeams) return []
      const selectedPublicTeamsList = (() => {
        if (checked) return [...selectedPublicTeams, teamId]
        return selectedPublicTeams.filter((id) => id !== teamId)
      })()

      updateStoredFilter('selectedPublicTeams', selectedPublicTeamsList)
      return selectedPublicTeamsList
    },
    [selectedPublicTeams, updateStoredFilter],
  )

  const onPublicTeamCheckChange = useCallback(
    (teamId: string, checked: boolean) => setSelectedPublicTeams(getChangedselectedPublicTeams(teamId, checked)),
    [getChangedselectedPublicTeams, setSelectedPublicTeams],
  )

  const isTeamChecked = useCallback((teamId: string) => selectedPublicTeams && selectedPublicTeams.includes(teamId), [
    selectedPublicTeams,
  ])

  const checkBoxOptions: { label: string; value: string }[] = useMemo(
    () =>
      sortBy(
        'label',
        publicTeams.map((team) => ({ label: getFullTeamLabel(team, language, isMultiTenant), value: team.id })),
      ),
    [isMultiTenant, language, publicTeams],
  )

  return (
    <FilterContainer title={t('page.shifts.sideMenu.otherTeams')}>
      {checkBoxOptions.map((option) => {
        return (
          <Box key={option.label} alignItems="center" display="flex">
            <FiltersCheckBox
              key={option.label}
              label={option.label}
              checked={isTeamChecked(option.value)}
              onChange={(event) => {
                const { checked } = event.target
                onPublicTeamCheckChange(option.value, checked)
              }}
            />
          </Box>
        )
      })}
    </FilterContainer>
  )
}

export default PublicTeams
