import { useEffect, useState } from 'react'
import { QueryStatus } from 'react-query'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import usePackage from 'hooks/usePackage'
import { PersonT } from 'types'
import useActiveConnections from './useActiveConnections'

const getPageToRedirect = (
  currentExpectedSecurityLevel: string,
  hasClassicLightAccess: boolean,
  highSec: PersonT[],
): string | undefined => {
  const highSecConnection = highSec.length !== 0
  const shouldRenderNew = hasClassicLightAccess && highSecConnection
  const expectedHigh = currentExpectedSecurityLevel === 'high'
  const expectedLow = currentExpectedSecurityLevel === 'low'

  // Redirect to security setup
  if (hasClassicLightAccess && !highSecConnection) {
    return routes.securitySetup
  }

  // Redirect to old
  if (expectedHigh && !shouldRenderNew) {
    return routes.personalData
  }

  // redirect to new
  if (expectedLow && shouldRenderNew) {
    return routes.employmentData
  }

  return undefined
}

const useRedirectByTenantTypes = (
  currentExpectedSecurityLevel: 'high' | 'low',
  origin: string | null = null,
): { status: QueryStatus; isRedirecting: boolean } => {
  const history = useHistory()
  const [status, { highSec }] = useActiveConnections()
  const { hasClassicLightAccess } = usePackage()
  const [isRedirecting, setIsRedirecting] = useState(true)

  useEffect(() => {
    if (highSec === undefined) {
      return
    }

    if (highSec.length === 0 && currentExpectedSecurityLevel === 'low') {
      setIsRedirecting(false)
    }

    const pageToRedirect = getPageToRedirect(currentExpectedSecurityLevel, hasClassicLightAccess, highSec)
    if (pageToRedirect) {
      const state = {
        from: { pathname: history.location.pathname },
        ...(origin && { origin }),
      }
      history.replace(pageToRedirect, state)
    } else {
      setIsRedirecting(false)
    }
  }, [history, currentExpectedSecurityLevel, highSec, hasClassicLightAccess, origin])

  return { status, isRedirecting }
}

export default useRedirectByTenantTypes
