import palette from 'utils/theme/palette'

export const styles = {
  contentCard: {
    display: 'flex',
    padding: '8px',
    borderColor: palette.primary[100],
    backgroundColor: 'white',
    marginBottom: '8px',
    borderRadius: '8px',
  },
  dateRangeText: {
    weight: 400,
    size: '12px',
    lineHeight: '18px',
    color: palette.grey[600],
  },
  titleText: {
    weight: 600,
    size: '14px',
    lineHeight: '22px',
    color: palette.grey[900],
  },
  contentCardText: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconText: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  texts: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  arrow: {
    marginTop: '16px',
  },
  contentCardLink: {
    textDecoration: 'none',
  },
}
