import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const FaceIdIosL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M3.42188 9.68359V6.53906c0-1.71883 1.39838-3.11718 3.11718-3.11718h3.14453c.39261 0 .71091-.31831.71091-.71094 0-.39263-.3183-.71094-.71091-.71094H6.53906C4.03623 2 2 4.03621 2 6.53906v3.14453c0 .39261.31831.71091.71094.71091.39263 0 .71094-.3183.71094-.71091ZM9.68359 28.5781c.39261 0 .71091.3183.71091.711 0 .3926-.3183.7109-.71091.7109H6.53906C4.03623 30 2 27.9638 2 25.4609v-3.1445c0-.3926.31831-.7109.71094-.7109.39263 0 .71094.3183.71094.7109v3.1445c0 1.7188 1.39838 3.1172 3.11718 3.1172h3.14453ZM30 22.3164v3.1445C30 27.9638 27.9638 30 25.4609 30h-3.1445c-.3926 0-.7109-.3183-.7109-.7109 0-.3927.3183-.711.7109-.711h3.1445c1.7188 0 3.1172-1.3984 3.1172-3.1172v-3.1445c0-.3926.3183-.7109.711-.7109.3926 0 .7109.3183.7109.7109Zm0-15.77734v3.14453c0 .39261-.3183.71091-.7109.71091-.3927 0-.711-.3183-.711-.71091V6.53906c0-1.71883-1.3984-3.11718-3.1172-3.11718h-3.1445c-.3926 0-.7109-.31831-.7109-.71094 0-.39263.3183-.71094.7109-.71094h3.1445C27.9638 2 30 4.03621 30 6.53906ZM20.9062 22.6947c.2995-.2767.318-.7438.0413-1.0433-.2767-.2995-.7437-.318-1.0432-.0414-1.0658.9845-2.4524 1.5267-3.9044 1.5267s-2.8386-.5422-3.9044-1.5267c-.2995-.2766-.7665-.2581-1.0432.0414-.2767.2995-.2582.7666.0413 1.0433 1.3395 1.2372 3.0818 1.9185 4.9063 1.9185s3.5668-.6813 4.9063-1.9185Zm-3.4296-10.14v5.25c0 1.0403-.8464 1.8867-1.8868 1.8867h-.6015c-.4078 0-.7383-.3305-.7383-.7383 0-.4077.3305-.7383.7383-.7383h.6015c.2262 0 .4102-.184.4102-.4101v-5.25c0-.4078.3305-.7383.7383-.7383.4077 0 .7383.3305.7383.7383Zm5.0859 2.0644v-2.1054c0-.3851-.3122-.6973-.6973-.6973-.385 0-.6972.3122-.6972.6973v2.1054c0 .3851.3122.6973.6972.6973.3851 0 .6973-.3122.6973-.6973Zm-12.96094 0c0 .3851.31219.6973.69724.6973.3851 0 .6973-.3122.6973-.6973v-2.1054c0-.3851-.3122-.6973-.6973-.6973-.38505 0-.69724.3122-.69724.6973v2.1054Z"
      />
    </Svg>
  )
}

export default FaceIdIosL
