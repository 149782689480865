import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import ActionButtons from 'components/Buttons/ActionButtons'
import { FormsAccessPointT } from 'components/Forms/types'

interface IFormButtonsProps {
  onCancel: () => void
  onSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  accessPoint: FormsAccessPointT
}

const FormsButtons: React.FC<IFormButtonsProps> = ({ onCancel, onSubmit, accessPoint }) => {
  const { t } = useFavurTranslation()

  return (
    <ActionButtons
      cancelAction={onCancel}
      cancelLabel={t(accessPoint === 'userSettings' ? 'personalData.button.cancel' : 'userDataWorkflow.button.cancel')}
      validateLabel={t(accessPoint === 'userSettings' ? 'personalData.button.save' : 'userDataWorkflow.button.save')}
      validateAction={onSubmit}
    />
  )
}

export default FormsButtons
