import React, { useCallback } from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import BackwardLink from 'components/BackwardLink'
import { headerClasses } from 'pages/UserSettings/styles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Page from 'components/Page'
import { SimpleLoading } from 'components/LoadingIcon'
import usePersonTeams from 'pages/UserSettings/hooks/usePersonTeams'
import { Box } from '@mui/material'
import UserSettingsSectionCTA from 'pages/UserSettings/components/SectionCTA'
import { TeamsM } from 'icons'
import palette from 'utils/theme/palette'
import { getTeamNameByLanguage } from 'types/utils'
import { TeamT } from 'types'
import routes from 'services/RoutesProvider/routes'
import { classes } from './styles'

const EmployerPersonTeamsPage: React.FC = () => {
  const { favurUuid } = useParams<{ favurUuid: string }>()
  const { t, language } = useFavurTranslation()
  const history = useHistory()
  const { person, loading } = usePersonTeams(favurUuid)
  const gotoBackLink = useCallback(() => {
    history.goBack()
  }, [history])

  if (!loading && person?.teamPermissions && person.teamPermissions.length === 1) {
    return <Redirect to={`${routes.employersTeams(person.favurUuid as string)}/${person.teamPermissions[0].teamId}`} />
  }

  return (
    <Page
      header={<BackwardLink onClickIcon={gotoBackLink} title={t('navigation.main.menu.teams')} />}
      sxHeader={headerClasses.subHeader}
    >
      {loading || !person ? (
        <SimpleLoading />
      ) : (
        <Box sx={classes.teamList}>
          {person.teamPermissions?.map((ptp) => (
            <UserSettingsSectionCTA
              icon={<TeamsM fill={palette.primary.main} />}
              title={getTeamNameByLanguage(ptp.team as TeamT, language)}
              onClick={() => {
                history.push(`${routes.employersTeams(person.favurUuid as string)}/${ptp.teamId}`)
              }}
              key={ptp.team?.id}
            />
          ))}
        </Box>
      )}
    </Page>
  )
}

export default EmployerPersonTeamsPage
