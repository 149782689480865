import React, { useCallback, useContext, useState } from 'react'
import { removeTypenameFromObject } from 'utils/apollo'
import FlashMessagesContext from 'services/FlashMessages/context'
import useUserSettings from 'hooks/useUserSettings'
import { SettingsT } from 'types'
import { PendingSwitchT, SwitchStatesT } from './types'
import { defaultInAppSettings, defaultSettings } from './constants'

const useNotificationSettings = () => {
  const { removeAll, setFlashMessage } = useContext(FlashMessagesContext)
  const [open, setOpen] = useState(false)
  const [pendingSwitchState, setPendingSwitchState] = useState<PendingSwitchT>()
  const [pushSwitchStates, setPushSwitchStates] = useState<SwitchStatesT>({})
  const [inAppSwitchStates, setInAppSwitchStates] = useState<SwitchStatesT>({})

  const { updateUserSettings: saveSettings, loading, refetch } = useUserSettings({
    onComplete: (settings) => {
      const push = removeTypenameFromObject(settings?.pushNotifications ?? defaultSettings)
      setPushSwitchStates(push as SwitchStatesT)

      const inApp = removeTypenameFromObject(settings?.inAppNotifications ?? defaultInAppSettings)
      setInAppSwitchStates(inApp as SwitchStatesT)
    },
  })

  const handlePushChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const switchWitchDialog = ['shiftsUpdate']
      const setting = event.target.name
      const value = event.target.checked
      if (!value && switchWitchDialog.includes(setting)) {
        setPendingSwitchState({ switchName: setting, switchValue: value })
        setOpen(true)
      } else {
        setPushSwitchStates({ ...pushSwitchStates, [setting]: value })
      }

      if (value && setting in inAppSwitchStates) {
        setInAppSwitchStates({ ...inAppSwitchStates, [setting]: value })
      }
    },
    [inAppSwitchStates, pushSwitchStates],
  )

  const handleInAppChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInAppSwitchStates({ ...inAppSwitchStates, [event.target.name]: event.target.checked })
    },
    [inAppSwitchStates],
  )

  const handleClose = () => {
    setOpen(false)
    setPendingSwitchState(undefined)
  }

  const disableAnyway = useCallback(() => {
    if (pendingSwitchState) {
      setPushSwitchStates({ ...pushSwitchStates, [pendingSwitchState.switchName]: pendingSwitchState.switchValue })
    }

    setPendingSwitchState(undefined)
    setOpen(false)
  }, [pendingSwitchState, pushSwitchStates])

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      removeAll()
      e.preventDefault()
      const settings = {
        pushNotifications: pushSwitchStates,
        inAppNotifications: inAppSwitchStates,
      } as SettingsT

      const mutationResult = await saveSettings(settings)

      if (mutationResult?.data?.updateUserSettings.success) {
        setFlashMessage('page.userSettings.personalData.updateSuccessMessage', 2000)
      }

      refetch()
    },
    [removeAll, pushSwitchStates, inAppSwitchStates, saveSettings, refetch, setFlashMessage],
  )

  return {
    pushSwitchStates,
    inAppSwitchStates,
    loading,
    dialogOpen: open,
    handlePushChange,
    handleInAppChange,
    handleClose,
    disableAnyway,
    onSubmit,
  }
}

export default useNotificationSettings
