import React, { useMemo } from 'react'
import { Typography, Box, Chip } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import Badge from 'components/Badge'
import { PlannedShiftT, ShiftsByTenantCCT } from 'types'
import { prepareShowingShifts } from 'components/ShiftsList/components/utils'
import { getShiftKey } from 'types/utils'
import { ShiftUpdateTaskT, TaskT } from 'pages/Tasks/types'
import { ChangeAlertS } from 'icons'
import { classes } from './styles'

interface IBadgesProps {
  tenant: ShiftsByTenantCCT
  shiftUpdates: TaskT<ShiftUpdateTaskT>[]
}
const Badges: React.FC<IBadgesProps> = ({ tenant, shiftUpdates }) => {
  const { t } = useFavurTranslation()

  const shifts = tenant.shifts as PlannedShiftT[]

  const hasUnacknowledgedTask = useMemo(() => {
    const unacknowledgedTaskIds = shiftUpdates
      .filter((task) => !task.taskData.acknowledgedAt)
      .map((task) => `${task.id}`)
    return shifts.filter((shift) => unacknowledgedTaskIds.includes(`${shift.updateTaskId}`)).length > 0
  }, [shiftUpdates, shifts])

  const showFootnote = useMemo(() => shifts.find((shift) => shift.isManual === true) && !hasUnacknowledgedTask, [
    hasUnacknowledgedTask,
    shifts,
  ])

  const showingShifts = useMemo(() => prepareShowingShifts(shifts), [shifts])

  return (
    <>
      <Box sx={classes.badgesContainer}>
        {hasUnacknowledgedTask ? (
          <Chip
            sx={classes.unacknowledgedChip}
            label={
              <Box sx={classes.hiddenShiftContainer}>
                <ChangeAlertS />
                <Typography variant="caption" sx={classes.hiddenShift}>
                  {t('page.shifts.shiftCard.shiftUpdate.hidden')}
                </Typography>
              </Box>
            }
          />
        ) : (
          showingShifts.map((shift) => <Badge shift={shift} key={getShiftKey(shift, 'nextShift')} sx={classes.badge} />)
        )}
      </Box>
      {showFootnote && (
        <Typography variant="caption" sx={classes.footnote}>
          {t('page.dashboard.content.manualShift')}
        </Typography>
      )}
    </>
  )
}

export default Badges
