import { Box, Typography } from '@mui/material'
import React from 'react'
import { CmsContentT } from 'types'
import { useOwnersOfUser } from 'hooks/useOwnersOfUser'
import { SimpleLoading } from 'components/LoadingIcon'
import { styles } from '../styles'
import ContentCard from './ContentCard'
import NoPages from './NoPages'
import useListCmsContents from '../useListCmsContents'

const PageList: React.FC = () => {
  const { owners } = useOwnersOfUser({ teamManagersOnly: false })
  const { contents, loading } = useListCmsContents()

  if (loading) {
    return <SimpleLoading />
  }

  if (!loading && contents?.length === 0) {
    return <NoPages />
  }

  return (
    <>
      <Box>
        <Typography variant="h3" sx={styles.title}>
          {owners?.[0]?.owner?.name ?? ''}
        </Typography>
      </Box>
      <Box sx={styles.cardContainer}>
        {contents?.map((cardContent: CmsContentT) => (
          <ContentCard key={cardContent.uuid} content={cardContent} />
        ))}
      </Box>
    </>
  )
}

export default PageList
