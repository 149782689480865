import React, { useCallback } from 'react'
import { sortBy } from 'lodash/fp'
import { PersonT } from 'types'
import EmployeeList from 'components/EmployeeList'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { getSortingLastName } from 'utils/person'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import { PersonWithWorkflow } from '../useRequestEmployeeData'

interface ISingleProps {
  allPersons: PersonT[]
  members: Map<number, boolean>
  setMembers: React.Dispatch<React.SetStateAction<Map<number, boolean>>>
}

const Single: React.FC<ISingleProps> = ({ allPersons, members, setMembers }) => {
  const { t } = useFavurTranslation()

  const onChange = useCallback(
    ({ id }: { id: number }) => {
      setMembers((curr) => new Map(curr).set(id, !curr.get(id)))
    },
    [setMembers],
  )

  const getChecked = useCallback((id: number): boolean => members.get(id) ?? false, [members])

  const getInfoText = useCallback(
    (person: PersonT) => {
      const personWithWorkflow = person as PersonWithWorkflow
      const { shareUserDataTask, lastSharedData } = personWithWorkflow
      if (shareUserDataTask) {
        return t('requestEmployeeData.single.subTitle.alreadyRequested', {
          date: getFormattedFromISOString(shareUserDataTask.insertedAt, uiDateFormat, undefined),
        })
      }

      return lastSharedData
        ? t('requestEmployeeData.single.subTitle.lastShared', {
            lastSharedData: getFormattedFromISOString(lastSharedData, uiDateFormat, undefined),
          })
        : t('requestEmployeeData.single.subTitle.noData')
    },
    [t],
  )

  return (
    <EmployeeList
      employees={sortBy((p) => getSortingLastName(p), allPersons)}
      checkbox
      onClickItem={(person) => {
        onChange({ id: person.id as number })
      }}
      itemIsChecked={(person) => getChecked(person.id as number)}
      itemIsDisabled={(person) => (person as PersonWithWorkflow).shareUserDataTask !== undefined}
      search
      getInfo={getInfoText}
      data-testid="employee-list"
    />
  )
}

export default Single
