import React from 'react'
import { IonPicker } from '@ionic/react'
import { Box } from '@mui/material'
import { classes } from './styles'

interface IWheelPickerProps {
  children?: React.ReactNode
}

const WheelPicker: React.FC<IWheelPickerProps> = ({ children }) => {
  return (
    <Box sx={classes.pickerStyles}>
      <IonPicker>{children}</IonPicker>
    </Box>
  )
}

export default WheelPicker
