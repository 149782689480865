import React, { useCallback, useRef } from 'react'
import { Box } from '@mui/material'
import { AbsenceRequestTaskT, TaskT } from 'pages/Tasks/types'
import NoTaskBox from 'pages/Tasks/NoTaskBox'
import useScrolledState from 'hooks/useScrolledState'
import { SimpleLoading } from 'components/LoadingIcon'
import useSelectedStatesContext, {
  useSelectedStatesActionsContext,
} from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { DivisionLine } from 'components/Basics'
import LoadMoreButton from 'components/LoadMoreButton'
import { absenceRequestListClasses } from '../styles'
import AbsenceRequestListFooter from '../Footer'
import AbsenceRequestListHeader from '../Header'
import PinPointableTaskCard from '../PinPointableTaskCard'

interface IAbsenceRequestListProps {
  tasks: TaskT<AbsenceRequestTaskT>[]
  title: string
  subtitle: string
  onNew: () => void
  office?: boolean
  loading?: boolean
  loadMore?: () => void
  hasMoreElements?: boolean
  hideTitle?: boolean
}

const AbsenceRequestList: React.FC<IAbsenceRequestListProps> = ({
  tasks,
  title,
  subtitle,
  office,
  loading,
  loadMore,
  hasMoreElements,
  onNew,
  hideTitle = false,
}) => {
  const listRef = useRef(null)
  const { checkIsScrolled, hasOverflow, isAtBottom, isScrolled } = useScrolledState(listRef)
  const { selectedPerson } = useSelectedStatesContext()
  const { deselectPerson } = useSelectedStatesActionsContext()

  const goBackCallback = useCallback(() => {
    if (!office || !selectedPerson) {
      return undefined
    }

    return () => {
      deselectPerson()
    }
  }, [office, deselectPerson, selectedPerson])

  return (
    <Box sx={absenceRequestListClasses.container}>
      {!hideTitle && (
        <Box sx={absenceRequestListClasses.header(isScrolled)}>
          <AbsenceRequestListHeader title={title} subtitle={subtitle} goBack={goBackCallback()} />
        </Box>
      )}
      <Box
        sx={absenceRequestListClasses.taskListContainer}
        ref={listRef}
        onScroll={() => {
          checkIsScrolled()
        }}
      >
        {loading && (
          <div data-testid="loading-absence-planner-task-list">
            <SimpleLoading />
          </div>
        )}
        {!loading && tasks.length === 0 && <NoTaskBox />}
        {tasks.map((task) => (
          <PinPointableTaskCard task={task} key={task.id} office={Boolean(office)} />
        ))}
        {!loading && hasMoreElements && (
          <LoadMoreButton
            onClick={() => {
              loadMore && loadMore()
            }}
            testId="tasks-load-more"
          />
        )}
      </Box>

      <Box sx={absenceRequestListClasses.footer(hasOverflow, isAtBottom)}>
        <Box sx={absenceRequestListClasses.bottomDivisionLineContainer}>
          <DivisionLine />
        </Box>
        <AbsenceRequestListFooter onNew={onNew} />
      </Box>
    </Box>
  )
}

export default AbsenceRequestList
