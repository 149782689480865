import React from 'react'
import { createRoot } from 'react-dom/client'
import { defineCustomElements } from '@ionic/pwa-elements/loader'
import './i18n/config'
import './services/Datadog/configLogs'
import './services/Datadog/configRum'
import App from './App'
import './utils/favicon'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)
root.render(<App />)

// Call the element loader after the platform has been bootstrapped.
defineCustomElements(window)
