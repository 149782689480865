import React, { useCallback, useState } from 'react'
import { Backdrop, InputAdornment, Popper } from '@mui/material'
import { Controller } from 'react-hook-form'
// eslint-disable-next-line import/no-extraneous-dependencies
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { newDateWithoutTime } from 'utils/date'
import { isNative } from 'utils/platform'
import { CalendarAppointmentS } from 'icons'
import { FormHandlersT } from 'hooks/useFavurForm'
import { uiDateFormat } from 'utils/constants'
import palette from 'utils/theme/palette'
import CustomActionBar from '../components/ActionBar'
import { classes, datePickerClasses } from '../styles'

interface IDatePickerProps {
  autoOpen?: boolean
  dataTestId: string
  // eslint-disable-next-line
  favurForm: Record<string, any>
  formField: `${string}`
  formHandlers: FormHandlersT
  minDate?: Date
  required?: boolean
  label?: string
  backdrop?: boolean
  useDefaultValue?: boolean
}

const DatePicker: React.FC<IDatePickerProps> = ({
  autoOpen,
  dataTestId,
  favurForm,
  formField,
  formHandlers,
  minDate,
  required = true,
  label,
  backdrop = false,
  useDefaultValue = true,
}) => {
  const [open, setOpen] = useState(false)

  const renderDatePicker = useCallback(
    // @ts-ignore
    ({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
      const { label: dateFromLabel, helperText: dateFromHelperText } = formHandlers

      return (
        <MuiDatePicker
          label={label ? label : dateFromLabel}
          minDate={minDate || newDateWithoutTime()}
          format={uiDateFormat}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(data: any, v: any) => {
            onChange(data, v)
            favurForm.trigger(formField)
          }}
          value={value ? newDateWithoutTime(value) : null}
          inputRef={ref}
          open={open}
          slots={{
            actionBar: CustomActionBar,
            openPickerIcon: () => (
              <InputAdornment position="end" sx={classes.openPickerIcon}>
                <CalendarAppointmentS fill={palette.secondary.main} />
              </InputAdornment>
            ),
          }}
          onAccept={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          defaultValue={useDefaultValue ? minDate : undefined}
          slotProps={{
            layout: {
              sx: datePickerClasses.layout,
            },
            popper: {
              slots: {
                ...(isNative() || !backdrop
                  ? {}
                  : {
                      root: (rootProps) => (
                        <>
                          <Backdrop open={open} />
                          <Popper
                            open={open}
                            {...rootProps}
                            sx={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}
                          />
                        </>
                      ),
                    }),
              },
            },
            dialog: {
              sx: {
                ...datePickerClasses.monthSwitcherIcons,
                ...datePickerClasses.baseDialog,
              },
            },
            toolbar: { hidden: true },
            desktopPaper: backdrop ? { sx: { boxShadow: 0 } } : undefined,
            textField: {
              variant: 'standard',
              helperText: dateFromHelperText,
              sx: { width: '100%' },
              required,
              onBlur,
              onFocus(e: React.FocusEvent<HTMLInputElement>) {
                if (!e.relatedTarget) {
                  setOpen(Boolean(autoOpen))
                }
              },
              error: Boolean(error),
              inputProps: {
                //@ts-ignore
                'data-testid': dataTestId,
              },
            },
          }}
          views={['year', 'month', 'day']}
        />
      )
    },
    [
      formHandlers,
      label,
      backdrop,
      minDate,
      open,
      required,
      dataTestId,
      autoOpen,
      favurForm,
      formField,
      useDefaultValue,
    ],
  )
  // @ts-ignore
  return <Controller control={favurForm.control} name={formField} render={renderDatePicker} />
}

export default DatePicker
