import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import routes from '../RoutesProvider/routes'

const useRedirect = () => {
  const history = useHistory()
  const onSuccess = useCallback(
    (response: AxiosResponse) => {
      if (response.data?.errors && Array.isArray(response.data.errors) && response.data.errors.length) {
        if (response.data.errors.find((err: { code: string }) => err.code === 'common.error.be.termsAndConditions')) {
          history.push(routes.termsAndConditionsUpdate)
          return Promise.reject(response.data.errors[0])
        }
      }
      return response
    },
    [history],
  )

  const onReject = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: any,
    ) => {
      if (error?.code === 'common.error.be.termsAndConditions') {
        history.push(routes.termsAndConditionsUpdate)
      }

      return Promise.reject(error)
    },
    [history],
  )

  return [onSuccess, onReject]
}

export default useRedirect
