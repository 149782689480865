import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const ArrowRightS: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_S} fill={fill}>
      <path
        fillRule="evenodd"
        d="M5.44 2.146l-.706.708a.499.499 0 000 .707L9.172 8l-4.438 4.44a.497.497 0 000 .706l.707.708a.5.5 0 00.707 0l5.5-5.5a.502.502 0 000-.708l-5.5-5.5a.5.5 0 00-.707 0z"
      />
    </Svg>
  )
}

export default ArrowRightS
