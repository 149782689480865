/* eslint-disable camelcase */
export const absencePeriods: { [key: string]: string } = {
  fullDay: 'absenceRequest.new.fullDay',
  halfDayMorning: 'absenceRequest.new.halfDayMorning',
  halfDayAfternoon: 'absenceRequest.new.halfDayAfternoon',
}

export type AbsencePeriodsT = 'full_day' | 'half_day_morning' | 'half_day_afternoon'
export const absencePeriodsTranslationMap: Record<string, string> = {
  full_day: 'fullDay',
  half_day_morning: 'halfDayMorning',
  half_day_afternoon: 'halfDayAfternoon',
}

export enum PersonStatusType {
  active = 'active',
  applicant = 'applicant',
  inactive = 'inactive',
}

export type TaskTypeT = 'absence_request' | 'share_user_data' | 'monthly_sheet' | 'shift_update'
export const taskTypes: Record<string, TaskTypeT> = {
  absenceRequest: 'absence_request',
  shareUserData: 'share_user_data',
  monthlySheet: 'monthly_sheet',
  shiftUpdate: 'shift_update',
}
