import React from 'react'
import { Box } from '@mui/material'
import { TimelineItemContentT } from '../../types'
import { classes } from './styles'
import TimeLineItemDocument from './Document'
import TimelineItemComponentText from './Text'
import TimeLineItemBadge from './Badge'
import TimeLineItemForm from './Form'

interface IItemContentProps {
  content: TimelineItemContentT | undefined
}

const ItemContent: React.FC<IItemContentProps> = ({ content }) => {
  if (!content) return null

  const hasGreyContainedElements = Boolean(content.text || content.badge)

  return (
    <Box sx={classes.card}>
      {content.document && <TimeLineItemDocument document={content.document} tenantName={content.tenantName} />}
      {content.formTaskUuid && <TimeLineItemForm tenantName={content.tenantName} clickable={content.clickable} />}
      {hasGreyContainedElements && (
        <Box sx={classes.greyContainedElements}>
          {content.badge && <TimeLineItemBadge badge={content.badge} datetime={content.datetime} />}
          {content.textHeader && content.textHeader}
          {content.text && <TimelineItemComponentText text={content.text} />}
        </Box>
      )}
    </Box>
  )
}

export default ItemContent
