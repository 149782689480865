import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import theme from 'utils/theme'
import { ArrowRightThinM, InfoS } from 'icons'
import { IconPropsT } from 'icons/types'
import DivisionLine from 'components/Basics/DivisionLine'
import { classes } from './styles'

interface ListItemProps {
  icon?: React.ReactNode
  onClick?: () => void
  showDivider?: boolean
  text: string
  warning?: string
  info?: string
  alertIcon?: React.FC<IconPropsT>
  disabled?: boolean
  subText?: string
}

const ListItem: React.FC<ListItemProps> = ({
  icon,
  onClick = () => {},
  showDivider = true,
  text,
  warning,
  info,
  alertIcon: AlertIcon = InfoS,
  disabled = false,
  subText,
}) => {
  return (
    <>
      <Box sx={[classes.listItem, ...(info ? [classes.infoPadding] : [])]}>
        <IconButton type="button" sx={classes.displayButton} onClick={onClick} disableRipple size="large">
          <Box sx={classes.content}>
            <Box sx={classes.nameLine}>
              <Box component="span" sx={classes.mainText}>
                <Typography
                  variant="subtitle2"
                  sx={[classes.mainTextTypography, ...(disabled ? [classes.mainTextDisabled] : [])]}
                  data-testid="list-item"
                >
                  {text}
                </Typography>
              </Box>
              <Box component="span" sx={classes.mainIcon}>
                {icon ?? <ArrowRightThinM />}
              </Box>
            </Box>
            {(warning || subText) && (
              <Box sx={classes.subText}>
                {subText && (
                  <Typography sx={classes.caption} variant="caption">
                    {subText}
                  </Typography>
                )}
                {warning && (
                  <Box component="span" sx={classes.warning}>
                    <AlertIcon fill={theme.palette.secondary.main} />
                    <Typography sx={classes.warningText} variant="caption">
                      {warning}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {info && (
              <Box sx={classes.subText}>
                <Box component="span" sx={classes.info}>
                  <Typography
                    sx={[classes.infoText, ...(disabled ? [classes.infoTextDisabled] : [])]}
                    variant="caption"
                  >
                    {info}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </IconButton>
      </Box>
      {showDivider && <DivisionLine />}
    </>
  )
}

export default ListItem
