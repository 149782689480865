import React, { useState, useEffect, useCallback } from 'react'
import { Box, Typography, Button } from '@mui/material'
import info from 'info.json'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { gql } from '@apollo/client'
import { isAndroidNative, isIphoneNative, isNativeNoOverride } from 'utils/platform'
import { ANDROID_PLAY_STORE_LINK, IOS_APP_STORE_LINK } from 'constants/appStores'
import Page from 'components/Page'
import { FF_APP_VERSION, appVersions } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import useFeatureFlagContext from 'hooks/useFeatureFlagContext'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import VersionRequirementDialog from './VersionRequirementDialog'
import { classes } from './styles'

export const localStorageKey = 'lastRecommendedVersion'

export type VersionNumberObjectT = {
  recommendedVersion: string
  requiredVersion: string
}

export const query = gql`
  query versionRequirement($versionNumber: String) {
    versionRequirement(buildVersionNumber: $versionNumber) {
      recommendedVersion
      requiredVersion
    }
  }
`

export const getMostRecentVersion = (version1: string, version2: string): string => {
  const comparison = version1.localeCompare(version2, undefined, { numeric: true })
  return comparison > -1 ? version1 : version2
}

const saveToLocalStorage = (version: string) => {
  localStorage.setItem(localStorageKey, version)
}

interface Props {
  children?: React.ReactNode
}

const UpdateApp: React.FC<Props> = ({ children }) => {
  const { t } = useFavurTranslation()
  const FFVersion = useFeatureFlag(FF_APP_VERSION)
  const { settings: setFeatureFlag } = useFeatureFlagContext()
  const currentVersion = info.version
  const { data, loading } = useJamesApolloQuery<{ versionRequirement: VersionNumberObjectT }>(query, {
    fetchPolicy: 'cache-and-network',
    variables: { versionNumber: currentVersion },
  })
  const [dialogSeen, setDialogSeen] = useState(false)
  const [recommendedVersion, setRecommendedVersion] = useState<string>(currentVersion)
  const [requiredVersion, setRequiredVersion] = useState<string>(currentVersion)

  const redirect = useCallback(() => {
    if (isNativeNoOverride()) {
      if (isIphoneNative()) {
        window.location.href = IOS_APP_STORE_LINK
      }
      if (isAndroidNative()) {
        window.location.href = ANDROID_PLAY_STORE_LINK
      }
    } else {
      window.location.reload()
    }
  }, [])

  const dismissDialog = useCallback(() => {
    FFVersion !== appVersions.current && setFeatureFlag(FF_APP_VERSION, appVersions.current)
    setDialogSeen(true)
    saveToLocalStorage(recommendedVersion)
  }, [FFVersion, recommendedVersion, setFeatureFlag])

  useEffect(() => {
    setFeatureFlag(FF_APP_VERSION, appVersions.current)
  }, [redirect, setFeatureFlag])

  useEffect(() => {
    if (loading || !data) return

    const {
      versionRequirement: { recommendedVersion: recommendedVersionData, requiredVersion: requiredVersionData },
    } = data

    setRecommendedVersion(recommendedVersionData)
    setRequiredVersion(requiredVersionData)
    setDialogSeen(localStorage.getItem(localStorageKey) === recommendedVersionData)
  }, [loading, data])

  if (!data || loading) return <>{children}</>

  const meetsRequired = getMostRecentVersion(currentVersion, requiredVersion) === currentVersion
  const meetsRecommended = getMostRecentVersion(currentVersion, recommendedVersion) === currentVersion
  const showDialog = (!meetsRecommended && !dialogSeen) || FFVersion === appVersions.beforeRecommended

  if (!meetsRequired || FFVersion === appVersions.beforeRequired) {
    return (
      <Page hideNativeNavigation hideWebToolbar flex showHeader hideHamburger>
        <Box sx={classes.content}>
          <Box sx={classes.imageContainer}>
            <img src="/assets/images/UpdateApp/UpdateApp.png" alt="Update application" data-testid="update-app-image" />
            <Typography variant="h1" sx={classes.title} align="center">
              {t('updateApp.title')}
            </Typography>
            <Typography variant="body1" sx={classes.text}>
              {t('updateApp.text')}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button size="large" variant="contained" color="secondary" onClick={redirect}>
              {t('updateApp.button')}
            </Button>
          </Box>
        </Box>
      </Page>
    )
  }

  return (
    <>
      {showDialog && <VersionRequirementDialog dismiss={dismissDialog} />}
      {children}
    </>
  )
}

export default UpdateApp
