import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'
import { DivisionLine } from 'components/Basics'
import Card from 'components/Basics/Card'
import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import routes from 'services/RoutesProvider/routes'
import { HomeS } from 'icons'
import { PersonT } from 'types'
import { confirmClasses as classes } from './styles'
import { profileClasses } from '../../styles'

const DetailedEmployersElement: React.FC<{
  person: PersonT
  onDelete: (favurUuid: string, tenantName: string) => void
}> = ({ person, onDelete }) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const [showDialog, setShowDialog] = useState(false)
  const personMarkedToDelete = Boolean(person.deleteAfter)

  return (
    <>
      <Card data-testid={`user-settings_employer-container_${person.id}`}>
        <Box sx={[profileClasses.listElement]}>
          <Box sx={personMarkedToDelete ? classes.disabledColor : []}>
            <Grid container spacing={1}>
              <Grid item xs={12} data-testid="user-settings_tenant-name">
                <Box sx={classes.tenantNameTitle}>
                  <Box sx={[classes.homeIcon, ...(personMarkedToDelete ? [classes.disabledColor] : [])]}>
                    <HomeS />
                  </Box>
                  <Typography variant="subtitle1" sx={classes.tenantNameTitleText}>
                    {person.tenant?.name || '-'}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="caption">{t('component.employeeDetail.activeSince')}</Typography>
                <Typography variant="subtitle1" sx={classes.subtitle}>
                  {person.tenantConnectionEstablished
                    ? getFormattedFromISOString(person.tenantConnectionEstablished, uiDateFormat, undefined)
                    : '-'}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="caption">{t('documents.label')}</Typography>
                <Typography variant="subtitle1" sx={classes.subtitle}>
                  {person.numberOfDocuments || 0} {t('documents.label')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6} data-testid="user-settings_status-active">
                <Typography variant="caption">{t('page.userSettings.employers.status')}</Typography>
                <Typography variant="subtitle1" sx={classes.subtitle}>
                  {person.status !== 'inactive'
                    ? t('page.userSettings.employers.active')
                    : t('page.userSettings.employers.terminated')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {person.status === 'inactive' && (
            <Typography variant="body2">{t('deleteConnection.documents.inactiveText')}</Typography>
          )}

          <DivisionLine />
          {!personMarkedToDelete ? (
            <Box>
              <Box sx={classes.flexAndColumn}>
                <Typography variant="body2" component="div">
                  {t('deleteConnection.documents.text')}
                  <Box component="ul" sx={classes.documentTypesList}>
                    <li>{t('page.documents.documentType.payslip')}</li>
                    <li>{t('page.documents.documentType.salary_statement')}</li>
                    <li>{t('page.documents.documentType.monthly_sheet')}</li>
                  </Box>
                </Typography>
                <Button
                  sx={classes.toDocumentsButton}
                  size="small"
                  color="secondary"
                  onClick={() => history.push(routes.documents)}
                >
                  {t('deleteConnection.documents.linkText')}
                </Button>
              </Box>
              <DivisionLine />
              <Box sx={[classes.flexAndColumn, classes.confirmationTextTop]}>
                <Typography variant="caption">{t('deleteConnection.documents.confirmationText')}</Typography>
                <Button
                  sx={classes.deactivateButton}
                  size="small"
                  color="secondary"
                  onClick={() => setShowDialog(true)}
                  data-testid="delete-connection-button"
                >
                  {t('deleteConnection.button.deleteConnection')}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={classes.flexAndColumn}>
              <Typography variant="body2">{t('deleteConnection.markedToDelete.text')}</Typography>
            </Box>
          )}
        </Box>
      </Card>

      <Dialog
        sx={classes.dialog}
        open={showDialog}
        title={t('deleteConnection.dialog.title', { tenantName: person.tenant?.name || '' })}
        testId="delete-connection-dialog"
        actions={
          <Box sx={classes.dialogButtons}>
            <Button
              color="secondary"
              size="large"
              data-testid="delete-connection-dialog_button-cancel"
              onClick={() => setShowDialog(false)}
            >
              {t('common.cancel')}
            </Button>

            <Button
              color="secondary"
              size="large"
              data-testid="delete-connection-dialog_button-delete"
              onClick={() => {
                setShowDialog(false)
                onDelete(person.favurUuid || '', person.tenant?.name || '')
              }}
            >
              {t('common.delete')}
            </Button>
          </Box>
        }
      >
        <Typography variant="body2" sx={classes.dialogText}>
          {t('deleteConnection.dialog.text', { tenantName: person.tenant?.name || '' })}
        </Typography>
      </Dialog>
    </>
  )
}

export default DetailedEmployersElement
