import { ContactFieldT, AddressT, addressTypes } from './types'
import { defaultAddressValues } from './constants'

export const hasAddressType = (addresses: ContactFieldT[], addressType: AddressT) => {
  return addresses.map((address) => address.type === addressType).includes(true)
}

export const addAddress = (addressType: AddressT, append: (inputs: ContactFieldT) => void) => {
  append(defaultAddressValues(addressType))
}

export const removeAddress = (
  addressType: AddressT,
  addressArray: ContactFieldT[],
  remove: (index: number) => void,
  setHasForeignAddress: (bool: boolean) => void,
  setHasEmergencyAddress: (bool: boolean) => void,
) => {
  const addressIndex = addressArray.findIndex((address) => address.type === addressType)
  addressIndex !== -1 && remove(addressIndex)
  addressType === addressTypes.foreign && setHasForeignAddress(false)
  addressType === addressTypes.emergency && setHasEmergencyAddress(false)
}
