import React, { useCallback, useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Box, Paper, Typography } from '@mui/material'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { PersonT, TeamT } from 'types'
import { BoxedLoading } from 'components/LoadingIcon'
import useRolesViews from 'hooks/useRolesViews'
import { addToTeamsMutation, teamsQuery } from 'pages/Teams/queries'
import FlashMessagesContext from 'services/FlashMessages/context'
import TeamList from 'components/TeamList'
import ActionButtons from 'components/Buttons/ActionButtons'
import { getPersonQuery } from 'shared/queries'
import { getUserName } from 'utils/person'
import { orderTeamsByLanguage } from 'types/utils'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { classes } from './styles'

interface IAddToTeamsProps {
  goBack: () => void
  person: PersonT
}

const AddToTeams: React.FC<IAddToTeamsProps> = ({ goBack, person }) => {
  const { t, language } = useFavurTranslation()
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const { persons } = useRolesViews()
  const [teams, setTeams] = useState<TeamT[]>()
  const [employeeTeams, setEmployeeTeams] = useState<string[]>()
  const [selectedTeams, setSelectedTeams] = useState<string[]>([])

  useJamesApolloQuery<{ findAllTeams: TeamT[] }>(teamsQuery, {
    variables: { personIds: persons ? persons.map((managerPerson) => managerPerson.id) : [] },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const filteredTeams = data.findAllTeams.filter(
        (team) =>
          team.owner?.id === person.owner?.id && (team.tenant === null || team.tenant?.id === person.tenant?.id),
      )
      setTeams(filteredTeams)
    },
  })

  useJamesApolloQuery<{ getPerson: PersonT }>(getPersonQuery, {
    variables: { uuid: person.favurUuid },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setEmployeeTeams(data.getPerson.teamPermissions?.map((tp) => tp.team?.id as string))
    },
  })

  const [addToTeams] = useMutation(addToTeamsMutation)

  const onSubmit = useCallback(() => {
    removeAll()
    addToTeams({ variables: { personId: person.id, teamIds: selectedTeams } })
      .then(() => goBack())
      .catch(() => setFlashMessage('common.error.be.default'))
  }, [addToTeams, goBack, person.id, removeAll, selectedTeams, setFlashMessage])

  const teamOnClick = useCallback(
    (team: TeamT) => {
      if (selectedTeams.includes(team.id)) setSelectedTeams(selectedTeams.filter((teamId) => teamId !== team.id))
      else setSelectedTeams([...selectedTeams, team.id])
    },
    [selectedTeams],
  )

  const checkboxSelected = useCallback(
    (team: TeamT) => selectedTeams.includes(team.id) || Boolean(employeeTeams && employeeTeams.includes(team.id)),
    [employeeTeams, selectedTeams],
  )

  const itemDisabled = useCallback((team: TeamT) => Boolean(employeeTeams && employeeTeams.includes(team.id)), [
    employeeTeams,
  ])

  const loading = !teams || !employeeTeams

  return (
    <Page
      header={
        <WithBackwardLink
          onClick={goBack}
          title={t('page.team.addToTeam.title')}
          topTitle={getUserName(person)}
          multipleLines={Boolean(teams?.length)}
          subheader={teams?.length ? t('page.team.addToTeam.total', { total: teams.length }) : undefined}
        />
      }
      growSubheader={Boolean(teams?.length)}
      hasFloatingButtons
      hideNativeNavigation
    >
      {loading && <BoxedLoading />}
      {!loading && teams?.length === 0 && (
        <Box component={Paper} boxShadow="2" sx={classes.noTeam}>
          <Typography variant="body1" sx={classes.noTeamText} data-testid="no-team">
            {t(`page.teams.noTeams`)}
          </Typography>
        </Box>
      )}
      {!loading && teams?.length && (
        <Box>
          <TeamList
            teams={orderTeamsByLanguage(teams, language)}
            onClick={teamOnClick}
            checkbox
            checkboxSelected={checkboxSelected}
            itemDisabled={itemDisabled}
            search
          />
          <ActionButtons
            cancelAction={goBack}
            cancelLabel={t('common.cancel')}
            validateLabel={t('common.confirm')}
            validateAction={onSubmit}
            isValidateButtonActive={selectedTeams.length > 0}
            isFloating
            noNavigation
          />
        </Box>
      )}
    </Page>
  )
}

export default AddToTeams
