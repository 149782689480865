import React from 'react'
import { Box, Typography } from '@mui/material'
import { CmsContentT } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { styles } from '../styles'
import EventCard from './EventCard'

interface EventGroupProps {
  dateLabel: string
  contents: CmsContentT[]
}

const EventGroup: React.FC<EventGroupProps> = ({ dateLabel, contents }) => {
  const { t } = useFavurTranslation()

  return (
    <Box sx={styles.eventGroup}>
      <Typography sx={styles.eventDateGroup} variant="body1">
        {t(dateLabel)}
      </Typography>
      <Box sx={styles.eventsGroupList}>
        {contents.map((content) => (
          <EventCard key={content.uuid} content={content} />
        ))}
      </Box>
    </Box>
  )
}

export default EventGroup
