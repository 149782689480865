import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { CmsContentBlocksT, CmsContentT, ImageContentBlockT } from 'types'
import { useHistory } from 'react-router-dom'
import { useContext } from 'react'
import FlashMessagesContext from 'services/FlashMessages/context'
import routes from 'services/RoutesProvider/routes'
import { ApolloError } from '@apollo/client'
import { getAPIURL } from 'constants/environment'
import { BLOCK_TYPE } from '../constants'
import { getCmsContentQuery } from '../queries'
import { FormImageUrl } from '../types'

export const formImageUrl = ({ filename }: FormImageUrl) =>
  filename ? `${getAPIURL()}/publisher/image/${filename}` : ''

const updateBlocks = (blocks: CmsContentBlocksT[]): CmsContentBlocksT[] =>
  !blocks?.length
    ? []
    : blocks.map((block: CmsContentBlocksT) => ({
        data: {
          ...(block.data as object),
          ...(block.type === BLOCK_TYPE.image && {
            src: formImageUrl({
              filename: (block.data as ImageContentBlockT)?.filename,
            }),
          }),
        },
        language: block.language,
        order: block.order,
        type: block.type,
      }))

const useGetCmsContent = ({ contentUuid }: { contentUuid: string }) => {
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const history = useHistory()
  const onError = (error: ApolloError) => {
    setFlashMessage(error.message)
    history.push(`${routes.cmsContentIndex}`)
  }
  const { data: contentData, loading: loading } = useJamesApolloQuery<{ cmsContentGet: CmsContentT }>(
    getCmsContentQuery,
    {
      variables: { contentUuid: contentUuid },
      fetchPolicy: 'cache-and-network',
      onError: onError,
    },
  )

  return {
    content: { ...contentData?.cmsContentGet, blocks: updateBlocks(contentData?.cmsContentGet.blocks ?? []) },
    loading,
  }
}

export default useGetCmsContent
