import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { getPublicTeamsQuery } from 'shared/queries'
import { TeamT } from 'types'

const usePublicTeams = () => {
  const [publicTeams, setPublicTeams] = useState<TeamT[]>()

  const { loading } = useQuery<{ getPublicTeams: TeamT[] }>(getPublicTeamsQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      setPublicTeams(data.getPublicTeams)
    },
  })

  return { publicTeams, loading }
}

export default usePublicTeams
