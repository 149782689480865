import React, { useEffect } from 'react'
import { tutorialTypes } from 'constants/tutorials'
import PinLogin from 'pages/PinLogin'
import useRolesViews from 'hooks/useRolesViews'
import useTutorials from 'hooks/useTutorials'
import routes from 'services/RoutesProvider/routes'
import useStateBackLink from 'hooks/useStateBackLink'
import { useHistory } from 'react-router'
import { SimpleLoading } from 'components/LoadingIcon'
import { AbsencePlannerStateProvider } from './contexts/AbsencePlannerStateContext'
import { TaskBarStateProvider } from './contexts/TaskBarStateContext'
import AbsencePlannerPage from './AbsencePlannerPage'

const AbsencePlanner: React.FC = () => {
  const { canManageAbsences } = useRolesViews()
  const history = useHistory()
  const { beenShownBefore, isReady } = useTutorials()
  const { setBackLink } = useStateBackLink()

  useEffect(() => {
    if (!isReady) return
    if (canManageAbsences) {
      if (!beenShownBefore('absenceplanManager')) {
        setBackLink(routes.absencePlanner)
        history.push(`${routes.tutorial}/${tutorialTypes.absencePlanManager}`)
      }
    } else {
      if (!beenShownBefore('absenceplanFrontliner')) {
        setBackLink(routes.absencePlanner)
        history.push(`${routes.tutorial}/${tutorialTypes.absencePlanFrontliner}`)
      }
    }
  }, [history, isReady, beenShownBefore, setBackLink, canManageAbsences])

  if (!isReady) {
    return (
      <div data-testid="loading-tutorials-absence-planner">
        <SimpleLoading />
      </div>
    )
  }

  return (
    <PinLogin>
      <TaskBarStateProvider>
        <AbsencePlannerStateProvider>
          <AbsencePlannerPage />
        </AbsencePlannerStateProvider>
      </TaskBarStateProvider>
    </PinLogin>
  )
}

export default AbsencePlanner
