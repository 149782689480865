import React from 'react'
import { Paper, IconButton, Typography, Box } from '@mui/material'
import { FilterM } from 'icons'
import useFavurTranslation from 'hooks/useFavurTranslation'
import ChipList from './ChipList'
import useFilterContext from './context'
import { classes } from './styles'

const Filter: React.FC = () => {
  const { t } = useFavurTranslation()
  const { openFilterPage, getChips, setFilters, getQuickFilterCTA } = useFilterContext()
  const quickFilterCTA = getQuickFilterCTA()
  return (
    <Box sx={classes.container}>
      <Paper elevation={0} sx={classes.root}>
        <Box sx={classes.leftContainer}>
          <Box sx={classes.filterButtonContainer}>
            <IconButton onClick={openFilterPage} sx={classes.filterButton} size="large" data-testid="open-filters">
              <FilterM />
              {!getChips().length ? (
                <Typography sx={[classes.noFilterLabel, classes.label]}>{t('filter.chips.no_filter')}</Typography>
              ) : null}
            </IconButton>
          </Box>
          <ChipList />
        </Box>
        {quickFilterCTA && (
          <IconButton
            onClick={() => setFilters(quickFilterCTA.filters)}
            sx={[classes.label, classes.quickFilterCTA]}
            size="large"
          >
            {quickFilterCTA.label}
          </IconButton>
        )}
      </Paper>
    </Box>
  )
}

export default Filter
