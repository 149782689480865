import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Box, Paper, Button } from '@mui/material'

import Page from 'components/Page'
import Grid from 'components/Grid'
import useJamesMutation from 'hooks/useJamesMutation'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FlashMessagesContext from 'services/FlashMessages/context'

import { classes } from './styles'
import { AcceptInvitationMutationResponse, IInvitationStepProps } from '../types'

const InviteExistingUser = ({ invitation, setStep }: IInvitationStepProps) => {
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const { t } = useFavurTranslation()
  const history = useHistory()

  const [acceptInvitationMutation] = useJamesMutation<{ invitationCode: string }, AcceptInvitationMutationResponse>(
    'acceptInvitation(invitationCode: "#invitationCode") { success }',
  )

  const onSubmit = () =>
    acceptInvitationMutation({ invitationCode: invitation.code })
      .then((res) => (res?.acceptInvitation.success ? setStep('SUCCESS') : setFlashMessage('common.error.be.default')))
      .catch((_e) => setStep('FAILURE'))

  return (
    <Page
      showHeader
      header={
        <>
          <Typography variant="h2">{t('register.content.title')}</Typography>
          <Typography variant="h2">{invitation?.tenantName}</Typography>
        </>
      }
    >
      <Box component={Paper} sx={classes.registerContainerPaper} p={1}>
        <Grid container>
          <Grid col={4}>
            <Typography variant="body1">{t('register.content.terms')}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={classes.actionButtonsContainer}>
        <Button
          sx={classes.cancelButton}
          size="large"
          variant="contained"
          color="secondary"
          tabIndex={-1}
          data-testid="cancel-invitation"
          onClick={() => (history.action === 'PUSH' ? history.goBack() : history.push('/'))}
        >
          {t('i18n.switcher.button.cancelAction')}
        </Button>
        <Button
          sx={classes.connectButton}
          size="large"
          variant="contained"
          color="secondary"
          onClick={onSubmit}
          data-testid="accept-invitation"
        >
          {t('register.actions.connect')}
        </Button>
      </Box>
    </Page>
  )
}

export default InviteExistingUser
