import { shiftTypes } from 'constants/shift'
import theme from 'utils/theme'
import { getBackgroundColor } from './helpers'
import type { PlannedShiftT } from 'types'

const getRootBorder = (shift: PlannedShiftT) => {
  const defaultBorder = `1px solid rgba(0, 0, 0, 0.08)`

  if (shift.type === shiftTypes.absence) {
    return '0'
  }

  if (shift.isManual) {
    return shift.hexBackgroundColor && shift.hexBackgroundColor !== ''
      ? `1px solid ${shift.hexBackgroundColor}`
      : defaultBorder
  }

  return defaultBorder
}

const getLabelColor = (shift: PlannedShiftT) => {
  if (shift.type === shiftTypes.absence) {
    return theme.palette.grey[600]
  }

  if (shift.isManual) return theme.palette.grey[900]

  return shift.hexBackgroundColor && shift.hexTextColor !== '' ? shift.hexTextColor : theme.palette.common.white
}

export const badgeClasses = (shift: PlannedShiftT, disabled = false, mini = false) => ({
  '&.MuiChip-root': {
    textAlign: 'center',
    minWidth: mini ? 0 : '68px',
    width: mini ? 'calc(100% - 2px)' : 'auto',
    height: mini ? '18px' : '24px',
    borderRadius: theme.spacing(1),
    backgroundColor: getBackgroundColor(shift, theme),
    opacity: disabled ? 0.5 : 1.0,
    border: getRootBorder(shift),
    padding: mini
      ? `${theme.spacing(0.1875)} ${theme.spacing(0.5)}`
      : `${theme.spacing(0.1875)} ${theme.spacing(0.75)}`,
    '&:hover': {
      backgroundColor: getBackgroundColor(shift, theme),
    },
    '&:focus': {
      backgroundColor: getBackgroundColor(shift, theme),
    },
  },
  '& .MuiChip-label': {
    marginLeft: shift.type === shiftTypes.absence && !mini ? theme.spacing(0.25) : 0,
    padding: 0,
    lineHeight: '22px',
    textOverflow: 'ellipsis',
    letterSpacing: 0.4,
    fontSize: mini ? '10px' : '14px',
    fontWeight: 600,
    color: getLabelColor(shift),
  },
})

export const tooltipClasses = {
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[900],
    boxShadow: theme.shadows[2],
    padding: `${theme.spacing(0.5)} ${theme.spacing(0.75)}`,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'right',
    letterSpacing: '0.4px',
    marginBottom: '0px !important',
  },
  arrow: {
    color: theme.palette.common.white,
    '&::before': {
      boxShadow: theme.shadows[2],
    },
  },
}
