import useFilterUtils from 'components/Filter/useFilterUtils'
import { useAppStatesContext } from 'services/AppStates'
import { DASHBOARD_NEXT_SHIFT_DATE } from 'constants/shift'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useStateBackLink from './useStateBackLink'

const refShiftListDateParamName = 'dateref'

const useGoBackShiftDate = ({ shouldPushBackLink = true }: { shouldPushBackLink?: boolean } = {}) => {
  const history = useHistory()
  const { setLoadFilterTasks } = useFilterUtils()
  const { pushBackLinkOrDefault } = useStateBackLink()
  const { set } = useAppStatesContext()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const refShiftListDate = urlParams.get(refShiftListDateParamName)
  const gotoBackLink = useCallback(() => {
    setLoadFilterTasks()
    if (refShiftListDate) {
      set(DASHBOARD_NEXT_SHIFT_DATE, refShiftListDate)
    }

    if (shouldPushBackLink) {
      pushBackLinkOrDefault(() => history.goBack())

      return
    }

    history.goBack()
  }, [setLoadFilterTasks, refShiftListDate, shouldPushBackLink, set, history, pushBackLinkOrDefault])

  return {
    gotoBackLink,
  }
}

export default useGoBackShiftDate
