import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_S } from './constants'

const AbsenceFullS: React.FC<IconPropsT> = ({ fill, id }) => {
  return (
    <Svg size={ICON_SIZE_S} id={id}>
      <path d="M1.167 8a7.333 7.333 0 1 0 14.666 0A7.333 7.333 0 0 0 1.167 8Z" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.167 4.333c0-.184.149-.333.333-.333h6a.333.333 0 0 1 0 .667h-6a.333.333 0 0 1-.333-.334Zm.666 1c0 1.6.8 2.667 2.668 2.667 1.867 0 2.666-1.067 2.666-2.667H5.833Zm5.334 5.334c0-1.6-.8-2.667-2.668-2.667-1.866 0-2.666 1.067-2.666 2.667h5.334Zm-5.667.666a.333.333 0 0 0 0 .667h6a.333.333 0 1 0 0-.667h-6Z"
        fill="#fff"
      />
    </Svg>
  )
}

export default AbsenceFullS
