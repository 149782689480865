import theme from 'utils/theme'

export const classes = {
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '600px',
    gap: '16px',
    marginTop: '16px',
    '& .MuiButton-root': {
      margin: '0 12px',
    },
  },
  regularButton: {
    color: theme.palette.primary.main,
    // @ts-ignore
    backgroundColor: theme.palette.primary[50],
    '&:hover, &:focus': {
      // @ts-ignore
      backgroundColor: theme.palette.primary[50],
      // @ts-ignore
      borderColor: theme.palette.primary[50],
    },
  },
  commentHeaderContainer: {
    paddingTop: '24px',
    paddingBottom: '24px',
  },
  commentBadgeContainer: {
    paddingBottom: '4px',
  },
  commentTextBody: {
    marginTop: '20px',
  },
}
