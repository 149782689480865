import { Box, Typography } from '@mui/material'
import ActionButtons from 'components/Buttons/ActionButtons'
import { BoxedLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import { defaultSuccessPageTimeout } from 'components/SuccessGeneric'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { headerClasses } from 'pages/Shifts/styles'
import { taskStates } from 'pages/Tasks/constants'
import React, { useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { PersonT } from 'types'
import { taskTypes } from 'shared/constants'
import ConfirmationDialog from './components/ConfirmationDialog'
import RequestEmployeeDataHeader from './components/RequestEmployeeDataHeader'
import SuccessScreen from './components/SuccessScreen'
import Global from './Global'
import Single from './Single'
import { classes } from './styles'
import { requestEmployeeDataModes, RequestEmployeeDataModeT } from './types'
import useRequestEmployeeData from './useRequestEmployeeData'

const getAllSelectedPersons = (members: Map<number, boolean>, personList: PersonT[]) =>
  personList.filter((person) => person.id && members.get(person.id))

const RequestEmployeeData: React.FC<RouteComponentProps<{ mode?: RequestEmployeeDataModeT }>> = ({ match }) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [processSucess, setProcessSuccess] = useState(false)
  const [members, setMembers] = useState<Map<number, boolean>>(new Map())
  const { mode } = match.params
  const {
    fetchTenantPersons,
    loading,
    personsWithTasks,
    personsWithoutTasks,
    allPersons,
    tenantList,
    selectedTenant,
  } = useRequestEmployeeData()

  const validateButtonActive = useMemo(() => {
    if (personsWithoutTasks.length === 0) {
      return false
    }

    if (mode === requestEmployeeDataModes.global) {
      return true
    }

    return Array.from(members.values()).some((marked) => marked)
  }, [members, mode, personsWithoutTasks.length])

  useEffect(() => {
    if (!processSucess) return undefined
    const successTimeout = setTimeout(
      () => history.push(`${routes.tasks}/${taskStates.pending}?task_type=${taskTypes.shareUserData}`),
      defaultSuccessPageTimeout,
    )
    return () => {
      clearTimeout(successTimeout)
    }
  }, [history, processSucess])

  if (loading) {
    return (
      <Page
        header={
          <Box sx={headerClasses.headerContainer}>
            <Typography variant="h2">
              {t(
                mode === requestEmployeeDataModes.global
                  ? 'requestEmployeeData.global.title'
                  : 'requestEmployeeData.single.title',
              )}
            </Typography>
          </Box>
        }
      >
        <BoxedLoading />
      </Page>
    )
  }

  return processSucess ? (
    <SuccessScreen />
  ) : (
    <Page
      growSubheader={tenantList && tenantList.length > 1}
      header={
        <RequestEmployeeDataHeader
          tenants={tenantList}
          onChange={fetchTenantPersons}
          mode={mode as RequestEmployeeDataModeT}
        />
      }
      hideNativeNavigation
      hasFloatingButtons
    >
      {selectedTenant && (
        <ConfirmationDialog
          mode="single"
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          persons={
            mode === requestEmployeeDataModes.global ? personsWithoutTasks : getAllSelectedPersons(members, allPersons)
          }
          tenant={selectedTenant}
          setProcessSuccess={setProcessSuccess}
        />
      )}
      <Box sx={classes.employeeListContainer}>
        {mode === requestEmployeeDataModes.global ? (
          <Global personsWithTasks={personsWithTasks} personsWithoutTasks={personsWithoutTasks} />
        ) : (
          <Single allPersons={allPersons} members={members} setMembers={setMembers} />
        )}
        <ActionButtons
          cancelAction={() => history.goBack()}
          cancelLabel={t('common.cancel')}
          validateLabel={t('requestEmployeeData.common.request')}
          validateAction={() => setDialogOpen(true)}
          isValidateButtonActive={validateButtonActive}
          isFloating
          noNavigation
        />
      </Box>
    </Page>
  )
}

export default RequestEmployeeData
