export const inputDrawerClasses = {
  input: {
    width: '100%',
  },
  inputTextArea: {
    maxHeight: '200px',
    // this !important is needed because the input forces overflow: hidden in the style tag of the textarea, that overrides the css
    overflow: 'hidden scroll !important',
  },
}
