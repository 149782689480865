import type {
  SettingsInAppNotificationsOptionsT,
  SettingsInAppNotificationsT,
  SettingsPushNotificationsOptionsT,
  SettingsPushNotificationsT,
} from 'types'

export const workingHoursSettings: SettingsPushNotificationsOptionsT[] = [
  'newShifts',
  'shiftsUpdate',
  'monthlySheetReminder',
]
export const documentsSettings: SettingsPushNotificationsOptionsT[] = ['documents']
export const communicationSettings: SettingsPushNotificationsOptionsT[] = [
  'shareUserData',
  // 'invitations',
]
export const communicationAbsenceBetaSettings: SettingsPushNotificationsOptionsT[] = ['frontlinerAbsenceRequest']
export const adminSettings: (SettingsPushNotificationsOptionsT | SettingsInAppNotificationsOptionsT)[] = [
  'absenceRequest',
  'officeMonthlySheetApproved',
  'receivedUserData',
  'shiftUpdateSeen',
]

export const defaultSettings: SettingsPushNotificationsT = {
  absenceRequest: true,
  documents: true,
  frontlinerAbsenceRequest: true,
  invitations: true,
  monthlySheetReminder: true,
  newShifts: true,
  officeMonthlySheetApproved: true,
  receivedUserData: true,
  shareUserData: true,
  shiftsUpdate: true,
  shiftUpdateSeen: false,
}

export const defaultInAppSettings: SettingsInAppNotificationsT = {
  absenceRequest: true,
  officeMonthlySheetApproved: true,
  receivedUserData: true,
  shiftUpdateSeen: false,
}
