import React, { useMemo, useRef } from 'react'
import { Box } from '@mui/material'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { throttledRefreshHighSecSession } from 'utils/throttledRefreshHighSecSession'
import { useAbsencePlannerData } from 'pages/AbsencePlanner/hooks/useAbsencePlannerData'
import { useCalendarActions } from 'pages/AbsencePlanner/hooks/useCalendarActions'
import { FilterContext } from 'pages/AbsencePlanner/contexts/FilterContext'
import useScrolledState from 'hooks/useScrolledState'
import { useFilter } from 'pages/AbsencePlanner/hooks/useFilter'
import { AvatarSidebarStateProvider } from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import useRolesViews from 'hooks/useRolesViews'
import { gridClasses } from './styles'
import MonthSwitcherBar from '../MonthSwitcherBar'
import AbsencePlannerGridBody from './Body'
import TaskBar from '../TaskBar'
import TableBackgroundContainer from './TableBackgroundContainer/TableBackgroundContainer'
import MonthScrollerContainer from './MonthScrollerContainer'
import ToggleSideBarButton from '../AvatarSideBar/ToggleSideBarButton'
import CalendarRow from './CalendarRow'
import TopBoxBlock from './TopBoxBlock'
import AvatarSideBar from '../AvatarSideBar'
import TeamNameRows from './TeamNameRows'
import SmallScreenGradient from './CalendarRow/SmallScreenGradient'
import GridBodyContainer from './MonthScrollerContainer/GridBodyContainer'

export const scrollerDivId = 'absence_planner_scroll_container'

const AbsencePlannerGrid: React.FC = () => {
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { personIds } = useRolesViews()
  const filters = useFilter()
  const { shownTypes, allTeams, shownTeams, isMultiTenant, persons, loading: filtersLoading } = filters

  const scrollRef = useRef<HTMLDivElement>(null)
  const { checkIsScrolled, isScrolled, isAtBottom, hasOverflow } = useScrolledState(scrollRef)

  const {
    shiftsDateRange,
    loading,
    personData,
    userPerson,
    taskCountTotal,
    taskCounts,
    updateDateRange,
    reloadAbsenceRequest,
    renewPersonData,
  } = useAbsencePlannerData({ shownTypes, shownTeams })

  const {
    baseDate,
    shownMonthsIndexes,
    shownMonthIndex,
    onNextMonthClick,
    onPreviousMonthClick,
    onTodayClick,
    onMonthScrollerScroll,
    onMonthSelect,
  } = useCalendarActions({
    filtersLoading,
    badgesLoading: loading,
    scrollRef,
    checkIsScrolled,
    updateDateRange,
  })

  const teams = useMemo(() => allTeams.filter((team) => shownTeams.includes(team.id)), [allTeams, shownTeams])
  return (
    <Box
      sx={gridClasses.gridContainer}
      onMouseOver={throttledRefreshHighSecSession()}
      onTouchStart={throttledRefreshHighSecSession()}
    >
      <FilterContext.Provider value={filters}>
        <AvatarSidebarStateProvider>
          <SmallScreenGradient />
          <TopBoxBlock isScrolled={isScrolled} />
          <TableBackgroundContainer>
            <MonthSwitcherBar
              baseDate={baseDate}
              shownMonthIndex={shownMonthsIndexes[shownMonthIndex]}
              toNextMonth={onNextMonthClick}
              toPrevMonth={onPreviousMonthClick}
              toToday={onTodayClick}
              isSmallScreen={isSmallScreen}
              onMonthSelect={onMonthSelect}
            />
          </TableBackgroundContainer>

          <MonthScrollerContainer ref={scrollRef} onScroll={onMonthScrollerScroll}>
            <AvatarSideBar
              teams={teams}
              taskCounts={taskCounts}
              loading={filtersLoading}
              persons={persons}
              firstPersonIds={personIds}
            />
            <TeamNameRows
              teams={teams}
              teamsLoading={filtersLoading}
              personIds={personIds}
              isMultiTenant={isMultiTenant}
            />
            <GridBodyContainer>
              <CalendarRow baseDate={baseDate} shownMonthsIndexes={shownMonthsIndexes} />
              <AbsencePlannerGridBody
                persons={persons}
                teams={teams}
                personData={personData}
                userPerson={userPerson}
                shiftsDateRange={shiftsDateRange}
                loading={loading}
                isSmallScreen={isSmallScreen}
                firstPersonIds={personIds}
                scrollContainerRef={scrollRef}
              />
            </GridBodyContainer>
          </MonthScrollerContainer>
          <Box sx={gridClasses.toggleBarButtonContainer}>
            <ToggleSideBarButton isSmallScreen={isSmallScreen} hasOverflow={hasOverflow} isAtBottom={isAtBottom} />
          </Box>
          <TaskBar
            taskCountTotal={taskCountTotal}
            refetchAll={reloadAbsenceRequest}
            refetchTask={(personId: string, dateFrom: string, dateTo: string) =>
              renewPersonData(personId, dateFrom, dateTo)
            }
          />
        </AvatarSidebarStateProvider>
      </FilterContext.Provider>
    </Box>
  )
}

export default AbsencePlannerGrid
