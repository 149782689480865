import { isNative } from 'utils/platform'
import theme from 'utils/theme'

export const swipableViewsStyles = { flexGrow: 1, display: 'flex' }
export const swipableViewsContainerStyles = { width: '100%', alignSelf: 'stretch' }

export const classes = {
  calendarContainer: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: theme.breakpoints.values.sm - 2,
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    width: '100%',
  },
  closeButton: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    paddingBottom: 'var(--ion-safe-area-bottom)',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  weekContainer: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    display: 'flex',
    '&:last-of-type': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    flexGrow: 1,
    flexBasis: 0,
  },
  weekDaysContainer: {
    display: 'flex',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    justifyContent: 'space-between',
  },
  weekDay: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 600,

    width: isNative() ? '50px' : 'calc(100% / 7)',
    textAlign: 'center',
    color: theme.palette.grey[600],
  },
  dayContainer: {
    width: 'calc(100% / 7)',
    textAlign: 'center',
    marginTop: '1px',
  },
  differentMonth: {
    '& .MuiButton-root': {
      color: theme.palette.grey[600],
    },
  },
  referenceDay: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    width: '22px',
    margin: '0 auto',
    borderRadius: '20px',
  },
  loading: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    '& .MuiBox-root': {
      position: 'relative',
      top: '260px',
    },
  },
  monthContainer: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  badge: {
    margin: '1px auto',
  },
  calendarDayButton: {
    padding: 0,
    minWidth: 0,
    width: '100%',
    minHeight: '100%',
    alignItems: 'flex-start',
  },
  insideButton: {
    width: '100%',
  },
  dayNumber: {
    marginBottom: '1px',
  },
  unacknowledgedChip: {
    height: '18px',
    width: `calc(100% - 2px)`,
    paddingTop: theme.spacing(0.25),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}
