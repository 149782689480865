import { Box, Typography } from '@mui/material'
import Avatar from 'components/Avatar'
import React from 'react'
import { getUserName } from 'utils/person'
import { PersonT } from 'types'
import { getAvatarDataFromPerson } from 'utils/avatar'
import { avatarSizes } from 'pages/UserSettings/pages/Profile/components/CropImage/types'
import { classes } from './styles'

interface IAvatarBlockProps {
  person: PersonT
}

const AvatarBlock: React.FC<IAvatarBlockProps> = ({ person }) => {
  const avatarData = getAvatarDataFromPerson(person, avatarSizes.avatar504)
  return (
    <Box sx={classes.block}>
      <Avatar avatar={avatarData} size={180} />
      <Typography variant="subtitle1" sx={classes.name}>
        {getUserName(person, true)}
      </Typography>
    </Box>
  )
}

export default AvatarBlock
