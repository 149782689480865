import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { uiDateFormat } from 'utils/constants'
import { getDeadlineTranslationKey, getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import NotificationTemplate from 'components/Notification/Templates/Notification'
import { AlertS } from 'icons'
import palette from 'utils/theme/palette'
import { notificationStatuses, ReminderMonthlySheetNotificationT } from 'types'
import type { NotificationPropsT } from 'components/Notification/types'

interface IMonthlySheetReminder extends NotificationPropsT {
  notification: ReminderMonthlySheetNotificationT
}

const MonthlySheetReminder: React.FC<IMonthlySheetReminder> = (props) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { notification, dismiss } = props
  const { taskUuid } = notification.data
  const handleDetails = useCallback(() => {
    dismiss(notification)
    if (taskUuid) {
      history.push(routes.monthlySheetsWithTask(taskUuid))
    }
  }, [dismiss, history, notification, taskUuid])
  const iconColor = notification.status === notificationStatuses.new ? palette.secondary.main : palette.grey[300]
  const templateProps = {
    ...props,
    body: t('reminder.monthlySheet.body', {
      dateEnd: getFormattedFromDate(newDateWithoutTime(notification.data.dateTo), uiDateFormat),
      dateStart: getFormattedFromDate(newDateWithoutTime(notification.data.dateFrom), uiDateFormat),
      reminderDeadline: t(getDeadlineTranslationKey(notification.data.reminderDeadline)),
    }),
    details: handleDetails,
    detailsText: t('reminder.monthlySheet.details'),
    dismissText: t('reminder.monthlySheet.dismiss'),
    title: t('reminder.monthlySheet.title'),
    titleIcon: <AlertS fill={iconColor} />,
  }

  return <NotificationTemplate {...templateProps} />
}

export default MonthlySheetReminder
