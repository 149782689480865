import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_M } from './constants'

const ArrowUpThinM: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_M} fill={fill}>
      <path
        fillRule="evenodd"
        d="M3.22 16.78a.75.75 0 0 1 0-1.06l7.86-7.84a1.3 1.3 0 0 1 1.84 0l7.86 7.84a.75.75 0 0 1-1.06 1.06L12 9.08l-7.72 7.7c-.3.3-.77.3-1.06 0Z"
      />
    </Svg>
  )
}

export default ArrowUpThinM
