import React, { useMemo } from 'react'
import routes from 'services/RoutesProvider/routes'
import { isNative } from 'utils/platform'
import navigation from 'constants/navigation'
import { DashboardApp, DashboardAppActive, HomeL } from 'icons'
import { NavPointConditionsT } from 'components/Page/Header/Toolbar/Menu/types'
import usePackage from './usePackage'
import useRolesViews from './useRolesViews'
import useSessionContext from './useSessionContext'

type DashboardIconT = {
  fill?: string | undefined
  negative?: boolean
}
interface IGetNavPointsConditionsParams {
  hasClassicAccess: boolean
  hasClassicLightAccess: boolean
  canManageTeams: boolean
  hasActivePersons: boolean
  isManager: boolean
  cmsActivated: boolean
}

const getNavigationItems = (conditions: NavPointConditionsT, isManager: boolean) => {
  return [
    navigation.dashboard,
    ...(conditions.managerTasks ? [navigation.managerTasks] : []),
    ...(conditions.shifts ? [navigation.shifts] : []),
    ...(conditions.absencePlanner && isManager ? [navigation.absencePlanner] : []),
    ...(conditions.personalTasks ? [navigation.personalTasks] : []),
    ...(conditions.documents ? [navigation.documents] : []),
    ...(conditions.balances ? [navigation.balances] : []),
    ...(conditions.settings ? [navigation.settings] : []),
  ].slice(0, 4)
}

const getNativeNavigation = (conditions: NavPointConditionsT, isManager: boolean) => {
  if (!isNative()) return []
  return getNavigationItems(conditions, isManager)
}
// eslint-disable-next-line complexity
const getPrimaryItems = (conditions: NavPointConditionsT, isManager: boolean) => {
  return [
    ...(conditions.dashboard ? [navigation.dashboard] : []),
    ...(conditions.shifts ? [navigation.shifts] : []),
    ...(conditions.absencePlanner && isManager ? [navigation.absencePlanner] : []),
    ...(conditions.managerTasks ? [navigation.managerTasks] : []),
    ...(conditions.personalTasks ? [navigation.personalTasks] : []),
    ...(conditions.documents ? [navigation.documents] : []),
    ...(conditions.balances ? [navigation.balances] : []),
    ...(conditions.absencePlanner && !isManager ? [navigation.absencePlanner] : []),
    ...(conditions.content ? [navigation.content] : []),
  ]
}

const getPrimaryNavigation = (conditions: NavPointConditionsT, isManager: boolean) => {
  return getPrimaryItems(conditions, isManager)
}

const getSecondaryNavigation = (conditions: NavPointConditionsT) => {
  return [
    ...(conditions.settings ? [navigation.settings] : []),
    ...(conditions.teams ? [navigation.teams] : []),
    ...(conditions.notifications ? [navigation.notifications] : []),
    ...(conditions.logout ? [navigation.logout] : []),
  ]
}
const getTertiaryNavigation = () => [navigation.contact, navigation.privacy, navigation.appInfo]

// eslint-disable-next-line complexity
const getNavPointsConditions: (params: IGetNavPointsConditionsParams) => NavPointConditionsT = ({
  hasClassicAccess,
  hasClassicLightAccess,
  canManageTeams,
  hasActivePersons,
  isManager,
  cmsActivated,
}: IGetNavPointsConditionsParams) => ({
  dashboard: true,
  shifts: hasClassicAccess,
  balances: hasClassicAccess || hasClassicLightAccess,
  settings: true,
  notifications: false, //Not defined on the wiki but asumed that everyone has access
  documents: true,
  tasks: false,
  managerTasks: hasClassicLightAccess && isManager,
  personalTasks: hasClassicLightAccess,
  teams: hasClassicLightAccess && canManageTeams,
  logout: false,
  contact: true,
  appInfo: true,
  privacy: true,
  absencePlanner: hasActivePersons && hasClassicAccess,
  content: cmsActivated,
})

const useFavurNavigation = () => {
  const { cmsActivated } = useSessionContext()
  const { hasClassicAccess, hasClassicLightAccess } = usePackage()
  const { canManageTeams, hasOfficeView, hasActivePersons } = useRolesViews()
  const conditions = getNavPointsConditions({
    hasClassicAccess,
    hasClassicLightAccess,
    canManageTeams,
    hasActivePersons,
    isManager: hasOfficeView,
    cmsActivated,
  })

  const getDashboardIcon = (pathname: string): React.FC<DashboardIconT> => {
    if (isNative()) {
      return HomeL
    }

    return pathname === routes.dashboard ? DashboardAppActive : DashboardApp
  }

  const nativeNavigation = useMemo(() => getNativeNavigation(conditions, hasOfficeView), [conditions, hasOfficeView])
  const primaryNavigation = useMemo(() => getPrimaryNavigation(conditions, hasOfficeView), [conditions, hasOfficeView])
  const secondaryNavigation = useMemo(() => getSecondaryNavigation(conditions), [conditions])
  const tertiaryNavigation = useMemo(() => getTertiaryNavigation(), [])

  return {
    conditions,
    nativeNavigation,
    primaryNavigation,
    secondaryNavigation,
    tertiaryNavigation,
    routes,
    getDashboardIcon,
  }
}

export default useFavurNavigation
