import React from 'react'
import { CmsContentBlocksT, ImageContentBlockT, TextContentBlockT } from 'types'
import { Box } from '@mui/material'
import { BLOCK_TYPE } from '../constants'
import SeparatorBlock from './SeparatorBlock'
import { styles } from './styles'
import TextBlock from './TextBlock'
import ImageBlock from './ImageBlock'

interface IBlockProps {
  block: CmsContentBlocksT
}

const renderBlock = (block: CmsContentBlocksT) => {
  switch (block.type) {
    case BLOCK_TYPE.divider:
      return <SeparatorBlock />
    case BLOCK_TYPE.text:
      if (!block.data) return <></>
      return <TextBlock data={block.data as TextContentBlockT} />
    case BLOCK_TYPE.image:
      if (!block.data) return <></>
      return <ImageBlock data={block.data as ImageContentBlockT} />
    default:
      return <></>
  }
}

const Block: React.FC<IBlockProps> = ({ block }) => <Box sx={styles.wrapper}>{renderBlock(block)}</Box>

export default Block
