import React, { useContext } from 'react'
import { Typography, Button, Box } from '@mui/material'

import Dialog from 'components/Dialog'
import useFavurTranslation from 'hooks/useFavurTranslation'
import FlashMessagesContext from 'services/FlashMessages/context'
import { ResendSMSResult } from 'types'
import { useMutation } from '@apollo/client'
import { resendSMSMutationApollo as mutation } from './mutations'
import { resendSMSDialogClasses } from './styles'

interface IResendSMSProperties {
  isOpen: boolean
  closeDialog: () => void
  goBackAStep: () => void
  resetTimer: () => void
}

const ResendSMSDialog: React.FC<IResendSMSProperties> = ({ isOpen, closeDialog, goBackAStep, resetTimer }) => {
  const { t } = useFavurTranslation()
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const [resendSMSMutation] = useMutation<ResendSMSResult>(mutation)

  const resendSMS = async () => {
    const mutationResult = await resendSMSMutation({ variables: { appSignature: null } })

    if (mutationResult?.data?.resendSMS?.success) {
      setFlashMessage('common.dialog.resendSMS.flash.content')
      resetTimer()
      closeDialog()
    }
  }

  return (
    <Dialog
      data-testid="no-sms-received-dialog"
      open={isOpen}
      actions={
        <Box sx={resendSMSDialogClasses.buttonContainer}>
          <Button
            sx={resendSMSDialogClasses.button}
            onClick={resendSMS}
            color="secondary"
            size="small"
            aria-label="resendSMS"
            data-testid="resend-sms"
          >
            {t('common.dialog.resendSMS.button.resendSMS')}
          </Button>
          <Button
            sx={resendSMSDialogClasses.button}
            onClick={goBackAStep}
            color="secondary"
            size="small"
            aria-label="goBack"
            data-testid="go-back"
          >
            {t('common.dialog.resendSMS.button.back')}
          </Button>
          <Button
            sx={resendSMSDialogClasses.button}
            onClick={closeDialog}
            color="secondary"
            size="small"
            aria-label="closeDialog"
            data-testid="close-dialog"
          >
            {t('common.dialog.button.close')}
          </Button>
        </Box>
      }
      title={t('common.dialog.resendSMS.title')}
      sx={resendSMSDialogClasses.dialog}
    >
      <Typography variant="body2" sx={resendSMSDialogClasses.content}>
        {t('common.dialog.resendSMS.content1')}
      </Typography>
      <Typography variant="body2" sx={resendSMSDialogClasses.content}>
        {t('common.dialog.resendSMS.content2')}
      </Typography>
    </Dialog>
  )
}

export default ResendSMSDialog
