import React from 'react'
import { Box, Typography } from '@mui/material'
import Page from 'components/Page'
import useFavurTranslation from 'hooks/useFavurTranslation'
import BackwardLink from 'components/BackwardLink'
import { SimpleLoading } from 'components/LoadingIcon'
import { headerClasses as subheaderClasses } from 'components/Page/Header/SubHeader/styles'
import { headerClasses } from 'pages/Tasks/styles'
import useNewAbsenceRequest from './useNewAbsenceRequest'
import NewContent from './NewContent'

const New: React.FC = () => {
  const { t } = useFavurTranslation()

  const props = useNewAbsenceRequest()

  const { loadingMutation, loading, gotoBackLink } = props

  if (loadingMutation || loading) {
    return (
      <Page
        hideNativeNavigation
        hideHamburger
        hideBackground
        header={
          <Box sx={subheaderClasses.subheaderContainer}>
            <Typography variant="subtitle1">{t('absenceRequest.new.title')}</Typography>
          </Box>
        }
        sxHeader={headerClasses.subHeader}
        removeLastMargin
      >
        <Box display="flex" flexDirection="column" justifyContent="center">
          <SimpleLoading />
        </Box>
      </Page>
    )
  }

  return (
    <Page
      hideNativeNavigation
      hideHamburger
      hideBackground
      header={<BackwardLink onClickIcon={gotoBackLink} title={t('absenceRequest.new.title')} />}
      sxHeader={headerClasses.subHeader}
      removeLastMargin
    >
      <NewContent {...props} showAbsencePlannerPreview />
    </Page>
  )
}

export default New
