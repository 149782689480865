import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { NotificationT, ShiftNotificationT } from 'types'
import { getFormattedFromISOString, getFormattedFromDate, getDateFromISOString } from 'utils/date'
import useShitfsContext from 'hooks/useShiftsContext'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { shiftUpdateTypes } from 'constants/shift'
import { shiftDateFormat } from 'constants/dateFormats'
import { uiDateFormat } from 'utils/constants'
import routes from 'services/RoutesProvider/routes'
import { taskStates } from 'pages/Tasks/constants'
import NotificationTemplate from '../Templates/Notification'

export interface IShiftNotification {
  notification: ShiftNotificationT
  dismiss: (notification: NotificationT) => void
}

const ShiftNotification: React.FC<IShiftNotification> = (props) => {
  const { t } = useFavurTranslation()
  const history = useHistory()
  const { notification, dismiss } = props
  const { setShiftsContext } = useShitfsContext()
  const isInserted = notification.dataType === shiftUpdateTypes.inserted

  const textVariables = {
    dateTo: getFormattedFromISOString(notification.data.dateTo, uiDateFormat, undefined),
    dateFrom: getFormattedFromISOString(notification.data.dateFrom, uiDateFormat, undefined),
    count: notification.data.count,
  }

  const redirectTaskShiftUpdate = useCallback(
    (taskUuid: string) => {
      const status = taskStates.todo
      return history.push(`${routes.shiftUpdateDetail}/${taskUuid}/${status}`)
    },
    [history],
  )

  const redirectShifts = useCallback(() => {
    const fromDate = getDateFromISOString(notification.data.dateFrom)
    const start = getFormattedFromDate(fromDate, shiftDateFormat)
    const end = getFormattedFromDate(fromDate, shiftDateFormat)
    setShiftsContext({
      start,
      end,
    })

    return history.push(routes.shifts)
  }, [history, notification.data.dateFrom, setShiftsContext])

  const handleRedirect = useCallback(() => {
    dismiss(notification)

    if (isInserted) {
      return redirectShifts()
    }

    const { taskUuid } = notification.data
    if (taskUuid) {
      return redirectTaskShiftUpdate(taskUuid)
    }

    return history.push(routes.dashboard)
  }, [dismiss, history, isInserted, notification, redirectShifts, redirectTaskShiftUpdate])

  const getText = () => {
    if (notification.data.count > 1 && notification.dataType === shiftUpdateTypes.inserted) {
      return 'notification.new.newshift.multiday.body'
    }
    if (notification.data.count === 1 && notification.dataType === shiftUpdateTypes.inserted) {
      return 'notification.new.newshift.singleday.body'
    }
    if (notification.data.count > 1 && notification.dataType === shiftUpdateTypes.updated) {
      return 'notification.new.shiftchange.multiday.body'
    }
    if (notification.data.count === 1 && notification.dataType === shiftUpdateTypes.updated) {
      return 'notification.new.shiftchange.singleday.body'
    }

    return ''
  }

  const templateProps = {
    ...props,
    title: t(isInserted ? 'notification.new.newshift.title' : 'notification.new.shiftchange.title'),
    body: t(getText(), textVariables),
    dismissText: t('component.notification.button.dismiss'),
    details: handleRedirect,
    detailsText: t('component.notification.button.viewDetails'),
  }

  return <NotificationTemplate {...templateProps} />
}

export default ShiftNotification
