import React from 'react'
import { Chip } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { showNewLabel } from './utils'
import { newTutorialChipClasses } from './styles'

interface ITutorialNewProps {
  id: string
}

const NewTutorialChip: React.FC<ITutorialNewProps> = ({ id }) => {
  const { t } = useFavurTranslation()

  if (!showNewLabel(id)) return null
  return <Chip sx={newTutorialChipClasses.newChip} label={t('component.tutorialEntryPoint.new')} />
}

export default NewTutorialChip
