import { Box, Button, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import React from 'react'
import { PinPointS } from 'icons'
import { classes } from './styles'

interface IActionButtonProps {
  onClick?: () => void
}

const ActionButton: React.FC<IActionButtonProps> = ({ onClick }) => {
  const { t } = useFavurTranslation()

  if (!onClick) return null

  return (
    <Box sx={classes.buttonContainer}>
      <Button
        sx={classes.buttonDesktop}
        endIcon={
          <Box sx={classes.pinPointButton}>
            <PinPointS />
          </Box>
        }
        onClick={onClick}
      >
        <Typography variant="button">{t('component.absenceRequestConflicts.button.viewGrid')}</Typography>
      </Button>
    </Box>
  )
}

export default ActionButton
