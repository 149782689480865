import React from 'react'
import { IonPickerColumn, IonPickerColumnOption } from '@ionic/react'
import { Typography } from '@mui/material'

export type WheelPickerOption = { value: number; label: string; disabled?: boolean }

interface IWheelPickerColumnProps {
  options: WheelPickerOption[]
  value: number
  onChange: (value: number) => void
}

const WheelPickerColumn: React.FC<IWheelPickerColumnProps> = ({ options, value, onChange }) => {
  return (
    <IonPickerColumn value={value} onIonChange={(event) => onChange(event.target.value as number)}>
      {options.map((option) => (
        <IonPickerColumnOption disabled={option.disabled} key={option.value} value={option.value}>
          <Typography variant="body1">{option.label}</Typography>
        </IonPickerColumnOption>
      ))}
    </IonPickerColumn>
  )
}

export default WheelPickerColumn
