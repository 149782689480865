import React, { useCallback } from 'react'
import routes from 'services/RoutesProvider/routes'
import { NotificationT, ShareUserDataNotificationT } from 'types'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { ROLE_VIEWS } from 'constants/roles'
import NotificationTemplate from '../Templates/Notification'

interface IShareUserDataProps {
  notification: ShareUserDataNotificationT
  dismiss: (notification: NotificationT) => void
}

const ShareUserData: React.FC<IShareUserDataProps> = (props) => {
  const { notification, dismiss } = props
  const { t } = useFavurTranslation()
  const { pushWithCurrentPageBackLink } = useStateBackLink()

  const handleDetails = useCallback(() => {
    dismiss(notification)
    pushWithCurrentPageBackLink(`${routes.userDataDetails}/${notification.data.taskUuid}`, ROLE_VIEWS.frontliner)
  }, [dismiss, notification, pushWithCurrentPageBackLink])

  const templateProps = {
    ...props,
    title: t('notification.new.sharedata.requested.title'),
    body: t('notification.new.sharedata.tenant', { tenantName: notification.data.tenantName }),
    dismissText: t('component.notification.button.dismiss'),
    details: handleDetails,
    detailsText: t('component.notification.button.viewDetails'),
  }

  return <NotificationTemplate {...templateProps} />
}

export default ShareUserData
