import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import { HourglassM } from 'icons'
import useFavurTranslation from 'hooks/useFavurTranslation'
import routes from 'services/RoutesProvider/routes'
import theme from 'utils/theme'
import useStateBackLink from 'hooks/useStateBackLink'
import { pastMonthReminderClasses } from './styles'

interface IPastMonthReminder {
  pastTaskUuid: string
  currentTaskUuid: string
}

const PastMonthReminder: React.FC<IPastMonthReminder> = ({ pastTaskUuid, currentTaskUuid }) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { setBackLink } = useStateBackLink()
  return (
    <Box sx={pastMonthReminderClasses.container}>
      <Box sx={pastMonthReminderClasses.textRow}>
        <Box sx={pastMonthReminderClasses.iconContainer}>
          <HourglassM stroke={theme.palette.primary.main} />
        </Box>
        <Typography variant="caption" sx={pastMonthReminderClasses.mainText}>
          {t('monthlySheet.pastWtcReminder.text')}
        </Typography>
      </Box>
      <Box sx={pastMonthReminderClasses.buttonRow}>
        <Button
          variant="text"
          color="secondary"
          onClick={() => {
            setBackLink(routes.monthlySheetsWithTask(currentTaskUuid))
            history.push(`${routes.monthlySheetsWithTask(pastTaskUuid)}?ref=${currentTaskUuid}`)
          }}
        >
          {t('monthlySheet.pastWtcReminder.button')}
        </Button>
      </Box>
    </Box>
  )
}

export default PastMonthReminder
