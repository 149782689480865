import React, { useMemo } from 'react'
import { chunk, groupBy } from 'lodash/fp'
import { isToday, addMonths } from 'date-fns'
import { Box } from '@mui/material'
import { dateToISOStringWithoutTime, getCalendarMonthInterval, newDateWithoutTime } from 'utils/date'
import useShifts from 'hooks/useShifts'
import { PlannedShiftT } from 'types'
import { SimpleLoading } from 'components/LoadingIcon'
import useShiftUpdates from 'pages/Shifts/useShiftUpdates'
import { ShiftsByDateT } from 'components/ShiftsList/types'
import { getTaskIds } from '../utils'
import CalendarDay from './CalendarDay'
import { classes } from './styles'

const getShiftsByDay = (shifts: ShiftsByDateT[]): { [key: string]: PlannedShiftT[] } =>
  Object.entries(groupBy('date', shifts)).reduce((acc, [key, value]) => {
    const mapped = value.flatMap((date) => date.tenants.flatMap((tenant) => tenant.shifts)) as PlannedShiftT[]
    return { ...acc, [key]: mapped }
  }, {})

interface IShiftsMonthlyCalendarProps {
  referenceDay: Date
  index: number
  onClickDay: (day: Date) => void
  closeCalendar: () => void
  personIds: number[]
  weeksToShow?: number
}

const getWeekKey = (week: Date[]) =>
  `${dateToISOStringWithoutTime(week[0])}-${dateToISOStringWithoutTime(week[week.length - 1])}`

const ShiftsMonthlyCalendar: React.FC<IShiftsMonthlyCalendarProps> = ({
  referenceDay,
  index,
  onClickDay,
  closeCalendar,
  personIds,
  weeksToShow = 6,
}) => {
  const originDay = addMonths(referenceDay, index)
  const daysToShow = weeksToShow * 7
  const calendarDates = getCalendarMonthInterval(originDay, daysToShow)
  const weekCalendarDates = chunk(7, calendarDates)
  const startDate = dateToISOStringWithoutTime(calendarDates[0])
  const endDate = dateToISOStringWithoutTime(calendarDates[calendarDates.length - 1])

  const { shifts, status } = useShifts({ dates: { start: startDate, end: endDate }, personIds: personIds as number[] })
  const groupedShiftsByDate = useMemo(() => getShiftsByDay(shifts), [shifts])

  const taskIds = useMemo(() => getTaskIds(shifts), [shifts])
  const { tasks, loading: tasksLoading } = useShiftUpdates({ taskIds })
  const loading = status === 'loading' || tasksLoading

  return (
    <Box sx={classes.monthContainer}>
      {loading && (
        <Box sx={classes.loading}>
          <SimpleLoading />
        </Box>
      )}
      {weekCalendarDates.map((week) => (
        <Box sx={classes.weekContainer} key={getWeekKey(week)}>
          {week.map((day) => {
            const isoDate = dateToISOStringWithoutTime(day)
            return (
              <CalendarDay
                day={day}
                key={isoDate}
                isCurrentMonth={referenceDay.getMonth() === day.getMonth()}
                isToday={isToday(day)}
                shifts={loading ? [] : groupedShiftsByDate[isoDate] ?? []}
                onClick={() => {
                  onClickDay(newDateWithoutTime(isoDate))
                  closeCalendar()
                }}
                shiftUpdates={tasks ?? []}
              />
            )
          })}
        </Box>
      ))}
    </Box>
  )
}

export default ShiftsMonthlyCalendar
