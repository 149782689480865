import { PlannedShiftT } from 'types'
import { isNative } from 'utils/platform'
import { getBackgroundColor } from 'components/Badge/helpers'
import favurTheme from 'utils/theme'
import theme from 'utils/theme'

export const classes = {
  main: (calendarOpen: boolean) => ({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: calendarOpen ? 'transparent' : 'white',
    zIndex: 10,
    width: calendarOpen && !isNative() ? theme.breakpoints.values.sm : '100vw',
    // @ts-ignore
    borderBottom: isNative() ? `1px solid ${theme.palette.primary[50]}` : 'none',
  }),
  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: `${isNative() ? '100%' : '600px'}`,
    height: '48px',
    justifyContent: 'center',
    padding: `0px ${!isNative() ? 0 : 12}px`,
  },
  headerActions: {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0px ${!isNative() ? 16 : 0}px}`,
  },
  headerDays: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: `${theme.spacing(0.5)} 0`,
  },
  monthButtonsContainer: {
    display: 'flex',
    height: '25px',
    alignItems: 'center',
    gap: '24px',
  },
  headerActionsMonth: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  headerActionMonthButton: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    width: '92px',
    '&>p': {
      width: '72px',
    },
    '&:hover:before': {
      backgroundColor: 'transparent',
    },
    '&>h3': {
      width: '72px',
      textTransform: 'none',
      fontSize: '16px',
    },
    '& .MuiButton-endIcon': {
      marginLeft: '0px',
    },
  },
  dayOfWeek: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '50px',
  },
  dayOfWeekTitle: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
  },
  dayOfWeekContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  dayNumberContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  weekDay: {
    color: theme.palette.text.secondary,
    background: 'transparent',
    fontWeight: 350,
    fontSize: '14px',
    lineHeight: '22px',
    width: '22px',
    height: '22px',
    marginTop: '4px',
    display: 'flex',
    justifyContent: 'center',
    '& .MuiChip-label': {
      padding: '0px',
    },
  },
  weekDayAnimation: {
    animationName: '$daySelectedAnimation',
    animationDuration: '0.5s',
    willChange: 'opacity',
  },
  weekDaySelectedToday: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    '&:hover, &:focus, &:hover:before': {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  weekDaySelectedNotToday: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    '&:hover, &:focus, &:hover:before': {
      color: 'white',
      backgroundColor: theme.palette.primary.dark,
    },
  },
  weekDayNotSelectedToday: {
    color: theme.palette.secondary.main,
    '&:hover, &:focus, &:hover:before': {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
    },
  },
  weekDayNotSelectedNotToday: {
    color: theme.palette.text.secondary,
    '&:hover, &:focus, &:hover:before': {
      color: theme.palette.text.secondary,
      backgroundColor: 'transparent',
    },
  },
  calendarIconText: {
    marginLeft: '8px',
    paddingBottom: '2px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.primary.main,
    paddingTop: '3px',
    textTransform: 'none',
  },
  headerActionCalendarToday: {
    position: 'relative',
    left: `${isNative() ? 4 : 16}px`,
    marginRight: `${isNative() ? 0 : 14}px`,
    '&:hover:before': {
      backgroundColor: 'transparent',
    },
  },
  '@keyframes daySelectedAnimation': {
    '0%': {
      opacity: '0',
    },
    '100%': {
      opacity: '1',
    },
  },
  bottomChipsContainer: {
    marginTop: '-2px',
    width: '24px',
    display: 'flex',
  },
  stickyContainer: (calendarOpen: boolean) => ({
    position: 'sticky',
    top: 0,
    zIndex: 2,
    display: calendarOpen ? 'block' : 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderLeft: `1px solid${theme.palette.grey[100]}`,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
  }),
  bottomShadow: {
    boxShadow: theme.shadows[8],
  },
  sideMenuIcon: {
    color: theme.palette.primary.main,
    paddingTop: '4px',
    paddingRight: '0px',
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      background: 'none',
    },
    position: 'relative',
    top: '14px',
  },
}

export const chipClasses = {
  avatarShiftColor: (shift: PlannedShiftT) => ({
    height: '2px',
    flexGrow: 1,
    maxWidth: '22px',
    margin: '0 1px',
    backgroundColor: getBackgroundColor(shift, theme, true),
    '& .MuiChip-label': {
      padding: 0,
    },
    border: '0.25px solid rgba(0, 0, 0, 0.08)',
    borderRadius: '3px',
  }),
}

export const shiftsWeeklyCalendarClasses = {
  dateText: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: favurTheme.palette.primary.main,
    textTransform: 'none',
  },
  iconButtons: {
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
    color: favurTheme.palette.primary.main,
  },
}
