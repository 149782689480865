import React from 'react'
import { Box, IconButton, useMediaQuery } from '@mui/material'
import DailyShiftsList from 'components/ShiftsList/DailyShiftsList'
import MonthlyCalendarSlider from 'components/ShiftsList/components/ShiftsMonthlyCalendar'
import { dateToISOStringWithoutTime, newDateWithoutTime } from 'utils/date'
import { ArrowLeftS, ArrowRightS } from 'icons'
import { isNative } from 'utils/platform'
import { IShiftState } from 'components/ShiftsList/reducer'
import theme from 'utils/theme'
import { classes } from '../newStyles'

interface IShiftsPageBodyProps {
  state: IShiftState
  goToDay: (date: Date) => void
  closeCalendar: () => void
  handleIndexMonthChange: (value: number) => void
  handleIndexChange: (value: number) => void
  loadingGoToDay: boolean
  finishLoadingGoToDay: () => void
}

const ShiftsPageBody: React.FC<IShiftsPageBodyProps> = ({
  state,
  goToDay,
  closeCalendar,
  handleIndexMonthChange,
  handleIndexChange,
  loadingGoToDay,
  finishLoadingGoToDay,
}) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      sx={[
        classes.swipeContainer,
        ...(isNative() ? [] : [classes.webExtraStyles]),
        ...(isSmallScreen && state.calendarOpen ? [classes.fullWidth] : []),
      ]}
    >
      {!isNative() && (
        <Box sx={classes.buttonContainer}>
          <IconButton
            sx={[classes.arrowButtons, classes.arrowMarginRight]}
            onClick={() => (state.calendarOpen ? handleIndexMonthChange(-1) : handleIndexChange(-1))}
            data-testid="calendar__button-prev"
            size="large"
          >
            <ArrowLeftS />
          </IconButton>
        </Box>
      )}
      {state.calendarOpen ? (
        <MonthlyCalendarSlider
          closeCalendar={() => closeCalendar()}
          referenceDay={state.referenceDay}
          onClickDay={(day: Date) => goToDay(day)}
          handleSwipeAction={handleIndexMonthChange}
          swipeIndex={state.monthIndex}
        />
      ) : (
        <>
          <DailyShiftsList
            baseDate={dateToISOStringWithoutTime(newDateWithoutTime())}
            displayFull
            handleSwipeAction={handleIndexChange}
            swipeIndex={state.index}
            swipeAnimation={state.animateTransition}
            loadingGoToDay={loadingGoToDay}
            finishLoadingGoToDay={finishLoadingGoToDay}
          />
        </>
      )}
      {!isNative() && (
        <Box sx={classes.buttonContainer}>
          <IconButton
            sx={[classes.arrowButtons, classes.arrowMarginLeft]}
            onClick={() => (state.calendarOpen ? handleIndexMonthChange(1) : handleIndexChange(1))}
            data-testid="calendar__button-next"
            size="large"
          >
            <ArrowRightS />
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export default ShiftsPageBody
