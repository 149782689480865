import React from 'react'
import theme from 'utils/theme'
import { InfoM } from 'icons'
import useFavurTranslation from 'hooks/useFavurTranslation'
import TaskBadge from '..'
import { getClasses } from '../styles'
import { ITaskBadgeProps } from '../types'

const getTooltipKey = (withTooltip: boolean, isOffice: boolean) => {
  if (!withTooltip) return undefined
  return isOffice
    ? `workTimeControl.sentAutomatedReminder.tooltipManager`
    : `workTimeControl.sentAutomatedReminder.tooltipFrontliner`
}

const SentAutomatedReminder: React.FC<ITaskBadgeProps> = ({ withTooltip = false, isOffice = false }) => {
  const { t } = useFavurTranslation()
  const tooltipKey = getTooltipKey(withTooltip, isOffice)
  return (
    <TaskBadge
      text={t('timeline.badge.sentAutomatedReminder')}
      sx={getClasses(withTooltip)}
      icon={<InfoM fill={theme.palette.primary.main} />}
      tooltipText={tooltipKey && t(tooltipKey)}
    />
  )
}

export default SentAutomatedReminder
