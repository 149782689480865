import React, { useContext } from 'react'
import { queryCache } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Box, TextField } from '@mui/material'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { UseFormReturn } from 'react-hook-form'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import Card from 'components/Basics/Card'
import BirthDateInput, { TBirthDateInputs } from 'components/Forms/components/BirthDateInput'
import Grid from 'components/Grid'
import ActionButtons from 'components/Buttons/ActionButtons'
import FlashMessagesContext from 'services/FlashMessages/context'
import useJamesMutation from 'hooks/useJamesMutation'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useUserDataForm, { Inputs } from 'hooks/useUserDataForm'
import { defaultPhoneNumberCountryCode } from 'constants/i18n'
import { formClasses } from 'pages/UserSettings/styles'
import { uiDateFormat } from 'utils/constants'
import { classes } from './styles'

const PersonalDataForm: React.FC<Inputs & { phone: string }> = ({ phone, firstName, lastName, birthDate }) => {
  const history = useHistory()
  const { t } = useFavurTranslation()
  const { setFlashMessage } = useContext(FlashMessagesContext)

  const [updateUserDataMutation] = useJamesMutation<{
    firstName: string
    lastName: string
    birthDate: string
  }>('updateUserData(firstName: "#firstName", lastName: "#lastName", birthDate: "#birthDate") { success }')

  const form = useUserDataForm({
    defaultValues: {
      firstName,
      lastName,
      birthDate: getFormattedFromDate(newDateWithoutTime(birthDate), uiDateFormat),
    },
    onSubmit: (data) =>
      updateUserDataMutation(data).then(() => {
        queryCache.invalidateQueries((query) => query?.queryKey?.[0]?.toString().startsWith('user'))
        setFlashMessage('page.userSettings.personalData.updateSuccessMessage', 2000)
      }),
  })

  const {
    register,
    formState: { errors },
    onSubmit,
  } = form

  const favurRegister = (name: 'firstName' | 'lastName' | 'birthDate' | 'termsAndConditions') => {
    const { ref, ...registered } = register(name)
    return {
      ...registered,
      inputRef: ref,
    }
  }

  const { countryCallingCode, nationalNumber = '' } =
    parsePhoneNumberFromString(phone || '', defaultPhoneNumberCountryCode) || {}

  return (
    <Box component="form" sx={(formClasses.container, { paddingTop: '16px' })}>
      <Card p={1}>
        <Grid container data-testid="personal-data-form">
          <Grid sx={classes.gridForFormField} col={4}>
            <TextField
              variant="standard"
              fullWidth
              id="user-personal-data_first-name"
              label={t('page.userSettings.personalData.firstName')}
              {...favurRegister('firstName')}
              error={Boolean(errors.firstName)}
              helperText={errors.firstName?.message && t(errors.firstName?.message)}
              data-testid="user-personal-data_first-name"
            />
          </Grid>
          <Grid sx={classes.gridForFormField} col={4}>
            <TextField
              variant="standard"
              fullWidth
              id="user-personal-data_last-name"
              label={t('page.userSettings.personalData.lastName')}
              {...favurRegister('lastName')}
              error={Boolean(errors.lastName)}
              helperText={errors.lastName?.message && t(errors.lastName?.message)}
              data-testid="user-personal-data_last-name"
            />
          </Grid>
          <Grid sx={classes.gridForFormField} col={4}>
            <BirthDateInput
              disabled
              {...((form as unknown) as UseFormReturn<TBirthDateInputs>)}
              label={t('page.userSettings.personalData.birthDate')}
            />
          </Grid>

          <Grid sx={classes.gridForFormField} col={4}>
            <TextField
              variant="standard"
              disabled
              fullWidth
              id="user-personal-data_phone-number"
              label={t('page.userSettings.personalData.phoneNumber')}
              value={nationalNumber}
              InputProps={{
                startAdornment: (
                  <Box sx={formClasses.countryCode}>{countryCallingCode ? `+${countryCallingCode}` : ''}</Box>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ marginTop: '32px', marginLeft: '8px' }}>
          <ActionButtons
            cancelAction={history.goBack}
            cancelLabel={t('page.userSettings.personalData.actions.cancel')}
            validateLabel={t('page.userSettings.personalData.actions.save')}
            validateAction={onSubmit}
          />
        </Box>
      </Card>
    </Box>
  )
}

export default PersonalDataForm
