import React from 'react'
import { Box, Typography } from '@mui/material'
import Switch from 'components/Switch'
import Card from 'components/Basics/Card'
import { classes } from './styles'

interface IDashboardCardToggleProps {
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  title: string
  subtitle?: string
  disabled?: boolean
  name?: string
  icon?: React.JSX.Element
}

const CardToggle: React.FC<IDashboardCardToggleProps> = ({
  checked,
  onChange,
  title,
  subtitle,
  disabled = false,
  name = 'toggle',
  icon,
}) => {
  return (
    <Card>
      <Box sx={classes.mainContainer}>
        <Box sx={classes.innerContent}>
          {icon}
          <Box sx={classes.textContainer}>
            <Typography variant="body1">{title}</Typography>
            {subtitle && (
              <Typography variant="body2" sx={classes.dashboardCardToggleBody}>
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
        <Switch name={name} disabled={disabled} checked={checked} onChange={onChange} />
      </Box>
    </Card>
  )
}

export default CardToggle
