import { Box } from '@mui/material'
import React, { useState } from 'react'
import { ValidationStatusT, validationStatuses } from 'pages/EmployeeCard/types'
import { useEmployeeCardRevalidate } from 'pages/EmployeeCard/hooks/useEmployeeCardRevalidate'
import { newDateWithoutTime } from 'utils/date'
import { classes } from './styles'
import ValidationCard from './ValidationCard'
import ValidationReload from './ValidationReload'

interface IValidationBlockProps {
  favurUuid: string
}

const ValidationBlock: React.FC<IValidationBlockProps> = ({ favurUuid }) => {
  const [personStatus, setPersonStatus] = useState<ValidationStatusT>(validationStatuses.notValidated)
  const [animating, setAnimating] = useState(false)

  const lastUpdated = newDateWithoutTime()

  const onComplete = (status: string) => {
    setPersonStatus(validationStatuses[status])
    setAnimating(true)
  }
  const { fetch, loading } = useEmployeeCardRevalidate(favurUuid, onComplete)

  return (
    <Box sx={classes.block} data-testid="employee-card_validation-block">
      <ValidationCard
        lastUpdated={lastUpdated}
        status={personStatus}
        animating={animating}
        setAnimating={setAnimating}
      />
      <ValidationReload
        onClick={() => {
          fetch()
        }}
        validated={personStatus !== validationStatuses.notValidated}
        loading={loading}
      />
    </Box>
  )
}

export default ValidationBlock
