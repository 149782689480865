import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'

import CenteredBox from 'components/CenteredBox'
import useDialogs from 'hooks/useDialogs'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useSessionTimeout } from 'hooks/useSessionTimeout'
import routes from 'services/RoutesProvider/routes'
import { SecuritySetupProcessT } from '../../types'
import UserIdentificationPin from '../UserIdentificationPinSvg'
import { classes as securityClasses } from '../../styles'
import { classes } from './styles'

const PinInfo: React.FC<{ goToStep: (stepName: string) => void; securityProcessType: SecuritySetupProcessT }> = ({
  goToStep,
  securityProcessType,
}) => {
  const { t } = useFavurTranslation()
  const { openDialog } = useDialogs()
  const history = useHistory()

  const timeoutAction = useCallback(() => {
    openDialog(securityProcessType)
    history.push(routes.dashboard)
  }, [history, openDialog, securityProcessType])

  // security timeout handler
  useSessionTimeout(securityProcessType, timeoutAction)

  return (
    <CenteredBox sx={[securityClasses.root, securityClasses.spaceBetween]}>
      <Box>
        <Typography variant="h2" sx={securityClasses.title}>
          {t('securitySetup.pinInfo.content.title')}
        </Typography>
        <UserIdentificationPin sx={classes.image} />
        <Typography variant="body1" sx={classes.text}>
          {t('securitySetup.pinInfo.content.text1')}
        </Typography>
        <br />
        <Typography variant="body1" sx={classes.text}>
          {t('securitySetup.pinInfo.content.text2')}
        </Typography>
      </Box>
      <Box>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="secondary"
          sx={securityClasses.actionButton}
          onClick={() => goToStep('PIN_ENTER')}
          data-testid="pin-info-continue-pin-setup"
        >
          {t('securitySetup.pinInfo.button.action')}
        </Button>
      </Box>
    </CenteredBox>
  )
}

export default PinInfo
