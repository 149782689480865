/* eslint-disable complexity */
// remove disable with FF_SHARE_USER_DATA_REDESIGN
import React, { useEffect, useState } from 'react'
import { TextField, Typography } from '@mui/material'
import isEqual from 'lodash/fp/isEqual'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { FormHandlersT } from 'hooks/useFavurForm'
import Grid from 'components/Grid'
import { SimpleLoading } from 'components/LoadingIcon'
import DatePicker from 'components/DatePickers/UncontrolledDatePicker'
import FileSelect from '../components/FileSelect'
import NationalitySelect from '../components/NationalitySelect'
import UncontrolledSelect from '../components/UncontrolledSelect'
import InfoAdornment from '../components/InfoAdornment'
import FormsButtons from '../components/Buttons'
import InfoDialog from '../components/InfoDialog'
import { contactLanguages, genders, residenceCategoryTypes, personalDocumentFileTypes } from './constants'
import { classes } from '../styles'
import { isDomestic } from '../helpers'
import SendShareUserDataCard from '../components/SendShareUserDataCard'
import { useFormsContext } from '../context'
import { PersonalDataApiValuesT, PersonalDataValuesT } from './types'
import type { DataFormT, IDefaultFormProps } from '../types'

const PersonalDataForm: React.FC<IDefaultFormProps> = ({
  changeTabTrigger,
  onCancel,
  setChangeTabTrigger,
  setIsFormDirty,
  accessPoint,
}) => {
  const { t } = useFavurTranslation()
  const [openSocialSecurityDialog, setOpenSocialSecurityDialog] = useState(false)
  const [openResidenceDialog, setOpenResidenceDialog] = useState(false)
  const { getPersonalDataForm, onPersonalDataSubmit, getIncludeFiles } = useFormsContext()

  const { favurForm, formHandlers, defaultValues, formReady } = getPersonalDataForm() as DataFormT<
    PersonalDataApiValuesT,
    PersonalDataValuesT
  >
  // eslint-disable-next-line fp/no-mutating-methods
  const watchNationality = favurForm.watch('nationality')

  useEffect(() => {
    if (setIsFormDirty) {
      setIsFormDirty(favurForm.formState.isDirty)
    }
  }, [favurForm.formState.isDirty, setIsFormDirty])

  useEffect(() => {
    if (changeTabTrigger && setChangeTabTrigger) {
      favurForm.handleSubmit()
      setChangeTabTrigger(false)

      /*
       * The problem is, because how FileSelect works, it sets que form as "dirty.",
       * even if the data is fine on the first load.
       *
       * So, if the form is dirty in "EmploymentTabPanel", updates changeTabTrigger
       * to true and executes this code, trying to save the changes.
       *
       * But the ideal behaviour is:
       * - If you try to change the tab and and the form is dirty, try to save the changes and:
       *   - If there is an error -> stay on the same page (the isEqual check with the form errors)
       *   - If there are no errors -> switch to the new tab (onSubmitCallback call)
       */
      if (isEqual(favurForm.formState.errors, {})) {
        onPersonalDataSubmit()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTabTrigger])

  if (!formReady) {
    return <SimpleLoading />
  }

  const { passportFront, passportBack, residencePermitFront, residencePermitBack } = personalDocumentFileTypes

  return (
    <>
      <Grid container data-testid="personal-data-form">
        <Grid sx={classes.gridForFormField} col={4}>
          <TextField
            variant="standard"
            required
            fullWidth
            {...formHandlers('firstName')}
            inputProps={{ 'data-testid': 'user-personal-data_first-name' }}
          />
        </Grid>
        <Grid sx={classes.gridForFormField} col={4}>
          <TextField
            variant="standard"
            required
            fullWidth
            {...formHandlers('lastName')}
            inputProps={{ 'data-testid': 'user-personal-data_last-name' }}
          />
        </Grid>
        <Grid sx={classes.gridForFormField} col={4}>
          <TextField
            variant="standard"
            fullWidth
            {...formHandlers('nickname')}
            inputProps={{ 'data-testid': 'user-personal-data_nickname' }}
          />
        </Grid>
        <Grid sx={classes.gridForFormField} col={4}>
          <TextField
            variant="standard"
            disabled
            fullWidth
            {...formHandlers('birthDate')}
            defaultValue={defaultValues?.birthDate}
            inputProps={{ 'data-testid': 'user-personal-data_birthdate' }}
          />
        </Grid>
        <Grid sx={classes.gridForFormField} col={4}>
          <UncontrolledSelect
            translate
            sortOptions
            defaultValue={defaultValues?.gender}
            formHandlers={formHandlers('gender')}
            name="gender"
            options={genders}
            required
          />
        </Grid>
        <Grid sx={classes.gridForFormField} col={4}>
          <NationalitySelect
            sortOptions
            defaultValue={defaultValues?.nationality}
            inputName="nationality"
            formHandlers={formHandlers('nationality')}
          />
        </Grid>
        <Grid sx={classes.gridForFormField} col={4}>
          <UncontrolledSelect
            translate
            sortOptions
            defaultValue={defaultValues?.contactLanguage}
            formHandlers={formHandlers('contactLanguage')}
            name="contactLanguage"
            options={contactLanguages}
            required
          />
        </Grid>
        <Grid sx={classes.gridForFormField} col={4}>
          <TextField
            variant="standard"
            required
            fullWidth
            {...formHandlers('placeOfOrigin')}
            inputProps={{ 'data-testid': 'user-personal-data_place-of-origin' }}
          />
        </Grid>
        <Grid sx={classes.gridForFormField} col={4}>
          <TextField
            variant="standard"
            required={isDomestic(watchNationality ?? '')}
            fullWidth
            {...formHandlers('socialInsuranceNumber')}
            InputProps={{
              endAdornment: (
                <InfoAdornment type="input" open={openSocialSecurityDialog} setOpen={setOpenSocialSecurityDialog} />
              ),
            }}
            inputProps={{ 'data-testid': 'user-personal-data_social-insurance-number' }}
          />
          <InfoDialog
            open={openSocialSecurityDialog}
            action={() => setOpenSocialSecurityDialog(false)}
            text={t('personalData.socialInsuranceNumber.dialog')}
          />
        </Grid>
        <Grid sx={classes.gridForFormField} col={4}>
          <TextField
            variant="standard"
            required={isDomestic(watchNationality ?? '')}
            fullWidth
            {...formHandlers('healthInsurance')}
            inputProps={{ 'data-testid': 'user-personal-data_health-insurance' }}
          />
        </Grid>
        {!isDomestic(watchNationality ?? '') && (
          <>
            <Grid sx={classes.gridForTitle} col={4}>
              <Typography variant="subtitle1">{t('personalData.IdentityDocument.title')}</Typography>
            </Grid>
            <Grid sx={classes.gridForSubtitle} col={4}>
              <Typography variant="subtitle2">{t('personalData.residencePermit.title')}</Typography>
              <InfoAdornment type="title" open={openResidenceDialog} setOpen={setOpenResidenceDialog} />
              <InfoDialog
                open={openResidenceDialog}
                action={() => setOpenResidenceDialog(false)}
                text={t('personalData.residence.dialog')}
              />
            </Grid>
            <Grid col={2}>
              <UncontrolledSelect
                defaultValue={defaultValues?.residenceCategoryType}
                formHandlers={formHandlers('residenceCategoryType')}
                name="residenceCategoryType"
                options={residenceCategoryTypes}
                required
              />
            </Grid>
            <Grid col={2}>
              <DatePicker
                formField="residenceCategoryValidUntil"
                dataTestId="user-personal-data_resicence-permit-valid-until"
                favurForm={favurForm}
                formHandlers={formHandlers('residenceCategoryValidUntil') as FormHandlersT}
              />
            </Grid>
            <Grid sx={classes.gridForFormField} col={4}>
              <TextField
                variant="standard"
                required
                fullWidth
                {...formHandlers('residenceCategoryZemisNumber')}
                inputProps={{ 'data-testid': 'user-personal-data_resicence-zemis_number' }}
              />
            </Grid>
            {getIncludeFiles() && (
              <>
                <Grid sx={classes.gridForTitle} col={4}>
                  <Typography variant="subtitle1">{t('personalData.uploadedDocuments.title')}</Typography>
                </Grid>
                <Grid sx={[classes.gridForSubtitle, classes.gridForFileTitle]} col={4}>
                  <Typography variant="subtitle2">{t('personalData.residencePermit.title')}</Typography>
                </Grid>
                <Grid sx={classes.gridForFormField} container col={4}>
                  <FileSelect
                    /* @ts-ignore */
                    form={favurForm}
                    name="residencePermitFront"
                    filetype={residencePermitFront}
                    translationKey="personalData.button.uploadFileFront"
                  />
                  {favurForm.formState.errors?.residencePermitFront ? (
                    <Typography sx={classes.fileFieldErrorText} variant="caption" color="error">
                      {t('personalData.error.requiredField')}
                    </Typography>
                  ) : null}
                  <FileSelect
                    /* @ts-ignore */
                    form={favurForm}
                    name="residencePermitBack"
                    filetype={residencePermitBack}
                    translationKey="personalData.button.uploadFileBack"
                  />
                </Grid>
                <Grid sx={classes.divider} col={4} />
                <Grid sx={[classes.gridForSubtitle, classes.gridForFileTitle]} col={4}>
                  <Typography variant="subtitle2">{t('personalData.passport.label')}</Typography>
                </Grid>
                <Grid sx={classes.gridForFormField} container col={4}>
                  <FileSelect
                    /* @ts-ignore */
                    form={favurForm}
                    name="passportFront"
                    filetype={passportFront}
                    translationKey="personalData.button.uploadFileFront"
                  />
                  {favurForm.formState.errors?.passportFront ? (
                    <Typography sx={classes.fileFieldErrorText} variant="caption" color="error">
                      {t('personalData.error.requiredField')}
                    </Typography>
                  ) : null}
                  <FileSelect
                    /* @ts-ignore */
                    form={favurForm}
                    name="passportBack"
                    filetype={passportBack}
                    translationKey="personalData.button.uploadFileBack"
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      {accessPoint === 'userSettings' && <SendShareUserDataCard />}
      {accessPoint !== 'shareUserDataWorkflow' && (
        <FormsButtons onCancel={onCancel} onSubmit={favurForm.handleSubmit} accessPoint={accessPoint} />
      )}
    </>
  )
}

export default PersonalDataForm
