import React, { useCallback, useEffect, useState } from 'react'
import { isNative, getNativeVersion } from 'utils/platform'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import useFavurTranslation from 'hooks/useFavurTranslation'
import OldNativeVersionDialogDialog from './Dialog'
import {
  CapacitorPlatformT,
  NativeVersionObjectT,
  NativeVersionRequirementQueryReturnT,
  capacitorPlatformTypes,
} from './types'
import { nativeVersionRequirementQuery } from './queries'
const SHOW_ONLY_ONCE_KEY = 'SHOWN_OLD_NATIVE_VERSION_DIALOG'

const OldNativeVersionDialog: React.FC<{ showOnlyOnce?: boolean }> = ({ showOnlyOnce }) => {
  const { t } = useFavurTranslation()
  const [showDialog, setShowDialog] = useState(false)
  const [nativeVersion, setNativeVersion] = useState<NativeVersionObjectT>()

  useJamesApolloQuery<NativeVersionRequirementQueryReturnT>(nativeVersionRequirementQuery, {
    skip: !nativeVersion,
    variables: {
      platform: nativeVersion?.platform,
      nativeVersionNumber: nativeVersion?.version,
    },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setShowDialog(!data.nativeVersionRequirement.requirementMet)
    },
  })

  const getPlatformText = useCallback(
    (platform: CapacitorPlatformT) => {
      switch (platform) {
        case capacitorPlatformTypes.android:
          return t('common.platform.android')
        case capacitorPlatformTypes.ios:
          return t('common.platform.ios')
        default:
          return ''
      }
    },
    [t],
  )

  useEffect(() => {
    const alreadyShown = localStorage.getItem(SHOW_ONLY_ONCE_KEY) === 'true' && showOnlyOnce

    if (alreadyShown || !isNative()) return

    const doGetNativeVersion = async () => setNativeVersion(await getNativeVersion())

    doGetNativeVersion()
  }, [showOnlyOnce])

  if (!nativeVersion) return null

  return (
    <OldNativeVersionDialogDialog
      open={showDialog}
      onClose={() => {
        setShowDialog(false)
        if (showOnlyOnce) {
          localStorage.setItem(SHOW_ONLY_ONCE_KEY, 'true')
        }
      }}
      platform={getPlatformText(nativeVersion.platform)}
    />
  )
}

export default OldNativeVersionDialog
