import { useEffect, useState } from 'react'
import { QueryStatus } from 'react-query'
import { PersonT, ActiveConnectionResponseT } from 'types'

import useJamesQuery from 'hooks/useJamesQuery'

const useActiveConnections = (): [QueryStatus, { highSec?: PersonT[]; lowSec?: PersonT[] }] => {
  const [lowSec, setLowSec] = useState<PersonT[] | undefined>(undefined)
  const [highSec, setHighSec] = useState<PersonT[] | undefined>(undefined)

  const { data, status } = useJamesQuery<ActiveConnectionResponseT>(
    'employers_connections',
    `{
      connections{
          persons {
            tenantConnectionEstablished
            tenantConnectionStatus
            tenantName
            updatedAt
        }
      }
    }`,
  )

  useEffect(() => {
    if (status === 'success' && data) {
      const persons = data?.connections?.persons ?? []
      setLowSec(persons.filter((value: PersonT) => value.tenantConnectionStatus === 'low'))
      setHighSec(persons.filter((value: PersonT) => value.tenantConnectionStatus === 'high'))
    }
  }, [status, setLowSec, setHighSec, data])

  return [status, { lowSec, highSec }]
}

export default useActiveConnections
