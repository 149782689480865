import React from 'react'
import Svg from 'components/Svg'
import { IconPropsT } from './types'
import { ICON_SIZE_L } from './constants'

const SecurityContactL: React.FC<IconPropsT> = ({ fill }) => {
  return (
    <Svg size={ICON_SIZE_L} fill={fill}>
      <path
        fillRule="evenodd"
        d="M8.14 14.8a4.13 4.13 0 0 1 4.03 4.29l-.02.15.02.2a5.91 5.91 0 0 1-1.37 4.1l-.19.2-.03.15-.01.17-.01.4v.5c1.48.47 2.91 1.07 4.3 1.78a.75.75 0 0 1-.7 1.33 27.82 27.82 0 0 0-4.09-1.7c-1.19-.44-1.28-2.85-.56-3.64.8-.86 1.21-2 1.16-3.17l-.01-.25v-.14a2.62 2.62 0 0 0-2.52-2.88 2.63 2.63 0 0 0-2.56 2.68l.02.2v.14c-.13 1.25.3 2.5 1.15 3.43.68.75.63 3-.44 3.57l-.13.06L5 26.8a.75.75 0 0 1-.61-1.36l.09-.04 1.17-.43v-.03l.01-.13.02-.49v-.26l-.01-.17c-.01-.07-.02-.12-.04-.14a5.9 5.9 0 0 1-1.56-4.3l.02-.21-.01-.15c-.04-1 .27-1.96.88-2.74l.15-.17a4.15 4.15 0 0 1 3.02-1.39zm13.2 4.2l.01.17v.14c-.12 1.26.3 2.5 1.16 3.43.71.78.62 3.2-.6 3.64-1.4.46-2.76 1.02-4.07 1.7a.75.75 0 0 1-.68-1.35 29.2 29.2 0 0 1 4.26-1.77v-.02l.01-.13.02-.49v-.26l-.02-.17c0-.07-.01-.12-.03-.14a5.9 5.9 0 0 1-1.56-4.3l.01-.21V19h1.5zm6.59 0v.09l-.02.15.02.2a5.9 5.9 0 0 1-1.37 4.1l-.19.2v.02l-.02.06-.02.13v.75l.01.22.01.04 1.17.44a.75.75 0 0 1-.42 1.43l-.1-.03-1.17-.43c-1.19-.44-1.28-2.85-.56-3.64.8-.86 1.2-2 1.16-3.17l-.01-.25V19h1.5zM27 4a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-6l-4.19 3.35a.5.5 0 0 1-.81-.39V16h-4a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h15zm-.5 1.5h-14v9h5v2.38l2.97-2.38h6.03v-9zm-7 7.5a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1zm0-6.5a2.5 2.5 0 0 1 .64 4.92l-.14.03v.8c0 .14-.11.25-.25.25h-.5a.25.25 0 0 1-.25-.25V11c0-.28.22-.5.5-.5a1.5 1.5 0 0 0 0-3c-.67 0-1.3.5-1.46 1.16l-.02.14c-.01.13-.1.2-.27.2h-.5c-.17 0-.25-.07-.24-.2a2.5 2.5 0 0 1 2.49-2.3z"
      />
    </Svg>
  )
}

export default SecurityContactL
